import {getDistributionRequest, getPaginatedDistributionsRequest} from '../api/distributions';

import {
  GET_DISTRIBUTION,
  GET_DISTRIBUTION_FAILURE,
  GET_DISTRIBUTION_SUCCESS, GET_DISTRIBUTIONS, GET_DISTRIBUTIONS_ERROR, GET_DISTRIBUTIONS_SUCCESS,
} from './types';

function getDistribution() {
  return { type: GET_DISTRIBUTION }
}

function getDistributionSuccess(json) {
  return{
    type: GET_DISTRIBUTION_SUCCESS,
    payload: json
  }
}

function getDistributionFailure(error) {
  return{
    type: GET_DISTRIBUTION_FAILURE,
    payload: error
  }
}

export default function requestGetDistribution(token, id) {
  return dispatch => {
    dispatch(getDistribution());
    return getDistributionRequest(token, id)
      .then(json => dispatch(getDistributionSuccess(json)))
      .catch((error) => {
        return error.json().then((errors) => {
          return dispatch(getDistributionFailure(errors));
        })
      });
  };
}

export const requestGetPaginatedDistributions = (token, limit = 100, page = 0, orderBy = 'id', order = 'desc', status = null, q = null, userId = null) => dispatch => {
  dispatch({ type: GET_DISTRIBUTIONS });
  return getPaginatedDistributionsRequest(token, limit, page, orderBy, order, status, q, userId)
    .then(json => {
      return dispatch({ type: GET_DISTRIBUTIONS_SUCCESS, payload: json })
    })
    .catch(e => {
      console.log('catch e', e);
      return dispatch({ type: GET_DISTRIBUTIONS_ERROR });
    })
}
