import {mergeState, deleteFromState} from '11online-redux-helpers';

import{
   POST_DENARI_UPLOAD,
   POST_DENARI_UPLOAD_SUCCESS,
   POST_DENARI_UPLOAD_FAILURE,
   GET_ALL_DONATIONS,
   GET_ALL_DONATIONS_SUCCESS,
   GET_ALL_DONATIONS_FAILURE,
   PATCH_DONATION,
   PATCH_DONATION_SUCCESS,
   PATCH_DONATION_FAILURE,
   DELETE_DONATION,
   DELETE_DONATION_SUCCESS,
   DELETE_DONATION_FAILURE,
   GET_ALL_HOLD_ACCOUNTS,
   GET_ALL_HOLD_ACCOUNTS_SUCCESS,
   GET_ALL_HOLD_ACCOUNTS_FAILURE,
   POST_HOLD_ACCOUNT,
   POST_HOLD_ACCOUNT_SUCCESS,
   POST_HOLD_ACCOUNT_FAILURE,
   PATCH_HOLD_ACCOUNT,
   PATCH_HOLD_ACCOUNT_SUCCESS,
   PATCH_HOLD_ACCOUNT_FAILURE,
   DELETE_HOLD_ACCOUNT,
   DELETE_HOLD_ACCOUNT_SUCCESS,
   DELETE_HOLD_ACCOUNT_FAILURE,
  ARCHIVE_HOLD_ACCOUNT_ERROR,
  ARCHIVE_HOLD_ACCOUNT_SUCCESS,
  ARCHIVE_HOLD_ACCOUNT,
  GET_DONATIONS,
  GET_DONATIONS_ERROR,
  GET_DONATIONS_SUCCESS,
} from '../actions/types';

export function reducer( state = {
    isFetching: false,
    donations: {},
    holdAccounts: {},
    recentUploadNames: {}
}, action = {type: ''}) {
   switch (action.type) {
     case GET_DONATIONS:
       return {
         ...state,
         isFetching: true,
       };
     case GET_DONATIONS_SUCCESS:
       return {
         ...state,
         isFetching: false,
         paginatedDonations: action.payload.data || [],
       };
     case GET_DONATIONS_ERROR:
       return {
         ...state,
         isFetching: false,
       };
      case POST_DENARI_UPLOAD:
         return {
               ...state,
               isFetching: true
         };
      case POST_DENARI_UPLOAD_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               donations: mergeState(state.donations, action.payload.donations),
               holdAccounts: mergeState(state.holdAccounts, action.payload.holdAccounts)
         };
      case POST_DENARI_UPLOAD_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false,
         };
      case GET_ALL_DONATIONS:
         return {
               ...state,
               isFetching: true
         };
      case GET_ALL_DONATIONS_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               donations: mergeState(state.donations, action.payload)
         };
      case GET_ALL_DONATIONS_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false,
         };
      case PATCH_DONATION:
         return {
               ...state,
               isFetching: true
         };
     case PATCH_DONATION_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               donations: {
                 ...(state.donations || {}),
                 [action.payload.id]: action.payload,
               },
         };
      case PATCH_DONATION_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false,
         };
      case DELETE_DONATION:
         return {
               ...state,
               isFetching: true
         };
     case DELETE_DONATION_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               holdAccounts: Object.values(state.donations || {}).reduce((acc, x) => {
                 const { id } = x;
                 if (id === action.payload.id) return acc;
                 return { ...acc, [id]: x };
               }, {}),
           paginatedDonations: (state.paginatedDonations || []).filter(({ id }) => id !== action.payload.id),
         };
      case DELETE_DONATION_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false
         };
      case GET_ALL_HOLD_ACCOUNTS:
         return {
               ...state,
               isFetching: true
         };
      case GET_ALL_HOLD_ACCOUNTS_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               holdAccounts: mergeState(state.holdAccounts, action.payload)
         };
      case GET_ALL_HOLD_ACCOUNTS_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false
         };
      case POST_HOLD_ACCOUNT:
            return {
                ...state,
                isFetching: true
            };
      case POST_HOLD_ACCOUNT_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               holdAccounts: mergeState(state.holdAccounts, action.payload)
         };
      case POST_HOLD_ACCOUNT_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false
         };
      case PATCH_HOLD_ACCOUNT:
         return {
               ...state,
               isFetching: true
         };
      case PATCH_HOLD_ACCOUNT_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               holdAccounts: mergeState(state.holdAccounts, action.payload)
         };
      case PATCH_HOLD_ACCOUNT_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false
         };
      case DELETE_HOLD_ACCOUNT:
         return {
               ...state,
               isFetching: true
         };
      case DELETE_HOLD_ACCOUNT_SUCCESS:
         return {
               ...state,
               isFetching: false,
               error: [],
               holdAccounts: deleteFromState(state.holdAccounts, action.payload)
         };
      case DELETE_HOLD_ACCOUNT_FAILURE:
         return {
               ...state,
               error: action.payload,
               isFetching: false
         };
     case ARCHIVE_HOLD_ACCOUNT:
       return {
         ...state,
         isFetching: true,
       };
     case ARCHIVE_HOLD_ACCOUNT_SUCCESS:
       return {
         ...state,
         isFetching: false,
         error: [],
         holdAccounts: {
           ...(state.holdAccounts || {}),
           [action.payload.id]: action.payload,
         }
       }
     case ARCHIVE_HOLD_ACCOUNT_ERROR:
       return {
         ...state,
         isFetching: false,
         error: action.payload,
       };
     default:
       return state;
   }
}
