import React, { Component } from 'react';
import DistributionForm from '../blocks/DistributionForm/DistributionForm';

export default class DistributionFormManager extends Component {
  render() {
    const { params } = this.props,
      { id } = params || {};
    return (
        <DistributionForm id={id} />
    );
  }
}
