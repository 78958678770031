import {mergeState, deleteFromState} from '11online-redux-helpers';

import{
   GET_ALL_ADVANCE_REQUESTS,
   GET_ALL_ADVANCE_REQUESTS_SUCCESS,   
   GET_ALL_ADVANCE_REQUESTS_FAILURE,
   PATCH_ADVANCE_REQUEST,
   PATCH_ADVANCE_REQUEST_SUCCESS,
   PATCH_ADVANCE_REQUEST_FAILURE,
   GET_ALL_ADVANCE_REQUESTS_BY_USER,
   GET_ALL_ADVANCE_REQUESTS_BY_USER_SUCCESS,
   GET_ALL_ADVANCE_REQUESTS_BY_USER_FAILURE,
   POST_ADVANCE_REQUEST,
   POST_ADVANCE_REQUEST_SUCCESS,
   POST_ADVANCE_REQUEST_FAILURE,
	DELETE_ADVANCE_REQUEST,
	DELETE_ADVANCE_REQUEST_SUCCESS,
	DELETE_ADVANCE_REQUEST_FAILURE,
   DELETE_CLOSED_ADVANCE_REQUEST,
   DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS,
   DELETE_CLOSED_ADVANCE_REQUEST_FAILURE,
  GET_ADVANCE_REQUESTS,
  GET_ADVANCE_REQUESTS_ERROR,
  GET_ADVANCE_REQUESTS_SUCCESS,
} from '../actions/types';

export function reducer(state = {
    isFetching: false,
    advanceRequests: {},
    advanceRequestsByUser: {}  
}, action = {type: ''}) {
    switch (action.type) {
      case GET_ADVANCE_REQUESTS:
        return {
          ...state,
          isFetching: true,
        };
      case GET_ADVANCE_REQUESTS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          paginatedAdvanceRequests: action.payload.data || [],
        };
      case GET_ADVANCE_REQUESTS_ERROR:
        return {
          ...state,
          isFetching: false,
        };
        case GET_ALL_ADVANCE_REQUESTS:
            return {
                ...state,
                isFetching: true
            };
        case GET_ALL_ADVANCE_REQUESTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                advanceRequests: mergeState(state.advanceRequests, action.payload)
            };
        case GET_ALL_ADVANCE_REQUESTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case PATCH_ADVANCE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case PATCH_ADVANCE_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                advanceRequests: mergeState(state.advanceRequests, action.payload),
                advanceRequestsByUser: mergeState(state.advanceRequestsByUser, action.payload)
            };
        case PATCH_ADVANCE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_ALL_ADVANCE_REQUESTS_BY_USER:
            return {
                ...state,
                isFetching: true
            };
        case GET_ALL_ADVANCE_REQUESTS_BY_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                advanceRequestsByUser: mergeState({}, action.payload)
            };
        case GET_ALL_ADVANCE_REQUESTS_BY_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case POST_ADVANCE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case POST_ADVANCE_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                advanceRequests: mergeState(state.advanceRequests, action.payload)
            };
        case POST_ADVANCE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
			case DELETE_ADVANCE_REQUEST:
				return {
					...state,
					isFetching: true
				};
			case DELETE_ADVANCE_REQUEST_SUCCESS:
				return {
					...state,
					isFetching: false,
					error: [],
					advanceRequests: deleteFromState(state.advanceRequests, action.payload),
					advanceRequestsByUser: deleteFromState(state.advanceRequestsByUser, action.payload)
				};
			case DELETE_ADVANCE_REQUEST_FAILURE:
				return {
					...state,
					error: action.payload,
					isFetching: false
				};
         case DELETE_CLOSED_ADVANCE_REQUEST:
            return {
               ...state,
               isFetching: true
            };
         case DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS:
            return {
               ...state,
               isFetching: false,
               error: [],
               advanceRequests: deleteFromState(state.advanceRequests, action.payload),
               advanceRequestsByUser: deleteFromState(state.advanceRequestsByUser, action.payload)
            };
         case DELETE_CLOSED_ADVANCE_REQUEST_FAILURE:
            return {
               ...state,
               error: action.payload,
               isFetching: false
            };
        
        default:
            return state;
    }
}
