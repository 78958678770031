import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Input from '../../fields/input';
import Subheader from 'material-ui/Subheader';

const inputStyle = {
   width: '80px',
}

export default class DonationsDialog extends React.Component {

   render() {
      return (
         <Dialog
            modal={true}
            open={this.props.open}
            contentStyle={{width: '40%'}}
         >
            <div className="donations-dialog-container">
               <Subheader className="curved-style">Donation Edit</Subheader>
               <form id="donations-form">
                  <div style={{maxWidth: 80, margin: '0 auto'}}>
                     <Input
                        key="donation-amount-input"
                        className="amount-input"
                        floatingLabelText="Amount"
                        value={this.props.amount}
                        id="amount"
                        prefix="$"
                        style={inputStyle}
                        onChange={amount => this.props.onChange('amount', amount)}
                     />
                  </div>
                  <Input	
                     key="donation-count-input"
                     className="count-input"
                     floatingLabelText="Count"
                     value={this.props.count}
                     id="count"
                     style={inputStyle}
                     onChange={count => this.props.onChange('count', count)}
                  />
               </form>
               <RaisedButton
                  label="Update"
                  className="donation-action-button"
                  backgroundColor="#1E88E5"
                  labelColor="#ffffff"
                  onClick={this.props.onClick}
               />
               <RaisedButton
                  label="Cancel"
                  className="cancel-donation-dialog"
                  backgroundColor="#525252"
                  labelColor="#ffffff"
                  onClick={this.props.handleClose}
               />
            </div>
         </Dialog>
      )
   }
}