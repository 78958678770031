import {deleteDistributionRequest} from '../api/distributions';

import {
   DELETE_DISTRIBUTION,
   DELETE_DISTRIBUTION_SUCCESS,
   DELETE_DISTRIBUTION_FAILURE
} from '../actions/types';


function deleteDistribution() {
    return {
        type: DELETE_DISTRIBUTION
    };
}

function deleteDistributionSuccess(json) {
    return {
        type: DELETE_DISTRIBUTION_SUCCESS,
        payload: json
    };
}

function deleteDistributionFailure(error) {
    return {
        type: DELETE_DISTRIBUTION_FAILURE,
        payload: error
    };
}

export default function requestDeleteDistribution(id, token) {
    return dispatch => {
        dispatch(deleteDistribution());
        return deleteDistributionRequest(id, token)
            .then((json) => {
                return dispatch(deleteDistributionSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(deleteDistributionFailure(errors));
                })
            });
    };
}