import React, {Component} from 'react';
import {Link} from 'react-router';
// import Icon from '../blocks/icon';
import MenuItem from 'material-ui/MenuItem';

class DynamicMenuItem extends Component {


    render() {
        return (
            <Link to={this.props.to}>
                <MenuItem id={this.props.id} onClick={this.props.onClick} leftIcon={this.props.leftIcon} >{this.props.name}</MenuItem>
            </Link>
        )
    }

}

export default DynamicMenuItem;