import {getAllAdvanceRequestsRequest, getPaginatedAdvanceRequestsRequest} from '../api/advance-requests';

import {
    GET_ALL_ADVANCE_REQUESTS,
    GET_ALL_ADVANCE_REQUESTS_SUCCESS,
    GET_ALL_ADVANCE_REQUESTS_FAILURE, GET_ADVANCE_REQUESTS, GET_ADVANCE_REQUESTS_SUCCESS, GET_ADVANCE_REQUESTS_ERROR
} from './types';


function getAllAdvanceRequests(){
    return{
        type: GET_ALL_ADVANCE_REQUESTS
    }
}

function getAllAdvanceRequestsSuccess(json){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_SUCCESS,
        payload: json
    }
}

function getAllAdvanceRequestsFailure(error){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_FAILURE,
        payload: error
    }
}


export default function requestGetAllAdvanceRequests(token) {
    return dispatch => {
        dispatch(getAllAdvanceRequests());
        return getAllAdvanceRequestsRequest(token)
            .then((json) => {
                return dispatch(getAllAdvanceRequestsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllAdvanceRequestsFailure(errors));
                })
            });
    };
}

export const requestGetPaginatedAdvanceRequests = (token, params) => dispatch => {
    dispatch({ type: GET_ADVANCE_REQUESTS });
    return getPaginatedAdvanceRequestsRequest(token, params)
      .then(json => {
          return dispatch({ type: GET_ADVANCE_REQUESTS_SUCCESS, payload: json });
      })
      .catch(e => {
          return dispatch({ type: GET_ADVANCE_REQUESTS_ERROR });
      })
}
