import React from 'react';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';
import DropDownSelect from '../../fields/dropdown-select-field';
import DatePicker from 'material-ui/DatePicker';
import IconButton from 'material-ui/IconButton';
import Icon from '../../blocks/icon';
import {selectEndDate, selectStartDate} from "../../../reducers/distribution-form";
import { setEndDate, setStartDate } from "../../../actions/distribution-form";
import { requestGetSearchUsers } from '../../../actions/get-active-users';
import moment from 'moment';
import Cookies from 'cookies-js';
import Actions from '../../../actions';
import _ from 'lodash';

class Filters extends React.PureComponent {
    state = {
        userSearch: undefined,
    };

    componentWillUnmount = () => this.debouncedSearchUsers.flush;

    debouncedSearchUsers = _.debounce(filters => this.props.searchUsers(filters), 500);

    missionaryOptions = () => (this.props.users || []).map(user => ({ value: user.id, text: `${user.name} (${user.email})` }));

    donationUploadOptions = () => {
        const { user = {} } = this.props;
        if (!user || !('donations' in user)) return [];
        return [ ...new Set(user.donations.map(x => x.upload_name)) ].map(name => ({ value: name, text: name }));
    };

    onDateChange = func => (event, date) => func(date ? moment(date).unix() : null);

    clearDates = () => {
        this.props.setStartDate(null);
        this.props.setEndDate(null);
    };

    onUserSearch = search => {
        if (this.props.user) this.props.resetDistributionForm();
        if (!search || search.length < 2) return;
        const filters = { role: 'missionary', q: search.trim().toLowerCase() };
        this.debouncedSearchUsers(filters);
    }

    onUserSelect = val => {
        if (val && 'value' in val) this.props.onUserSelect(val.value);
    }

    render = () => {
        const styles = {
            container: {
                display: 'flex',
                flexDirection: 'column',
            },
            row: {
                display: 'flex',
                alignItems: 'center',
                padding: 5,
            },
            input: {
                margin: 5,
            }
        };

        const { startDate, endDate, user, distributionId, readOnly } = this.props,
          { name: userName } = user || {};
        const donationOpts = this.donationUploadOptions();
        let { userSearch: searchTxt } = this.state;
        if (distributionId && distributionId.length) searchTxt = userName;
        else if (typeof userName === 'string' && searchTxt === undefined) searchTxt = userName;

        return (
            <div style={styles.container}>
                <div style={styles.row}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <AutoComplete
                          id="user_id"
                          floatingLabelText="Select Missionary"
                          dataSource={this.missionaryOptions()}
                          onNewRequest={this.onUserSelect}
                          openOnFocus={true}
                          filter={AutoComplete.caseInsensitiveFilter}
                          onUpdateInput={this.onUserSearch}
                          style={styles.input}
                          searchText={searchTxt}
                          animated={true}
                          disabled={!!(readOnly || distributionId && distributionId.length)}
                          listStyle={{
                              width: 500
                          }}
                        />
                        <small style={{ color: 'gray' }}>Begin typing to search missionaries...</small>
                    </div>
                    <DropDownSelect
                        id="donation_upload_name"
                        floatingLabelText="Select Donation Upload"
                        floatingLabelStyle={{left: 0}}
                        options={donationOpts}
                        value={this.props.donationUpload}
                        onChange={this.props.onDonationUploadSelect}
                        disabled={readOnly || !this.props.user || !donationOpts || !donationOpts.length}
                        style={styles.input}
                    />
                </div>
                <div style={styles.row}>
                    <DatePicker
                        id="start_date"
                        floatingLabelText="From Date"
                        value={startDate}
                        maxDate={endDate ? endDate : null}
                        onChange={this.onDateChange(this.props.setStartDate)}
                        autoOk={true}
                        disabled={readOnly || !this.props.user}
                        style={styles.input}
                    />
                    <DatePicker
                        id="end_date"
                        floatingLabelText="To Date"
                        value={endDate}
                        minDate={startDate ? startDate : null}
                        onChange={this.onDateChange(this.props.setEndDate)}
                        autoOk={true}
                        disabled={readOnly || !this.props.user}
                        style={styles.input}
                    />
                    <IconButton onClick={this.clearDates} disabled={readOnly}>
                        <Icon type="backspace" color='red' />
                    </IconButton>
                </div>
            </div>
        )
    }
}

export default connect(state => {
    const start = selectStartDate(state),
        end = selectEndDate(state);
    return {
        startDate: start ? moment.unix(start).toDate() : start,
        endDate: end ? moment.unix(end).toDate() : end,
    };
}, {
    setStartDate,
    setEndDate,
    searchUsers: (filters) => Actions.requestGetSearchUsers(Cookies.get('token'), filters),
})(Filters);
