import {getAllSettingsRequest} from '../api/settings';

import{
    GET_ALL_SETTINGS,
    GET_ALL_SETTINGS_SUCCESS,
    GET_ALL_SETTINGS_FAILURE
} from './types';


function getAllSettings(){
    return{
        type: GET_ALL_SETTINGS
    }
}

function getAllSettingsSuccess(json){
    return{
        type: GET_ALL_SETTINGS_SUCCESS,
        payload: json
    }
}

function getAllSettingsFailure(error){
    return{
        type: GET_ALL_SETTINGS_FAILURE,
        payload: error
    }
}

export default function requestGetAllSettings(token) {
    return dispatch => {
        dispatch(getAllSettings());
        return getAllSettingsRequest(token)
            .then((json) => {
                return dispatch(getAllSettingsSuccess(json));
            })
            .catch((error) => {
            console.log(error);
                return error.json().then((errors) => {
                    return dispatch(getAllSettingsFailure(errors));
                })
            });
    };
}