import React, {Component} from 'react';
import DonationsTable from '../blocks/donations/donations-table';

class AdminDonations extends Component {
   render() {
      return (
         <DonationsTable admin={true} userId={null}/>
      )
   }
}

export default AdminDonations;