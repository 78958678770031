import {restoreArchivedUserRequest} from '../api/users';

import{
    RESTORE_ARCHIVED_USER,
    RESTORE_ARCHIVED_USER_SUCCESS,
    RESTORE_ARCHIVED_USER_FAILURE
} from './types';

function restoreArchivedUser(){
    return{
        type: RESTORE_ARCHIVED_USER
    };
}

function restoreArchivedUserSuccess(json){
    return{
        type: RESTORE_ARCHIVED_USER_SUCCESS,
        payload: json
    }
}

function restoreArchivedUserFailure(error){
    return{
        type: RESTORE_ARCHIVED_USER_FAILURE,
        payload: error
    };
}

export default function requestRestoreArchivedUser(formData, token) {
    return dispatch => {
        dispatch(restoreArchivedUser());
        return restoreArchivedUserRequest(formData, token)
            .then((json) => {
                return dispatch(restoreArchivedUserSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(restoreArchivedUserFailure(errors));
                })
            });
    };
}

