import {updatePasswordRequest} from '../api/users';


import{
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE
} from '../actions/types';

function updatePassword() {
    return {
        type: UPDATE_PASSWORD
    };
}

function updatePasswordSuccess(json) {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        payload: json
    };
}

function updatePasswordFailure(error) {
    return {
        type: UPDATE_PASSWORD_FAILURE,
        payload: error
    };
}

export default function requestUpdatePassword(id, formData, token) {
    return dispatch => {
        dispatch(updatePassword());
        return updatePasswordRequest(id, formData, token)
            .then((json) => {
                return dispatch(updatePasswordSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(updatePasswordFailure(errors));
                })
            });
    };
}