import {
    NEW_ERROR
} from '../actions/types';

function showError(message) {
    return {
        type: NEW_ERROR,
        message: message
    };
}

export default function newError(message) {
    return dispatch => {
        dispatch(showError(message));
    };
}