import {deleteCategoryRequest} from '../api/categories';

import{
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE
} from './types';

function deleteCategory(){
	return{
      type: DELETE_CATEGORY
   }
}

function deleteCategorySuccess(json){
   return{
		type: DELETE_CATEGORY_SUCCESS,
		payload: json
   }
}

function deleteCategoryFailure(error){
   return{
		type: DELETE_CATEGORY_FAILURE,
		payload: error
   }
}

export default function requestDeleteCategory(id, token) {
    return dispatch => {
      dispatch(deleteCategory());
      return deleteCategoryRequest(id, token)
			.then((json) => {
					return dispatch(deleteCategorySuccess(json));
			})
			.catch((error) => {
					return error.json().then((errors) => {
						return dispatch(deleteCategoryFailure(errors));
					})
			});
    };
}