import {mergeState, deleteFromState} from '11online-redux-helpers';

import{
    GET_ARCHIVED_USERS,
    GET_ARCHIVED_USERS_SUCCESS,
    GET_ARCHIVED_USERS_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    RESTORE_ARCHIVED_USER,
    RESTORE_ARCHIVED_USER_SUCCESS,
    RESTORE_ARCHIVED_USER_FAILURE


} from '../actions/types';


export function reducer(state = {
    isFetching: false,
    users: {}
}, action = {type: ''}) {
    switch (action.type) {
        case GET_ARCHIVED_USERS:
            return {
                ...state,
                isFetching: true
            };
        case GET_ARCHIVED_USERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: mergeState(state.users, action.payload)
            };
        case GET_ARCHIVED_USERS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                users: {}
            };
        case DELETE_USER:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: deleteFromState(state.users, action.payload)
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case RESTORE_ARCHIVED_USER:
            return {
                ...state,
                isFetching: true
            };
        case RESTORE_ARCHIVED_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: deleteFromState(state.users, action.payload)
            };
        case RESTORE_ARCHIVED_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };


        default:
            return state;
    }
}

