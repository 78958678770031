import { connect } from 'react-redux';
import {
  setDistributionFormUserId,
  setDonationUpload,
  resetDistributionForm,
  setDistribution,
  setSocialSecurity,
  setMedicare,
  addHoldAccountTransaction,
  rebuildDistribution,
  submitDistribution,
} from "../../../actions/distribution-form";
import {
  selectCompleteSteps,
  selectDistributableFunds,
  selectDistributionFormUser,
  selectDistributionFormUserId,
  selectDonationUpload, selectEndingBalance,
  selectHoldAccounts, selectProcessedExpenseReports,
  selectTotalDonations, selectTotalExpenses, selectTotalPayroll
} from "../../../reducers/distribution-form";
import Cookies from 'cookies-js';
import Actions from '../../../actions';
import {parseDecimalString} from "../../../utilities/string-helpers";
import {distributionReadOnly} from "../../../utilities/distribution-form-helpers";

const mapStateToProps = (state) => {
  const user = selectDistributionFormUser(state),
    { profile } = user || {},
    { tax_status: taxStatus, fund_balance: fundBalance } = profile || {},
    { distribution } = state.rootReducer.distributionForm;

  const holdAccounts = selectHoldAccounts(state);

  return {
    userId: selectDistributionFormUserId(state),
    user: selectDistributionFormUser(state),
    donationUpload: selectDonationUpload(state),
    users: state.rootReducer.users.searchResults || [],
    loading: state.rootReducer.users.isFetching || state.rootReducer.distributions.isFetching,
    taxStatus,
    fundBalance: parseDecimalString(fundBalance),
    holdAccounts: Object.keys(holdAccounts || {}).length ? Object.values(holdAccounts) : [],
    totalDonations: selectTotalDonations(state),
    endingBalance: selectEndingBalance(state),
    totalExpenses: selectTotalExpenses(state),
    distributableFunds: selectDistributableFunds(state),
    processedExpenseReports: selectProcessedExpenseReports(state),
    completeSteps: selectCompleteSteps(state),
    totalPayroll: selectTotalPayroll(state),
    readOnly: distributionReadOnly(state.rootReducer.auth.user, distribution)
  }
};

const mapDispatchToProps = {
  setDistributionFormUserId,
  setDonationUpload,
  resetDistributionForm,
  setDistribution,
  setSocialSecurity,
  setMedicare,
  addHoldAccountTransaction,
  getUser: userId => Actions.requestGetUser(userId, Cookies.get('token')),
  getAllSettings: () => Actions.requestGetAllSettings(Cookies.get('token')),
  getDistribution: (id) => Actions.requestGetDistribution(Cookies.get('token'), id),
  rebuildDistribution,
  submitDistribution,
  newMessage: msg => Actions.newMessage(msg),
  newError: msg => Actions.newError(msg),
};

export default connect(mapStateToProps, mapDispatchToProps);
