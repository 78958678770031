import {getAllHoldAccountsRequest} from '../api/hold-accounts';

import{
   GET_ALL_HOLD_ACCOUNTS,
   GET_ALL_HOLD_ACCOUNTS_SUCCESS,
   GET_ALL_HOLD_ACCOUNTS_FAILURE
} from './types';


function getAllHoldAccounts(){
    return{
        type: GET_ALL_HOLD_ACCOUNTS
    }
}

function getAllHoldAccountsSuccess(json){
    return{
        type: GET_ALL_HOLD_ACCOUNTS_SUCCESS,
        payload: json
    }
}

function getAllHoldAccountsFailure(error){
    return{
        type: GET_ALL_HOLD_ACCOUNTS_FAILURE,
        payload: error
    }
}

export default function requestGetAllHoldAccounts(token) {
    return dispatch => {
        dispatch(getAllHoldAccounts());
        return getAllHoldAccountsRequest(token)
            .then((json) => {
                return dispatch(getAllHoldAccountsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllHoldAccountsFailure(errors));
                })
            });
    };
}