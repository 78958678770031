import {patchUserRequest} from '../api/users';

import{
    PATCH_USER,
    PATCH_USER_SUCCESS,
    PATCH_USER_FAILURE
} from './types';


function patchUser(){
    return{
        type: PATCH_USER
    }
}

function patchUserSuccess(json){
    return{
        type: PATCH_USER_SUCCESS,
        payload: json
    }
}

function patchUserFailure(error){
    return{
        type: PATCH_USER_FAILURE,
        payload: error
    }
}


export default function requestPatchUser(user_id, formData, token) {
    return dispatch => {
        dispatch(patchUser());
        return patchUserRequest(user_id, formData, token)
            .then((json) => {
                return dispatch(patchUserSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchUserFailure(errors));
                })
            });
    };
}
