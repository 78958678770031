import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Actions from '../../actions';
import PaginatedTable from './PaginatedTable';
import { withLoading } from './with-loading';
import moment from 'moment';
import _ from 'lodash';
import AdvanceProcessModal from './advance-process-modal';
import ButtonLink from './button-link';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import Dialog from 'material-ui/Dialog';
import {
  DELETE_ADVANCE_REQUEST_FAILURE,
  DELETE_ADVANCE_REQUEST_SUCCESS,
  DELETE_CLOSED_ADVANCE_REQUEST_FAILURE,
  DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS
} from '../../actions/types';
import DeleteButton from './delete-button';

const buttonStyle = {
  width: '7em',
  margin: '2px',
}

class AdvanceRequestsTable extends Component {
  _isMounted = false;

  state = {
    toDelete: undefined,
    page: 0,
    limit: 10,
    total_count: undefined,
    sort: { column: 'id', order: 'desc' },
    q: undefined,
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAdvanceRequests();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAdvanceRequests() {
    this.props.dispatch(Actions.requestGetPaginatedAdvanceRequests(
      Cookies.get('token'),
      {
        limit: this.state.limit,
        page: this.state.page,
        order_by: this.state.sort.column,
        order: this.state.sort.order,
        ...(this.state.q && this.state.q.length ? { q: this.state.q } : {}),
        ...(this.props.status ? { status: this.props.status } : {}),
      }
    )).then(json => {
      this.setStateIfMounted({ total_count: json.payload.total_count });
    })
  }

  setStateIfMounted = (updates, callback) =>
    this._isMounted ? this.setState(updates, () => callback ? callback() : undefined) : undefined;

  onNextPageClick = () => this.setStateIfMounted({ page: this.state.page + 1 }, () => {
    this.getAdvanceRequests();
  })

  onPreviousPageClick = () => this.setStateIfMounted({ page: this.state.page - 1 }, () => {
    this.getAdvanceRequests();
  })

  onRowSizeChange = (limit) => this.setStateIfMounted({ limit }, () => this.getAdvanceRequests());

  onSortOrderChange = (column, order) => this.setStateIfMounted({
    sort: {
      column,
      order
    }
  }, () => this.getAdvanceRequests());

  onFilterValueChange = q => this.setStateIfMounted({ q, page: 0 }, () => this.getAdvanceRequests());

  onRowSelection = rows => this.setStateIfMounted({ selectedRows: rows });

  archiveAdvanceRequest = () => {
    if (!this.state.toDelete) return;
    this.props.dispatch(Actions.requestDeleteClosedAdvanceRequest(this.state.toDelete.id, Cookies.get('token')))
      .then(result => {
        if (result.type === DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS) {
          this.props.dispatch(Actions.newMessage('You have successfully archived the advance request.'));
          this.getAdvanceRequests();
          this.handleClose();
        } else if (result.type === DELETE_CLOSED_ADVANCE_REQUEST_FAILURE) {
          this.props.dispatch(Actions.newError('Something went wrong.'));
        }
      });
  }

  deleteAdvanceRequest(advance_request_id) {
    this.props.dispatch(Actions.requestDeleteAdvanceRequest(advance_request_id, Cookies.get('token')))
      .then((result) => {
        if (result.type === DELETE_ADVANCE_REQUEST_SUCCESS) {
          this.props.dispatch(Actions.newMessage('You have successfully deleted the advance request.'));
          this.getAdvanceRequests();
        } else if (result.type === DELETE_ADVANCE_REQUEST_FAILURE) {
          this.props.dispatch(Actions.newError('Something went wrong.'));
        }
      });
  }

  getButtons = (advanceRequest) => {
    const processButton = <AdvanceProcessModal
      key={'process' + advanceRequest.id}
      advanceRequestSingle={advanceRequest}
      incrementForceUpdate={this.getAdvanceRequests}
    />;
    const viewButton = <ButtonLink
      key={'view' + advanceRequest.id}
      label="View"
      labelColor="#ffffff"
      backgroundColor='#525252'
      style={buttonStyle}
      to={'/expense-report/' + advanceRequest.expense_report_id}
    />
    const archiveButton = <RaisedButton
      key={'archive' + advanceRequest.id}
      label='Archive'
      labelColor='#ffffff'
      backgroundColor='#e60000'
      style={buttonStyle}
      onClick={() => this.openDeleteDialogBox(advanceRequest)}
    />
    const deleteButton = (
      <DeleteButton
        key={ 'delete' + advanceRequest.id }
        label='Delete'
        labelColor='#ffffff'
        backgroundColor='#e60000'
        style={ buttonStyle }
        message="Delete this advance request?"
        noActions={!!advanceRequest.distribution_id}
        onClick={() => !!advanceRequest.distribution_id ? undefined : this.deleteAdvanceRequest(advanceRequest.id)}
        body={!advanceRequest.distribution_id
          ? undefined
          : <div>
            <p>
              This advance request is referenced in this
              <a href={`/admin/distribution-form/${advanceRequest.distribution_id}`}> distribution</a>.
            </p>
            <p>Please delete or modify the distribution before deleting this advance request.</p>
          </div>
        }
      />
    )
    let buttons = [];
    if (advanceRequest.status === 'requested') buttons.push(processButton);
    if (advanceRequest.status === 'submitted' || advanceRequest.status === 'closed') buttons.push(viewButton)
    if (advanceRequest.status === 'closed') buttons.push(archiveButton)
    if (advanceRequest.status === 'requested') buttons.push(deleteButton);
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {buttons}
      </div>
    );
  }

  tableRows = () => (this.props.advanceRequests || []).map(x => ({
    id: x.id,
    name: x.user_name,
    missionary_number: x.missionary_number,
    mat_date: x.mat_date && x.mat_date !== '0000-00-00' ? moment(x.mat_date).format('MM/DD/YYYY') : '',
    amount_requested: '$' + x.amount_requested.toFixed(2),
    amount_approved: x.amount_approved ? '$' + (x.amount_approved).toFixed(2) : null,
    status: _.upperFirst(x.status),
    buttons: this.getButtons(x),
  }))

  tableCols = () => [
    { key: 'id', label: 'ID#', 'sortable': true },
    { key: 'name', label: 'Name', 'sortable': true },
    { key: 'missionary_number', label: 'Account #', 'sortable': true },
    { key: 'mat_date', label: 'MAT', 'sortable': false },
    { key: 'amount_requested', label: 'Amount Requested', 'sortable': false },
    { key: 'amount_approved', label: 'Amount Approved', 'sortable': false },
    { key: 'status', label: 'Status', 'sortable': true },
    { key: 'buttons', label: '', 'sortable': false },
    {},
  ]

  openDeleteDialogBox = (advanceRequest) => this.setStateIfMounted({ toDelete: advanceRequest });

  handleClose = () => this.setStateIfMounted({ toDelete: undefined });

  render() {
    return (
      <span>
        <PaginatedTable
          data={this.tableRows()}
          columns={this.tableCols()}
          totalCount={this.state.total_count}
          page={this.state.page + 1}
          onNextPageClick={this.onNextPageClick}
          onPreviousPageClick={this.onPreviousPageClick}
          onRowSizeChange={this.onRowSizeChange}
          initialSort={this.state.sort}
          onSortOrderChange={this.onSortOrderChange}
          onFilterValueChange={this.onFilterValueChange}
          onRowSelection={this.onRowSelection}
          rowSize={this.state.limit}
        />
        <Dialog
          actions={[
            <RaisedButton
              labelColor="#ffffff"
              backgroundColor={'#525252'}
              label="Cancel"
              onClick={this.handleClose}
              style={{ marginRight: 3 }}
            />,
            <RaisedButton
              labelColor="#ffffff"
              backgroundColor={'#d40a0a'}
              label="Archive"
              onClick={this.archiveAdvanceRequest}
            />
          ]}
          modal={true}
          open={!!this.state.toDelete}
          contentStyle={{ width: '90%' }}
          actionsContainerStyle={{ textAlign: 'center', paddingBottom: 20 }}
        >
          <Subheader className="curved-style">Are you sure you want to archive this advance request?</Subheader>
        </Dialog>
      </span>
    )
  }
}

export default connect(state => ({
  advanceRequests: state.rootReducer.advanceRequests.paginatedAdvanceRequests || [],
  loading: state.rootReducer.advanceRequests.isFetching,
}))(withLoading(AdvanceRequestsTable));
