import{loginRequest} from '../api/users';

import{
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE
}from '../actions/types';

function loginUser() {
    return {
        type: LOGIN_USER
    };
}

function loginUserSuccess(json) {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: {
            user: json
        }
    };
}


function loginUserFailure(error) {
    return {
        type: LOGIN_USER_FAILURE,
        payload: error
    };
}
export default function login(email, password) {
    return dispatch => {
        dispatch(loginUser());
        return loginRequest(email, password)
            .then((json) => {
                return dispatch(loginUserSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(loginUserFailure(errors));
                })
            });
    };
}
