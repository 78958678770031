import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import DistributionsTable from '../blocks/Distributions/distributions-table';
import {Tabs, Tab} from 'material-ui/Tabs';
import { DISTRIBUTION_STATUSES } from '../../constants';

class AdminDistributions extends Component {
   constructor(props) {
      super(props);
   }

   handleTabChange = status => browserHistory.push('/admin/distributions/' + status);

   renderTable = () => {
      const { params = {} } = this.props || {},
        { status } = params || {};
      if (!status) {
         browserHistory.push('/admin/distributions/' + DISTRIBUTION_STATUSES.APPROVED);
         return null;
      }
      return (
        <DistributionsTable
          admin={true}
          userId={null}
          status={status}
          selectable={status === DISTRIBUTION_STATUSES.PENDING}
          enableSelectAll={status === DISTRIBUTION_STATUSES.PENDING}
        />
      )
   }
   
   render() {
      return (
         <Tabs
            value={this.props.params.status || DISTRIBUTION_STATUSES.APPROVED}
            onChange={this.handleTabChange}
            className="dist-table-tab"
         >
            <Tab label="Approved" value={DISTRIBUTION_STATUSES.APPROVED} className="distribution-tab-individual">
               { this.renderTable() }
            </Tab>
            <Tab label="Pending" value={DISTRIBUTION_STATUSES.PENDING} className="distribution-tab-batch">
               { this.renderTable() }
            </Tab> 
         </Tabs>
      )
   }
}

export default AdminDistributions;
