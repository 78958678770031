import {getAllDistributionsRequest} from '../api/distributions';

import{
   GET_ALL_DISTRIBUTIONS,
   GET_ALL_DISTRIBUTIONS_SUCCESS,
   GET_ALL_DISTRIBUTIONS_FAILURE
} from './types';

function getAllDistributions(){
   return{
      type: GET_ALL_DISTRIBUTIONS
   }
}

function getAllDistributionsSuccess(json){
   return{
      type: GET_ALL_DISTRIBUTIONS_SUCCESS,
      payload: json
   }
}

function getAllDistributionsFailure(error){
   return{
      type: GET_ALL_DISTRIBUTIONS_FAILURE,
      payload: error
   }
}

export default function requestGetAllDistributions(token) {
    return dispatch => {
      dispatch(getAllDistributions());
      return getAllDistributionsRequest(token)
         .then((json) => {
               return dispatch(getAllDistributionsSuccess(json));
         })
         .catch((error) => {
               return error.json().then((errors) => {
                  return dispatch(getAllDistributionsFailure(errors));
               })
         });
    };
}