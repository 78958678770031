import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import { connect } from 'react-redux';
import {selectAdvanceRequests} from "../../../reducers/distribution-form";
import {formatCurrency} from "../../../utilities/currency-helpers";

const tableStyle = {
  margin: '0 auto',
  width: '100%',
};

const thStyle = {
  textAlign: 'left',
  borderBottom: '1px solid',

};

class AdvanceRequestDialog extends React.Component {
  render = () => {
    return (
      <Dialog
        modal={true}
        open={this.props.open}
        contentStyle={{width: '60%'}}
      >
        <Subheader className="curved-style" style={{marginBottom: 5}}>
          Advance Requests
        </Subheader>
        <table style={tableStyle}>
          <thead>
          <tr>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Amount Requested</th>
            <th style={thStyle}>Amount Approved</th>
          </tr>
          </thead>
          <tbody>
          {
            (this.props.advanceRequests || []).filter(x => x.approved).map((req, idx) => (
              <tr key={idx}>
                <td>Request ID: {req.id}</td>
                <td>{formatCurrency(req.amount_requested)}</td>
                <td>{formatCurrency(req.amount_approved)}</td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <div style={{textAlign: 'center', marginTop: 40}}>
          <RaisedButton
            className="distribution-warning-cancel"
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Close"
            onClick={this.props.onClose}
          />
        </div>
      </Dialog>
    );
  };
}

export default connect(state => {
  return {
    advanceRequests: Object.values(selectAdvanceRequests(state) || {}),
  };
}, {})(AdvanceRequestDialog);
