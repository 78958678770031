import React from 'react';
import {connect} from 'react-redux';
import Actions from '../../../actions';
import Cookies from 'cookies-js';
import Subheader from 'material-ui/Subheader';
import DeleteButton from '../../blocks/delete-button';
import moment from 'moment';
import ButtonLink from '../../blocks/button-link';
import RaisedButton from 'material-ui/RaisedButton';
import DistributionsList from './distributions-list';
import {
   DELETE_DISTRIBUTION_SUCCESS,
   DELETE_DISTRIBUTION_FAILURE,
   POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS,
   POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE
} from '../../../actions/types';
import {withLoading} from "../with-loading";
import {formatCurrency} from "../../../utilities/currency-helpers";
import PaginatedTable from '../PaginatedTable';

const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
}

class DistributionsTable extends React.PureComponent {
   _isMounted = false;

   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         limit: 10,
         sort: { column: 'id', order: 'desc' },
         total_count: 0,
         q: undefined,
         selectedRows: [],
      };
   };

   componentDidMount() {
      this._isMounted = true;
      this.getDistributions();
   };

   componentDidUpdate(prevProps) {
      if (prevProps.status !== this.props.status) this.getDistributions();
   }

   componentWillUnmount() {
      this._isMounted = false;
   }

   getDistributions() {
      let { q } = this.state;
      if (q && q.length) q = q.trim().toLowerCase();
      this.props.dispatch(Actions.requestGetPaginatedDistributions(
        Cookies.get('token'),
        this.state.limit,
        this.state.page,
        this.state.sort.column,
        this.state.sort.order,
        this.props.status,
        q,
        this.props.userId,
      )).then(json => {
         this.setStateIfMounted({ total_count: json.payload.total_count });
      });
   }

   deleteDistribution = (id) => {
      this.props.dispatch(Actions.requestDeleteDistribution(id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_DISTRIBUTION_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Distribution Deleted'));
            }
            else if (result.type === DELETE_DISTRIBUTION_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   approveDistributions = () => {
      //set form data
      let formData = new FormData();
      formData.append('distribution_ids', JSON.stringify(this.state.selectedRows.map(({ id }) => id)));
      this.props.dispatch(Actions.requestPostBatchDistributionsApprove(formData, Cookies.get('token')))
         .then(result => {
            if(result.type === POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Distributions Approved'));
               this.setStateIfMounted({ selectedRows: [] });
               this.getDistributions();
            } else if (result.type === POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   approveSelected = () => {
      if (!this.state.selectedRows.length) {
         this.props.dispatch(Actions.newError('No distributions select.'));
         return;
      }
      this.approveDistributions();
   }

   getButtons = (distribution) => {
      const viewRoute = this.props.admin ? `/admin/distribution-form/${distribution.id}` : `/distribution-form/${distribution.id}`;
      const viewButton =  <ButtonLink
                              label="View"
                              backgroundColor="#1E88E5"
                              labelColor="#ffffff"
                              style={buttonStyle}
                              to={viewRoute}
                           />
      const deleteButton =  <DeleteButton
                              message="Delete this distribution?"
                              label="Delete"
                              backgroundColor="#e60000"
                              labelColor="#ffffff"
                              style={buttonStyle}
                              contentStyle={{width: '40%'}}
                              onClick={()=>this.deleteDistribution(distribution.id)}
                           />

      const editButton = <ButtonLink
        label="Edit"
        backgroundColor="#1E88E5"
        labelColor="#ffffff"
        style={buttonStyle}
        to={`/admin/distribution-form/${distribution.id}`}
      />;
      return (
         <div>
            {this.props.status === 'approved' ? viewButton : ''}
            {this.props.status === 'pending' && this.props.admin  ? editButton : ''}
            {this.props.admin === true ? deleteButton : ''}
         </div>
      )
   }

   getAdminButtons = () => {
      if(this.props.status === 'pending' && this.props.admin ) {
         return (
            <div style={{textAlign: 'center'}}>
               <RaisedButton
                  labelColor="#ffffff"
                  backgroundColor={'#1E88E5'}
                  style={{margin: 5}}
                  label="Approve Selected"
                  onClick={() => this.approveSelected()}
                  disabled={!this.state.selectedRows || !this.state.selectedRows.length}
               />
            </div>
         )
      }
   }

   tableCols = () => {
      const cols = [
         {key: 'id', label: 'ID', sortable: true},
         {
            key: 'name',
            label: 'Name',
            sortable: true,
            style: {
               width: 200,
               overflow: 'hidden',
            }
         },
         {
            key: 'email',
            label: 'Email',
            sortable: true,
            style: {
               width: 200,
               overflow: 'hidden',
            }
         },
         {key: 'end_date', label: 'Month End Date', sortable: true},
         {key: 'total_funds_disbursed', label: 'Distribution', 'sortable': true},
         {key: 'created_at', label: 'MAT Day', 'sortable': true},
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];
      if (this.props.admin) cols.splice(3, 0, ...[
         {key: 'ending_balance', label: 'Ending Balance', 'sortable': true},
         {key: 'total_payroll', label: 'Payroll', 'sortable': true},
         {key: 'total_expenses', label: 'Expenses', 'sortable': true},
      ]);
      return cols;
   };

   setStateIfMounted = (updates, callback) =>
     this._isMounted ? this.setState(updates, () => callback ? callback() : undefined) : undefined;

   onNextPageClick = () => this.setStateIfMounted({ page: this.state.page + 1 }, () => {
      this.getDistributions();
   })

   onPreviousPageClick = () => this.setStateIfMounted({ page: this.state.page - 1 }, () => {
      this.getDistributions();
   })

   onRowSizeChange = (limit) => this.setStateIfMounted({ limit }, () => this.getDistributions());

   onSortOrderChange = (column, order) => this.setStateIfMounted({ sort: { column, order } }, () => this.getDistributions());

   onFilterValueChange = q => this.setStateIfMounted({ q, page: 0 }, () => this.getDistributions());

   distributionToTableRow = distribution => ({
      id: distribution.id,
      name: distribution.user_name,
      email: distribution.user_email,
      end_date: distribution.end_date,
      ending_balance: formatCurrency(distribution.ending_balance || 0),
      total_payroll: formatCurrency(distribution.total_payroll || 0),
      total_expenses: formatCurrency(distribution.total_expenses || 0),
      total_funds_disbursed: formatCurrency(distribution.total_funds_disbursed || 0),
      created_at: moment(distribution.created_at).format('MM/DD/YYYY'),
      buttons: this.getButtons(distribution),
   })

   onRowSelection = rows => this.setStateIfMounted({ selectedRows: rows });

   tableRows = () => (this.props.paginatedDistributions || []).map(this.distributionToTableRow)

   title = () => this.props.userId !== null ? 'Distributions' : this.props.status !== null ? 'Distributions - Status: ' + this.props.status : '';

   render() {
      const data = this.tableRows();
      return (
        <div className="list-view table-view">
           <Subheader className="table-style">
              { this.title() }
           </Subheader>
           <div className="distributions-table">
              <PaginatedTable
                columns={this.tableCols()}
                data={data}
                onNextPageClick={this.onNextPageClick}
                onPreviousPageClick={this.onPreviousPageClick}
                onRowSizeChange={this.onRowSizeChange}
                initialSort={this.state.sort}
                onSortOrderChange={this.onSortOrderChange}
                onFilterValueChange={this.onFilterValueChange}
                totalCount={this.state.total_count}
                page={this.state.page + 1}
                selectable={!!this.props.selectable}
                enableSelectAll={!!this.props.enableSelectAll}
                onRowSelection={this.onRowSelection}
                rowSize={this.state.limit}
              />
              {this.getAdminButtons()}
           </div>
           <div className="distributions-list">
              <DistributionsList data={data}/>
           </div>
        </div>
      );
   }
}

export default connect(
   state => ({
      loading: state.rootReducer.distributions.isFetching,
      paginatedDistributions: state.rootReducer.distributions.paginatedDistributions,
   })
)(withLoading(DistributionsTable));
