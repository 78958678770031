import React, {Component} from 'react';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import {connect} from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import ButtonLink from '../blocks/button-link';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {
    POST_FILE_UPLOAD_SUCCESS,
    POST_FILE_UPLOAD_FAILURE,
    DELETE_FILE_UPLOAD_SUCCESS,
    DELETE_FILE_UPLOAD_FAILURE
} from '../../actions/types';


class MobileFileUpload extends Component {


    submitFile(e) {
        e.preventDefault();
        const form = document.getElementById('add-file-form');
        let formData = new FormData(form);
        const that = this;
        this.props.dispatch(Actions.requestPostFileUpload(formData, Cookies.get('token')))
            .then(function (result) {
                if (result.type === POST_FILE_UPLOAD_SUCCESS) {
                    that.props.dispatch(Actions.newMessage('You have successfully added a new file'));
                    that.handleClose();
                } else if (result.type === POST_FILE_UPLOAD_FAILURE) {
                    that.props.dispatch(Actions.newError('Something went wrong.'));
                }
            });
    }

    removeFile(id) {
        const that = this;
        this.props.dispatch(Actions.requestDeleteFileUpload(id, Cookies.get('token')))
            .then(function (result) {
                if (result.type === DELETE_FILE_UPLOAD_SUCCESS) {
                    that.props.dispatch(Actions.newMessage('You have successfully removed a product image'));
                    that.forceUpdate();
                } else if (result.type === DELETE_FILE_UPLOAD_FAILURE) {
                    that.props.dispatch(Actions.newError('Something went wrong.'));
                }
            })
    }

    render() {
        const view_only = this.props.viewOnly;
        const iconButtonElement = (
            <IconButton
                touch={true}
                tooltip="more"
                tooltipPosition="bottom-left"
            >
                <MoreVertIcon color={'black'}/>
            </IconButton>
        );

        const lightGray = {
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.54)"
        };


        const imageUploadsList = this.props.expense_reports.map((fileUpload, index) => {

            const rightIconMenu = (
                <IconMenu iconButtonElement={iconButtonElement}>
                    <MenuItem id='delete-file' onClick={() => this.removeFile(fileUpload.id)}>Delete File</MenuItem>
                    <ButtonLink labelStyle={{fontSize: '16px', textTransform: 'capitalize', textAlign: 'left'}}
                                style={{width: '100%', boxShadow: 'none'}} backgroundColor="white"
                                to={fileUpload.file_url} blank_target={true}
                                labelColor="black" label="View File"/>


                </IconMenu>
            );


            return (




                <div className="message-list-items" key={index}>
                    <div className="message-list-items">
                        <div>
                           <span tabIndex='0' style={{
                               border: '10px',
                               boxSizing: "border-box",
                               display: "block",
                               fontFamily: "Roboto, sans-serif",
                               cursor: "pointer",
                               textDecoration: "none",
                               padding: "0",
                               outline: "none",
                               fontSize: "16px",
                               fontWeight: "inherit",
                               position: "relative",
                               color: "rgba(0, 0, 0, 0.87)",
                               lineHeight: "16px",
                               transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                               background: "none",
                               height: "100%",
                               float: "left",
                               width: "100%",
                               margin: "10px 0"
                           }}>
                               <div>
                                   <div style={{
                                       marginLeft: "0px",
                                       padding: "16px 0",
                                       position: "relative"
                                   }}>
                                       <div style={{
                                           display: "block",
                                           position: "absolute",
                                           top: "50%",
                                           right: 0,
                                           transform: 'translateY(-50%)'
                                       }}>
                                           {
                                               view_only === true
                                                   ?
                                                   null
                                                   :
                                                   rightIconMenu
                                           }
                                       </div>

                                           <img key={"file-" + [index]}
                                                src={fileUpload.file_url}
                                                style={{width: '50%'}} alt='remove-preview'/>
                                           <p style={lightGray}>Created At: {fileUpload.created_at}</p>
                                           <p style={lightGray}>Updated At: {fileUpload.updated_at}</p>

                                   </div>

                               </div>
                           </span>
                        </div>
                    </div>
                    <Divider style={{clear: 'both'}} inset={true}/>


                </div>
            )

        });

        return (
            <div>
                <Subheader className="message-list-subheader">Entries</Subheader>
                {imageUploadsList}
            </div>
        )
    };
}


export default connect(
    state => ({
        expenseReports: state.rootReducer.expenseReports
    })
)(MobileFileUpload);
