import {postCategoryRequest} from '../api/categories'

import{
    POST_CATEGORY,
    POST_CATEGORY_SUCCESS,
    POST_CATEGORY_FAILURE
} from './types';


function postCategory(){
	return{
      type: POST_CATEGORY
   }
}

function postCategorySuccess(json){
   return{
		type: POST_CATEGORY_SUCCESS,
		payload: json
   }
}

function postCategoryFailure(error){
   return{
		type: POST_CATEGORY_FAILURE,
		payload: error
   }
}

export default function requestPostCategory(formData, token) {
    return dispatch => {
      dispatch(postCategory());
      return postCategoryRequest(formData, token)
			.then((json) => {
					return dispatch(postCategorySuccess(json));
			})
			.catch((error) => {
					return error.json().then((errors) => {
						return dispatch(postCategoryFailure(errors));
					})
			});
    };
}