export const HOLD_ACCOUNT_TRANSACTIONS = {
    TRANSFER: 'transfer',
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
};

export const DISTRIBUTION_STATUSES = {
    PENDING: 'pending',
    APPROVED: 'approved',
}
