import React, {Component} from 'react';
import _ from 'lodash';
import DatePicker from 'material-ui/DatePicker';


class DatePickerInput extends Component {


    disableDates = (date) => {
        return this.props.datesToEnable.indexOf(date.getFullYear()) === -1;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(
            this.props.defaultValue !== nextProps.defaultValue
            ||
            _.get(this.props.errors, 'error.' + this.props.id) !== _.get(nextProps.errors, 'error.' + nextProps.id)
            ||
            this.props.datesToEnable !== nextProps.datesToEnable
        ) {
            const inputValue = document.getElementById(this.props.id).value;
            // since the dates to enable can reset the value, we need to reset the value on an update
            if(inputValue && this.props.datesToEnable !== nextProps.datesToEnable) {
                this.resetValue(inputValue);
            }
            return true;
        } else {
            return false;
        }
    }

    resetValue = (value) => {
        let interval = setInterval(() => {
            const input = document.getElementById(this.props.id);
            // once the input is reset to false, we can reset it
            if(input && !input.value) {
                input.value = value;
                clearInterval(interval);
            }
        }, 100)
    }

    render() {
        return (
                <DatePicker
                    className={this.props.className}
                    type={this.props.type ? this.props.type : 'text'}
                    id={this.props.id} name={this.props.id}
                    hintText={this.props.placeholder}
                    errorText={_.get(this.props.errors, 'error.' + this.props.id) ? _.get(this.props.errors, 'error.' + this.props.id)  : null}
                    autoOk={true}
                    floatingLabelText={this.props.floatingLabelText}
                    floatingLabelStyle={{whiteSpace: 'nowrap'}}
                    floatingLabelFixed={!!this.props.floatingLabelText}
                    shouldDisableDate={this.props.datesToEnable ? this.disableDates : null}
                    openToYearSelection={this.props.openToYearSelection}
                    onChange={this.props.onChange}
                    textFieldStyle={this.props.style}
                    disabled={this.props.disabled}
                    maxDate={this.props.maxDate}
                    minDate={this.props.minDate}
                />
        )
    }

}

export default DatePickerInput;