import React, {Component} from 'react';
import {connect} from 'react-redux';
import DynamicMenuItem from '../blocks/dynamic-menu-item';
import _ from 'lodash';
import Drawer from 'material-ui/Drawer';
import HamburgerNav from '../../assets/images/menu_icon@2x.png';
import FontIcon from 'material-ui/FontIcon';


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    handleToggle = () => this.setState({open: !this.state.open});
    handleClose = () => this.setState({open: false});



    render() {

        // write a check for where we are in the app to make an active flag
        const pathname = this.props.location.pathname;


        const toggleStyle = {
            "position": "absolute",
            "top": "0",
            "padding": "12px"
        }
        const logoStyle = {
            "height" : "50px",
            "width" : "100%",
            "backgroundColor" : "white",
            "textAlign": "center",
            "boxShadow" : "rgba(133,133,133,0.43) 0px 1px 11px 0px"
        }

        const userAuth = _.get(this.props.auth, 'user.id');

        const userRole = _.get(this.props.auth, 'user.role');

        const hasEmployeeUpAccess = (userRole === 'employee' || userRole === 'admin') ? true : false;

        const hasMissionaryOnlyAccess = (userRole === 'missionary') ? true : false;

        return (
            <div className="menus">
                <div className="logo" style={logoStyle}><div className="logo-img"></div></div>

                <div className="menu-container">

                    <img src={HamburgerNav} style={toggleStyle} onClick={this.handleToggle} alt='menu'/>

                    <Drawer docked={false} open={this.state.open} onRequestChange={(open) => this.setState({open})} width={330}>
                        {hasEmployeeUpAccess ? <DynamicMenuItem id="dashboard-link" to="/admin/dashboard" path={pathname} name='All Expense Reports' leftIcon={<FontIcon className="material-icons">home</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasMissionaryOnlyAccess ? <DynamicMenuItem id="expense-history-link" to={'/dashboard/' + userAuth} path={pathname} name='My Expense History' leftIcon={<FontIcon className="material-icons">home</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasMissionaryOnlyAccess ? <DynamicMenuItem id="expense-report-link" to="/new-report/reimbursement" path={pathname} name='New Expense Report' leftIcon={<FontIcon className="material-icons">add</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="processed-reports-link" to="/admin/expense-reports/processed" path={pathname} name='Processed Reports' leftIcon={<FontIcon className="material-icons">done</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="unprocessed-reports-link" to="/admin/expense-reports/unprocessed" path={pathname} name='Unprocessed Reports' leftIcon={<FontIcon className="material-icons">clear</FontIcon>} onClick={this.handleClose}/>: null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="advance-history" to="/admin/advance-requests" path={pathname} name='All Advance Requests' leftIcon={<FontIcon className="material-icons">remove_red_eye</FontIcon>} onClick={this.handleClose}/> : null}
                        
                        {hasEmployeeUpAccess ? <DynamicMenuItem id="advance-unprocessed" to="/admin/advance-requests/unprocessed" path={pathname} name='Unprocessed Advance Requests' leftIcon={<FontIcon className="material-icons">thumbs_up_down</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="donations" to="/admin/donations" path={pathname} name='Donations' leftIcon={<FontIcon className="material-icons">add</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="hold-accounts" to="/admin/hold-accounts" path={pathname} name='Hold Accounts' leftIcon={<FontIcon className="material-icons">account_balance</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="hold-accounts" to="/admin/distributions/approved" path={pathname} name='Distributions' leftIcon={<FontIcon className="material-icons">attach_money</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasMissionaryOnlyAccess ? <DynamicMenuItem id="advance-request" to="/advance-request" path={pathname} name='Advance Request'  leftIcon={<FontIcon className="material-icons">account_balance</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="view-users-link" to="/admin/users" path={pathname} name='All Users' leftIcon={<FontIcon className="material-icons">person_add</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="user-create-link" to="/admin/user/create" path={pathname} name='Add User' leftIcon={<FontIcon className="material-icons">person_add</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="distributions" to="/admin/distribution-form" path={pathname} name='MAT Day Processing' leftIcon={<FontIcon className="material-icons">attach_money</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="missionary-expense-report-download" to="/reports" path={pathname} name='Report Downloads' leftIcon={<FontIcon className="material-icons">cloud_download</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="settings-link" to="/admin/denari-import" path={pathname} name='Denari Import' leftIcon={<FontIcon className="material-icons">cloud_upload</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasEmployeeUpAccess ? <DynamicMenuItem id="settings-link" to="/admin/settings" path={pathname} name='Settings' leftIcon={<FontIcon className="material-icons">settings</FontIcon>} onClick={this.handleClose}/> : null}

                        {hasMissionaryOnlyAccess ? <DynamicMenuItem id="missionary-profile" to={'/profile/' + userAuth} path={pathname} name='Profile'  leftIcon={<FontIcon className="material-icons">person</FontIcon>} onClick={this.handleClose}/> : null}

                        {userAuth ? <DynamicMenuItem id="login-link" to="/login" path={pathname} name='Logout' leftIcon={<FontIcon className="material-icons">exit_to_app</FontIcon>} onClick={this.handleClose}/> : <DynamicMenuItem id="login-link" to="/login" path={pathname} name='Login' leftIcon={<FontIcon className="material-icons">person_outline</FontIcon>} onClick={this.handleClose}/>}

                    </Drawer>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        auth: state.rootReducer.auth,
        employees: state.rootReducer.employees,
        users: state.rootReducer.users
    })
)(Navigation);
