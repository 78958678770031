import React, {Component} from 'react';
import Table from '../blocks/table';
import FontIcon from "material-ui/FontIcon";
import DeleteButton from "./delete-button";
import ButtonLink from "./button-link";
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {DELETE_FILE_UPLOAD_SUCCESS} from "../../actions/types";

class FileUploadTable extends Component {
  deleteFileUpload = id => {
    this.props.deleteFileUpload(id).then(res => {
      const { type } = res || {};
      if (type === DELETE_FILE_UPLOAD_SUCCESS) this.props.newMsg('File upload deleted!');
      else this.props.newErr('Unable to delete file upload!');
    })
  };

  rows = () => (this.props.fileUploads || []).map(x => {
    const { file_url, filename, id, updated_at } = x,
      isPdf = file_url.includes('.pdf');
    return {
      file_url: isPdf
        ? <FontIcon className="material-icons">picture_as_pdf</FontIcon>
        : <img
            key={`file_${id}`}
            src={file_url}
            style={{ width: '100px' }}
            alt='remove-preview'
          />,
      file_name: filename || '',
      updated_at,
      delete_button: <DeleteButton
        key={`delete_${id}`}
        message="Delete this file upload?"
        label="Delete File"
        backgroundColor="#e60000"
        labelColor="#ffffff"
        onClick={() => this.deleteFileUpload(id)}
      />,
      view: <ButtonLink
        backgroundColor="#1E88E5"
        to={file_url}
        blank_target={true}
        labelColor="#ffffff"
        label="View File"
      />
    }
  });

  render = () => (
    <Table
      columns={[
        {key: 'file_url', label: 'Image Preview', 'sortable': false},
        {key: 'file_name', label: 'File Name', 'sortable': false},
        {key: 'updated_at', label: 'Updated At', 'sortable': false},
        {key: 'delete_button', label: 'Delete', 'sortable': false},
        {key: 'view', label: 'View', 'sortable': false},
      ]}
      data={this.rows()}
    />
  );
}

export default connect(state => ({
}), {
  deleteFileUpload: id => Actions.requestDeleteFileUpload(id, Cookies.get('token')),
  newMsg: msg => Actions.newMessage(msg),
  newErr: err => Actions.newError(err),
})(FileUploadTable);
