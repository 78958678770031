import {deleteHoldAccountRequest} from '../api/hold-accounts';

import {
   DELETE_HOLD_ACCOUNT,
   DELETE_HOLD_ACCOUNT_SUCCESS,
   DELETE_HOLD_ACCOUNT_FAILURE
} from '../actions/types';


function deleteHoldAccount() {
   return {
      type: DELETE_HOLD_ACCOUNT
   };
}

function deleteHoldAccountSuccess(json) {
   return {
      type: DELETE_HOLD_ACCOUNT_SUCCESS,
      payload: json
   };
}

function deleteHoldAccountFailure(error) {
   return {
      type: DELETE_HOLD_ACCOUNT_FAILURE,
      payload: error
   };
}

export default function requestDeleteHoldAccount(id, token) {
   return dispatch => {
      dispatch(deleteHoldAccount());
      return deleteHoldAccountRequest(id, token)
         .then((json) => {
            return dispatch(deleteHoldAccountSuccess(json));
         })
         .catch((error) => {
            return error.json().then((errors) => {
               return dispatch(deleteHoldAccountFailure(errors));
            })
         });
   };
}