import {patchDonationRequest} from '../api/donations';

import{
   PATCH_DONATION,
   PATCH_DONATION_SUCCESS,
   PATCH_DONATION_FAILURE
} from './types';


function patchDonation(){
    return{
        type: PATCH_DONATION
    }
}

function patchDonationSuccess(json){
    return{
        type: PATCH_DONATION_SUCCESS,
        payload: json
    }
}

function patchDonationFailure(error){
    return{
        type: PATCH_DONATION_FAILURE,
        payload: error
    }
}

export default function requestPatchDonation(donation_id, formData, token) {
    return dispatch => {
        dispatch(patchDonation());
        return patchDonationRequest(donation_id, formData, token)
            .then((json) => {
                return dispatch(patchDonationSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchDonationFailure(errors));
                })
            });
    };
}
