import {v4 as uuidv4} from "uuid";
import {
    selectDistributionFormUser,
    selectEndDate,
    selectEndingBalance, selectHoldAccounts,
    selectStartDate
} from "../reducers/distribution-form";
import {advanceRequestsToProcess, expenseReportsToProcess, roundDecimal} from "./distribution-form-helpers";
import {HOLD_ACCOUNT_TRANSACTIONS} from "../constants";
import {getNumWithDefault} from "./objectHelpers";
import {parseDecimalString} from "./string-helpers";

/**
 * Returns array of expenses for the specified hold account, for the current user,
 * within the current time range
 *
 * @param state:            state
 * @param holdAccountId:    target hold account ID
 */
export const holdAccountExpenses = (state, holdAccountId) => {
    const user = selectDistributionFormUser(state),
      startDate = selectStartDate(state),
      endDate = selectEndDate(state);
    if (!user || !startDate || !endDate) return [];
    const expReports = expenseReportsToProcess(user, startDate, endDate, state);
    return expReports.reduce((acc, x) => {
        const { expense } = x;
        return [ ...acc, ...(expense || []).filter(x => x.hold_account_id === holdAccountId).map(y => ({ ...y, expense_report_id: x.id })) ];
    }, []);
};

const holdAccountAdvanceRequests = (state, holdAccountId) => {
    const user = selectDistributionFormUser(state),
      startDate = selectStartDate(state),
      endDate = selectEndDate(state);
    if (!user || !startDate || !endDate) return [];
    const reqs = advanceRequestsToProcess(user, startDate, endDate);
    return reqs.reduce((acc, x) => {
        const { hold_account_id: accountId } = x;
        if (accountId !== holdAccountId) return acc;
        return [ ...acc, x ];
    }, []);
};

/**
 * Calculates total deposit (donations + general fund) amount for a hold account
 *
 * @param holdAccount:      target hold account
 */
export const totalDepositAmount = holdAccount => (holdAccount.deposits || []).reduce((sum, x) => {
    const { recurring, approved, amount_approved: amountApproved = 0 } = x;
    if (recurring && !approved) return sum;
    return roundDecimal(sum + amountApproved);
}, 0);

/**
 * Calculates general fund deposit (non-donations) amount for a hold account
 *
 * @param holdAccount:      target hold account
 */
const generalFundDepositAmount = holdAccount => (holdAccount.deposits || []).reduce((sum, x) => {
    const { amount_approved: amountApproved = 0, fromGeneralFund, recurring } = x;
    if (!fromGeneralFund && !recurring) return sum;
    return sum + amountApproved;
}, 0);

/**
 * Calculates total withdrawal amount for a hold account
 *
 * @param holdAccount:      target hold account
 */
const totalWithdrawalAmount = holdAccount => (holdAccount.withdrawals || []).reduce((sum, x) => {
    const { recurring, approved, amount_approved: amountApproved = 0 } = x;
    if (recurring && !approved) return sum;
    return roundDecimal(sum + amountApproved);
}, 0);

/**
 * Calculates total transfer amount for a hold account
 *
 * @param holdAccount:      target hold account
 */
const totalTransferAmount = holdAccount => (holdAccount.transfers || []).reduce((sum, x) => {
    const { recurring, approved, amount_approved: amountApproved = 0 } = x;
    if (recurring && !approved) return sum;
    return sum + amountApproved;
}, 0);

/**
 * Calculates the working balance (available funds) for a hold account. Working balance
 * is the original balance + non-gen-fund deposits - total withdrawals - total transfers - reimbursed amount
 *
 * @param holdAccount:      target hold account
 */
export const holdAccountWorkingBalance = holdAccount => {
    const genFundDeposits = getNumWithDefault('totalDepositFromGeneralFundAmount', holdAccount, 0),
      reimbursedAmount = getNumWithDefault('reimbursedAmount', holdAccount, 0),
      totalWithdrawalAmount = getNumWithDefault('totalWithdrawalAmount', holdAccount, 0),
      totalTransferAmount = getNumWithDefault('totalTransferAmount', holdAccount, 0),
      totalDepositAmount = getNumWithDefault('totalDepositAmount', holdAccount, 0),
      balance = getNumWithDefault('balance', holdAccount, 0);
    const bal = roundDecimal(
      balance + (totalDepositAmount - genFundDeposits) - totalWithdrawalAmount - totalTransferAmount - reimbursedAmount
    );
    if (bal < 0) return 0;
    return bal;
};

export const holdAccountEndingBalance = holdAccount => {
    const reimbursedAmount = getNumWithDefault('reimbursedAmount', holdAccount, 0),
      totalWithdrawalAmount = getNumWithDefault('totalWithdrawalAmount', holdAccount, 0),
      totalTransferAmount = getNumWithDefault('totalTransferAmount', holdAccount, 0),
      totalDepositAmount = getNumWithDefault('totalDepositAmount', holdAccount, 0),
      balance = getNumWithDefault('balance', holdAccount, 0);
    return roundDecimal(
      balance + totalDepositAmount - totalWithdrawalAmount - totalTransferAmount - reimbursedAmount
    );
};

/**
 * Builds and returns a withdrawal transaction item for a hold account expense
 *
 * @param exp:          expense info
 * @param funds:        funds available within hold account
 * @param accountId:    hold account ID
 */
const expenseWithdrawal = (exp, funds, accountId) => {
    //TODO should this be expense report id
    const { id, usd_amount: usdAmount, expense_report_id: expReportId } = exp;
    let amountApproved = 0;
    if (funds > usdAmount) amountApproved = usdAmount;
    else if (funds > 0) amountApproved = funds;
    return {
        expense_id: id,
        amount: usdAmount,
        amount_approved: amountApproved,
        added_unreimbursed_amount: usdAmount - amountApproved,
        description: `Expense Report ID: ${expReportId}`,
        accountId,
        type: HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL,
    };
};

const advanceRequestWithdrawal = (advanceRequest, accountId) => {
    const { id, amount_requested: amountRequested } = advanceRequest;
    return {
        advance_request_id: id,
        amount: amountRequested,
        amount_approved: 0,
        added_unreimbursed_amount: 0,
        description: `Advance Request ID: ${id}`,
        accountId,
        type: HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL,
        approved: false,
        id: uuidv4(),
    }
};

/**
 * Redistributes funds for a hold account. Should be invoked any time the funds amount
 * changes (transaction addition, deletion, approval; time range change; etc.)
 *
 * @param holdAccount:      target hold account
 * @param state:            state
 */
export const onHoldAccountFundsChange = (holdAccount, state) => {
    const user = selectDistributionFormUser(state),
      startDate = selectStartDate(state),
      endDate = selectEndDate(state);
    if (!user || !startDate || !endDate) return holdAccount;
    const { id, balance = 0, startingUnreimbursedAmount = 0, withdrawals = [], transfers = [] } = holdAccount;
    let unreimbursedAmount = startingUnreimbursedAmount;
    let reimbursedAmount = 0;
    const depositAmount = totalDepositAmount(holdAccount);
    let funds = roundDecimal(balance + depositAmount);
    const expenses = holdAccountExpenses(state, id).map(exp => {
        const expense = expenseWithdrawal(exp, funds, id),
          { amount_approved: amountApproved, added_unreimbursed_amount: addedUnreimbursed } = expense;
        funds = roundDecimal(funds - amountApproved);
        unreimbursedAmount = roundDecimal(unreimbursedAmount + (addedUnreimbursed || 0));
        const existingWithdrawal = (withdrawals || []).find(x => x.expense_id === expense.expense_id),
          { id: withdrawalId, DBid } = existingWithdrawal || {};
        return { ...expense, id: withdrawalId, DBid };
    });
    if (unreimbursedAmount > 0 && funds > 0) {
        if (funds > unreimbursedAmount) reimbursedAmount = unreimbursedAmount;
        else if (funds > 0) reimbursedAmount = funds;
        funds = roundDecimal(funds - reimbursedAmount);
        unreimbursedAmount = roundDecimal(unreimbursedAmount - reimbursedAmount);
    }
    let updatedWithdrawals = [
        ...(withdrawals || []).filter(x => !x.expense_id && !x.advance_request_id).map(x => {
            const { recurring, approved, amount } = x;
            if (recurring && !approved) return x;
            let amountApproved = 0;
            if (funds > amount) amountApproved = amount;
            else if (funds > 0) amountApproved = funds;
            funds = roundDecimal(funds - amountApproved);
            return { ...x, amount_approved: amountApproved };
        }),
        ...(expenses || []),
    ];
    //TODO should gen fund deposits be subtracted from funds?
    const updatedTransfers = (transfers || []).map(x => {
        const { recurring, approved, amount } = x;
        if (recurring && !approved) return x;
        let amountApproved = 0;
        if (funds > amount) amountApproved = amount;
        else if (funds > 0) amountApproved = funds;
        funds = roundDecimal(funds - amountApproved);
        return { ...x, amount_approved: amountApproved };
    });

    const advanceReqs = holdAccountAdvanceRequests(state, id).map(req => {
        const newReq = advanceRequestWithdrawal(req, id);
        const oldReq = (withdrawals || []).find(y => y.advance_request_id === newReq.advance_request_id);
        if (!oldReq || !oldReq.approved) return newReq;
        const { amount } = newReq;
        let amountApproved = 0;
        if (funds > amount) amountApproved = amount;
        else if (funds > 0) amountApproved = funds;
        funds = roundDecimal(funds - amountApproved);
        return { ...newReq, amount_approved: amountApproved, approved: true, id: oldReq.id, DBid: oldReq.DBid };
    });

    updatedWithdrawals = [ ...updatedWithdrawals, ...(advanceReqs || []) ];

    const updatedAccount = {
        ...holdAccount,
        reimbursedAmount,
        unreimbursed_amount: unreimbursedAmount,
        expensesAmount: (expenses || []).reduce((sum, x) => (sum + x.amount_approved), 0),
        totalWithdrawalAmount: totalWithdrawalAmount({ ...holdAccount, withdrawals: updatedWithdrawals }),
        totalTransferAmount: totalTransferAmount({ ...holdAccount, transfers: updatedTransfers }),
        withdrawals: updatedWithdrawals,
        transfers: updatedTransfers,
        totalDepositAmount: depositAmount,
        totalDepositFromGeneralFundAmount: generalFundDepositAmount(holdAccount),
    };
    updatedAccount.workingBalance = holdAccountWorkingBalance(updatedAccount);
    updatedAccount.endingBalance = holdAccountEndingBalance(updatedAccount);
    return updatedAccount;
};

/**
 * Adds withdrawal to a hold account
 *
 * @param state:        state
 * @param transaction:  withdrawal info
 */
export const addWithdrawal = (state, transaction, redistributeFunds = true) => {
    const { accountId, amount, id } = transaction;
    const { [accountId]: account } = selectHoldAccounts(state);
    const updatedAccount = {
        ...account,
        withdrawals: [
            ...(account.withdrawals || []),
            {
                ...transaction,
                amount_approved: amount,
                added_unreimbursed_amount: 0,
                id: id || uuidv4(),
            }
        ]
    };
    if (redistributeFunds) return onHoldAccountFundsChange(updatedAccount, state);
    else return updatedAccount;
};

/**
 * Approves/un-approves a withdrawal for a hold account
 *
 * @param state:        state
 * @param transaction:  withdrawal info
 */
export const approveWithdrawal = (state, transaction) => {
    const { accountId, transactionId, approved } = transaction;
    const { [accountId]: account } = selectHoldAccounts(state);
    const { withdrawals = [] } = account;
    const withdrawal = (withdrawals || []).find(x => x.id === transactionId),
      { amount } = withdrawal;
    const updatedAccount = {
        ...account,
        withdrawals: (withdrawals || []).map(x => x.id !== transactionId ? x : {
            ...x,
            approved,
            amount_approved: approved ? amount : 0,
            added_unreimbursed_amount: 0
        }),
    };
    return onHoldAccountFundsChange(updatedAccount, state);
};

/**
 * Deletes a withdrawal from a hold account
 *
 * @param holdAccount:      hold account from which we will delete the withdrawal
 * @param transactionId:    transaction ID for the withdrawal
 * @param state:            state
 */
export const deleteWithdrawal = (holdAccount, transactionId, state) => onHoldAccountFundsChange({
    ...holdAccount,
    withdrawals: (holdAccount.withdrawals || []).filter(x => x.id !== transactionId),
}, state);

/**
 * Approves/un-approves transfer for a hold account
 *
 * @param state:        state
 * @param transaction:  transfer info
 */
export const approveTransfer = (state, transaction) => {
    const { accountId, transactionId, approved } = transaction;
    const { [accountId]: account } = selectHoldAccounts(state);
    const { transfers = [], workingBalance = 0 } = account;
    const transfer = (transfers || []).find(x => x.id === transactionId),
        { amount, amount_approved } = transfer;
    let amountApproved = 0;
    if (approved) {
        if (workingBalance >= amount) amountApproved = amount;
        else amountApproved = workingBalance > 0 ? workingBalance : 0;
    }
    else amountApproved = amount_approved;
    const updatedAccount = {
        ...account,
        transfers: transfers.map(x => x.id !== transactionId ? x : {
            ...x,
            approved,
            amount_approved: approved ? amountApproved : 0,
            added_unreimbursed_amount: 0
        }),
    };
    return onHoldAccountFundsChange(updatedAccount, state);
};

/**
 * Adds a transfer (into General Fund) to a hold account
 *
 * @param state:        state
 * @param transaction:  transfer info
 */
export const addTransfer = (state, transaction, redistributeFunds = true) => {
    const { amount, accountId, id } = transaction;
    const { [accountId]: account = {} } = selectHoldAccounts(state),
        { transfers = [], workingBalance } = account || {};
    let amountApproved = 0;
    if (workingBalance >= amount) amountApproved = amount;
    else amountApproved = workingBalance > 0 ? workingBalance : 0;
    const updatedAccount = {
        ...account,
        transfers: [ ...transfers, { ...transaction, amount_approved: amountApproved, added_unreimbursed_amount: 0, id: id || uuidv4() } ],
    };
    if (redistributeFunds) return onHoldAccountFundsChange(updatedAccount, state);
    else return updatedAccount;
};

/**
 * Deletes a transfer from a hold account
 *
 * @param holdAccount:      hold account from which the transfer will be deleted
 * @param transactionId:    ID of the transfer
 * @param state:            state
 */
export const deleteTransfer = (holdAccount, transactionId, state) => {
    const updatedAccount = {
        ...holdAccount,
        transfers: (holdAccount.transfers || []).filter(x => x.id !== transactionId),
    };
    return onHoldAccountFundsChange(updatedAccount, state);
};

/**
 * Approves/un-approves deposit for a hold account
 *
 * @param state:        state
 * @param transaction:  deposit info
 */
export const approveDeposit = (state, transaction) => {
    const { accountId, transactionId, approved } = transaction;
    const { [accountId]: account } = selectHoldAccounts(state);
    const deposit = (account.deposits || []).find(x => x.id === transactionId);
    if (!deposit) {
        console.error('Unable to find deposit with ID='+transactionId);
        return account;
    }
    const { amount, amount_approved } = deposit;
    let amountApproved = amount;
    if (approved) {
        const endingBalance = selectEndingBalance(state);
        if (amountApproved > endingBalance) {
            if (endingBalance <= 0) amountApproved = 0;
            else amountApproved = endingBalance;
        }
    }
    else amountApproved = amount_approved;
    const updatedAccount = {
        ...account,
        deposits: account.deposits.map(x => x.id !== transactionId ? x : {
            ...x,
            approved,
            amount_approved: approved ? amountApproved : 0,
            added_unreimbursed_amount: 0,
        }),
    };
    return onHoldAccountFundsChange(updatedAccount, state);
};

/**
 * Adds a deposit to a hold account
 *
 * @param state:        state
 * @param transaction:  transaction info for the deposit
 */
export const addDeposit = (state, transaction, redistributeFunds = true) => {
    const { amount, accountId, donation_id: donationId, id } = transaction;
    const { [accountId]: account } = selectHoldAccounts(state);
    const { deposits = [] } = account;
    const endingBalance = selectEndingBalance(state);
    let amountApproved = amount;
    const updatedAccount = {
        ...account,
        deposits: [
            ...(deposits || []),
            { ...transaction, amount_approved: amountApproved, added_unreimbursed_amount: 0, id: id || uuidv4() }
        ],
    };
    if (redistributeFunds) return onHoldAccountFundsChange(updatedAccount, state);
    else return updatedAccount;
};

/**
 * Deletes deposit identified by transactionId from the holdAccount
 *
 * @param holdAccount:      target hold account
 * @param transactionId:    ID of deposit to delete
 * @param state:            state
 */
export const deleteDeposit = (holdAccount, transactionId, state) => {
    const updatedAccount = {
        ...holdAccount,
        deposits: (holdAccount.deposits || []).filter(x => x.id !== transactionId),
    };
    return onHoldAccountFundsChange(updatedAccount, state);
};

/**
 * Resets hold account balances for distribution edit
 *
 * @param state
 * @param distribution
 * @returns {{holdAccounts: *}}
 */
export const resetHoldAcctBalanceForEdit = (state, distribution) => {
    const holdAccounts = selectHoldAccounts(state);
    const { hold_account_transactions: transactions = [] } = distribution;
    return {
        ...state,
        holdAccounts: Object.keys(holdAccounts).reduce((acc, id) => {
            const holdAcct = holdAccounts[id];
            const distTransaction = (transactions || []).find(t => t.id === holdAcct.id),
              { pivot } = distTransaction || {},
              { starting_balance, starting_unreimbursed_amount } = pivot || {};
            if (!starting_balance && !starting_unreimbursed_amount) return { ...acc, [id]: holdAcct };
            let ret = { ...holdAcct };
            if (starting_balance) {
                const parsedBal = parseDecimalString(starting_balance);
                ret = { ...ret, balance: parsedBal, workingBalance: parsedBal, startingBalance: parsedBal };
            }
            if (starting_unreimbursed_amount) {
                ret = { ...ret, startingUnreimbursedAmount: parseDecimalString(starting_unreimbursed_amount) };
            }
            return { ...acc, [id]: ret };
        }, {})
    }
};
