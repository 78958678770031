import {deleteExpenseReportRequest} from '../api/expense-reports';

import {
    DELETE_EXPENSE_REPORT,
    DELETE_EXPENSE_REPORT_SUCCESS,
    DELETE_EXPENSE_REPORT_FAILURE
} from '../actions/types';

function deleteExpenseReport() {
    return {
        type: DELETE_EXPENSE_REPORT
    };
}

function deleteExpenseReportSuccess(json) {
    return {
        type: DELETE_EXPENSE_REPORT_SUCCESS,
        payload: json
    };
}

function deleteExpenseReportFailure(error) {
    return {
        type: DELETE_EXPENSE_REPORT_FAILURE,
        payload: error
    };
}

export default function requestDeleteExpenseReport(expense_report_id, token) {
    return dispatch => {
        dispatch(deleteExpenseReport());
        return deleteExpenseReportRequest(expense_report_id, token)
            .then((json) => {
                return dispatch(deleteExpenseReportSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(deleteExpenseReportFailure(errors));
                })
            });
    };
}
