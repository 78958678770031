import {
   mergeState,
   deleteFromState
} from '11online-redux-helpers';

import {
   GET_ALL_DISTRIBUTIONS,
   GET_ALL_DISTRIBUTIONS_SUCCESS,
   GET_ALL_DISTRIBUTIONS_FAILURE,
   POST_DISTRIBUTION,
   POST_DISTRIBUTION_SUCCESS,
   POST_DISTRIBUTION_FAILURE,
   PATCH_DISTRIBUTION,
   PATCH_DISTRIBUTION_SUCCESS,
   PATCH_DISTRIBUTION_FAILURE,
   DELETE_DISTRIBUTION,
   DELETE_DISTRIBUTION_SUCCESS,
   DELETE_DISTRIBUTION_FAILURE,
   POST_BATCH_DISTRIBUTIONS,
   POST_BATCH_DISTRIBUTIONS_SUCCESS,
   POST_BATCH_DISTRIBUTIONS_FAILURE,
   POST_BATCH_DISTRIBUTIONS_APPROVE,
   POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS,
   POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE,
  GET_DISTRIBUTION,
  GET_DISTRIBUTION_FAILURE,
  GET_DISTRIBUTION_SUCCESS,
  GET_DISTRIBUTIONS,
  GET_DISTRIBUTIONS_SUCCESS,
  GET_DISTRIBUTIONS_ERROR,
} from '../actions/types';

export function reducer(state = {
   isFetching: false,
   distributions: {}
}, action = { type: ''}) {
   switch (action.type) {
      case GET_DISTRIBUTIONS:
         return {
            ...state,
            isFetching: true,
         };
      case GET_DISTRIBUTIONS_ERROR:
         return {
            ...state,
            isFetching: false,
         };
      case GET_DISTRIBUTIONS_SUCCESS:
         return {
            ...state,
            isFetching: false,
            paginatedDistributions: action.payload.data || [],
         }
      case GET_DISTRIBUTION:
         return {
            ...state,
            isFetching: true,
         };
      case GET_DISTRIBUTION_SUCCESS:
         return {
            ...state,
            isFetching: false,
            distributions: {
               ...(state.distributions || {}),
               [action.payload.id]: action.payload,
            },
         };
      case GET_DISTRIBUTION_FAILURE:
         return {
            ...state,
            isFetching: false,
            error: action.payload,
         };
      case GET_ALL_DISTRIBUTIONS:
         return {
            ...state,
            isFetching: true
         };
      case GET_ALL_DISTRIBUTIONS_SUCCESS:
         return {
            ...state,
            isFetching: false,
            error: [],
            distributions: mergeState(state.distributions, action.payload)
         };
      case GET_ALL_DISTRIBUTIONS_FAILURE:
         return {
            ...state,
            error: action.payload,
            isFetching: false
         };
      case POST_DISTRIBUTION:
         return {
            ...state,
            isFetching: true
         };
      case POST_DISTRIBUTION_SUCCESS:
         return {
            ...state,
            isFetching: false,
            error: [],
            distributions: mergeState(state.distributions, action.payload),
         };
      case POST_DISTRIBUTION_FAILURE:
         return {
            ...state,
            error: action.payload,
            isFetching: false
         };
      case PATCH_DISTRIBUTION:
         return {
            ...state,
            isFetching: true
         };
      case PATCH_DISTRIBUTION_SUCCESS:
         return {
            ...state,
            isFetching: false,
            error: [],
            distributions: {
               ...(state.distributions || {}),
               [action.payload.id]: action.payload,
            },
         };
      case PATCH_DISTRIBUTION_FAILURE:
         return {
            ...state,
            error: action.payload,
            isFetching: false
         };
      case DELETE_DISTRIBUTION:
         return {
            ...state,
            isFetching: true
         };
      case DELETE_DISTRIBUTION_SUCCESS:
         return {
            ...state,
            isFetching: false,
            error: [],
            distributions: deleteFromState(state.distributions, action.payload),
            paginatedDistributions: (state.paginatedDistributions || []).filter(({ id }) => id !== action.payload.id),
         };
      case DELETE_DISTRIBUTION_FAILURE:
         return {
            ...state,
            error: action.payload,
            isFetching: false
         };
      case POST_BATCH_DISTRIBUTIONS:
         return {
            ...state,
            isFetching: true
         };
      case POST_BATCH_DISTRIBUTIONS_SUCCESS:
         return {
            ...state,
            isFetching: false,
            error: [],
            distributions: mergeState(state.distributions, action.payload)
         };
      case POST_BATCH_DISTRIBUTIONS_FAILURE:
         return {
            ...state,
            error: action.payload,
            isFetching: false
         };
      case POST_BATCH_DISTRIBUTIONS_APPROVE:
         return {
            ...state,
            isFetching: true
         };
      case POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS:
         return {
            ...state,
            isFetching: false,
            error: [],
            distributions: mergeState(state.distributions, action.payload)
         };
      case POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE:
         return {
            ...state,
            error: action.payload,
            isFetching: false
         };
      default:
         return state;
   }
}
