export const dateSubmittedTableSort = (a, b) => {
   let aDate = a.date_submitted != null ? a.date_submitted : '99/99/9999'
   let bDate = b.date_submitted != null ? b.date_submitted : '99/99/9999'
   aDate = aDate.split('/');
   bDate = bDate.split('/');
   return bDate[2] - aDate[2] || bDate[0] - aDate[0] || bDate[1] - aDate[1]
}

export const dateSubmittedListSort = (a, b) => {
   let aDate = a.date_submitted != null ? a.date_submitted : '9999-99-99'
   let bDate = b.date_submitted != null ? b.date_submitted : '9999-99-99'
   aDate = aDate.split('-');
   bDate = bDate.split('-');
   return bDate[0] - aDate[0] || bDate[1] - aDate[1] || bDate[2] - aDate[2]
}

export const dateSortDesc = (a, b) => {
   let aDate = a.date;
   let bDate = b.date;
   if (aDate === null || aDate === undefined || bDate === null || bDate === undefined) return -1;
   aDate = aDate.split('/');
   bDate = bDate.split('/');
   return bDate[2] - aDate[2] || bDate[0] - aDate[0] || bDate[1] - aDate[1]
}
