import {getUserRequest} from '../api/users';

import{
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE
} from './types';


function getUser(){
    return{
        type: GET_USER
    }
}

function getUserSuccess(json){
    return{
        type: GET_USER_SUCCESS,
        payload: json
    }
}

function getUserFailure(error){
    return{
        type: GET_USER_FAILURE,
        payload: error
    }
}


export default function requestGetUser(user_id, token) {
    return dispatch => {
        dispatch(getUser());
        return getUserRequest(user_id, token)
            .then((json) => {
                return dispatch(getUserSuccess(json));
            })
            .catch((error) => {
                return dispatch(getUserFailure(error));
            });
    };
}
