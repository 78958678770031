import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';


export const getAllSettingsRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/settings',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};


export const patchSettingsRequest = (formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/settings',
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};