import{
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    LOGIN_USER_WITH_TOKEN_SUCCESS,
    LOGIN_USER_WITH_TOKEN_FAILURE,
    RESET_PASSWORD_EMAIL,
    RESET_PASSWORD_EMAIL_SUCCESS,
    RESET_PASSWORD_EMAIL_FAILURE,
}from '../actions/types';

export function reducer(state = {
    isFetching: false,
    user:{},
    password: []
}, action = {type: ''}){
    switch(action.type){
        case LOGIN_USER:
            return {
                ...state,
                isFetching: true
            };
        case LOGIN_USER_SUCCESS:
            return{
                ...state,
                isFetching: false,
                error: [],
                user: action.payload.user
            };
        case LOGIN_USER_FAILURE:
            return{
                ...state,
                error: action.payload,
                isFetching: false

            };
        case RESET_PASSWORD:
            return{
                ...state,
                isFetching: true
            };
        case RESET_PASSWORD_SUCCESS:
            return{
                ...state,
                isFetching: false,
                error: []
            };
        case RESET_PASSWORD_FAILURE:
            return{
                ...state,
                error: action.payload,
                isFetching: false
            };
        case UPDATE_PASSWORD:
            return{
                ...state,
                isFetching: true
            };
        case UPDATE_PASSWORD_SUCCESS:
            return{
                ...state,
                isFetching: false,
                error: []
            };
        case UPDATE_PASSWORD_FAILURE:
            return{
                ...state,
                error: action.payload,
                isFetching: false
            };
        case LOGIN_USER_WITH_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                user: action.payload.user
            };
        case LOGIN_USER_WITH_TOKEN_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case RESET_PASSWORD_EMAIL:
            return {
                ...state,
                isFetching: true
            };
        case RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: []
            };
        case RESET_PASSWORD_EMAIL_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        default:
            return state;
    }
}