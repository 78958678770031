import{
    NEW_MESSAGE,
    NEW_ERROR,
    CLEAR_MESSAGE
} from '../actions/types';

export function reducer(state = {
    message: '',
    open: false,
    bodyStyle: null
}, action = {type: ''}) {
    switch (action.type) {
        case NEW_MESSAGE:
            return {
                ...state,
                message: action.message,
                open: true,
                bodyStyle: null
            };
        case NEW_ERROR:
            return {
                ...state,
                message: action.message,
                open: true,
                bodyStyle: {backgroundColor: 'rgb(244, 67, 54)'}
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: '',
                open: false
            };

        default:
            return state;
    }
}