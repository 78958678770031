import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import HoldAccountTransactionDialog from './HoldAccountTransactionDialog';
import { connect } from 'react-redux';
import { addHoldAccountTransaction, deleteHoldAccountTransaction } from "../../../actions/distribution-form";
import {selectDistributionFormUser, selectHoldAccounts} from "../../../reducers/distribution-form";
import {parseDecimalString} from "../../../utilities/string-helpers";
import {HOLD_ACCOUNT_TRANSACTIONS} from "../../../constants";
import DataTables from 'material-ui-datatables';
import {formatCurrency} from "../../../utilities/currency-helpers";
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Icon from '../icon';

class HoldAccountsDialog extends React.Component {
  state = {
    addTransactionOpen: false,
  };

  getHoldAccountName = (accountId) => {
    const account = (this.props.holdAccounts || []).find(x => x.id === accountId);
    if (account) return account.fund_name;
  };

  onTransactionSubmit = transaction => {
    const { accountId, amount } = transaction;
    const { transactionType } = this.props;
    this.setState({ addTransactionOpen: false }, () => {
      const transaction = {
        accountId: accountId,
        amount: parseDecimalString(amount),
        type: transactionType,
        description: `Mat Day Manual ${transactionType}`,
      };
      if (transactionType.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT) transaction.fromGeneralFund = true;
      this.props.addHoldAccountTransaction(transaction);
    });
  };

  deleteTransaction = ({ accountId, id }) => {
    const { transactionType } = this.props;
    this.props.deleteHoldAccountTransaction(accountId, id, transactionType);
  };


  getButtons = () => {
    const buttons = [];
    const closeButton =  <RaisedButton
      key="dist-dialog-close"
      label="Close"
      className="cancel-category-dialog"
      backgroundColor="#525252"
      labelColor="#ffffff"
      onClick={() => this.props.handleClose()}
    />
    const addButton =  <RaisedButton
      key="dist-dialog-submit"
      label="Add Transaction"
      className="category-action-button"
      backgroundColor="#1E88E5"
      labelColor="#ffffff"
      onClick={() => this.setState({ addTransactionOpen: true })}
    />;

    if (!this.props.readOnly) buttons.push(addButton);
    buttons.push(closeButton);

    return (
      <div style={{textAlign: 'center', marginTop: '30px'}}>
        {buttons}
      </div>
    );
  }

  tableCols = () => {
    const { transactionType } = this.props;
    if (!transactionType) return [];
    const cols = [
      {
        key: 'fundName',
        label: 'Fund Name',
        sortable: false
      },
      {
        key: 'description',
        label: 'Description',
        sortable: false
      },
      {
        key: 'amountRequested',
        label: 'Amount Requested',
        sortable: false
      },
      {
        key: 'amountApproved',
        label: 'Amount Approved',
        sortable: false
      },
      {
        key: 'unreimbursed',
        label: 'Unreimbursed Amount',
        sortable: false
      },
      {
        key: 'action',
        label: '',
        sortable: false,
      }
    ];
    if (transactionType.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL)
      cols.splice(2, 0, {
        key: 'expenseId',
        label: 'Expense ID',
        sortable: false
      });
    return cols;
  };

  tableRows = () => {
    const { transactionType, holdAccounts = [], readOnly } = this.props;
    if (!transactionType) return [];
    let collectionKey = 'transfers';
    if (transactionType.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL) collectionKey = 'withdrawals';
    else if (transactionType.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT) collectionKey = 'deposits';
    const rows = (holdAccounts || []).reduce((acc, x) => [ ...acc, ...(x[collectionKey] || []) ], []).filter(x => !x.recurring || x.approved).map(transaction => {
      const { accountId, description, amount, amount_approved: amountApproved, added_unreimbursed_amount: unreimbursedAmount, expense_id: expId, recurring } = transaction;
      return {
        fundName: this.getHoldAccountName(accountId),
        description,
        amountRequested: formatCurrency(amount || 0),
        amountApproved: formatCurrency(amountApproved || 0),
        expenseId: expId,
        unreimbursed: formatCurrency(unreimbursedAmount || 0),
        action: recurring || expId || readOnly
          ? null
          : <FloatingActionButton mini={true} backgroundColor='red' onClick={() => this.deleteTransaction(transaction)}>
            <Icon type="minus" />
          </FloatingActionButton>
      }
    });
    if (transactionType.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL) {
      holdAccounts.forEach(x => {
        const { startingUnreimbursedAmount, reimbursedAmount } = x;
        if (startingUnreimbursedAmount === 0 || typeof reimbursedAmount !== 'number' || isNaN(reimbursedAmount)) return;
        rows.push({
          fundName: x.fund_name,
          description: 'Unreimbursed Reimbursement',
          amountRequested: formatCurrency(startingUnreimbursedAmount),
          amountApproved: formatCurrency(reimbursedAmount),
          expenseId: null,
          unreimbursed: formatCurrency(Math.abs(startingUnreimbursedAmount - reimbursedAmount)),
          action: null,
        })
      })
    }

    return rows;
  };

  render() {
    return(
      <Dialog
        modal={true}
        open={this.props.open}
        contentStyle={{width: '80%', maxWidth: 'none'}}
        autoScrollBodyContent={true}
        overlayClassName={'hold-accounts-dialog-table'}
      >
        <div>
          <Subheader className="curved-style">
            {'Hold Account ' + this.props.transactionType + 's'}
          </Subheader>
          <DataTables
            data={this.tableRows()}
            columns={this.tableCols()}
            showHeaderToolbar={true}
            showHeaderToolbarFilterIcon={false}
          />
          {this.getButtons()}
        </div>

        <HoldAccountTransactionDialog
          open={this.state.addTransactionOpen}
          holdAccounts={this.props.holdAccounts}
          transactionType={this.props.transactionType}
          onSubmit={this.onTransactionSubmit}
          onClose={() => this.setState({ addTransactionOpen: false })}
        />
      </Dialog>
    )
  }
}

export default connect(state => {
  const holdAccounts = selectHoldAccounts(state);
  return {
    holdAccounts: Object.keys(holdAccounts || {}).length ? Object.values(holdAccounts) : [],
  };
}, {
  addHoldAccountTransaction,
  deleteHoldAccountTransaction,
})(HoldAccountsDialog);
