import React from 'react';
import { connect } from 'react-redux';
import CurrencyInput from "../../fields/CurrencyInput";
import {Card, CardHeader, CardText} from 'material-ui/Card';
import {Tabs, Tab} from 'material-ui/Tabs';
import Icon from "../icon";
import IconButton from "material-ui/IconButton";
import {
    selectAccruedUnreimbursedAmount,
    selectEndDate, selectEndingUnreimbursedAmount,
    selectReimbursedAmount,
    selectStartDate,
    selectStartingBalance, selectStartingUnreimbursedAmount
} from "../../../reducers/distribution-form";
import moment from "moment";
import Input from '../../fields/input';
import Checkbox from 'material-ui/Checkbox';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    column: {
        flex: '0 1 50%',
    }
};

class Summary extends React.PureComponent {
    holdAccountTransfers = () => (this.props.holdAccounts || [])
        .reduce((sum, x) => (sum + (x.totalTransferAmount || 0)), 0);

    holdAccountColumns = () => {
        const { holdAccounts } = this.props;
        return (holdAccounts || []).map(account => {
            const {
                fund_name: fundName,
                id,
                balance,
                endingBalance,
                totalTransferAmount,
                deposits = [],
                unreimbursed_amount: endingUnreimbursedAmount,
                reimbursedAmount,
                startingUnreimbursedAmount,
                totalWithdrawalAmount = 0,
                totalDepositAmount = 0,
            } = account;
            const donationAmount = (deposits || []).filter(x => !!x.donation_id)
                .reduce((sum, x) => (sum + x.amount), 0);
            return (
                <div style={styles.column} key={id}>
                    <h4>{fundName}</h4>
                    <div style={styles.container}>
                        <div style={styles.column}>
                            <CurrencyInput
                                id="starting_balance"
                                floatingLabelText="Starting Balance"
                                value={balance}
                                underlineShow={false}
                                disabled={true}
                            />
                            <CurrencyInput
                                id="donations_amount"
                                floatingLabelText="Donations Amount"
                                value={donationAmount}
                                underlineShow={false}
                                disabled={true}
                            />
                            <CurrencyInput
                              id="deposit_amount"
                              floatingLabelText="Deposit Amount"
                              value={totalDepositAmount || 0}
                              underlineShow={false}
                              disabled={true}
                              style={{ width: '180px' }}
                              inputSuffix={
                                  <IconButton onClick={() => this.props.openHoldAccountDepositsDialog()}>
                                      <Icon type="info_outline" />
                                  </IconButton>
                              }
                            />
                            <CurrencyInput
                                id="transfer_amount"
                                floatingLabelText="Transfer Amount"
                                value={totalTransferAmount}
                                underlineShow={false}
                                disabled={true}
                                style={{ width: '180px' }}
                                inputSuffix={
                                    <IconButton onClick={() => this.props.openHoldAccountTransfersDialog()}>
                                        <Icon type="info_outline" />
                                    </IconButton>
                                }
                            />
                            <CurrencyInput
                                id="withdrawals"
                                floatingLabelText="Withdrawal Amount"
                                value={(totalWithdrawalAmount || 0) + (reimbursedAmount || 0)}
                                underlineShow={false}
                                disabled={true}
                                style={{ width: '180px' }}
                                inputSuffix={
                                    <IconButton onClick={() => this.props.openHoldAccountWithdrawalsDialog()}>
                                        <Icon type="info_outline" />
                                    </IconButton>
                                }
                            />
                            <CurrencyInput
                                id="ending_balance"
                                floatingLabelText="Ending Balance"
                                value={endingBalance || 0}
                                underlineShow={false}
                                disabled={true}
                            />
                        </div>
                        <div style={styles.column}>
                            <CurrencyInput
                                id="starting_unreimbursed_amount"
                                floatingLabelText="Starting Unreimbursed Amount"
                                value={startingUnreimbursedAmount}
                                underlineShow={false}
                                disabled={true}
                            />
                            <CurrencyInput
                                id="reimbursed_amount"
                                floatingLabelText="Reimbursed Amount"
                                value={reimbursedAmount || 0}
                                underlineShow={false}
                                disabled={true}
                            />
                            <CurrencyInput
                                id="ending_unreimbursed_amount"
                                floatingLabelText="Ending Unreimbursed Amount"
                                value={endingUnreimbursedAmount}
                                underlineShow={false}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    subtitle = () => {
        const { startDate, endDate, user } = this.props;
        if (!user || !startDate || !endDate) return null;
        const { name } = user;
        return (
            <div>
                {name}
                <br/>
                {startDate.format('YYYY-MM-DD')} - {endDate.format('YYYY-MM-DD')}
            </div>
        )
    };

    totalExpenses = () => {
        const { totalExpenses, reimbursedAmount } = this.props;
        if (typeof reimbursedAmount === 'number' && !isNaN(reimbursedAmount)) return totalExpenses + reimbursedAmount;
        return totalExpenses;
    };

    render = () => {
        const { user, endingBalance, totalDonations, distributableFunds, totalPayroll, reimbursedAmount } = this.props;
        const { profile = {} } = user || {},
            { tax_status: taxStatus, missionary_number: missionaryNumber, fica_tax: fica } = profile || {};

        return (
            <Card initiallyExpanded={true}>
                <CardHeader
                    title="Summary"
                    subtitle={this.subtitle()}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardText expandable={true}>
                    <Tabs>
                        <Tab label="Overview">
                            <div style={styles.container}>
                                <div style={styles.column}>
                                    <Input
                                      id="missionary_number"
                                      floatingLabelText="Missionary Number"
                                      value={missionaryNumber || ''}
                                      underlineShow={false}
                                      disabled={true}
                                    />
                                    <Input
                                      id="tax_status"
                                      floatingLabelText="Tax Status"
                                      value={taxStatus || ''}
                                      underlineShow={false}
                                      disabled={true}
                                    />
                                    <Checkbox
                                      label="FICA Tax"
                                      checked={!!fica}
                                      disabled={true}
                                      labelPosition="left"
                                      labelStyle={{ width: 'auto' }}
                                      style={{ marginTop: 10 }}
                                    />
                                </div>
                                <div style={styles.column}>
                                    <CurrencyInput
                                        id="starting_balance"
                                        floatingLabelText="Starting Balance"
                                        value={this.props.startingBalance || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                    <CurrencyInput
                                        id="total_donations"
                                        floatingLabelText="Total Donations"
                                        value={totalDonations || 0}
                                        underlineShow={false}
                                        disabled={true}
                                        style={{ width: '180px' }}
                                        inputSuffix={
                                            <IconButton onClick={() => this.props.openDonationsDialog()}>
                                                <Icon type="info_outline" />
                                            </IconButton>
                                        }
                                    />
                                    <CurrencyInput
                                        id="hold_account_transfers"
                                        floatingLabelText="Hold Account Transfers"
                                        value={this.holdAccountTransfers()}
                                        underlineShow={false}
                                        disabled={true}
                                        style={{ width: '180px' }}
                                        inputSuffix={
                                            <IconButton onClick={() => this.props.openHoldAccountTransfersDialog()}>
                                                <Icon type="info_outline" />
                                            </IconButton>
                                        }
                                    />
                                    <CurrencyInput
                                        id="distributable_funds"
                                        floatingLabelText="Distributable Funds"
                                        value={distributableFunds || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                </div>
                                <div style={styles.column}>
                                    <CurrencyInput
                                        id="total_payroll"
                                        floatingLabelText="Total Payroll"
                                        value={totalPayroll || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                    <CurrencyInput
                                        id="total_expenses"
                                        floatingLabelText="Total Expenses"
                                        value={this.totalExpenses() || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                    <CurrencyInput
                                        id="ending_balance"
                                        floatingLabelText="Ending Balance"
                                        value={endingBalance}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                </div>
                                <div style={styles.column}>
                                    <CurrencyInput
                                        id="starting_unreimbursed_amount"
                                        floatingLabelText="Starting Unreimbursed Amount"
                                        value={this.props.startingUnreimbursedAmount || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                    <CurrencyInput
                                        id="accrued_unreimbursed_amount"
                                        floatingLabelText="Accrued Unreimbursed Amount"
                                        value={this.props.accruedUnreimbursedAmount || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                    <CurrencyInput
                                      id="reimbursed_amount"
                                      floatingLabelText="Reimbursed Amount"
                                      value={reimbursedAmount || 0}
                                      underlineShow={false}
                                      disabled={true}
                                    />
                                    <CurrencyInput
                                        id="ending_unreimbursed_amount"
                                        floatingLabelText="Ending Unreimbursed Amount"
                                        value={this.props.endingUnreimbursedAmount || 0}
                                        underlineShow={false}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab label="Hold Accounts">
                            <div style={styles.container}>
                                {
                                    this.holdAccountColumns()
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </CardText>
            </Card>
        )
    }
}

export default connect(state => {
    const start = selectStartDate(state),
        end = selectEndDate(state);
    return {
        startDate: start ? moment.unix(start) : start,
        endDate: end ? moment.unix(end) : end,
        reimbursedAmount: selectReimbursedAmount(state),
        startingBalance: selectStartingBalance(state),
        startingUnreimbursedAmount: selectStartingUnreimbursedAmount(state),
        accruedUnreimbursedAmount: selectAccruedUnreimbursedAmount(state),
        endingUnreimbursedAmount: selectEndingUnreimbursedAmount(state),
    };
}, {

})(Summary);
