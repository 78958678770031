import Cookies from 'cookies-js';
import {
    LOGOUT_USER
} from '../actions/types';

function logoutUser() {
    return {
        type: LOGOUT_USER
    };
}

export default function logout() {
    return dispatch => {
        Cookies.expire('token');
        dispatch(logoutUser());
    };
}