import {denariUploadRequest} from '../api/donations'

import{
   POST_DENARI_UPLOAD,
   POST_DENARI_UPLOAD_SUCCESS,
   POST_DENARI_UPLOAD_FAILURE
} from './types';

function postDenariUpload(){
   return{
      type: POST_DENARI_UPLOAD
   }
}

function postDenariUploadSuccess(json){
   return{
      type: POST_DENARI_UPLOAD_SUCCESS,
      payload: json
   }
}

function postDenariUploadFailure(error){
   return{
      type: POST_DENARI_UPLOAD_FAILURE,
      payload: error
   }
}

export default function requestPostDenariUpload(formData, token) {
   return dispatch => {
      dispatch(postDenariUpload());
      return denariUploadRequest(formData, token)
         .then((json) => {
            return dispatch(postDenariUploadSuccess(json));
         })
         .catch((error) => {
            return error.json().then((errors) => {
               return dispatch(postDenariUploadFailure(errors));
            })
         });
   };
}
