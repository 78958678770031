import { deleteFileUploadRequest } from '../api/file-uploads';

import {
    DELETE_FILE_UPLOAD,
    DELETE_FILE_UPLOAD_SUCCESS,
    DELETE_FILE_UPLOAD_FAILURE
} from '../actions/types';

function deleteFileUpload() {
    return {
        type: DELETE_FILE_UPLOAD
    };
}

function deleteFileUploadSuccess(json) {
    return {
        type: DELETE_FILE_UPLOAD_SUCCESS,
        payload: json
    };
}

function deleteFileUploadFailure(error) {
    return {
        type: DELETE_FILE_UPLOAD_FAILURE,
        payload: error
    };
}

export default function requestDeleteFileUpload(file_id, token) {
    return dispatch => {
        dispatch(deleteFileUpload());
        return deleteFileUploadRequest(file_id, token)
            .then((json) => {
                return dispatch(deleteFileUploadSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(deleteFileUploadFailure(errors));
                })
            });
    };
}
