import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import { connect } from 'react-redux';
import {
  selectProcessedExpenseReports,
  selectReimbursedAmount,
  selectStartingUnreimbursedAmount,
  selectUnreimbursedAmount
} from "../../../reducers/distribution-form";
import {formatCurrency} from "../../../utilities/currency-helpers";

const tableStyle = {
  margin: '0 auto',
  width: '100%',
};

const thStyle = {
  textAlign: 'left',
  borderBottom: '1px solid',

};

class ExpenseListDialog extends React.Component {
  rows = () => {
    const { reimbursedAmount, processedExpenseReports, unreimbursedAmount = 0, startingUnreimbursedAmount } = this.props;
    const exps = (processedExpenseReports || []).map(report => (
      <tr key={report.reportId}>
        <td>Expense Report ID: {report.reportId}</td>
        <td>{formatCurrency(report.amount)}</td>
        <td>{formatCurrency(report.amountReimbursed)}</td>
        <td style={{
          color: report.unreimbursedAmount ? 'red' : 'inherit',
        }}>{formatCurrency(report.unreimbursedAmount)}</td>
      </tr>
    ));
    if (typeof reimbursedAmount === 'number' && !isNaN(reimbursedAmount) && startingUnreimbursedAmount !== 0) {
      exps.push((
        <tr key="reimbursed_amount">
          <td>Unreimbursed Reimbursement</td>
          <td>{formatCurrency(startingUnreimbursedAmount || 0)}</td>
          <td>{formatCurrency(reimbursedAmount || 0)}</td>
          <td style={{ color: unreimbursedAmount ? 'red' : 'inherit' }}>{formatCurrency(unreimbursedAmount || 0)}</td>
        </tr>
      ));
    }
    return exps;
  };

  render = () => {
    return (
      <Dialog
        modal={true}
        open={this.props.open}
        contentStyle={{width: '60%'}}
      >
        <Subheader className="curved-style" style={{marginBottom: 5}}>
          Reimbursements
        </Subheader>
        <table style={tableStyle}>
          <thead>
          <tr>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Amount</th>
            <th style={thStyle}>Reimbursed</th>
            <th style={thStyle}>Unreimbursed</th>
          </tr>
          </thead>
          <tbody>
          {
            this.rows()
          }
          </tbody>
        </table>
        <div style={{textAlign: 'center', marginTop: 40}}>
          <RaisedButton
            className="distribution-warning-cancel"
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Close"
            onClick={this.props.onClose}
          />
        </div>
      </Dialog>
    );
  };
}

export default connect(state => {
  return {
    processedExpenseReports: selectProcessedExpenseReports(state),
    reimbursedAmount: selectReimbursedAmount(state),
    unreimbursedAmount: selectUnreimbursedAmount(state),
    startingUnreimbursedAmount: selectStartingUnreimbursedAmount(state),
  };
}, {})(ExpenseListDialog);
