/**
 * Generates a URL safe filename
 * @param fileName
 * @returns {string}
 */
export function sanitizeFileName(fileName) {
  if (!fileName || typeof fileName !== 'string' || !fileName.length) return;
  const [ name, ext ] = fileName.split('.');
  if (!name || !ext) return;
  return `${name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.${ext}`;
}

/**
 * Encodes file paths. Assumes paths have form:
 * '<directory>/<filename>.<extension>'
 * @param path
 * @returns {string}
 */
export function encodeFilePath(path) {
  if (!path || typeof path !== 'string' || !path.length) return;
  const [ dir, name ] = path.split('/');
  return `${dir}/${encodeURIComponent(name)}`;
}