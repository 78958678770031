import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import { List } from 'material-ui/List';
import { doesItemExistInState } from '../../utilities/exist-in-state';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import DropDownSelect from '../fields/dropdown-select-field';
import { distance_types } from '../../constants/options';
import ReimbursementListItems from '../blocks/reimbursement-list-items';
import MileageListItems from '../blocks/mileage-list-items';
import MobileFileUpload from '../blocks/mobile-file-uploads';
import Dialog from 'material-ui/Dialog';
import DatePickerInput from '../fields/date-picker-input';
import { updateForm } from '../../utilities/update';
import { Link } from 'react-router';
import moment from 'moment';
import FontIcon from 'material-ui/FontIcon';
import Check from '../fields/checkbox';
import expenseOptions from '../../utilities/expenseOptions';


import {
   POST_EXPENSE_FAILURE,
   GET_EXPENSE_REPORT_SUCCESS,
   POST_MILEAGE_SUCCESS,
   POST_MILEAGE_FAILURE,
   POST_EXPENSE_SUCCESS,
   POST_FILE_UPLOAD_SUCCESS,
   POST_FILE_UPLOAD_FAILURE,
   SAVE_EXPENSE_REPORT_SUCCESS,
   SAVE_EXPENSE_REPORT_FAILURE,
   SUBMIT_EXPENSE_REPORT_SUCCESS,
   SUBMIT_EXPENSE_REPORT_FAILURE,
   DELETE_FILE_UPLOAD_SUCCESS,
   DELETE_FILE_UPLOAD_FAILURE,
   DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
   DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE
} from '../../actions/types';


class MissionaryEditAdvanceReport extends Component {

   constructor(props) {
      super(props);
      this.state = {
         open_reimbursement: false,
         open_mileage: false,
         open_edit_reimbursement: false,
         open_edit_mileage: false,
         type: '',
         distance_type: '',
         foreignCurrency: '',
         exchangeRate: '',
         odo_start: '',
         odo_end: '',
         deleteExpenseReport: false,
         approvedAmount: '',
         startDate: '',
         endDate: '',
         usdChecked: false,
         mileage_reimbursement: '',
         forceUpdate: 0
      };
   }

   componentDidMount() {
      const doesExpenseReportExistInState = doesItemExistInState(this.props.expenseReports.expenseReports, this.props.params.expense_report_id);
      if (!doesExpenseReportExistInState)
         this.getExpenseReport();
      else
         this.updatePage(doesExpenseReportExistInState);

      const that = this;
      this.props.dispatch(Actions.requestGetAllSettings(Cookies.get('token')))
         .then(function (result) {
            result.payload.forEach((setting) => {
               if ('mileage_reimbursement' === setting.setting_name)
                  that.setState({ mileage_reimbursement: setting.setting_value })
            });
         });
      this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));
      this.props.dispatch(Actions.requestGetAllHoldAccounts(Cookies.get('token')));
   }

   componentDidUpdate(prevProps, prevState) {
      const doesExpenseReportExistInState = doesItemExistInState(this.props.expenseReports.expenseReports, this.props.params.expense_report_id);
      if (doesExpenseReportExistInState) {
         document.getElementById('start_date').value = doesExpenseReportExistInState.start_date;
         document.getElementById('end_date').value = doesExpenseReportExistInState.end_date;
      }
   }

   getExpenseReport() {
      this.props.dispatch(Actions.requestGetExpenseReport(this.props.params.expense_report_id, Cookies.get('token')))
         .then((result) => {
            if (result.type === GET_EXPENSE_REPORT_SUCCESS) {
               this.updatePage(result.payload);
            } else {
               this.props.dispatch(Actions.newError(result.payload.message));
            }
         })
   }

   updatePage(expenseReport) {
      updateForm('update-expense-report-form', expenseReport);
      this.setState({ type: expenseReport.type });
      this.setState({ distance_type: expenseReport.distance_type });
      const advance_request = _.get(expenseReport, 'advance_request', []);
      this.setState({ approvedAmount: advance_request.amount_approved.toFixed(2) });
      this.setState({ startDate: moment(expenseReport.start_date).format('MM/DD/YYYY') });
      this.setState({ endDate: moment(expenseReport.end_date).format('MM/DD/YYYY') })
      if (expenseReport.expense) {
         if (expenseReport.expense[expenseReport.expense.length - 1]) {
            if (expenseReport.expense[expenseReport.expense.length - 1].exchange_rate === 1) {
               this.setState({ usdChecked: true });
            }
            this.setState({ exchangeRate: expenseReport.expense[expenseReport.expense.length - 1].exchange_rate })
         }
      }
   }

   saveExpenseReport = (e, skipRedirect = false) => {
      if (e) {
         e.preventDefault();
      }
      const form = document.getElementById('update-expense-report-form');
      let formData = new FormData(form);
      const that = this;
      this.props.dispatch(Actions.requestSaveExpenseReport(this.props.params.expense_report_id, formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === SAVE_EXPENSE_REPORT_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully saved your expense report'));
               that.setState({ distance_type: result.payload.distance_type });
               if (!skipRedirect) {
                  browserHistory.push('/advance-request');
               }
            } else if (result.type === SAVE_EXPENSE_REPORT_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
            }
         })
   }

   submitExpenseReport(e) {
      e.preventDefault();

      const today = (new Date()).toISOString().split("T")[0];

      const form = document.getElementById('update-expense-report-form');
      let formData = new FormData(form);
      formData.append('date_submitted', today);

      const that = this;
      this.props.dispatch(Actions.requestSubmitExpenseReport(this.props.params.expense_report_id, formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === SUBMIT_EXPENSE_REPORT_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully submitted your expense report'));
               browserHistory.push('/advance-request');
            } else if (result.type === SUBMIT_EXPENSE_REPORT_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
            }
         })
   }

   submitExpense(e) {
      e.preventDefault();
      const form = document.getElementById('add-expense-form');
      let formData = new FormData(form);
      const that = this;
      this.props.dispatch(Actions.requestPostExpense(formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === POST_EXPENSE_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully added a new expense'));
               // that.getExpenseReport();
               that.setState({ foreignCurrency: '' });
               that.handleClose();
            } else if (result.type === POST_EXPENSE_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   submitMileage(e) {
      e.preventDefault();
      const form = document.getElementById('add-mileage-form');
      let formData = new FormData(form);
      const that = this;
      this.props.dispatch(Actions.requestPostMileage(formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === POST_MILEAGE_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully added a new mileage item'));
               // that.getExpenseReport();
               that.setState({ odo_start: '' });
               that.setState({ odo_end: '' });
               that.handleClose();
            } else if (result.type === POST_MILEAGE_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   submitFile(e) {
      e.preventDefault();
      if (document.getElementById('file_path').value === '') {
         this.props.dispatch(Actions.newError('No file has been chosen.'));
      } else {
         const form = document.getElementById('add-file-form');
         let formData = new FormData(form);
         const that = this;
         this.props.dispatch(Actions.requestPostFileUpload(formData, Cookies.get('token')))
            .then(function (result) {
               if (result.type === POST_FILE_UPLOAD_SUCCESS) {
                  that.props.dispatch(Actions.newMessage('You have successfully added a new file'));
                  document.getElementById('file_path').value = '';
                  that.incrementForceUpdate();
                  that.handleClose();
               } else if (result.type === POST_FILE_UPLOAD_FAILURE) {
                  if (result.payload.message) {
                     that.props.dispatch(Actions.newError(result.payload.message));
                  } else if (result.payload.file) {
                     that.props.dispatch(Actions.newError(result.payload.file));
                  }

               }
            });
      }
   }

   removeFile(id) {
      const that = this;
      this.props.dispatch(Actions.requestDeleteFileUpload(id, Cookies.get('token')))
         .then(function (result) {
            if (result.type === DELETE_FILE_UPLOAD_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully removed a product image'));
               that.forceUpdate();
            } else if (result.type === DELETE_FILE_UPLOAD_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   deleteUnsubmittedExpenseReport(e) {
      e.preventDefault();
      const that = this;
      this.props.dispatch(Actions.requestDeleteUnsubmittedExpenseReport(this.props.params.expense_report_id, Cookies.get('token')))
         .then(function (result) {
            if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
               that.handleClose();
               browserHistory.push('/dashboard/' + that.props.auth.user.id);
            } else if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
               that.handleClose();
            }
         })
   }

   options = (type) => {
      const categories = this.props.categories.categories;
      return expenseOptions(categories, type);
   };

   getHoldAccountOptions = () => {
      const holdAccounts = _.values(this.props.donations.holdAccounts);
      const userId = this.props.auth.user.id;
      const holdAccountOptions = holdAccounts
         .filter(account => {
            return account.user_id === userId;
         })
         .map(account => {
            return {
               value: account.id,
               text: account.fund_name
            };
         });

      return holdAccountOptions;
   };

   handleOpenReimbursement = () => {
      this.setState({ open_reimbursement: true });
   };

   handleOpenMileage = () => {
      if (this.state.distance_type === "none") {
         this.props.dispatch(Actions.newError('Must Select A Distance Type to Add A Mileage Expense'));
      } else {
         this.setState({ open_mileage: true })
      }
   }

   openDeleteDialogBox(event) {
      event.preventDefault();
      this.setState({ deleteExpenseReport: true });
   }

   handleClose = () => {
      this.setState({ open_mileage: false });
      this.setState({ open_reimbursement: false });
      this.setState({ deleteExpenseReport: false });
   };

   incrementForceUpdate = () => {
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({
         forceUpdate
      });
   }

   foreignCurrency(e) {
      this.setState({ foreignCurrency: e });
   }

   exchangeRate(e) {
      this.setState({ exchangeRate: e });
   }

   odoStart(e) {
      this.setState({ odo_start: e });
   }

   odoEnd(e) {
      this.setState({ odo_end: e });
   }

   totalDoesNotEqualApproved(e, total_above_approved, total_below_approved) {
      e.preventDefault();
      if (total_above_approved) {
         this.props.dispatch(Actions.newError('Expenses over the approved amount must be added to an expense report.'));
      } else if (total_below_approved) {
         this.props.dispatch(Actions.newError('Please add expenses until the report total meets the amount approved.'));
      }
   }

   updateDistanceType(e) {
      this.setState({ distance_type: e }, () => {
         this.saveExpenseReport(false, true);
      })
   }

   usdChecked(e) {
      this.setState({ usdChecked: e });
      if (e === true) {
         this.setState({ exchangeRate: 1 });
      } else if (e !== true) {
         this.setState({ exchangeRate: '' })
      }
   }

   render() {

      const actions_post_expense = [
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#525252' }
            label="Cancel"
            onClick={ this.handleClose }
            style={ { marginRight: 3 } }
         />,
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#1E88E5' }
            label="Add"
            onClick={ (e) => this.submitExpense(e) }
         />
      ];

      const actions_post_mileage = [
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#525252' }
            label="Cancel"
            onClick={ this.handleClose }
            style={ { marginRight: 3 } }
         />,
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#1E88E5' }
            label="Add"
            onClick={ (e) => this.submitMileage(e) }
         />
      ];

      const actions_delete_expense_report = [
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#525252' }
            label="Cancel"
            onClick={ this.handleClose }
            style={ { marginRight: 3 } }
         />,
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#d40a0a' }
            label="Delete"
            onClick={ (event) => this.deleteUnsubmittedExpenseReport(event) }
         />
      ];

      const doesExpenseReportExistInState = doesItemExistInState(this.props.expenseReports.expenseReports, this.props.params.expense_report_id);

      //calculate foreign currency into USD.
      let total_usd = 0;
      total_usd = isFinite(this.state.foreignCurrency / this.state.exchangeRate) ? (this.state.foreignCurrency / this.state.exchangeRate) : 0;


      //calculate mileage reimbursement per mile. If km, convert to mi first.
      let total_distance = this.state.odo_end - this.state.odo_start;
      let total_cost = 0;
      let mileageReimbursement = this.state.mileage_reimbursement;
      // eslint-disable-next-line
      if (this.state.distance_type == 'km') {
         let mi_conversion = total_distance * 0.621371;
         if (this.state.odo_end && this.state.odo_start) {
            total_cost = mi_conversion * mileageReimbursement;
         }
      } else {
         if (this.state.odo_end && this.state.odo_start) {
            total_cost = total_distance * mileageReimbursement
         }
      }

      let isUSDChecked = this.state.usdChecked;

      const reimbursement_form = (
         <form className="add-expense-form-div" id="add-expense-form" onSubmit={ (event) => this.submitExpense(event) } encType="multipart/form-data">
            <Check
               id="usd"
               label="Using USD?"
               labelPosition="right"
               labelStyle={ { textAlign: 'left' } }
               style={ { marginTop: 15 } }
               checked={ this.state.usdChecked }
               onCheck={ (event) => this.usdChecked(event) }
            />
            <Input
               className="inline-inputs" id="field_currency"
               floatingLabelText={ isUSDChecked ? 'USD Amount' : "Foreign Currency Amount" }
               errors={ this.props.expenseReports }
               onChange={ (event) => this.foreignCurrency(event) }
            />
            <Input
               className="inline-inputs" id="exchange_rate" floatingLabelText="Exchange Rate"
               errors={ this.props.expenseReports } onChange={ (event) => this.exchangeRate(event) }
               value={ this.state.exchangeRate }
               disabled={ isUSDChecked }
               description={ !isUSDChecked ? "Foreign currency divided by the exchange rate = US dollar" : '' }
            />
            <input
               type="hidden"
               name="exchange_rate"
               id="exchange_rate"
               value={ this.state.exchangeRate }
            />
            <div style={ { float: "left", width: "100%" } }>
               <Input
                  disabled={ true }
                  className="inline-inputs"
                  id="usd_amount"
                  floatingLabelText="Total in USD"
                  errors={ this.props.expenseReports }
                  value={ total_usd.toFixed(2) } prefix="$"
               />
               <input
                  type="hidden"
                  name="usd_amount"
                  id="usd_amount"
                  value={ total_usd }
               />
               <DatePickerInput
                  className="inline-inputs"
                  id="date"
                  floatingLabelText="Date *"
                  errors={ this.props.expenseReports }
               />
            </div>
            <div className="inline-inputs" style={ { marginBottom: 10, marginTop: 10 } }>
               <div style={ { display: 'flex', flexDirection: 'column' } }>
                  <div>
                     <DropDownSelect
                        style={ { marginBottom: '5px' } }
                        id="expense_code"
                        floatingLabelText="Expense Type"
                        floatingLabelStyle={ { left: 0 } }
                        errors={ this.props.expenseReports }
                        options={ this.options('expense') }
                     />
                  </div>
                  <div>
                     <Link
                        className="type-descriptions"
                        target="_blank"
                        to={ '/expense-descriptions/reimbursement' }
                     >
                        View
                        Type Descriptions
                     </Link>
                     <input
                        type="hidden"
                        name="expense_report_id"
                        id="expense_report_id"
                        value={ this.props.params.expense_report_id }
                     />
                  </div>
               </div>
            </div>
            <DropDownSelect
               className="inline-inputs"
               style={ { marginBottom: 10, marginTop: 10, float: 'left' } }
               id="hold_account_id"
               floatingLabelText="From Held Account?"
               floatingLabelStyle={ { left: 0 } }
               errors={ this.props.expenseReports }
               options={ this.getHoldAccountOptions() }
            />
         </form>
      );


      const mileage_form = (
         <form id="add-mileage-form" onSubmit={ (e) => this.submitMileage(e) }
            encType="multipart/form-data">
            <Input className="inline-inputs" id="odometer_start" floatingLabelText="Odometer Start"
               errors={ this.props.expenseReports } onChange={ (e) => this.odoStart(e) }
               value={ this.state.odo_start } />
            <Input className="inline-inputs" id="odometer_end" floatingLabelText="Odometer End"
               errors={ this.props.expenseReports } onChange={ (e) => this.odoEnd(e) }
               value={ this.state.odo_end } />
            <Input disabled={ true } className="inline-inputs" id="total_distance" floatingLabelText="Total Distance"
               errors={ this.props.expenseReports } value={ total_distance } />
            <input type="hidden" name="total_distance" id="total_distance"
               value={ total_distance } />
            <Input className="inline-inputs" id="total_cost" floatingLabelText="Calculated Amount"
               errors={ this.props.expenseReports } value={ total_cost.toFixed(2) } />
            <input type="hidden" name="total_cost" id="total_cost"
               value={ total_cost } />
            <DatePickerInput
               className="inline-inputs"
               id="date"
               floatingLabelText="Date *"
               errors={ this.props.expenseReports }
            />
            <div className="inline-inputs">
               <DropDownSelect style={ { marginBottom: '5px' } } id="type"
                  floatingLabelText="Expense Type"
                  floatingLabelStyle={ { left: 0 } }
                  errors={ this.props.expenseReports } options={ this.options('mileage') } />
               <Link className="type-descriptions" target="_blank" to={ '/expense-descriptions/mileage' }>View
                        Type Descriptions</Link>
               <input type="hidden" name="expense_report_id" id="expense_report_id"
                  value={ this.props.params.expense_report_id } />
            </div>
         </form>
      );

      let expense_report_total_amount = 0;


      const reimbursement_items = _.get(doesExpenseReportExistInState, 'expense', []).map((reimbursement, index) => {
         expense_report_total_amount += reimbursement.usd_amount;

         const reimbursement_date = moment(reimbursement.date).format('MM/DD/YYYY');
         const category_text = reimbursement.category.expense_category;
         const hold_account = _.isNull(reimbursement.hold_account_id) ? 'NO' : reimbursement.hold_account.fund_name;
         return (
            <ReimbursementListItems key={ index } id={ reimbursement.id } expense_id={ reimbursement.id }
               primaryText={ '$' + reimbursement.usd_amount.toFixed(2) }
               secondaryText={ reimbursement_date + ' - ' + category_text }
               tertiaryText={ 'From Hold Account: ' + hold_account }
               distance_type={ this.state.distance_type }
               expense_report_id={ this.props.params.expense_report_id }
               expense={ reimbursement }
            />
         )
      });


      const mileage_items = _.get(doesExpenseReportExistInState, 'mileage', []).map((mileage, index) => {
         expense_report_total_amount += mileage.total_cost;
         const mileage_date = moment(mileage.date).format('MM/DD/YYYY');
         const category_text = mileage.category.expense_category;
         return (
            <div key={ index }>
               <MileageListItems mileage_id={ mileage.id }
                  // eslint-disable-next-line
                  primaryText={ mileage.total_distance + ' ' + this.state.distance_type + ' - ' + '$' + mileage.total_cost.toFixed(2) }
                  secondaryText={ mileage_date + ' - ' + category_text }
                  tertiaryText={ '' } expense_report_id={ this.props.params.expense_report_id }
                  mileage={ mileage }
                  distanceType={ this.state.distance_type }
                  reportType="advance"
                  mileageReimbursement={ this.state.mileage_reimbursement }
               />
            </div>
         )
      });

      let total_below_approved = false;
      let total_above_approved = false;
      expense_report_total_amount = expense_report_total_amount.toFixed(2)
      //hacks added to get the decimal math to work
      //multiply currency by 100 in order to use integer math
      //expense report total amount is +/- one dollar of approved amount
      if (expense_report_total_amount * 100 < (this.state.approvedAmount - 1) * 100) {
         total_below_approved = true;
      } else if (expense_report_total_amount * 100 > (this.state.approvedAmount + 1) * 100) {
         total_above_approved = true;
      }

      const fileUploadsTable = [];
      const fileUploads = _.get(doesExpenseReportExistInState, 'file_upload', []);
      const columns = [
         { key: 'file_path', label: 'Image Preview', 'sortable': false },
         { key: 'file_name', label: 'File Name', 'sortable': false },
         { key: 'updated_at', label: 'Updated At', 'sortable': false },
         { key: 'delete_button', label: 'Delete', 'sortable': false },
         { key: 'view', label: 'View', 'sortable': false },
      ];
      for (const key in fileUploads) {
         fileUploadsTable.push({
            file_path: fileUploads[key].file_path.toLowerCase().indexOf('pdf') > -1 ?
               <FontIcon className="material-icons">picture_as_pdf</FontIcon> :
               <img
                  key={ "file-" + [key] }
                  src={ process.env.REACT_APP_API_ROOT + '/' + fileUploads[key].file_path }
                  style={ { width: '100px' } }
                  alt='remove-preview'
               />,
            file_name: fileUploads[key].file_path.split('/')[1],
            updated_at: fileUploads[key].updated_at,
            delete_button: <RaisedButton
               primary={ true } onClick={ () => this.removeFile(fileUploads[key].id) }
               labelColor="#ffffff"
               label="Delete File"
            />,
            view: <ButtonLink
               backgroundColor="#1E88E5"
               to={ process.env.REACT_APP_API_ROOT + '/' + fileUploads[key].file_path }
               blank_target={ true }
               labelColor="#ffffff"
               label="View File"
            />
         });
      }

      return (
         <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
            <div className="missionary-new-report">
               <span><h2 className="form-heading" style={ { float: 'left' } }>Edit Advance Expense Report</h2></span>
               <span className="mobile-span-report-total-cost" style={ { display: "inline-block", float: 'right', margin: '0 2%' } }><h2
                  className="report-total-cost">Expense Report Total: ${ expense_report_total_amount }</h2></span>
               <div className="form-block-container">
                  <form id="update-expense-report-form" onSubmit={ (e) => this.saveExpenseReport(e) }
                     encType="multipart/form-data">
                     <div className="form-block">
                        <Subheader className="table-style">Advance Expense Report Details</Subheader>
                        <div className="form-section new-report-section">
                           <input type="hidden" id="type" name="type" value={ this.state.type } />
                           <input type="hidden" name="distance_type" id="distance_type" value={ this.state.distance_type } />
                           <Input className="inline-inputs" id="amount_approved" name="amount_approved" floatingLabelText="Amount Approved" disabled={ true } value={ '$' + this.state.approvedAmount } />
                           <Input disabled={ true } className="inline-inputs" id="start_date" floatingLabelText="Start Date" value={ this.state.startDate } />
                           <Input disabled={ true } className="inline-inputs" id="end_date" floatingLabelText="End Date" value={ this.state.endDate } />
                           <input type="hidden" name="user_id" id="user_id" />
                           <input type="hidden" name="total_amount" id="total_amount" value={ expense_report_total_amount } />
                           <input type="hidden" name="advance_request_id" id="advance_request_id" value={ this.props.params.advance_request_id } />
                        </div>
                        <p style={ { paddingLeft: '3%', paddingBottom: '1%', fontSize: '14px' } }>These dates will populate automatically based on the earliest expense added and the latest expense added</p>
                     </div>
                  </form>
                  <div className="form-block">
                     <Subheader className="table-style">Expenses</Subheader>
                     <div className="form-section new-report-section">
                        <List>
                           <div className="list-view">
                              <Subheader className="message-list-subheader">Entries</Subheader>
                              { reimbursement_items }
                           </div>
                        </List>
                        <div>
                           <RaisedButton label={ "Add Expense" } labelColor={ "#ffffff" }
                              backgroundColor={ "#1E88E5" } onClick={ this.handleOpenReimbursement } />
                           <Dialog
                              actions={ actions_post_expense }
                              modal={ true }
                              open={ this.state.open_reimbursement }
                              contentStyle={ { width: '90%' } }
                              autoScrollBodyContent={ true }
                              actionsContainerStyle={ { textAlign: 'center', paddingBottom: 20 } }
                           >
                              <Subheader className="curved-style">Expense Entry</Subheader>
                              { reimbursement_form }
                           </Dialog>
                        </div>
                     </div>
                  </div>
                  <div className="form-block">
                     <Subheader className="table-style">Mileage Expenses</Subheader>
                     <div className="form-section new-report-section">
                        <List>
                           <div className="list-view">
                              <Subheader className="message-list-subheader">Entries</Subheader>
                              <DropDownSelect id="distance_type" floatingLabelText="Km/Mi"
                                 errors={ this.props.expenseReports } options={ distance_types }
                                 value={ this.state.distance_type } onChange={ (e) => this.updateDistanceType(e) } />
                              { mileage_items }
                           </div>
                        </List>
                        <div>
                           <RaisedButton label={ "Add Trip" } labelColor={ "#ffffff" }
                              backgroundColor={ "#1E88E5" } onClick={ this.handleOpenMileage } />
                           <Dialog
                              actions={ actions_post_mileage }
                              modal={ true }
                              open={ this.state.open_mileage }
                              contentStyle={ { width: '90%' } }
                              autoScrollBodyContent={ true }
                              actionsContainerStyle={ { textAlign: 'center', paddingBottom: 20 } }
                           >
                              <Subheader className="curved-style">Add Trip</Subheader>
                              { mileage_form }
                           </Dialog>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="form-block">
                  <Subheader className="table-style">Receipt Upload</Subheader>
                  <form id="add-file-form" onSubmit={ (e) => this.submitFile(e) }
                     encType="multipart/form-data">
                     <div className="form-section new-report-section">
                        <Subheader className="message-list-subheader">Upload Here (max file size: 5MB)</Subheader>
                        <input type="file" id="file_path" name="file" />
                        <input type="hidden" id="expense_report_id" name="expense_report_id"
                           value={ this.props.params.expense_report_id } />
                        <RaisedButton labelColor="#ffffff" backgroundColor={ '#525252' } label="Add File"
                           onClick={ (e) => this.submitFile(e) } />
                     </div>
                  </form>
                  <div className="form-section new-report-section desktop-images">
                     <Subheader className="message-list-subheader">Previous Uploads</Subheader>
                     <Table data={ fileUploadsTable } columns={ columns } forceUpdate={ this.state.forceUpdate } />
                  </div>
                  <div className="form-section new-report-section mobile-images">
                     <MobileFileUpload expense_reports={ fileUploads } />
                  </div>
               </div>
               <Dialog
                  actions={ actions_delete_expense_report }
                  modal={ true }
                  open={ this.state.deleteExpenseReport }
                  contentStyle={ { width: '90%' } }
                  actionsContainerStyle={ { textAlign: 'center', paddingBottom: 20 } }
               >
                  <Subheader className="curved-style">Are you sure you want to delete this expense report?</Subheader>
               </Dialog>
            </div>
            <div className="form-buttons" style={ { marginBottom: 40 } }>
               <RaisedButton
                  className="edit-report-form-buttons"
                  labelColor="#ffffff"
                  backgroundColor='#d40a0a'
                  label="Delete"
                  onClick={ (event) => this.openDeleteDialogBox(event) }
               />
               <RaisedButton
                  labelColor="#ffffff"
                  backgroundColor={ '#1E88E5' }
                  label="Finish Later"
                  onClick={ (e) => this.saveExpenseReport(e) }
               />
               <RaisedButton
                  labelColor="#ffffff"
                  backgroundColor={ '#1E88E5' }
                  label="Submit"
                  onClick={
                     (total_above_approved || total_below_approved) ?
                        (e) => this.totalDoesNotEqualApproved(e, total_above_approved, total_below_approved) :
                        (e) => this.submitExpenseReport(e)
                  }
               />
            </div>
         </div>
      )
   }
}

export default connect(
   state => ({
      auth: state.rootReducer.auth,
      expenseReports: state.rootReducer.expenseReports,
      users: state.rootReducer.users,
      fileUploads: state.rootReducer.fileUploads,
      donations: state.rootReducer.donations,
      categories: state.rootReducer.categories
   })
)(MissionaryEditAdvanceReport);
