import React, {Component} from 'react';
import Checkbox from 'material-ui/Checkbox';

const styles = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16
  },
};


class Check extends Component {
  state = {
    checked: this.props.checked,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.checked !== nextProps.checked) {
      this.setState({checked: nextProps.checked});
    }
  }

  updateCheck() {
    this.setState((oldState) => {
      if(this.props.onCheck) {
        this.props.onCheck(!oldState.checked);
      }
      return {
        checked: !oldState.checked,
      };
    });
  }

  render() {
    return (
      <div style={styles.block}>
        <input type="hidden" id={this.props.id} name={this.props.id} value="0"/>
        <Checkbox
          id={this.props.id}
          name={this.props.id}
          value="1"
          label={this.props.label}
          checked={this.state.checked}
          onCheck={this.updateCheck.bind(this)}
          style={this.props.style ? this.props.style : styles.checkbox}
          labelStyle={this.props.labelStyle}
          labelPosition={this.props.labelPosition}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}

export default Check;
