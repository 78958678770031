import {patchCategoryRequest} from '../api/categories';

import{
    PATCH_CATEGORY,
    PATCH_CATEGORY_SUCCESS,
    PATCH_CATEGORY_FAILURE
} from './types';

function patchCategory(){
	return{
		type: PATCH_CATEGORY
	}
}

function patchCategorySuccess(json){
	return{
		type: PATCH_CATEGORY_SUCCESS,
		payload: json
	}
}

function patchCategoryFailure(error){
	return{
		type: PATCH_CATEGORY_FAILURE,
		payload: error
	}
}

export default function requestPatchCategory(category_id, formData, token) {
    return dispatch => {
        dispatch(patchCategory());
        return patchCategoryRequest(category_id, formData, token)
            .then((json) => {
                return dispatch(patchCategorySuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchCategoryFailure(errors));
                })
            });
    };
}