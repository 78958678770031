import {updateProcessRequest} from '../api/expense-reports';


import{
    PATCH_PROCESS,
    PATCH_PROCESS_SUCCESS,
    PATCH_PROCESS_FAILURE
} from '../actions/types';

function updateProcess() {
    return {
        type: PATCH_PROCESS
    };
}

function updateProcessSuccess(json) {
    return {
        type: PATCH_PROCESS_SUCCESS,
        payload: json
    };
}

function updateProcessFailure(error) {
    return {
        type: PATCH_PROCESS_FAILURE,
        payload: error
    };
}

export default function requestUpdateProcess(id, formData, token) {
    return dispatch => {
        dispatch(updateProcess());
        return updateProcessRequest(id, formData, token)
            .then((json) => {
                return dispatch(updateProcessSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(updateProcessFailure(errors));
                })
            });
    };
}