import {patchAdvanceRequestRequest} from '../api/advance-requests';

import{
    PATCH_ADVANCE_REQUEST,
    PATCH_ADVANCE_REQUEST_SUCCESS,
    PATCH_ADVANCE_REQUEST_FAILURE
} from './types';


function patchAdvanceRequest(){
    return{
        type: PATCH_ADVANCE_REQUEST
    }
}

function patchAdvanceRequestSuccess(json){
    return{
        type: PATCH_ADVANCE_REQUEST_SUCCESS,
        payload: json
    }
}

function patchAdvanceRequestFailure(error){
    return{
        type: PATCH_ADVANCE_REQUEST_FAILURE,
        payload: error
    }
}


export default function requestPatchAdvanceRequest(advance_request_id, formData,token) {
    return dispatch => {
        dispatch(patchAdvanceRequest());
        return patchAdvanceRequestRequest(advance_request_id, formData, token)
            .then((json) => {
                return dispatch(patchAdvanceRequestSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchAdvanceRequestFailure(errors));
                })
            });
    };
}
