import React, {PureComponent} from 'react';
import DataTables from 'material-ui-datatables';

const ROW_SIZES = [
  10, 30, 50, 100
];
const STYLE_PROPS = {
  tableBodyStyle: {
    overflowX: 'none',
    overflowY: 'none'
  },
  tableWrapperStyle: {
    overflow: 'none'
  },
  tableRowStyle: {
    cursor: 'pointer'
  }
}

class PaginatedTable extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onRowSizeChange = (idx) => {
    this.props.onRowSizeChange(ROW_SIZES[idx]);
  }

  onRowSelection = selected => {
    if (!this._isMounted) return;
    if (selected === 'all') {
      this.setState({ selectedRows: this.props.data.map((_, idx) => idx) });
      this.props.onRowSelection(this.props.data);
    }
    else if (selected === 'none') {
      this.setState({ selectedRows: [] });
      this.props.onRowSelection([]);
    }
    else if (Array.isArray(selected)) {
      this.setState({ selectedRows: selected });
      this.props.onRowSelection(selected.map(idx => this.props.data[idx]));
    }
  }

  render() {
    return (
      <DataTables
        showFooterToolbar={this.props.showFooterToolbar !== '' ? this.props.showFooterToolbar : true}
        showHeaderToolbarFilterIcon={false}
        selectable={this.props.selectable}
        multiSelectable={this.props.selectable}
        filterHintText={this.props.filterHintText ? this.props.filterHintText : 'Search'}
        enableSelectAll={!!this.props.enableSelectAll}
        showRowHover={true}
        headerToolbarMode={this.props.toolbarMode ? this.props.toolbarMode : 'filter'}
        showHeaderToolbar={true}
        columns={this.props.columns || []}
        data={this.props.data || []}
        showCheckboxes={this.props.selectable}
        rowSizeList={ROW_SIZES}
        onNextPageClick={this.props.onNextPageClick}
        onPreviousPageClick={this.props.onPreviousPageClick}
        onFilterValueChange={this.props.onFilterValueChange}
        onSortOrderChange={this.props.onSortOrderChange}
        onRowSizeChange={this.onRowSizeChange}
        onRowSelection={this.onRowSelection}
        rowSize={this.props.rowSize || 10}
        page={this.props.page}
        count={this.props.totalCount}
        selectedRows={this.state.selectedRows}
        initialSort={this.props.initialSort}
        {
          ...STYLE_PROPS
        }
      />
    )
  }
}

export default PaginatedTable;
