import {
  ADD_HOLD_ACCOUNT_TRANSACTION,
  APPROVE_ADVANCE_REQUEST,
  APPROVE_DONATION,
  DELETE_HOLD_ACCOUNT_TRANSACTION,
  PROCESS_EXPENSE_REPORTS,
  RESET_DISTRIBUTION_FORM,
  SET_ADVANCE_REQUEST_AMOUNT_APPROVED,
  SET_DISTRIBUTION_FORM_USER_ID,
  SET_DISTRIBUTION,
  SET_DONATION_UPLOAD,
  SET_END_DATE,
  SET_FEDERAL_INCOME_TAX,
  SET_HOLD_ACCOUNTS,
  SET_MEDICARE,
  SET_SOCIAL_SECURITY,
  SET_START_DATE,
  SET_TOTAL_PAYROLL,
  APPROVE_HOLD_ACCOUNT_TRANSACTION,
  DISTRIBUTABLE_FUNDS_CHANGE,
  SET_MID_MONTH_DISBURSEMENT,
  SET_SALARY,
  SET_DISTRIBUTION_COMMENTS,
  SET_WIRE_FEE,
  SET_HOUSING_ALLOWANCE,
  SET_INSURANCE_PAYMENT,
  SET_MANUAL_MINISTRY_REIMBURSEMENT, SET_MANUAL_MINISTRY_ADVANCE,
} from "../actions/types";
import moment from 'moment';
import {parseDecimalString} from "../utilities/string-helpers";
import {
  addDeposit,
  addTransfer,
  addWithdrawal,
  approveDeposit,
  approveTransfer,
  approveWithdrawal,
  deleteDeposit,
  deleteTransfer,
  deleteWithdrawal,
  onHoldAccountFundsChange, resetHoldAcctBalanceForEdit,
} from "../utilities/hold-account-helpers";
import {
  getProcessedExpReports,
  expenseReportsToProcess,
  advanceRequestsToProcess,
  buildRecurringTransaction,
  roundDecimal
} from "../utilities/distribution-form-helpers";
import {HOLD_ACCOUNT_TRANSACTIONS} from "../constants";

const selectFromRootOrFormState = (key, state) =>
    'rootReducer' in state ? state.rootReducer.distributionForm[key] : state[key];

export const selectDistribution = state => selectFromRootOrFormState('distribution', state);

export const selectDistributionFormUserId = state => selectFromRootOrFormState('userId', state);

export const selectDonationUpload = state => selectFromRootOrFormState('donationUpload', state);

export const selectHoldAccounts = state => selectFromRootOrFormState('holdAccounts', state);

export const selectAdvanceRequests = state => selectFromRootOrFormState('advanceRequests', state);

export const selectStartDate = state => selectFromRootOrFormState('startDate', state);

export const selectEndDate = state => selectFromRootOrFormState('endDate', state);

export const selectReimbursedAmount = state => selectFromRootOrFormState('reimbursedAmount', state);

export const selectUnreimbursedAmount = state => selectFromRootOrFormState('unreimbursedAmount', state);

export const selectMidMonthDisbursement = state => selectFromRootOrFormState('midMonthDisbursement', state);

export const selectSalary = state => selectFromRootOrFormState('salary', state);

export const selectDistributionComments = state => selectFromRootOrFormState('comments', state);

export const selectStartingBalance = state => selectFromRootOrFormState('startingBalance', state);

export const selectWireFee = state => selectFromRootOrFormState('wireFee', state);

export const selectHousingAllowance = state => selectFromRootOrFormState('housingAllowance', state);

export const selectInsurancePayment = state => selectFromRootOrFormState('insurancePayment', state);

export const selectManualMinistryReimbursement = state => selectFromRootOrFormState('manualMinistryReimbursement', state);

export const selectManualMinistryAdvance = state => selectFromRootOrFormState('manualMinistryAdvance', state);

export const selectAccruedUnreimbursedAmount = state => {
  return (selectProcessedExpenseReports(state) || []).reduce((sum, x) => roundDecimal(sum + (x.unreimbursedAmount || 0)), 0);
};

export const selectEndingUnreimbursedAmount = state => {
  const startingUnreimbursed = selectStartingUnreimbursedAmount(state) || 0,
    accruedUnreimbursed = selectAccruedUnreimbursedAmount(state) || 0,
    reimbursedAmt = selectReimbursedAmount(state) || 0;
  return roundDecimal(
    startingUnreimbursed + accruedUnreimbursed - reimbursedAmt
  )
};

export const selectStartingUnreimbursedAmount = state => {
  const distribution = selectDistribution(state),
    user = selectDistributionFormUser(state);
  if (distribution) return parseDecimalString(distribution.unreimbursed_amount_starting);
  const { profile } = user || {},
    { unreimbursed_amount } = profile || {};
  return parseDecimalString(unreimbursed_amount || 0);
};

export const selectCompleteSteps = state => {
  const user = selectDistributionFormUser(state),
      startDate = selectStartDate(state),
      endDate = selectEndDate(state);
  return {
    0: !!(user && startDate && endDate),
    1: true,
    2: true,
    3: true,
    4: true,
  }
};

export const selectDistributableFunds = (state, includeHoldAccts = false) => {
  const startingBalance = selectStartingBalance(state);
  const totalDonations = selectTotalDonations(state, includeHoldAccts);
  const totalTransfers = selectHoldAccountTransferAmount(state);
  return startingBalance + totalDonations + totalTransfers;
};

export const selectHoldAccountTransferAmount = state =>
    Object.values(selectHoldAccounts(state) || {}).reduce((sum, x) => (sum + (x.totalTransferAmount || 0)), 0);

export const selectSocialSecurity = state => {
  const user = selectDistributionFormUser(state),
      { profile = {} } = user || {},
      { social_security: ss } = profile || {};
  const ssOverride = selectFromRootOrFormState('socialSecurity', state);
  return typeof ssOverride === 'number' ? ssOverride : parseDecimalString(ss);
};

export const selectMedicare = state => {
  const user = selectDistributionFormUser(state),
      { profile = {} } = user || {},
      { medicare: m } = profile || {};
  const mOverride = selectFromRootOrFormState('medicare', state);
  return typeof mOverride === 'number' ? mOverride : parseDecimalString(m);
};

export const selectFederalIncomeTax = state => {
  const user = selectDistributionFormUser(state),
      { profile = {} } = user || {},
      { federal_income_tax: fed } = profile || {};
  const fedOverride = selectFromRootOrFormState('federalIncomeTax', state);
  return typeof fedOverride === 'number' ? fedOverride : parseDecimalString(fed);
};

export const selectMinistryReimbursement = state => {
  const processedExpenseReports = selectFromRootOrFormState('processedExpenseReports', state) || [];
  const genFundReimbursement = selectReimbursedAmount(state);
  const expReimbursement = processedExpenseReports.reduce((sum, report) => roundDecimal(sum + report.amountReimbursed), 0);
  if (typeof genFundReimbursement === 'number' && !isNaN(genFundReimbursement)) return roundDecimal(genFundReimbursement + expReimbursement);
  return expReimbursement;
};

export const selectMinistryAdvance = state => {
  const advanceReqs = selectFromRootOrFormState('advanceRequests', state);
  return Object.values(advanceReqs || {}).reduce((sum, x) => {
    const { hold_account_id: holdAcctId, approved, amount_approved: amtApproved } = x;
    if (holdAcctId || !approved) return sum;
    return roundDecimal(sum + (amtApproved || 0));
  }, 0);
};

export const selectProcessedExpenseReports = state => selectFromRootOrFormState('processedExpenseReports', state);

export const selectDistributionFormUser = state => selectFromRootOrFormState('user', state);

export const selectDonations = state => selectFromRootOrFormState('donations', state);

export const selectUploadDonations = state => {
  const donationUpload = selectDonationUpload(state);
  if (!donationUpload || !donationUpload.length) return;
  const donations = selectDonations(state);
  return Object.keys(donations || {}).reduce((acc, id) => {
    const { upload_name: uploadName } = donations[id];
    if (uploadName !== donationUpload) return acc;
    return { ...acc, [id]: donations[id] };
  }, {});
};

export const selectUploadHoldAccountDonations = state => {
  const donationUpload = selectDonationUpload(state);
  const holdAccounts = selectHoldAccounts(state);
  if (!donationUpload || !donationUpload.length) return;
  const donations = selectDonations(state);
  return Object.keys(donations || {}).reduce((acc, id) => {
    const { upload_name: uploadName, hold_account_id: holdAcctId } = donations[id];
    if (uploadName !== donationUpload || !holdAcctId) return acc;
    const holdAcct = holdAccounts[holdAcctId] || {};
    return { ...acc, [id]: { ...donations[id], hold_account_name: holdAcct.fund_name } };
  }, {});
};

export const selectTotalPayroll = state => {
  const socialSecurity = selectSocialSecurity(state),
      medicare = selectMedicare(state),
      fed = selectFederalIncomeTax(state),
      salary = selectSalary(state);
  return roundDecimal(
    (salary || 0) + ((socialSecurity || 0) * 2) + ((medicare || 0) * 2) + fed
  );
};

export const selectGrossPayroll = state => {
  const socialSecurity = selectSocialSecurity(state),
    medicare = selectMedicare(state),
    fed = selectFederalIncomeTax(state),
    salary = selectSalary(state);
  return roundDecimal(
    (salary || 0) + (socialSecurity || 0) + (medicare || 0) + (fed || 0)
  );
};

export const selectPayrollDisbursement = state => {
  const salary = selectSalary(state);
  const user = selectDistributionFormUser(state),
    { profile = {} } = user || {},
    housingAllowance = selectHousingAllowance(state),
    { retirement_amount: retirement } = profile;
  return (salary || 0) + parseDecimalString(housingAllowance || 0) - parseDecimalString(retirement || 0);
};

export const selectTotalFundsDisbursed = state => {
  const payrollDisbursement = selectPayrollDisbursement(state) || 0,
    ministryReimbursement = selectMinistryReimbursement(state) || 0,
    ministryAdvance = selectMinistryAdvance(state) || 0,
    holdAcctWithdrawal = selectHoldAccountsWithdrawalAmount(state) || 0;
  return roundDecimal(
    payrollDisbursement
    + ministryReimbursement
    + ministryAdvance
    + holdAcctWithdrawal
    + (selectManualMinistryReimbursement(state) || 0)
    + (selectManualMinistryAdvance(state) || 0)
  );
};

export const selectAdminFee = state => {
  const totalDonations = selectTotalDonations(state, true);
  const adminFeeCap = selectFromRootOrFormState('adminFeeCap', state);
  const adminFeePercentage = selectFromRootOrFormState('adminFeePercentage', state);
  const adminFeeDecimal = ((adminFeePercentage || 0) * 100) / 10000;
  const fee = adminFeeDecimal * totalDonations;
  if (fee < adminFeeCap) return fee;
  return adminFeeCap;
};

const setAdminFeeInfo = ({ user, adminFeeCap: defaultAdminFeeCap, adminFeePercentage: defaultAdminFeePercentage }) => {
  const { profile = {} } = user || {},
      { admin_fee_percentage: adminFeePercentage, admin_fee_cap: adminFeeCap } = profile;
  return {
    adminFeeCap: parseDecimalString(adminFeeCap || defaultAdminFeeCap),
    adminFeePercentage: parseDecimalString(adminFeePercentage || defaultAdminFeePercentage),
  };
};

export const selectTotalExpenses = (state, includeReimbursement = false) => {
  const totalPayroll = selectTotalPayroll(state);
  const adminFee = selectAdminFee(state);
  const midMonthDisbursement = selectMidMonthDisbursement(state) || 0;
  const expReportApproved = (selectProcessedExpenseReports(state) || []).reduce((sum, report) => (sum + report.amountReimbursed), 0);
  const advanceReqApproved = Object.values(selectAdvanceRequests(state) || {}).reduce((sum, req) => {
    const { hold_account_id: holdAcctId, amount_approved: amtApproved } = req;
    if (holdAcctId || typeof amtApproved !== 'number') return sum;
    return sum + amtApproved;
  }, 0);
  return roundDecimal(
    totalPayroll
    + adminFee
    + (selectWireFee(state) || 0)
    + (selectInsurancePayment(state) || 0)
    + (selectHousingAllowance(state) || 0)
    + (selectManualMinistryReimbursement(state) || 0)
    + (selectManualMinistryAdvance(state) || 0)
    + expReportApproved
    + advanceReqApproved
    + (selectHoldAccountsDepositsAmount(state) || 0)
    + midMonthDisbursement
    + (includeReimbursement ? (selectReimbursedAmount(state) || 0) : 0)
  );
};

export const selectNonExpReportExpenses = state => {
  const totalPayroll = selectTotalPayroll(state);
  const adminFee = selectAdminFee(state);
  const midMonthDisbursement = selectMidMonthDisbursement(state) || 0;
  const holdAcctDeposits = selectHoldAccountsDepositsAmount(state) || 0;
  return roundDecimal(
    totalPayroll
    + adminFee
    + (selectWireFee(state) || 0)
    + (selectInsurancePayment(state) || 0)
    + (selectHousingAllowance(state) || 0)
    + (selectManualMinistryReimbursement(state) || 0)
    + (selectManualMinistryAdvance(state) || 0)
    + midMonthDisbursement
    + holdAcctDeposits
  );
};

export const selectEndingBalance = state => {
  const { reimbursedAmount = 0 } = 'rootReducer' in state ? state.rootReducer.distributionForm : state;
  const totalExpenses = selectTotalExpenses(state),
    fundsAvail = fundsAvailable('rootReducer' in state ? state.rootReducer.distributionForm : state),
    totalPayroll = selectFromRootOrFormState('totalPayroll', state);
  return roundDecimal(
    (fundsAvail || 0) - (totalExpenses || 0) + (totalPayroll || 0) - (reimbursedAmount || 0)
  );
};

export const selectTotalDonations = (state, includeHoldAccts = false) => {
  const donations = selectUploadDonations(state);
  const donationUpload = selectDonationUpload(state);
  return totalDonations(donations, donationUpload, includeHoldAccts);
};

export const selectHoldAccountsWithdrawalAmount = state => {
  return Object.values(selectHoldAccounts(state) || {}).reduce((sum, acct) => {
    const { totalWithdrawalAmount, reimbursedAmount } = acct;
    return sum + (totalWithdrawalAmount || 0) + (reimbursedAmount || 0);
  }, 0);
};

export const selectHoldAccountsDepositsAmount = state =>
  Object.values(selectHoldAccounts(state) || {})
    .reduce((sum, x) => (sum + (x.totalDepositFromGeneralFundAmount || 0)), 0);

export const selectRecurringHoldAccountTransactions = state => {
  const holdAccounts = selectHoldAccounts(state);
  return Object.values(holdAccounts || []).reduce((acc, x) => {
    const { deposits = [], transfers = [], withdrawals = [] } = x;
    return [
        ...acc,
        ...deposits.filter(x => x.recurring),
        ...transfers.filter(x => x.recurring),
        ...withdrawals.filter(x => x.recurring),
    ]
  }, []);
};

export const selectHoldAccountAdvanceRequests = state => {
  const holdAccounts = selectHoldAccounts(state);
  return Object.values(holdAccounts || {}).reduce((acc, x) => {
    const { withdrawals = [] } = x;
    return [ ...acc, ...(withdrawals || []).filter(x => x.advance_request_id) ];
  }, []);
};

function totalDonations(donations, donationUpload, includeHoldAccts = false) {
  return Object.values(donations || {}).reduce((sum, x) => {
    const { upload_name: uploadName, hold_account_id: holdAcctId, amount, approved } = x;
    if (uploadName !== donationUpload || (!includeHoldAccts && (holdAcctId && approved))) return sum;
    return sum + amount;
  }, 0);
}

function fundsAvailable(state) {
  const { donationUpload, holdAccounts = {}, totalPayroll, donations = {} } = state;
  const donationsAmount = totalDonations(donations, donationUpload);
  const transfers = Object.values(holdAccounts || {}).reduce((sum, acct) => (sum + acct.totalTransferAmount), 0);
  return roundDecimal(
    (donationsAmount || 0) + (selectStartingBalance(state) || 0) + (transfers || 0) - (totalPayroll || 0)
  );
}

function initHoldAccount(account, state) {
  const {
    mat_day_recurring_deposit: deposit,
    mat_day_recurring_transfer: transfer,
    mat_day_recurring_withdrawal: withdrawal,
    balance,
    unreimbursed_amount: unreimbursedAmount,
    id,
    fund_name: fundName,
  } = account;
  const transactions = [];
  const parsedBalance = balance && typeof balance === 'string' && balance.length ? parseFloat(balance) : balance;
  const deposits = [];
  const transfers = [];
  const withdrawals = [];
  const parsedRecurringDeposit = parseDecimalString(deposit);
  const parsedRecurringTransfer = parseDecimalString(transfer);
  const parsedRecurringWithdrawal = parseDecimalString(withdrawal);
  if (parsedRecurringDeposit) deposits.push(buildRecurringTransaction(parsedRecurringDeposit, HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT, id, fundName));
  if (parsedRecurringTransfer) transfers.push(buildRecurringTransaction(parsedRecurringTransfer, HOLD_ACCOUNT_TRANSACTIONS.TRANSFER, id, fundName));
  if (parsedRecurringWithdrawal) withdrawals.push(buildRecurringTransaction(parsedRecurringWithdrawal, HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL, id, fundName));
  return {
    ...account,
    startingUnreimbursedAmount: parseDecimalString(unreimbursedAmount),
    unreimbursed_amount: parseDecimalString(unreimbursedAmount),
    balance: parsedBalance,
    transactions,
    totalTransferAmount: 0,
    workingBalance: parsedBalance,
    startingBalance: parsedBalance,
    deposits,
    transfers,
    withdrawals
  };
}

function addHoldAcctTransaction(state, transaction, redistributeFunds = true) {
  const { accountId, type } = transaction;
  const lowerType = type.toLowerCase();
  let { [accountId]: account } = selectHoldAccounts(state) || {};
  if (lowerType === HOLD_ACCOUNT_TRANSACTIONS.TRANSFER) account = addTransfer(state, transaction, redistributeFunds);
  else if (lowerType === HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT) account = addDeposit(state, transaction, redistributeFunds);
  else if (lowerType === HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL) account = addWithdrawal(state, transaction, redistributeFunds);
  return account;
}

function approveHoldAcctTransaction(state, transaction) {
  const { accountId, transactionType } = transaction;
  if (!accountId || !transactionType) throw 'Missing account ID or transaction type in approve hold acct transaction';
  let { [accountId]: account } = selectHoldAccounts(state) || {};
  if (transactionType === HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT) account = approveDeposit(state, transaction);
  if (transactionType === HOLD_ACCOUNT_TRANSACTIONS.TRANSFER) account = approveTransfer(state, transaction);
  if (transactionType === HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL) account = approveWithdrawal(state, transaction);
  return account;
}

function deleteTransaction(state, { accountId, transactionId, type: transactionType }) {
  const { [accountId]: account } = state.holdAccounts;
  switch (transactionType.toLowerCase()) {
    case HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT:
      return {
        ...state.holdAccounts,
        [accountId]: deleteDeposit(account, transactionId, state),
      };
    case HOLD_ACCOUNT_TRANSACTIONS.TRANSFER:
      return {
        ...state.holdAccounts,
        [accountId]: deleteTransfer(account, transactionId, state),
      };
    case HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL:
      return {
        ...state.holdAccounts,
        [accountId]: deleteWithdrawal(account, transactionId, state),
      };
  }
}

function onDateChange(state, date, key) {
  let startDate = key === 'startDate' ? date : selectStartDate(state);
  let endDate = key === 'endDate' ? date : selectEndDate(state);
  const user = selectDistributionFormUser(state);
  if (!startDate || !endDate || !user) {
    return { startDate, endDate, processedExpenseReports: [], advanceRequests: {} };
  }
  let fundsAvail = roundDecimal(selectDistributableFunds(state) - selectNonExpReportExpenses(state));
  const processedExpReports = getProcessedExpReports(fundsAvail, expenseReportsToProcess(user, startDate, endDate, state));
  const advanceReqs = advanceRequestsToProcess(user, startDate, endDate);
  const newState = onDistributableFundsChange({
    ...state,
    processedExpenseReports: processedExpReports,
    advanceRequests: advanceReqs.reduce((acc, x) => ({ ...acc, [x.id]: { ...x, approved: false } }), {}),
    startDate,
    endDate,
  });
  newState.holdAccounts = Object.keys(newState.holdAccounts || {})
    .reduce((acc, accountId) => ({ ...acc, [accountId]: onHoldAccountFundsChange(newState.holdAccounts[accountId], newState) }), {});
  return newState;
}

function onDistributableFundsChange(state) {
  let fundsAvail = roundDecimal(selectDistributableFunds(state) - selectNonExpReportExpenses(state));
  const processedExpReports = processExpenseReports(state);
  processedExpReports.forEach(x => fundsAvail = roundDecimal(fundsAvail - (x.amountReimbursed || 0)));
  let { unreimbursedAmount = 0, reimbursedAmount: currReimbursedAmount = 0 } = state;
  unreimbursedAmount = roundDecimal(unreimbursedAmount + (currReimbursedAmount || 0));
  let reimbursedAmount = 0;
  if (unreimbursedAmount > 0) {
    if (fundsAvail > unreimbursedAmount) reimbursedAmount = unreimbursedAmount;
    else if (fundsAvail > 0) reimbursedAmount = fundsAvail;
    fundsAvail = roundDecimal(fundsAvail - reimbursedAmount);
    unreimbursedAmount = roundDecimal(unreimbursedAmount - reimbursedAmount);
  }
  const advanceRequests = selectAdvanceRequests(state);
  Object.keys(advanceRequests || {}).forEach(id => {
    const { approved, amount_requested: amountRequested } = advanceRequests[id];
    if (approved) {
      let amountApproved = 0;
      if (fundsAvail > amountRequested) amountApproved = amountRequested;
      else if (fundsAvail > 0) amountApproved = fundsAvail;
      fundsAvail = roundDecimal(fundsAvail - amountApproved);
      advanceRequests[id].amount_approved = amountApproved;
    }
  });
  return {
    ...state,
    processedExpenseReports: processedExpReports,
    advanceRequests,
    unreimbursedAmount,
    reimbursedAmount,
  }
}

//TODO on distributable funds change action
function processExpenseReports(state) {
  const startDate = selectStartDate(state),
    endDate = selectEndDate(state),
    user = selectDistributionFormUser(state);
  const expenseReports = expenseReportsToProcess(user, startDate, endDate, state);
  // let fundsAvail = fundsAvailable(state);
  let fundsAvail = selectDistributableFunds(state) - selectNonExpReportExpenses(state);
  return getProcessedExpReports(fundsAvail, expenseReports);
}

//TODO validate
function onApproveAdvanceRequest(state, { advanceRequestId, approved }) {
  const { advanceRequests = {} } = state;
  const req = advanceRequests[advanceRequestId];
  if (!req) return advanceRequests;
  const { amount_requested: amountRequested } = req;
  const endingBalance = selectEndingBalance(state);
  let amountApproved = amountRequested;
  if (endingBalance <= 0) amountApproved = 0;
  else if (amountApproved > endingBalance) amountApproved = endingBalance;
  return {
    ...advanceRequests,
    [advanceRequestId]: {
      ...req,
      approved,
      amount_approved: approved ? amountApproved : null,
    }
  }
}

//TODO validate
function onSetAdvanceRequestAmount(state, { advanceRequestId, amount }) {
  const { advanceRequests = {} } = state;
  const req = advanceRequests[advanceRequestId];
  if (!req) return advanceRequests;
  const { amount_requested: amtReq, amount_approved: amtApproved } = req;
  const endingBalance = selectEndingBalance(state) + (amtApproved || 0);
  let amountApproved = amount;
  if (typeof amount === 'number') {
    if (amountApproved > amtReq) amountApproved = 0;
    if (amountApproved > endingBalance) {
      if (endingBalance <= 0) amountApproved = 0;
      else amountApproved = endingBalance;
    }
  }
  return {
    ...advanceRequests,
    [advanceRequestId]: {
      ...req,
      amount_approved: amountApproved,
    }
  }
}

function setDonations(user) {
  const { donations = [] } = user;
  return {
    donations: (donations || []).reduce((acc, x) => {
      const { id, amount, hold_account_id: holdAccountId } = x;
      return { ...acc, [id]: { ...x, amount: parseDecimalString(amount), ...(holdAccountId ? { approved: false } : {}) } };
    }, {}),
  };
}

function onApproveDonation(state, { donationId, approved, id }, redistributeFunds = true) {
  const holdAccounts = selectHoldAccounts(state);
  const donations = selectDonations(state);
  const donation = donations[donationId] || {},
      { hold_account_id: holdAcctId, amount } = donation,
      transaction = { accountId: holdAcctId, amount, donation_id: donationId, type: HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT, description: `Donation ID: ${donationId}`, id, DBid: id },
      deposit = (holdAccounts[holdAcctId].deposits || []).find(x => x.amount === amount && x.donation_id === donationId);
  const holdAccount = approved ? addDeposit(state, transaction, redistributeFunds) : deleteDeposit(holdAccounts[holdAcctId], deposit.id, state);
  return {
    donations: Object.values(donations || {}).reduce((acc, x) => {
      const { id } = x;
      if (id !== donationId) return { ...acc, [id]: x };
      return { ...acc, [id]: { ...x, approved }};
    }, {}),
    holdAccounts: Object.values(holdAccounts || {}).reduce((acc, x) => {
      const { id } = x;
      if (id !== holdAccount.id) return { ...acc, [id]: x };
      return { ...acc, [id]: holdAccount };
    }, {}),
  }
}

function onSetDistribution(distribution, state) {
  const {
    start_date: startDate,
    end_date: endDate,
    donation_upload_name: donationUpload,
    employee_ss: employeeSS,
    employee_mc: employeeMed,
    federal_income_tax: fed,
    hold_account_transactions: transactions = [],
    comments,
    id: distributionId,
    user,
    unreimbursed_reimbursement,
    unreimbursed_amount_ending,
    net_payroll,
    mid_month_disbursement,
    starting_balance,
    status,
    wire_fee,
    housing_allowance,
    insurance_payment,
    manual_ministry_reimbursement,
    manual_ministry_advance,
  } = distribution;

  let ret = {
    ...state,
    wireFee: parseDecimalString(wire_fee || 0),
    housingAllowance: parseDecimalString(housing_allowance || 0),
    insurancePayment: parseDecimalString(insurance_payment || 0),
    manualMinistryReimbursement: parseDecimalString(manual_ministry_reimbursement || 0),
    manualMinistryAdvance: parseDecimalString(manual_ministry_advance || 0),
    distributionId,
    distribution,
    donationUpload,
    comments,
    salary: parseDecimalString(net_payroll || 0),
    reimbursedAmount: parseDecimalString(unreimbursed_reimbursement || 0),
    unreimbursedAmount: parseDecimalString(unreimbursed_amount_ending || 0),
    startDate: moment(startDate).unix(),
    endDate: moment(endDate).unix(),
    socialSecurity: parseDecimalString(employeeSS),
    medicare: parseDecimalString(employeeMed),
    federalIncomeTax: parseDecimalString(fed),
    advanceRequests: (user.advance_request || []).reduce((acc, x) => {
      if (x.distribution_id !== distributionId) return acc;
      const { dist_amount_approved, id } = x;
      const parsedDistAmtApproved = parseDecimalString(dist_amount_approved);
      return { ...acc, [id]: { ...x, amount_approved: parsedDistAmtApproved, approved: !!parsedDistAmtApproved } };
    }, {}),
    midMonthDisbursement: parseDecimalString(mid_month_disbursement || 0),
    startingBalance: parseDecimalString(starting_balance || 0),
  };

  const holdAccounts = selectHoldAccounts(state);
  if (status === 'approved') ret = resetHoldAcctBalanceForEdit(ret, distribution);

  (transactions || []).forEach(transaction => {
    const { pivot = {} } = transaction,
      { hold_account_id: accountId, transaction_type: type, amount_approved: amountApproved, id, description, transaction_amount } = pivot;
    let { expense_id } = pivot;
    if (typeof expense_id === 'number' && !expense_id) expense_id = null;
    const holdAccount = holdAccounts[accountId] || {},
      { fund_name: holdAccountName } = holdAccount || {};
    if (type.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.DEPOSIT) {
      let donationId = null;
      if (description.toLowerCase().includes('donation')) {
        donationId = description.match(/(\d+)/);
        if (donationId && donationId.length) {
          donationId = parseInt(donationId[0]);
          ret = {
            ...ret,
            ...onApproveDonation(ret, { donationId, approved: true, id }, false),
          };
        }
      }
      else {
        if (accountId && accountId in holdAccounts) {
          const transaction = { accountId, type, amount: parseDecimalString(transaction_amount), id, description, DBid: id, expense_id };
          if (description.toLowerCase().includes('manual')) transaction.fromGeneralFund = true;
          if (description.toLowerCase().includes('recurring')) {
            transaction.recurring = true;
            if (parseDecimalString(amountApproved) > 0) transaction.approved = true;
          }
          ret.holdAccounts = {
            ...holdAccounts,
            ...(ret.holdAccounts || {}),
            [accountId]: addHoldAcctTransaction(ret, transaction, false)
          };
        }
      }
    }
    else if (type.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.WITHDRAWAL) {
      if (accountId && accountId in holdAccounts) {
        //TODO should this be transaction_amount?
        const amtApproved = parseDecimalString(amountApproved) || 0;
        const transaction = { accountId, type, amount: amtApproved, id, description, DBid: id, expense_id, holdAccountName };
        if (description.toLowerCase().includes('recurring')) {
          transaction.recurring = true;
          if (amtApproved > 0) transaction.approved = true;
        }

        let advanceReqId = null;
        if (description.toLowerCase().includes('advance request')) {
          advanceReqId = description.match(/(\d+)/);
          if (advanceReqId && advanceReqId.length) {
            advanceReqId = parseInt(advanceReqId[0]);
            transaction.advance_request_id = advanceReqId;
            if (amtApproved > 0) transaction.approved = true;
          }
        }

        ret.holdAccounts = {
          ...holdAccounts,
          ...(ret.holdAccounts || {}),
          [accountId]: addHoldAcctTransaction(ret, transaction, false)
        }
      }
    }
    else if (type.toLowerCase() === HOLD_ACCOUNT_TRANSACTIONS.TRANSFER) {
      if (accountId && accountId in holdAccounts) {
        const transaction = { accountId, type, amount: parseDecimalString(transaction_amount), id, description, DBid: id, expense_id };
        if (description.toLowerCase().includes('recurring')) {
          transaction.recurring = true;
          if (parseDecimalString(amountApproved) > 0) transaction.approved = true;
        }
        ret.holdAccounts = {
          ...holdAccounts,
          ...(ret.holdAccounts || {}),
          [accountId]: addHoldAcctTransaction(ret, transaction, false)
        }
      }
    }
  });

  ret.holdAccounts = Object.keys(ret.holdAccounts).reduce((acc, id) => ({ ...acc, [id]: onHoldAccountFundsChange(ret.holdAccounts[id], ret) }), {});

  ret.processedExpenseReports = (user.expense_report || []).reduce((acc, x) => {
    if (x.distribution_id !== distributionId) return acc;
    const { id: reportId, dist_amount_reimbursed, dist_hold_account_withdrawals, dist_amount_unreimbursed, total_amount } = x;
    return [ ...acc, {
      reportId,
      amountReimbursed: parseDecimalString(dist_amount_reimbursed || 0),
      holdAccountWithdrawals: parseDecimalString(dist_hold_account_withdrawals || 0),
      unreimbursedAmount: parseDecimalString(dist_amount_unreimbursed || 0),
      amount: (total_amount || 0) - parseDecimalString(dist_hold_account_withdrawals || 0),
    }]
  }, []);
  return ret;
}

function setProfileInfo(user) {
  const { profile } = user || {},
      {
        unreimbursed_amount: unreimbursedAmount,
        net_salary: netSalary,
        social_security,
        medicare,
        fund_balance: fundBal,
        wire_fee,
        housing_allowance,
        insurance_amount,
      } = profile || {};
  return {
    unreimbursedAmount: parseDecimalString(unreimbursedAmount || 0),
    salary: parseDecimalString(netSalary || 0),
    socialSecurity: +social_security.toFixed(2),
    medicare: +medicare.toFixed(2),
    startingBalance: parseDecimalString(fundBal || 0),
    wireFee: parseDecimalString(wire_fee || 0),
    housingAllowance: parseDecimalString(housing_allowance || 0),
    insurancePayment: parseDecimalString(insurance_amount || 0),
  };
}

const onSetSalary = ({ salary = 0, ssPercent = 0, mcPercent = 0 }, state) => {
  const user = selectDistributionFormUser(state) || {},
    { profile = {} } = user || {},
    { fica_tax } = profile || {};
  const ssDecimal = !!fica_tax ? (ssPercent * 100) / 10000 : 0;
  const mcDecimal = !!fica_tax ? (mcPercent * 100) / 10000 : 0;
  const taxable = salary / (1 - ssDecimal - mcDecimal);
  const ss = roundDecimal(taxable * ssDecimal);
  const mc = roundDecimal(taxable * mcDecimal);
  return {
    salary,
    socialSecurity: ss,
    medicare: mc,
    totalPayroll: roundDecimal(
      salary + (ss * 2) + (mc * 2) + selectFederalIncomeTax(state)
    ),
  }
};

const calcTotalPayroll = state => {
  const salary = selectSalary(state),
    ss = selectSocialSecurity(state),
    mc = selectMedicare(state),
    fed = selectFederalIncomeTax(state);
  return roundDecimal(
    salary + (ss * 2) + (mc * 2) + fed
  )
};

const initialState = {
  distributionId: null,
  userId: null,
  user: null,
  donationUpload: null,
  holdAccounts: {},
  totalPayroll: null,
  startDate: null,
  endDate: null,
  adminFeeCap: null,
  adminFeePercentage: null,
  donations: {},
  unreimbursedAmount: null,
  salary: null,
  socialSecurity: null,
  medicare: null,
  federalIncomeTax: null,
  distribution: null,
  comments: null,
  reimbursedAmount: null,
  advanceRequests: {},
  processedExpenseReports: [],
  startingBalance: null,
  wireFee: null,
  housingAllowance: null,
  insurancePayment: null,
  manualMinistryReimbursement: null,
  manualMinistryAdvance: null,
  midMonthDisbursement: null,
};

export function reducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case RESET_DISTRIBUTION_FORM:
      return {
        ...state,
        ...initialState,
      };
    case SET_DISTRIBUTION:
      return {
        ...state,
        ...onSetDistribution(action.payload, state),
      };
    case SET_DISTRIBUTION_FORM_USER_ID:
      const ret = {
        ...state,
        userId: action.user.id,
        user: action.user,
        ...setAdminFeeInfo(action),
        ...setDonations(action.user),
        ...setProfileInfo(action.user),
      };
      return {
        ...ret,
        totalPayroll: calcTotalPayroll(ret),
      };
    case SET_DONATION_UPLOAD:
      return {
        ...state,
        donationUpload: action.payload,
      };
    case SET_HOLD_ACCOUNTS:
      return {
        ...state,
        holdAccounts: action.payload.reduce((acc, x) => ({ ...acc, [x.id]: initHoldAccount(x, state) }), {}),
      };
    case ADD_HOLD_ACCOUNT_TRANSACTION:
      return {
        ...state,
        holdAccounts: {
          ...(state.holdAccounts || {}),
          [action.payload.accountId]: addHoldAcctTransaction(state, action.payload)
        }
      };
    case APPROVE_HOLD_ACCOUNT_TRANSACTION:
      return {
        ...state,
        holdAccounts: {
          ...(state.holdAccounts || {}),
          [action.payload.accountId]: approveHoldAcctTransaction(state, action.payload),
        }
      };
    case DELETE_HOLD_ACCOUNT_TRANSACTION:
      return {
        ...state,
        holdAccounts: deleteTransaction(state, action.payload),
      };
    case SET_TOTAL_PAYROLL:
      return {
        ...state,
        totalPayroll: action.payload,
      };
    case SET_SOCIAL_SECURITY:
      return {
        ...state,
        socialSecurity: action.payload,
        totalPayroll: calcTotalPayroll({ ...state, socialSecurity: action.payload })
      };
    case SET_MID_MONTH_DISBURSEMENT:
      return {
        ...state,
        midMonthDisbursement: action.payload,
      };
    case SET_WIRE_FEE:
      return {
        ...state,
        wireFee: action.payload,
      };
    case SET_HOUSING_ALLOWANCE:
      return {
        ...state,
        housingAllowance: action.payload,
      };
    case SET_MANUAL_MINISTRY_REIMBURSEMENT:
      return {
        ...state,
        manualMinistryReimbursement: action.payload,
      };
    case SET_MANUAL_MINISTRY_ADVANCE:
      return {
        ...state,
        manualMinistryAdvance: action.payload,
      };
    case SET_INSURANCE_PAYMENT:
      return {
        ...state,
        insurancePayment: action.payload,
      };
    case SET_SALARY:
      return {
        ...state,
        ...onSetSalary(action.payload, state),
      };
    case SET_MEDICARE:
      return {
        ...state,
        medicare: action.payload,
        totalPayroll: calcTotalPayroll({ ...state, medicare: action.payload })
      };
    case SET_FEDERAL_INCOME_TAX:
      return {
        ...state,
        federalIncomeTax: action.payload,
        totalPayroll: calcTotalPayroll({ ...state, federalIncomeTax: action.payload })
      };
    case APPROVE_ADVANCE_REQUEST:
      return {
        ...state,
        advanceRequests: onApproveAdvanceRequest(state, action),
      };
    case SET_ADVANCE_REQUEST_AMOUNT_APPROVED:
      return {
        ...state,
        advanceRequests: onSetAdvanceRequestAmount(state, action),
      };
    case PROCESS_EXPENSE_REPORTS:
      return {
        ...state,
        processedExpenseReports: processExpenseReports(state),
      };
    case APPROVE_DONATION:
      return {
        ...state,
        ...onApproveDonation(state, action),
      };
    case SET_START_DATE:
      return {
        ...state,
        ...onDateChange(state, action.payload, 'startDate'),
      };
    case SET_END_DATE:
      return {
        ...state,
        ...onDateChange(state, action.payload, 'endDate'),
      };
    case DISTRIBUTABLE_FUNDS_CHANGE:
      return {
        ...state,
        ...onDistributableFundsChange(state),
      };
    case SET_DISTRIBUTION_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    default:
      return state;
  }
}
