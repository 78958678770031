import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import { List } from 'material-ui/List';
import { doesItemExistInState } from '../../utilities/exist-in-state';
import Input from '../fields/input';
import ReimbursementListItems from '../blocks/reimbursement-list-items';
import MileageListItems from '../blocks/mileage-list-items';
import { updateForm } from '../../utilities/update';
import ProcessedModal from '../blocks/processed-modal';
import MobileFileUpload from '../blocks/mobile-file-uploads';
import moment from 'moment';
import {
   GET_EXPENSE_REPORT_SUCCESS
} from '../../actions/types';


class MissionaryViewExpenseReport extends Component {

   constructor(props) {
      super(props);
      this.state = {
         type: '',
         distance_type: '',
         redirect: ''
      };
   }

   componentDidMount() {
      const expenseReport = doesItemExistInState(this.props.expenseReports.expenseReports, this.props.params.expense_report_id);

      if (!expenseReport) {
         this.props.dispatch(Actions.requestGetExpenseReport(this.props.params.expense_report_id, Cookies.get('token')))
            .then((result) => {
               if (result.type === GET_EXPENSE_REPORT_SUCCESS) {
                  updateForm('update-expense-report-form', result.payload);
                  this.setState({ type: result.payload.type });
                  this.setState({ distance_type: result.payload.distance_type });
                  this.forceUpdate();
               } else {
                  this.props.dispatch(Actions.newError(result.payload.message));
               }
            })
      } else {
         updateForm('update-expense-report-form', expenseReport);
         this.setState({ 
            distance_type: expenseReport.distance_type,
            type: expenseReport.type 
         });
         this.forceUpdate();
      }
      this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));

      //redirect for process modal
      if(typeof this.props.params.return !== undefined) {
         if(this.props.params.return === 'unprocessed') {
            this.setState({redirect:  '/admin/expense-reports/unprocessed'})
         } else if(this.props.params.return === 'dashboard') {
            this.setState({redirect: '/admin/dashboard'})
         }
      }
   }


   render() {
      const expenseReport = doesItemExistInState(this.props.expenseReports.expenseReports, this.props.params.expense_report_id);
      const totalReimbursement = expenseReport ? expenseReport.total_amount + expenseReport.user.profile.unreimbursed_amount : 0;
      let expense_report_total_amount = 0;

      //expense items
      const reimbursement_items = _.get(expenseReport, 'expense', [])
         .map((reimbursement, index) => {
            expense_report_total_amount += reimbursement.usd_amount;
            const reimbursement_date = moment(reimbursement.date).format('MM/DD/YYYY');
            const category_text = reimbursement.category.expense_category;
            const hold_account = _.isNull(reimbursement.hold_account_id) ? 'NO' : reimbursement.hold_account.fund_name;

            return (
               <ReimbursementListItems
                  key={ index }
                  id={ reimbursement.id } expense_id={ reimbursement.id }
                  primaryText={ '$' + reimbursement.usd_amount.toFixed(2) }
                  secondaryText={ reimbursement_date + ' - ' + category_text }
                  tertiaryText={ 'From Hold Account: ' + hold_account }
                  expenses_list={ this.state.expenses } distance_type={ this.state.distance_type }
                  expense_report_id={ this.props.params.expense_report_id } view_only={ true }
               />
            )
         });

         //mileage items
         const mileage_items = _.get(expenseReport, 'mileage', [])
            .map((mileage, index) => {
            expense_report_total_amount += mileage.total_cost;
            const mileage_date = moment(mileage.date).format('MM/DD/YYYY');
            const category_text = mileage.category.expense_category;

            return (
               <div key={ index }>
                  <MileageListItems
                     mileage_id={ mileage.id }
                     // eslint-disable-next-line
                     primaryText={ mileage.total_distance + ' ' + this.state.distance_type + ' - ' + '$' + mileage.total_cost.toFixed(2) }
                     secondaryText={ mileage_date + ' - ' + category_text }
                     tertiaryText={ '' }
                     view_only={ true }
                     expense_report_id={ this.props.params.expense_report_id }
                  />
               </div>
            )
         });

      //file uploads
      const columns = [
         { key: 'file_url', label: 'Image Preview', 'sortable': false },
         { key: 'file_name', label: 'File Name', 'sortable': false },
         { key: 'updated_at', label: 'Updated At', 'sortable': false },
         { key: 'view', label: 'View', 'sortable': false },
      ];

      const fileUploads = _.get(expenseReport, 'file_upload', []);
      const fileUploadsTable = fileUploads
         .map(upload => {
            return {
               file_url:  <img
                           key={ "file-"}
                           src={ upload.file_url }
                           style={ { width: '100px' } }
                           alt='remove-preview'
                        />,
               file_name: upload.filename,
               updated_at: upload.updated_at,
               view: <ButtonLink
                        backgroundColor="#1E88E5"
                        to={ upload.file_url }
                        blank_target={ true }
                        labelColor="#ffffff"
                        label="View File"
                     />
            };
         });


      return (
         <div className="missionary-new-report">
            <span><h2 className="form-heading" style={ { float: 'left' } }>View Expense Report</h2></span>
            <div className="form-buttons">
               <span className="mobile-span-report-total-cost" style={ { display: "inline-block" } }>
                  <h2 className="report-total-cost">Expense Report Total: ${ expense_report_total_amount.toFixed(2) }</h2>
               </span>
               {
                  (expenseReport.processed === 0) && (this.props.auth.user.role === 'admin' || this.props.auth.user.role === 'employee')
                     ?
                     <ProcessedModal 
                        key={'process-button' + expenseReport.id}
                        expenseReport={expenseReport}
                        type={expenseReport.type}
                        totalAmount={expenseReport.total_amount}
                        unreimbursedAmount={expenseReport.user.profile.unreimbursed_amount}
                        totalReimbursement={totalReimbursement}
                        advanceRequestId={expenseReport.advance_request ? expenseReport.advance_request.id : null}
                        style={{marginLeft: 3, display: 'inline-block'}}
                        redirectLink={this.state.redirect}                  
                     />
                     :
                     null
               }
            </div>
            <div className="form-block-container">
               <form id="update-expense-report-form" onSubmit={ (event) => this.saveExpenseReport(event) }
                  encType="multipart/form-data">
                  <div className="form-block">
                     <Subheader className="table-style">Expense Report Details</Subheader>
                     <div className="form-section new-report-section">
                        <Input disabled={ true }
                           className="inline-inputs"
                           id="type"
                           floatingLabelText="Reimbursement or Advance?"
                           errors={ this.props.expenseReports }
                           value={ _.upperFirst(this.state.type) } />
                        <Input disabled={ true }
                           className="inline-inputs"
                           id="start_date"
                           floatingLabelText="Start Date"
                           errors={ this.props.expenseReports }
                           value={ this.state.start_date } />
                        <Input disabled={ true }
                           className="inline-inputs"
                           id="end_date"
                           floatingLabelText="End Date"
                           errors={ this.props.expenseReports }
                           value={ this.state.end_date } />
                        <input type="hidden" name="user_id" id="user_id" />
                        <input type="hidden" name="total_amount" id="total_amount"
                           value={ this.state.expense_report_total_amount } />
                     </div>
                  </div>
               </form>

               <div className="form-block">
                  <Subheader className="table-style">Expenses</Subheader>
                  <div className="form-section new-report-section">
                     <List>
                        <div className="list-view">
                           <Subheader className="message-list-subheader">Entries</Subheader>
                           { reimbursement_items }
                        </div>
                     </List>
                  </div>
               </div>
               <div className="form-block">
                  <Subheader className="table-style">Mileage Expenses</Subheader>
                  <div className="form-section new-report-section">
                     <List>
                        <div className="list-view">
                           <Subheader className="message-list-subheader">Entries</Subheader>
                           { mileage_items }
                        </div>
                     </List>
                  </div>
               </div>
               <div className="form-block">
                  <Subheader className="table-style">File Upload</Subheader>
                  <div className="form-section new-report-section desktop-images">
                     <Subheader className="message-list-subheader">Uploaded Images</Subheader>
                     <Table data={ fileUploadsTable } columns={ columns } />
                  </div>
                  <div className="form-section new-report-section mobile-images">
                     <MobileFileUpload expense_reports={ fileUploads } viewOnly={ true } />
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

export default connect(
   state => ({
      auth: state.rootReducer.auth,
      expenseReports: state.rootReducer.expenseReports,
      users: state.rootReducer.users,
      expenses: state.rootReducer.expenses,
      fileUploads: state.rootReducer.fileUploads,
      categories: state.rootReducer.categories
   })
)(MissionaryViewExpenseReport);
