import React from 'react';
import { connect } from 'react-redux';
import {
    selectFederalIncomeTax,
    selectMedicare, selectSalary,
    selectSocialSecurity,
    selectTotalPayroll
} from "../../../reducers/distribution-form";
import { setSocialSecurity, setMedicare, setFederalIncomeTax, setSalary } from "../../../actions/distribution-form";
import FontIcon from 'material-ui/FontIcon';
import CurrencyInput from "../../fields/CurrencyInput";

const styles = {
    input: {
        width: '170px',
    },
    inputContainer: {
        margin: 20,
    }
};

class Payroll extends React.PureComponent {
    render = () => {
        const { readOnly } = this.props;
        const AddIcon = (<FontIcon className="material-icons">add</FontIcon>);

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <CurrencyInput
                    id="net_payroll"
                    floatingLabelText="Salary"
                    value={this.props.salary || 0}
                    style={styles.input}
                    containerStyle={styles.inputContainer}
                    onChange={this.props.setSalary}
                    disabled={readOnly}
                />
                {
                    AddIcon
                }
                <CurrencyInput
                    id="social_security"
                    floatingLabelText="Social Security"
                    inputSuffix="x2"
                    value={this.props.socialSecurity}
                    onChange={this.props.setSocialSecurity}
                    debounceMs={300}
                    style={styles.input}
                    containerStyle={styles.inputContainer}
                    disabled={readOnly}
                />
                {
                    AddIcon
                }
                <CurrencyInput
                    id="medicare"
                    floatingLabelText="Medicare"
                    value={this.props.medicare}
                    inputSuffix="x2"
                    onChange={this.props.setMedicare}
                    debounceMs={300}
                    style={styles.input}
                    containerStyle={styles.inputContainer}
                    disabled={readOnly}
                />
                {
                    AddIcon
                }
                <CurrencyInput
                    id="federal_income_tax"
                    floatingLabelText="Federal Income Tax"
                    value={this.props.federalIncomeTax}
                    onChange={this.props.setFederalIncomeTax}
                    debounceMs={300}
                    style={styles.input}
                    containerStyle={styles.inputContainer}
                    disabled={readOnly}
                />
                <FontIcon className="material-icons">drag_handle</FontIcon>
                <CurrencyInput
                    id="total_payroll"
                    floatingLabelText="Total Payroll"
                    value={this.props.totalPayroll}
                    disabled={true}
                    underlineShow={false}
                    style={styles.input}
                    containerStyle={styles.inputContainer}
                />
            </div>
        )
    }
}

export default connect(state => {
    return {
        socialSecurity: selectSocialSecurity(state),
        medicare: selectMedicare(state),
        federalIncomeTax: selectFederalIncomeTax(state),
        totalPayroll: selectTotalPayroll(state),
        salary: selectSalary(state),
    };
}, {
    setSocialSecurity,
    setMedicare,
    setFederalIncomeTax,
    setSalary,
})(Payroll);
