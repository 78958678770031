import {createNewUser} from '../api/users';

import{
    POST_USER,
    POST_USER_SUCCESS,
    POST_USER_FAILURE
} from './types';

function postUser(){
    return{
        type: POST_USER
    };
}

function postUserSuccess(json){
    return{
        type: POST_USER_SUCCESS,
        payload: json
    }
}

function postUserFailure(error){
    return{
        type: POST_USER_FAILURE,
        payload: error
    };
}

export default function requestPostUser(formData, token) {
    return dispatch => {
        dispatch(postUser());
        return createNewUser(formData, token)
            .then((json) => {
                return dispatch(postUserSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postUserFailure(errors));
                })
            });
    };
}
