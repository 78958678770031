import React, {Component} from 'react';
import DataTables from 'material-ui-datatables';
import {browserHistory} from 'react-router';
import _ from 'lodash';

class Table extends Component {
   _isMounted = false;

    constructor(props) {
        super(props);
        // set our initial state
        this.state = {
            page: 1,
            fullData: [],
            shownData: [],
            rowSize: this.props.rowSize ? this.props.rowSize : 10,
            rowSizeList: [10, 30, 50, 100],
            selectedRows: [],
            searchValue: null
        };
    }

    componentDidMount() {
       this._isMounted = true;

        const that = this;
        let stateObject = {}
        if(this.props.data.length > 0) {
            const that = this;
            this.setState({fullData: this.props.data}, function() {
                if(that.props.initialSort){
                    that.handleSortOrderChange(that.props.initialSort.column, that.props.initialSort.order);
                }else{
                    that.updateDisplay();
                }
            });
            // eslint-disable-next-line
            stateObject['fullData'] = this.props.data;
        }
        const location = Object.assign({}, browserHistory.getCurrentLocation());
        if(location.query.page) {
            // eslint-disable-next-line
            stateObject['page'] = parseInt(location.query.page);
        }
        this.setState(stateObject, function() {
            if(location.query.sortKey) {
                that.handleSortOrderChange(location.query.sortKey, location.query.sortOrder, false);
            } else {
                that.updateDisplay();
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // if we get new props, we need to update the state
        const that = this;
        if(this.props.data.length !== nextProps.data.length) {
            this.setState({fullData: nextProps.data}, function() {
                if(that.props.initialSort){
                    that.handleSortOrderChange(that.props.initialSort.column, that.props.initialSort.order);
                }else{
                    that.updateDisplay();
                }
            });
        }

        if(this.props.forceUpdate !== nextProps.forceUpdate) {
            if(this.state.searchValue !== null) {
                this.setState({fullData: nextProps.data}, function() {
                    that.handleFilterValueChange(that.state.searchValue);
                });
            } else {
                this.setState({fullData: nextProps.data, selectedRows: []}, function() {
                    that.updateDisplay();
                });
            }
        }

        // eslint-disable-next-line
        if(this.props.clearSelected != nextProps.clearSelected) {
            this.setState({selectedRows: []})
        }
    }

    componentWillUnmount() {
      this._isMounted = false;
   }

    setFullDataAndPageOne(data) {
        const that = this;
        if(this._isMounted === true) {
         this.setState({fullData: data}, function() {
               that.setState({page: 1}, function() {
                  that.updateDisplay();
               });
         });
        }
    }

    onPreviousPageClick() {
        const that = this;
        const location = Object.assign({}, browserHistory.getCurrentLocation());
        // eslint-disable-next-line
        const newPage = parseInt(this.state.page)-1;
        Object.assign(location.query, {page: newPage});
        browserHistory.push(location);
        if(this._isMounted === true) {
         this.setState({page: newPage}, function() {
               that.updateDisplay();
         });
        }
    }

    onNextPageClick() {
        const that = this;
        const location = Object.assign({}, browserHistory.getCurrentLocation());
        // eslint-disable-next-line
        const newPage = parseInt(this.state.page)+1;
        Object.assign(location.query, {page: newPage});
        browserHistory.push(location);
        if(this._isMounted === true) {
         this.setState({page: newPage}, function() {
               that.updateDisplay();
         });
        }
    }

    updateDisplay() {
        const page = this.state.page - 1;
        const data = this.state.fullData.slice(page*this.state.rowSize, page*this.state.rowSize+this.state.rowSize);
         if(this._isMounted === true) {
            this.setState({shownData: data}, () => {
                  // we need to add overflow-hidden to some tds to get the text ellipse, but allow for the notes and pickups to overflow
                  const elements = document.getElementsByTagName('td');
                  _.each(elements, element => {
                     if(element.children.length === 0) {
                        // add our overflow-hidden class
                        element.className = 'overflow-hidden';
                     } else {
                        element.className = '';
                     }
                  })
            });
         }
    }

    onRowSizeChange(value) {
        const that = this;
        if(this._isMounted === true) {
            this.setState({rowSize: this.state.rowSizeList[value], page: 1}, function() {
                  that.updateDisplay();
            });
        }
    }

    dbSearch = (value) => {
       if(this._isMounted === true) {
         this.props.dbDrivenSearch(value, (results) => {
               this.setState({shownData: results})
         });
         }
    }

    handleFilterValueChange = (value) => {
        const that = this;
        if(this._isMounted === true) {
         this.setState({searchValue: value});
        }
        if(value.length === 0) {
            // if nothing is in the search bar, just set it back to how it was
            this.updateDisplay();
        } else {
            const regex = new RegExp(value,"i");
            let data = [];
            // eslint-disable-next-line
            this.state.fullData.map((row) => {
                let addData = false;
                // eslint-disable-next-line
                that.props.columns.map((column) => {
                    if(typeof row[column.key] === 'string' && column.sortable) {
                        if(row[column.key].match(regex)) {
                            addData = true;
                        }
                    }
                })
                if(addData) {
                    data.push(row);
                }
            });
            if(this._isMounted === true) {
               this.setState({shownData: data});
            }
            // check if a db driven search is passed as a prop
            if(this.props.dbDrivenSearch) {
                this.dbSearch(value);
            }
        }
    }

    handleCellClick(key, order, rowData, content) {
        // check for an object (react element) and for a link in the row
        // eslint-disable-next-line
        if(rowData.link && typeof content !== 'object') {
            browserHistory.push(rowData.link);
        }
    }


    handleSortOrderChange(key, order, setToPageOne=true) {
        let data = this.state.fullData;
        const location = Object.assign({}, browserHistory.getCurrentLocation());
        Object.assign(location.query, {sortKey: key});
        Object.assign(location.query, {sortOrder: order});
        browserHistory.push(location);
        data.sort((a, b) => {
          const { [key]: aVal } = a,
            { [key]: bVal } = b;
          if (aVal === bVal) return 0;
          else if (aVal === null || aVal === undefined) return 1;
          else if (bVal === null || bVal === undefined) return -1;
          else if (order === 'asc') return aVal < bVal ? -1 : 1;
          else return aVal < bVal ? 1 : -1;
        });
        if(setToPageOne) {
            this.setFullDataAndPageOne(data);
        } else {
            const that = this;
            if(this._isMounted === true) {
               this.setState({fullData: data}, function() {
                  that.updateDisplay();
               });
            }
        }
    }

    onRowSelection(indexArray) {
        if(this._isMounted === true) {
            // eslint-disable-next-line
            if(typeof indexArray != 'string') {
                  this.setState({selectedRows: indexArray});
                  if(this.props.onSelected) {
                     let selectedRows = []
                     for(const index in indexArray) {
                        selectedRows.push(this.state.shownData[indexArray[index]])
                     }
                     this.props.onSelected(selectedRows)
                  }
            } else {
                  this.setState({selectedRows: []});
                  this.props.onSelected(indexArray)
            }
        }
    }




    render() {
        const styles = {
            padding: "0 2rem",
            color: ""
        };
        return (
            <DataTables
                showFooterToolbar={this.props.showFooterToolbar !== '' ? this.props.showFooterToolbar : true}
                showHeaderToolbarFilterIcon={false}
                selectable={this.props.selectable}
                multiSelectable={this.props.selectable}
                filterHintText={this.props.filterHintText ? this.props.filterHintText : 'Search'}
                enableSelectAll={this.props.enableSelectAll ? this.props.enableSelectAll : false}
                showRowHover={true}
                headerToolbarMode={this.props.toolbarMode ? this.props.toolbarMode : 'filter'}
                showHeaderToolbar={true}
                columns={this.props.columns}
                data={this.state.shownData}
                onCellClick={this.handleCellClick.bind(this)}
                showCheckboxes={this.props.selectable}
                rowSizeList={this.state.rowSizeList}
                onNextPageClick={this.onNextPageClick.bind(this)}
                onPreviousPageClick={this.onPreviousPageClick.bind(this)}
                onFilterValueChange={this.handleFilterValueChange}
                onSortOrderChange={this.handleSortOrderChange.bind(this)}
                onRowSizeChange={this.onRowSizeChange.bind(this)}
                onRowSelection={this.onRowSelection.bind(this)}
                tableRowStyle={{cursor: 'pointer'}}
                rowSize={this.state.rowSize}
                page={this.state.page}
                count={this.props.data.length}
                tableWrapperStyle={{overflow: 'none'}}
                tableBodyStyle={{overflowX: 'none', overflowY: 'none'}}
                tableStyle={styles}
                selectedRows={this.state.selectedRows}
                initialSort={this.props.initialSort}

            />
        )
    }

}

export default Table;
