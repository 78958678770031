import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';


export const getAllCategoriesRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/categories',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};

export const postCategoryRequest = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/categories',
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const deleteCategoryRequest = (id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/categories/' + id,
        {
            method: 'DELETE',
            headers: {
                "api-token": token
            }
        }
    ).then(handleResponse);
};

export const patchCategoryRequest = (category_id, formData, token) => {
	formData.append("_method", "PATCH");
	return fetch(
		process.env.REACT_APP_API_ROOT + '/categories/' + category_id + '/update',
		{
			method: 'POST',
			headers:{
					'api-token': token
			},
			body: formData
		}
   ).then(handleResponse);
}