import React, {Component} from 'react';
import HoldAccountsTable from '../blocks/HoldAccounts/hold-accounts-table';

class AdminHoldAccounts extends Component {
   render() {
      return (
         <HoldAccountsTable admin={true} userId={null}/>
      )
   }
}

export default AdminHoldAccounts;