import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import UsersTable from '../blocks/UsersTable';

class AdminArchivedUsers extends Component {
  render() {
    return (
      <div className="admin-dashboard">
        <ButtonLink backgroundColor="#525252" labelColor="#ffffff" label="Active Users" to={'/admin/users'}/>
        <div className="list-view table-view">
          <Subheader className="table-style">All Archived Users</Subheader>
          <UsersTable trashed={true}/>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(AdminArchivedUsers);
