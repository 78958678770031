export default function expenseOptions(categories, type) {		
	let options = Object.keys(categories).map(key => {
		return {
			value: categories[key].id,
			text: categories[key].expense_category,
			advance: categories[key].advance,
			expense: categories[key].expense,
			mileage: categories[key].mileage
			};
	});

	options = options.filter((option) => {
		return option[type] === 1;
	});
	
	options.sort(function(a,b){
		let x = a.text.toUpperCase();
		let y = b.text.toUpperCase();
		if(x > y)
			return 1;
		if(x < y)
			return -1;
		return 0
	});
	return options;
}