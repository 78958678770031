import {patchMileageRequest} from '../api/mileage';

import{
    PATCH_MILEAGE,
    PATCH_MILEAGE_SUCCESS,
    PATCH_MILEAGE_FAILURE
} from './types';


function patchMileage(){
    return{
        type: PATCH_MILEAGE
    }
}

function patchMileageSuccess(json){
    return{
        type: PATCH_MILEAGE_SUCCESS,
        payload: json
    }
}

function patchMileageFailure(error){
    return{
        type: PATCH_MILEAGE_FAILURE,
        payload: error
    }
}


export default function requestPatchMileage(mileage_id, formData, token) {
    return dispatch => {
        dispatch(patchMileage());
        return patchMileageRequest(mileage_id, formData, token)
            .then((json) => {
                return dispatch(patchMileageSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchMileageFailure(errors));
                })
            });
    };
}