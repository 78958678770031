import {getProcessedExpenseReportsRequest} from '../api/expense-reports';

import {
   GET_PROCESSED_EXPENSE_REPORTS,
   GET_PROCESSED_EXPENSE_REPORTS_SUCCESS,
   GET_PROCESSED_EXPENSE_REPORTS_FAILURE
} from './types';


function getProcessedExpenseReports() {
   return {
      type: GET_PROCESSED_EXPENSE_REPORTS
   }
}

function getProcessedExpenseReportsSuccess(json) {
   return {
      type: GET_PROCESSED_EXPENSE_REPORTS_SUCCESS,
      payload: json
   }
}

function getProcessedExpenseReportsFailure(error) {
   return {
      type: GET_PROCESSED_EXPENSE_REPORTS_FAILURE,
      payload: error
   }
}


export default function requestGetProcessedExpenseReports(token) {
   return dispatch => {
       dispatch(getProcessedExpenseReports());
       return getProcessedExpenseReportsRequest(token)
           .then((json) => {
               return dispatch(getProcessedExpenseReportsSuccess(json));
           })
           .catch((error) => {
               return error.json().then((errors) => {
                   return dispatch(getProcessedExpenseReportsFailure(errors));
               })
           });
   };
}