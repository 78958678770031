import React, {Component} from 'react';
import _ from 'lodash';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

class DropDownSelect extends Component {
    state = {
        value: ''
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({value: nextProps.value});
        }
    }

    componentDidMount() {
        if (this.props.value !== '') {
            this.setState({value: this.props.value});
        }
    }


    handleChange = (event, index, value) => {
        this.setState({value: value});
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }


    render() {

        let options = this.props.options;
         options.sort(function(a,b) {return (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0);} );

        return (
            <div>
                <input type="hidden" className={this.props.className} id={this.props.id} name={this.props.id} defaultValue={this.state.value}/>
                <SelectField
                    className={this.props.className}
                    value={this.state.value}
                    onChange={this.handleChange}
                    id={this.props.id} name={this.props.id}
                    hintText={this.props.placeholder}
                    errorText={_.get(this.props.errors, 'error.' + this.props.id) ? _.get(this.props.errors, 'error.' + this.props.id)  : null}
                    floatingLabelText={this.props.floatingLabelText}
                    floatingLabelFixed={!!this.props.floatingLabelText}
                    floatingLabelStyle={this.props.floatingLabelStyle}
                    multiple={this.props.multiple}
                    style={this.props.style}
                    autoWidth={true}
                    disabled={this.props.disabled}
                >
                    {options.map((option, index) => {
                        return (
                            <MenuItem
                                key={option.value + '-' + index}
                                value={option.value}
                                primaryText={option.text}
                                insetChildren={this.props.multiple}
                                checked={(this.props.multiple && _.get(this.state, 'value', []).indexOf(option.value) > -1) ? true: false}
                            />
                        );
                    })}
                </SelectField>
            </div>

        )
    }

}
export default DropDownSelect;
