import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';

export const getAllDistributionsRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/distribution',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};

export const getDistributionRequest = (token, id) => {
  return fetch(
    `${process.env.REACT_APP_API_ROOT}/distribution/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token
      }
    }
  ).then(handleResponse);
};

export const getPaginatedDistributionsRequest = (token, limit, page, orderBy, order, status, q, userId) => {
  const url = new URL(`${process.env.REACT_APP_API_ROOT}/paginated_distribution`);
  const params = {
    limit,
    page,
    order_by: orderBy,
    order,
    ...(status && status.length ? { status } : {}),
    ...(q && q.length ? { q } : {}),
    ...(typeof userId === 'number' ? { user_id: userId } : {}),
  };
  url.search = new URLSearchParams(params).toString();
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token
      },
    }
  ).then(handleResponse);
}

export const postDistributionRequest = (formData, token, json = false) => {
  const headers = { 'api-token': token };
  if (json) headers['Content-Type'] = 'application/json';
    return fetch(
      process.env.REACT_APP_API_ROOT + '/distribution',
      {
        method: 'POST',
        headers,
        body: json ? JSON.stringify(formData) : formData
      }
    ).then(handleResponse);
};

export const patchDistributionRequest = (id, formData, token, json = false) => {
  const headers = { 'api-token': token };
  if (json) headers['Content-Type'] = 'application/json';
  else formData.append("_method", "PATCH");
  return fetch(
      process.env.REACT_APP_API_ROOT + '/distribution/' + id,
      {
          method: json ? 'PATCH' : 'POST',
          headers,
          body: json ? JSON.stringify(formData) : formData
      }
    ).then(handleResponse);
};

export const deleteDistributionRequest = (id, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/distribution/' + id, {
         method: 'DELETE',
         headers: {
            "api-token": token
         }
      }
   ).then(handleResponse);
};

export const postBatchDistributionsRequest = (formdata, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/batch-distributions', {
         method: 'POST',
         headers: {
            "api-token": token
         },
         body: formdata
      }
   ).then(handleResponse);
};

export const postBatchDistributionsApproveRequest = (formdata, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/batch-approve', {
         method: 'POST',
         headers: {
            "api-token": token
         },
         body: formdata
      }
   ).then(handleResponse);
}
