import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import CustomListItemComponent from '../blocks/custom-list-item-component';
import { List } from 'material-ui/List';
import { doesItemExistInState } from '../../utilities/exist-in-state';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import DeleteButton from '../blocks/delete-button';
import Subheader from 'material-ui/Subheader';
import ExpenseTypeTag from '../blocks/expense-type-tag';
import Icon from '../blocks/icon';
import moment from 'moment';
import {
   GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE,
   DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
   DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE
} from '../../actions/types';

const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
}

class MissionaryDashboard extends Component {

   constructor(props) {
      super(props);
      this.state = {
         expenseReports: [],
         forceUpdate: 0
      };
   }

   componentDidMount() {
      this.props.dispatch(Actions.requestGetUser(this.props.params.auth_id, Cookies.get('token')));
      this.props.dispatch(Actions.requestGetAllExpenseReportsByUser(this.props.params.auth_id, Cookies.get('token'))).then((result) => {
         if (result.type === GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE) {
            this.props.dispatch(Actions.newError(result.payload.message));
         } else if (this.props.params.forceUpdate) {
            //need incase a report has been submitted that was waiting
            this.incrementForceUpdate();
         }
      });
   }

   deleteExpenseReport(id) {
      const that = this;
      this.props.dispatch(Actions.requestDeleteUnsubmittedExpenseReport(id, Cookies.get('token')))
         .then(function (result) {
            if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
               that.incrementForceUpdate();
            } else if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE) {
               const { payload = {} } = result,
                   { message } = payload;
               that.props.dispatch(Actions.newError(message || 'Something west wrong.'));
            }
         });
   }

   incrementForceUpdate = ()=>{
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   }

   getButtons = (expenseReport, edit_link) => {

      const editButton = <ButtonLink
         key={ 'edit-report-button' + expenseReport.id }
         backgroundColor="#1E88E5"
         labelColor="#ffffff"
         label="Edit"
         to={ edit_link }
         style={ { marginRight: 3 } }
      />

      const viewButton = <ButtonLink
         key={ 'view-report-button' + expenseReport.id }
         backgroundColor="#525252"
         labelColor="#ffffff"
         label="View"
         to={ '/expense-report/' + expenseReport.id }
         style={ { marginRight: 3 } }
      />

      const deleteButton = <DeleteButton
         key={ 'delete-report-button' + expenseReport.id }
         message="Delete this expense report?"
         label="Delete"
         backgroundColor="#e60000"
         labelColor="#ffffff"
         style={ buttonStyle }
         contentStyle={ { width: '40%' } }
         onClick={ () => this.deleteExpenseReport(expenseReport.id) }
      />

      let buttons = [];

      if (!expenseReport.submitted) {
         buttons.push(editButton);
         buttons.push(deleteButton);
      }
      if (expenseReport.submitted) {
         buttons.push(viewButton);
      }
      return buttons;
   }


   render() {
      const expenseReports      = _.values( this.props.expenseReports.expenseReportsByUser)
      const selectedUser        = doesItemExistInState(this.props.users.users, this.props.auth.user.id);
      const unreimbursed_amount = selectedUser ? selectedUser.profile.unreimbursed_amount.toFixed(2) : '0.00';

      const columns = [
         { key: 'id', label: 'ID', 'sortable': true },
         { key: 'type', label: 'Expense Type', 'sortable': false },
         { key: 'total_amount', label: 'Amount', 'sortable': false },
         { key: 'payout', label: 'Reimbursed Amount', 'sortable': false },
         { key: 'start_date', label: 'Start Date', 'sortable': false },
         { key: 'end_date', label: 'End Date', 'sortable': false },
         { key: 'date_submitted', label: 'Date Submitted', 'sortable': false },
         { key: 'processed', label: 'Processed', 'sortable': false },
         { key: 'buttons', label: '', 'sortable': false },
         {}
      ];

      const expenseReportsTable = expenseReports
         .map(report => {
            let editLink;
            if (report.type === 'reimbursement') {
               editLink = '/expense-report/' + report.id + '/edit';
            } else if (report.type === 'advance') {
               const advance_request = _.get(report, 'advance_request', []);
               editLink = '/expense-report/' + report.id + '/advance/' + advance_request.id + '/edit';
            }

            return({
               id: report.id,
               type: <ExpenseTypeTag type={report.type } />,
               total_amount: report.total_amount ? '$' + parseFloat(report.total_amount).toFixed(2) : null,
               payout: report.payout ? '$' + report.payout.toFixed(2) : null,
               start_date: report.start_date ? moment(report.start_date).format('MM/DD/YYYY') : null,
               end_date: report.end_date ? moment(report.end_date).format('MM/DD/YYYY') : null,
               date_submitted: report.date_submitted ? moment(report.date_submitted).format('MM/DD/YYYY') : null,
               processed: report.processed ? <Icon type="done" /> : <Icon type="clear" />,
               buttons: this.getButtons(report, editLink)
            });
         })
         .sort((a, b) => {
            //sort by expense report id descending
            return b.id - a.id;
         });


      return (
         <div>
            <h2 className="missionary-form-heading form-heading">Unreimbursed Amount: ${ unreimbursed_amount }</h2>
            <List className="missionary-dashboard-mobile-view mobile-list-view">
               <Subheader className="table-style">My Expense Report History</Subheader>
               <CustomListItemComponent expense_reports={ expenseReports } />
            </List>
            <div className="list-view missionary-dashboard-table-view table-view">
               <Subheader className="table-style">Dashboard</Subheader>
               <Table className="missionary-dash-table" data={ expenseReportsTable } columns={ columns } forceUpdate={ this.state.forceUpdate } />
            </div>
         </div>)
   }
}

export default connect(
   state => ({
      auth: state.rootReducer.auth,
      expenseReports: state.rootReducer.expenseReports,
      users: state.rootReducer.users
   })
)(MissionaryDashboard);
