import React, { Component } from 'react';
import { connect } from 'react-redux';
import Subheader from 'material-ui/Subheader';
import ExpenseReportsTable from '../blocks/ExpenseReportsTable';

class UnprocessedExpenseReports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="unprocessed-expense-reports">
        <div className="list-view table-view">
          <Subheader className="table-style">Unprocessed Expense Reports</Subheader>
          <ExpenseReportsTable
            submitted={1}
            processed={0}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(UnprocessedExpenseReports);
