import React, {Component} from 'react';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {POST_EXPENSE_REPORT_SUCCESS, POST_EXPENSE_REPORT_FAILURE} from '../../actions/types';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';



class MissionaryNewReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_new_report: true
        };
    }


    saveExpenseReport(event) {
        event.preventDefault();
        const form = document.getElementById('create-new-report-form');
        let formData = new FormData(form);
        const that = this;
        this.props.dispatch(Actions.requestPostExpenseReport(formData, Cookies.get('token')))
            .then(function (result) {
                if (result.type === POST_EXPENSE_REPORT_SUCCESS) {
                    that.props.dispatch(Actions.newMessage('You have successfully started an expense report'));
                    browserHistory.push('/expense-report/' + result.payload.id + '/edit');
                } else if (result.type === POST_EXPENSE_REPORT_FAILURE) {
                    that.props.dispatch(Actions.newError('Something went wrong.'));
                }
            })
    }

    cancelReport(event) {
        event.preventDefault();
        browserHistory.push('/dashboard/' + this.props.auth.user.id)
    }

    render() {

        const actions = [
            <RaisedButton 
               labelColor="#ffffff"
               backgroundColor={'#525252'}
               label="Cancel"
               onClick={(event) => this.cancelReport(event)} 
               style={{marginRight: 3}}                      
            />,
            <RaisedButton 
               labelColor="#ffffff"
               backgroundColor={'#1E88E5'}
               label="Start Report"
               onClick={(event) => this.saveExpenseReport(event)}                       
            />
        ];


        return (
            <Dialog
                modal={true}
                actions={actions}
                open={this.state.open_new_report}
                contentStyle={{width: '90%'}}
                autoScrollBodyContent={true}
                actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
            >
                <div className="missionary-new-report">
                  <Subheader className="curved-style">Do you want to start a new report?</Subheader>
                  <div className="form-block-container">
                     <form id="create-new-report-form" onSubmit={(event) => this.saveExpenseReport(event)}
                           encType="multipart/form-data">
                           <input type="hidden" id="type" name="type" value={this.props.params.type}/>
                           <input type="hidden" id="distance_type" name="distance_type" value="none"/>
                           <input type="hidden" name="user_id" id="user_id" value={this.props.auth.user.id}/>
                     </form>
                  </div>
                </div>
            </Dialog>
        )
    }
}

export default connect(
    state => ({
        auth: state.rootReducer.auth,
        expenseReports: state.rootReducer.expenseReports,
        users: state.rootReducer.users
    })
)(MissionaryNewReport);
