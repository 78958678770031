import React, { Component } from 'react';
import { connect } from 'react-redux';
import Subheader from 'material-ui/Subheader';
import ExpenseReportsTable from '../blocks/ExpenseReportsTable';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="admin-dashboard">
        <div className="list-view table-view">
          <div className="admin-dashboard-table">
            <Subheader className="table-style">Expense Reports</Subheader>
            <ExpenseReportsTable
              submitted={1}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(AdminDashboard);
