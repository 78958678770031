import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import DropDownSelect from '../../fields/dropdown-select-field';
import Input from '../../fields/input';
import Subheader from 'material-ui/Subheader';
import {parseDecimalString} from "../../../utilities/string-helpers";

class HoldAccountTransactionDialog extends React.Component {
  state = {
    amount: null,
    accountId: null,
  };

  holdAccountOptions = () => (this.props.holdAccounts || []).map(account => ({
    text: account.fund_name,
    value: account.id,
  }));

  formValid = () => Object.values(this.state).map(val => typeof val === 'string' ? !!val.length : !!val).every(Boolean);

  addTransactionSubmit = () => {
    if (!this.formValid()) return;
    const transaction = { ...this.state, amount: parseDecimalString(this.state.amount) };
    this.resetForm(() => this.props.onSubmit(transaction));
  };

  onChange = key => val => this.setState({ [key]: val });

  resetForm = (callback) => this.setState({ amount: null, accountId: null }, () => callback ? callback() : undefined);

  onClose = () => this.resetForm(this.props.onClose);

  render = () => (
    <Dialog
      modal={true}
      open={this.props.open}
      contentStyle={{width: '30%'}}
      onRequestClose={this.onClose}
    >
      <Subheader className="curved-style" style={{marginBottom: 5}}>
        Add {this.props.transactionType}
      </Subheader>
      <div style={{textAlign: 'center'}}>
        <DropDownSelect
          id="account_id"
          floatingLabelText="Account"
          floatingLabelStyle={{left: 0}}
          options={this.holdAccountOptions()}
          style={{width: '190px'}}
          value={this.state.accountId}
          onChange={this.onChange('accountId')}
        />
        <Input
          id="add_trans_input"
          floatingLabelText="Amount"
          style={{width: '190px', marginBottom: 10}}
          value={this.state.amount || ''}
          prefix="$"
          prefixStyle={{
            position: 'absolute',
            left: 62,
            bottom: 22,
            fontWeight: 600
          }}
          onChange={this.onChange('amount')}
        />
        <RaisedButton
          key="add-dialog-submit"
          label="Submit"
          className="category-action-button"
          backgroundColor="#1E88E5"
          labelColor="#ffffff"
          onClick={() => this.addTransactionSubmit()}
          disabled={!this.formValid()}
        />
        <RaisedButton
          key="add-dialog-close"
          label="Close"
          className="cancel-category-dialog"
          backgroundColor="#525252"
          labelColor="#ffffff"
          onClick={this.onClose}
        />
      </div>
    </Dialog>
  );
}

export default HoldAccountTransactionDialog;
