import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import Input from '../fields/input';

import {
   POST_DENARI_UPLOAD_SUCCESS,
   POST_DENARI_UPLOAD_FAILURE
} from '../../actions/types';


class DenariUpload extends Component {

   submitFile(event) {
      event.preventDefault();
      //input confirmation
      if(document.getElementById('upload_name').value === '') {
         this.props.dispatch(Actions.newError('Upload Name Required'));
         return;
      }
      if(document.getElementById('file_path').value === '') {
         this.props.dispatch(Actions.newError('No File Chosen'));
         return;
      }

      const form = document.getElementById('add-file-form');
      let formData = new FormData(form);
      this.props.dispatch(Actions.requestPostDenariUpload(formData, Cookies.get('token')))
         .then((result) => {
            if (result.type === POST_DENARI_UPLOAD_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Denari import posted successfully.'));
            } else if (result.type === POST_DENARI_UPLOAD_FAILURE) {
               this.props.dispatch(Actions.newError(result.payload.message));
            }
         });
    }


   render() {
      return(
         <div className="form-block">
            <Subheader className="table-style">Denari Upload</Subheader>
            <form id="add-file-form" onSubmit={(event) => this.submitFile(event)} encType="multipart/form-data">
               <div className="form-section new-report-section">
                  <Input
                     className="upload-name"
                     id="upload_name"
                     floatingLabelText="Upload Name"
                     style={{margin: '0 auto'}}
                  />
                  <input type="file" id="file_path" name="denari-file" style={{display: 'block', margin: '40px auto 60px'}}/>
                  <RaisedButton 
                     labelColor="#ffffff"
                     backgroundColor={'#525252'}
                     label="Upload"
                     onClick={(event) => this.submitFile(event)}
                  />
               </div>
            </form>
         </div>
      )
   }

}

export default connect()(DenariUpload);
