import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {POST_USER_SUCCESS, POST_USER_FAILURE} from '../../actions/types';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import DropDownSelect from '../fields/dropdown-select-field';
import {roles} from '../../constants/options';
import Subheader from 'material-ui/Subheader';
import {browserHistory} from 'react-router';


class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectValue: '',
        };
    }

    submitUser(event) {
        event.preventDefault();
        const form = document.getElementById('create-user-form');
        const formData = new FormData(form);
        formData.append('baseUrl', window.location.origin);
        formData.append('password', Math.random().toString(13).slice(-8))
        const that = this;
        this.props.dispatch(Actions.requestPostUser(formData, Cookies.get('token')))
            .then(function (result) {
                if (result.type === POST_USER_SUCCESS) {
                    // alert('you have successfully added a user');
                    that.props.dispatch(Actions.newMessage('You have successfully added a user'));
						  browserHistory.push('/admin/users');
                } else if (result.type === POST_USER_FAILURE) {
                    // alert('something went wrong');
                    //message alerts are currently in a different branch! be here soon!
                    that.props.dispatch(Actions.newError('Something went wrong. Perhaps that email already exists in the system.'));
                }
            })
    }

    clearForm = () => {
        const form = document.getElementById('create-user-form');
        form.reset();
        this.setSelectValue('');
    }

    setSelectValue(event) {
        const selectValue = event;
        this.setState({
            selectValue
        });
    }


    render() {


        return (

            <div className="users">
                <h2 className="form-heading">Create New User</h2>
                <div className="form-block-container">
                    <div className="form-block">
                        <Subheader className="table-style">Basic Info</Subheader>
                        <form id="create-user-form" onSubmit={(event) => this.submitUser(event)}
                              encType="multipart/form-data">
                            <div className="form-section">
                                <Input className="inline-inputs" id="name" floatingLabelText="Name"
                                       errors={this.props.users}/>
                                <Input className="inline-inputs" id="email" floatingLabelText="Email"
                                       errors={this.props.users}/>
                                <DropDownSelect
                                    className="inline-inputs" id="role"
                                    floatingLabelText="Role"
                                    floatingLabelStyle={{left: 0}}
                                    errors={this.props.users}
                                    options={roles}
                                    value={this.state.selectValue}
                                    onChange={(event) => {
                                            this.setSelectValue(event)
                                        }}
                                />
                                <Input className="inline-inputs" id="missionary_number" floatingLabelText="Account #"
                                       errors={this.props.users}/>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="form-buttons">
                    <RaisedButton labelColor="#ffffff" backgroundColor={'#525252'} label="Clear Form"
                                  onClick={this.clearForm}/>
                    <RaisedButton labelColor="#ffffff" backgroundColor={'#1E88E5'} label="Create User"
                                  onClick={(event) => this.submitUser(event)}/>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        users: state.rootReducer.users
    })
)(CreateUser);
