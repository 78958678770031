import React, {Component} from 'react';
import {ListItem} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Avatar from 'material-ui/Avatar';
import {grey400, darkBlack} from 'material-ui/styles/colors';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {connect} from 'react-redux';
import Input from '../fields/input';
import DropDownSelect from '../fields/dropdown-select-field';
import DatePickerInput from '../fields/date-picker-input';
import _ from 'lodash';
import {
  PATCH_EXPENSE_SUCCESS,
  PATCH_EXPENSE_FAILURE,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_FAILURE
} from '../../actions/types';
import ReimbursementIcon from '../../assets/images/reimbursement_icon@2x.png'
import {Link} from 'react-router';
import expenseOptions from '../../utilities/expenseOptions';


class ReimbursementListItems extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open_edit_expenses: false,
      open_delete_expenses: false,
      total_usd: null,
      expenseCode: null,
      foreignCurrency: null,
      exchangeRate: null,
      holdAccountId: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));
    this.props.dispatch(Actions.requestGetAllHoldAccounts(Cookies.get('token')));
  }


  //dialog box open and close

  handleOpenDeleteReimbursement = () => {
    this.setState({open_delete_expenses: true});
  };


  handleCloseExpenses = () => {
    this.setState({open_edit_expenses: false});
    this.setState({open_delete_expenses: false});
    this.setState({holdAccountId: null})
  };


  handleOpenEditReimbursement = () => {
    this.setState({open_edit_expenses: true});
    const expense = this.props.expense;
    this.setState({expenseCode: expense.expense_code});
    this.setState({foreignCurrency: expense.field_currency});
    this.setState({exchangeRate: expense.exchange_rate});
    this.setState({holdAccountId: expense.hold_account_id});
    this.updateDateInput(expense.date);
  };

  updateDateInput(date) {
    const dateInput = document.getElementById('date');
    if (dateInput) {
      dateInput.value = date;
    } else {
      setTimeout(() => {
        this.updateDateInput(date)
      }, 300)
    }
  }

  patchReimbursement(e) {
    e.preventDefault();
    const form = document.getElementById('update-reimbursement-form');
    let formData = new FormData(form);
    const that = this;
    this.props.dispatch(Actions.requestPatchExpense(this.props.expense_id, formData, Cookies.get('token')))
      .then(function (result) {
        if (result.type === PATCH_EXPENSE_SUCCESS) {
          that.props.dispatch(Actions.newMessage('You have successfully updated this reimbursement item'));
          that.handleCloseExpenses();
        } else if (result.type === PATCH_EXPENSE_FAILURE) {
          const { payload = {} } = result,
              { message } = payload;
          that.props.dispatch(Actions.newError(message || 'Something went wrong.'));
          that.handleCloseExpenses();

        }
      })

  }

  deleteExpense(e) {
    e.preventDefault();
    const that = this;
    this.props.dispatch(Actions.requestDeleteExpense(this.props.expense_id, Cookies.get('token')))
      .then(function (result) {
        if (result.type === DELETE_EXPENSE_SUCCESS) {
          that.props.dispatch(Actions.newMessage('You have successfully deleted this expense item'));
          that.handleCloseExpenses();
        } else if (result.type === DELETE_EXPENSE_FAILURE) {
          const { payload = {} } = result,
              { message } = payload;
          that.props.dispatch(Actions.newError(message || 'Something went wrong.'));
          that.handleCloseExpenses();
        }
      })

  }

  options = (type) => {
    const categories = this.props.categories.categories;
    return expenseOptions(categories, type);
  };

  getHoldAccountOptions = () => {
    const holdAccounts = _.values(this.props.donations.holdAccounts);
    const userId = this.props.auth.user.id;
    const holdAccountOptions = holdAccounts
      .filter(account => {
        return account.user_id === userId;
      })
      .map(account => {
        return {
          value: account.id,
          text: account.fund_name
        };
      });

    holdAccountOptions.unshift({
      value: '',
      text: 'None'
    });

    return holdAccountOptions;
  };


  foreignCurrency(event) {
    this.setState({foreignCurrency: event});
  }

  exchangeRate(event) {
    this.setState({exchangeRate: event});
  }

  fromHeldChecked = (event) => {
    this.setState({fromHeldChecked: event});
  }

  render() {

    let total_usd;
    total_usd = isFinite(this.state.foreignCurrency / this.state.exchangeRate) ? (this.state.foreignCurrency / this.state.exchangeRate).toFixed(2) : '0.00' ;


    const iconButtonElement = (
      <IconButton
        touch={true}
        tooltip="more"
        tooltipPosition="bottom-left"
      >
          <MoreVertIcon color={grey400}/>
        </IconButton>
    );

    const rightIconMenu = (
      <IconMenu iconButtonElement={iconButtonElement}>
        <MenuItem onClick={this.handleOpenEditReimbursement}>Edit</MenuItem>
        <MenuItem onClick={this.handleOpenDeleteReimbursement}>Delete</MenuItem>
      </IconMenu>
    );

    //buttons for delete expense items
    const actions_delete_expense = [
      <RaisedButton
        labelColor="#ffffff"
        backgroundColor={'#525252'}
        label="Cancel"
        onClick={this.handleCloseExpenses}
        style={{marginRight: 3}}
      />,
      <RaisedButton
        labelColor="#ffffff"
        backgroundColor={'#d40a0a'}
        label="Delete"
        onClick={(event) => this.deleteExpense(event)}
        style={{marginRight: 3}}
      />
    ];


    //buttons for reimbursement items
    const actions_edit_expenses = [
      <RaisedButton
        labelColor="#ffffff"
        backgroundColor={'#525252'}
        label="Cancel"
        onClick={this.handleCloseExpenses}
        style={{marginRight: 3}}
      />,
      <RaisedButton
        labelColor="#ffffff"
        backgroundColor={'#1E88E5'}
        label="Update"
        onClick={(event) => this.patchReimbursement(event)}
      />
    ];


    return (
      <div>
        <div className="message-list-items">
          <ListItem
            className="list-item-style"
            leftAvatar={<Avatar src={ReimbursementIcon}/>}
            // eslint-disable-next-line
            rightIconButton={this.props.view_only == true ? null : rightIconMenu}
            primaryText={<div className="dollar_amount">{this.props.primaryText}</div>}
            secondaryText={
              <p>
                <span style={{color: darkBlack}}>{this.props.secondaryText}</span><br />
                <strong>{this.props.tertiaryText}</strong>
              </p>
            }
            secondaryTextLines={2}
          />
          <Divider inset={true}/>
        </div>

        <Dialog
          modal={true}
          actions={actions_edit_expenses}
          open={this.state.open_edit_expenses}
          contentStyle={{width: '90%'}}
          autoScrollBodyContent={true}
          actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
        >
        <Subheader className="curved-style">Edit Expense Entry"</Subheader>
        <form
          id="update-reimbursement-form"
          onSubmit={(event) => this.patchReimbursement(event)}
          encType="multipart/form-data"
        >
          <Input
            className="inline-inputs"
            id="field_currency"
            floatingLabelText="Foreign Currency Amount"
            errors={this.props.expenseReports}
            onChange={(event) => this.foreignCurrency(event)}
            value={this.state.foreignCurrency}
          />
          <Input
            className="inline-inputs"
            id="exchange_rate"
            floatingLabelText="Exchange Rate"
            errors={this.props.expenseReports}
            onChange={(event) => this.exchangeRate(event)}
            value={this.state.exchangeRate}
          />
          <Input
            disabled={true}
            className="inline-inputs"
            id="usd_amount"
            floatingLabelText="Total in USD"
            errors={this.props.expenseReports}
            value={total_usd}
            prefix="$"
          />
          <input
            type="hidden"
            name="usd_amount"
            id="usd_amount"
            value={total_usd}
          />
          <DatePickerInput
            className="inline-inputs"
            id="date"
            floatingLabelText="Date *"
            errors={this.props.expenseReports}
          />
          <div className="inline-inputs" style={{marginBottom: 10, marginTop: 10}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>
                <DropDownSelect
                  style={{marginBottom: '5px'}} id="expense_code"
                  floatingLabelText="Expense Type"
                  floatingLabelStyle={{left: 0}}
                  errors={this.props.expenseReports}
                  options={this.options('expense')}
                  value={this.state.expenseCode}
                />
              </div>
              <div>
                <Link
                  className="type-descriptions"
                  target="_blank"
                  to={'/expense-descriptions/reimbursement'}>
                  View Type Descriptions
                </Link>
                <input
                  type="hidden"
                  name="expense_report_id"
                  id="expense_report_id"
                  value={this.props.expense_report_id}
                />
              </div>
            </div>
          </div>
          <DropDownSelect
            className="inline-inputs"
            style={{marginBottom: 10, marginTop: 10,float: 'left'}}
            id="hold_account_id"
            floatingLabelText="From Held Account?"
            floatingLabelStyle={{left: 0}}
            errors={this.props.expenseReports}
            options={this.getHoldAccountOptions()}
            value={this.state.holdAccountId}
          />
        </form>
      </Dialog>

      <Dialog
        modal={true}
        actions={actions_delete_expense}
        open={this.state.open_delete_expenses}
        contentStyle={{width: '90%'}}
        autoScrollBodyContent={true}
        actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
      >
        <Subheader className="curved-style">Delete Expense Item?</Subheader>
      </Dialog>
    </div>
    )
  };
}

export default connect(
  state => ({
    auth: state.rootReducer.auth,
    expenseReports: state.rootReducer.expenseReports,
    categories: state.rootReducer.categories,
    donations: state.rootReducer.donations
  })
)(ReimbursementListItems);
