import React, {Component} from 'react';
import {browserHistory, Link} from 'react-router';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE} from '../../actions/types';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import SocialPerson from 'material-ui/svg-icons/social/person';
import ActionLock from 'material-ui/svg-icons/action/lock';

class Login extends Component {

    constructor() {
        super();
        this.state = {
            username: '',
            'password': ''
        }
    }

    login(event) {
        const that = this;
        event.preventDefault();
        const email = document.getElementById('email').value.trim();
        const password = document.getElementById('password').value.trim();
        this.props.dispatch(Actions.login(email, password))
            .then(function (result) {
                if (result.type === LOGIN_USER_SUCCESS) {
                    Cookies.set('token', that.props.auth.user.api_token);
                    that.props.dispatch(Actions.newMessage('Welcome!'));
                    if (that.props.auth.user.created_at === that.props.auth.user.updated_at) {
                        // eslint-disable-next-line
                        if (that.props.auth.user.role == 'employee' || that.props.auth.user.role == 'admin') {
                            browserHistory.push('/admin/dashboard/');
                        } else {
                            browserHistory.push('/dashboard/' + that.props.auth.user.id);
                        }
                    } else {
                        browserHistory.push('/');
                    }
                } else if (result.type === LOGIN_USER_FAILURE) {
                    that.props.dispatch(Actions.newError('Oops, something went wrong'));
                }

            })
    }

    logout(replace) {
        this.props.dispatch(Actions.logout())
    }

    userNameChange(event) {
        this.setState({username: event});
    }

    passwordChange(event) {
        this.setState({password: event});
    }


    render() {

        return (
            <div className="login-view">
                <div className="login-container">
                    {this.props.auth.user.email
                        ?
                        <div className="form-container">
                            <p className="logged-in-as">{"Logged in as " + this.props.auth.user.email}</p>
                            <RaisedButton primary={true} onClick={() => this.logout()}>Logout</RaisedButton>
                        </div>
                        :
                        <div className="form-container">
                            <form onSubmit={(event) => this.login(event)}>
                                <h3 className="login-text">Login</h3>
                                <div className="login-form-inputs">
                                    <Input
                                        type="text"
                                        id="email"
                                        errors={this.props.auth}
                                        onChange={(event) => this.userNameChange(event)}
                                        value={this.state.username}
                                        placeholder="Username"
                                        fieldIcon={<SocialPerson/>}
                                    />
                                    <Input
                                        type="password"
                                        id="password"
                                        fieldIcon={<ActionLock />}
                                        errors={this.props.auth}
                                        onChange={(event) => this.passwordChange(event)}
                                        value={this.state.password}
                                        placeholder="Password"
                                    />
                                </div>
                                <br/>
                                <div className="login-button">
                                    <RaisedButton className="login-submit" primary={true} label="Login" type="submit"/>
                                </div>

                            </form>
                            <br/>
                            <Link className="forgot-pass" to={'/forgot-password'}>Forgot Password?</Link>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        auth: state.rootReducer.auth
    })
)(Login);
