import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';
import {buildUrl} from "../utilities/request-helpers";

export const loginRequest = (email, password) => {
    return fetch(
    process.env.REACT_APP_API_ROOT + '/login',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })

        }
    ).then(handleResponse);
};

export const loginRequestWithToken = (token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/token',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                api_token: token
            })
        }
    ).then(handleResponse);
};


export const resetPasswordRequest = (formData) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/password/reset',
        {
            method: 'POST',
            body: formData
        }
    ).then(handleResponse);
};

export const forgotPasswordEmailRequest = (formData) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/password/email',
        {
            method: 'POST',
            body: formData
        }
    ).then(handleResponse);
};

export const updatePasswordRequest = (id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/' + id + '/update-password',
        {
            method: 'POST',
            headers: {
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};


export const createNewUser = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user',
        {
            method: 'POST',
            headers: {
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const patchUserRequest = (user_id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/' + user_id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};


export const getUserRequest = (user_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/' + user_id,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);
};

export const getActiveUsersRequest = (token, filters) => {
    return fetch(
        buildUrl(process.env.REACT_APP_API_ROOT + '/user', filters),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "api-token": token
            }
        }
    ).then(handleResponse);
};

export const getPaginatedUsersRequest = (token, filters) => {
  return fetch(
    buildUrl(process.env.REACT_APP_API_ROOT + '/paginated_user', filters),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "api-token": token
      }
    }
  ).then(handleResponse);
};

export const getSearchUsersRequest = (token, filters) => {
  return fetch(
    buildUrl(process.env.REACT_APP_API_ROOT + '/search_user', filters),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "api-token": token
      }
    }
  ).then(handleResponse);
};

export const getArchivedUsersRequest = (token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/archived',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "api-token": token
            }
        }
    ).then(handleResponse);
};

export const getUsersByNameRequest = (name, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/search',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            },
            body: JSON.stringify({name: name})
        }
    ).then(handleResponse);
};

export const restoreArchivedUserRequest = (user_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/' + user_id + '/restore',
        {
            method: 'POST',
            headers: {
                "api-token": token
            },
        }
    ).then(handleResponse);
};

export const deleteUserRequest = (user_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/user/' + user_id,
        {
            method: 'DELETE',
            headers: {
                "api-token": token
            }
        }
    ).then(handleResponse);
};
