import _ from 'lodash';
import React, {Component} from 'react';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import DynamicMenuItem from './dynamic-menu-item';
import moment from 'moment';


class AdvanceRequestListItems extends Component {
    render() {


        const advanceRequestList = this.props.advance_requests.map((advanceRequest, index) => {
            const approvedAmount = advanceRequest.amount_approved ? '$' + advanceRequest.amount_approved.toFixed(2) : "Pending";
            

            const iconButtonElement = (
                <IconButton
                    touch={true}
                    tooltip="more"
                    tooltipPosition="bottom-left"
                >
                    <MoreVertIcon color={'black'}/>
                </IconButton>
            );


            let rightIconMenu;
            if (advanceRequest.expense_report_id) {
                if(advanceRequest.status === 'approved') {
                    rightIconMenu = <IconMenu iconButtonElement={iconButtonElement}>
                        <DynamicMenuItem id="edit" name="Edit Report" to={'/expense-report/' + advanceRequest.expense_report_id + '/advance/' + advanceRequest.id + '/edit'}/>
                    </IconMenu>
                } else if(advanceRequest.status === 'submitted') {
                    rightIconMenu = <IconMenu iconButtonElement={iconButtonElement}>
                        <DynamicMenuItem id="view" name="View Report" to={'/expense-report/' + advanceRequest.expense_report_id }/>
                    </IconMenu>
                }
            } else if (advanceRequest.status === 'approved') {
                rightIconMenu = <IconMenu iconButtonElement={iconButtonElement}>
                    <DynamicMenuItem id="add" name="Add Report" to={'/new-report/advance/' + advanceRequest.id}/>
                </IconMenu>
            }


            return (



                <div className="message-list-items" key={index}>
                    <div className="message-list-items">
                        <div>
                            <span tabIndex='0' style={{
                                border: '10px',
                                boxSizing: "border-box",
                                display: "block",
                                fontFamily: "Roboto, sans-serif",
                                cursor: "pointer",
                                textDecoration: "none",
                                padding: "0",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "inherit",
                                position: "relative",
                                color: "rgba(0, 0, 0, 0.87)",
                                lineHeight: "16px",
                                transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                background: "none",
                                height: "100%",
                                float: "left",
                                width: "100%",
                                margin: "10px 0"
                            }}>
                                <div>
                                    <div style={{
                                        marginLeft: "0px",
                                        padding: "16px 0",
                                        position: "relative"
                                    }}>
                                        <div style={{
                                            display: "block",
                                            position: "absolute",
                                            top: "50%",
                                            right: 0,
                                            transform: 'translateY(-50%)'
                                        }}>
                                            {rightIconMenu}
                                        </div>
                                        <div style={{
                                            backgroundColor: '#4DB6AC',
                                            color: "white",
                                            textTransform: "uppercase",
                                            fontFamily: "Roboto",
                                            textAlign: "center",
                                            padding: "10px 12px",
                                            width: "100%"
                                        }}>
                                            <div>Advance Request</div>
                                        </div>
                                        <div style={{width: "100%", float: "left"}}>
                                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <strong>{moment(advanceRequest.date_submitted).format('MM/DD/YYYY')}</strong>
                                                <span>{_.upperFirst(advanceRequest.type)}</span>
                                            </p>
                                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <strong>MAT Date Requested By</strong>
                                                <span>{moment(advanceRequest.mat_date).format('MM/DD/YYYY')}</span>
                                            </p>
                                            {advanceRequest.status === 'requested'
                                                ?
                                                <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <strong>Requested:</strong>
                                                    <span>${advanceRequest.amount_requested.toFixed(2)}</span>
                                                </p>
                                                :
                                                advanceRequest.status === 'approved'
                                                    ?
                                                    <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <strong>Approved:</strong>
                                                        <span>{approvedAmount}</span>
                                                    </p>
                                                    :
                                                    <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <strong>{_.upperFirst(advanceRequest.status)}:</strong>
                                                        <span>{approvedAmount}</span>
                                                    </p>

                                            }
                                        </div>
                                    </div>
                                </div>
                                </span>
                        </div>
                    </div>
                    <Divider style={{clear: 'both'}} inset={true}/>


                </div>
            )
        });


        return (
            <div>
                {advanceRequestList}
            </div>
        )
    };
}

export default AdvanceRequestListItems;