import { deleteMileageRequest } from '../api/mileage';

import {
    DELETE_MILEAGE,
    DELETE_MILEAGE_SUCCESS,
    DELETE_MILEAGE_FAILURE
} from '../actions/types';

function deleteMileage() {
    return {
        type: DELETE_MILEAGE
    };
}

function deleteMileageSuccess(json) {
    return {
        type: DELETE_MILEAGE_SUCCESS,
        payload: json
    };
}

function deleteMileageFailure(error) {
    return {
        type: DELETE_MILEAGE_FAILURE,
        payload: error
    };
}

export default function requestDeleteMileage(mileage_id, token) {
    return dispatch => {
        dispatch(deleteMileage());
        return deleteMileageRequest(mileage_id, token)
            .then((json) => {
                return dispatch(deleteMileageSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(deleteMileageFailure(errors));
                })
            });
    };
}
