import React, {Component} from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {Link} from 'react-router';


class ButtonLink extends Component {


    render() {
        return (
            <Link
                to={this.props.disabled ? null: this.props.to}
                target={this.props.blank_target ? '_blank' : null}
            >
                <RaisedButton
                    className={this.props.className}
                    label={this.props.label}
                    disabled={this.props.disabled}
                    backgroundColor={this.props.backgroundColor}
                    labelColor={this.props.labelColor}
                    buttonStyle={this.props.buttonStyle}
                    style={this.props.style}
                    labelStyle={this.props.labelStyle}
                />
            </Link>

        )
    }

}

export default ButtonLink;