import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import UsersTable from '../blocks/UsersTable';

class AdminActiveUsers extends Component {
  render() {
    return (
      <div className="users-table">
        <ButtonLink backgroundColor="#525252" labelColor="#ffffff" label="Archived Users" to={'/admin/users/archived'}/>
        <div className="list-view table-view">
          <Subheader className="table-style">Active Users</Subheader>
          <UsersTable
            trashed={false}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(AdminActiveUsers);
