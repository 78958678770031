import React, {Component} from 'react';
import {connect} from 'react-redux';
import Cookies from 'cookies-js';
import Actions from '../../actions';


class ReimbursementDescription extends Component {

	componentDidMount() {
		this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));
	}

	expenseCategories = () => {
		const categories = this.props.categories.categories;
		//eslint-disable-next-line
		let expenseCategories = Object.keys(categories).map(key => {
			if(categories[key].expense === 1)
				return <div key={"category" + key}><strong>{categories[key].expense_category}</strong> - {categories[key].description}</div>
		});
		return expenseCategories;
	}

   render() {
		return (
			<div className="expense-descriptions" style={{padding: '3% 5%'}}>
				<h2>Expense Types</h2>
				{this.expenseCategories()}
			</div>
		)
   }
	
}

export default connect(
   state => ({
		categories: state.rootReducer.categories
	})
)(ReimbursementDescription);