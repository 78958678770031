import React, {Component} from 'react';
import {connect} from 'react-redux';
import Cookies from 'cookies-js';
import Actions from '../../actions';

class AdvanceDescription extends Component {

	componentDidMount() {
		this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));
	}

	advanceCategories = () => {
		const categories = this.props.categories.categories;
		//eslint-disable-next-line
		let advanceCategories = Object.keys(categories).map(key => {
			if(categories[key].advance === 1) {
				return <div key={"category" + key}><strong>{categories[key].expense_category}</strong> - {categories[key].description}</div>
			}
		});
		return advanceCategories;
	}

    render() {
        return (
            <div className="expense-descriptions" style={{padding: '3% 5%'}}>

                <h2>Advance Request Types</h2>
					{this.advanceCategories()}
            </div>
        )
    }
}

export default connect(
    state => ({
		 categories: state.rootReducer.categories
	 })
)(AdvanceDescription);