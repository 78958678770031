export const roles = [
    {value: 'admin', 'text': 'Admin'},
    {value: 'employee', 'text': 'Employee'},
    {value: 'missionary', 'text': 'Missionary'},
    {value: 'none', 'text': 'None'}
];

export const report_types = [
    {value: 'reimbursement', 'text': 'Reimbursement'},
    {value: 'advance', 'text': 'Advance'}
];

export const distance_types = [
    {value: 'km', 'text': 'Kilometers'},
    {value: 'mi', 'text': 'Miles'},
    {value: 'none', 'text': 'None'}
];

export const reimbursement_expense_types = [
    {value: 'audio/visual', 'text': 'Audio/Visual', 'description': 'ministry/business related production of audio/visual material'},
    {value: 'bank charges/fees', 'text': 'Bank Charges/Fees', 'description': 'bank services for a ministry/business account'},
    {value: 'benevolence', 'text': 'Benevolence', 'description': 'funds given directly to or for the purchase of items to aide nationals (with no derived personal benefit)'},
    {value: 'book/subscriptions', 'text': 'Book/Subscriptions', 'description': 'ministry/business related books and periodicals'},
    {value: 'business meal', 'text': 'Business Meal', 'description': 'ministry/business related meals'},
    {value: 'computer software', 'text': 'Computer Software', 'description': 'ministry/business related work'},
    {value: 'conferences/seminars', 'text': 'Conferences/Seminars(fees only)', 'description': 'ministry/business related registration expense or fees'},
    {value: 'equipment', 'text': 'Equipment', 'description': 'ministry/business equipment or tools (does not include vehicles)'},
    {value: 'facility rent/utilities', 'text': 'Facility Rent/Utilities', 'description': 'rent or payment and utilities for church or other places of ministry'},
    {value: 'hospitality/gifts', 'text': 'Hospitality/Gifts', 'description': 'ministry/business related'},
    {value: 'internet/email/website', 'text': 'Internet/Email/Website', 'description': 'ministry/business related'},
    {value: 'licenses/fees/permits', 'text': 'Licenses/Fees/Permits', 'description': 'ministry/business related (does not include driver’s license)'},
    {value: 'passport/visa processing', 'text': 'Passport/Visa Processing', 'description': 'including updated passport photos, medical test or immunizations'},
    {value: 'postage', 'text': 'Postage', 'description': 'ministry/business related items, including missionary newsletters'},
    {value: 'printing', 'text': 'Printing', 'description': 'ministry/business related printed material, including missionary newsletters'},
    {value: 'repairs/maintenance', 'text': 'Repairs/Maintenance', 'description': 'ministry/business related (does not include personal home or vehicles)'},
    {value: 'services from national workers', 'text': 'Services from National Workers', 'description': 'payments to a national worker who is employed for providing direct ministry-related assistance (does not include: a maid, a nanny, a cook, or a gardener for personal use).'},
    {value: 'shipping', 'text': 'Shipping-Overseas Freight', 'description': 'ministry/business related supplies or documents and moving personal items for arrival and departure from the field'},
    {value: 'supplies', 'text': 'Supplies-Office/Work/Ministry', 'description': 'ministry/business related office, ministry, and medical supplies'},
    {value: 'telephone/fax', 'text': 'Telephone/Fax', 'description': 'ministry/business related'},
    {value: 'training', 'text': 'Training: Language/Education', 'description': 'ministry/business related (does not include cost for your children’s education)'},
    {value: 'travel', 'text': 'Travel', 'description': 'ministry/business related air and ground transportation costs, meals and lodging including furlough airfare (does not include vacations, personal trips to the US/anywhere, giving tours to visitors/relatives)'}

];

export const mileage_expense_types = [
    {value: 'benevolence', 'text': 'Benevolence', 'description': 'to aid nationals (with no derived personal benefit)'},
    {value: 'business meal', 'text': 'Business Meal', 'description': 'ministry/business related meals'},
    {value: 'conferences/seminars', 'text': 'Conferences/Seminars', 'description': 'ministry/business related'},
    {value: 'speaking engagements/visitation', 'text': 'Speaking Engagements/Visitation', 'description': 'ministry/business related'},
    {value: 'passport/visa processing', 'text': 'Passport/Visa Processing', 'description': 'ministry/business related'},
    {value: 'supplies', 'text': 'Supplies-Office/Work/Ministry', 'description': 'ministry/business related office, ministry, and medical supplies'},
    {value: 'travel', 'text': 'Travel', 'description': 'ministry/business (does not include commute to and from the job or personal trips)'}

];



export const advance_request_types = [
    {value: 'benevolence', 'text': 'Benevolence Program', 'description': 'ministry/business related program cost associated with aide to nationals'},
    {value: 'building', 'text': 'Building Construction/Maintenance', 'description': 'Ministry/business related buildings (church, children homes, or other ministry building)'},
    {value: 'operations', 'text': 'Church/Ministry Operations', 'description': 'ministry/business related daily operating expenses'},
    {value: 'other', 'text': 'Other Approved Projects', 'description': 'pre-approved by SS ministry/business related projects'},
    {value: 'shipping-overseas', 'text': 'Shipping-Overseas Freight', 'description': 'ministry/business related which would also include moving personal items to or from the field'},
    {value: 'training', 'text': 'Training: Language/Education', 'description': 'ministry/business related (does not include children’s education)'},
    {value: 'travel', 'text': 'Travel', 'description': 'ministry/business related including furlough airfare'},
    {value: 'processing', 'text': 'Visa Processing', 'description': 'ministry/business related processing & lawyer fees'},
];

export const taxStatusOptions = [
   {value: 'W2', text: 'W2'},
   {value: '1099', text: '1099'},
   {value: 'W2-Exempt', text: 'W2 Exempt'}
];