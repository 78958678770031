import React, { PureComponent } from 'react';
import {connect} from 'react-redux';
import Cookies from 'cookies-js';
import DatePickerInput from '../fields/date-picker-input';
import ButtonLink from '../blocks/button-link';
import moment from 'moment';
import DropDownSelect from "../fields/dropdown-select-field";

class QuickBooksReportForm extends PureComponent {
  state = {
    date: undefined,
    distributionStatus: 'approved',
  };

  downloadUrl = () =>
    `${process.env.REACT_APP_API_ROOT}/quickbooks-report`
    + `?mat_to_date=${this.state.date}&api_token=${Cookies.get('token')}&status=${this.state.distributionStatus}`;

  render() {
    return (
      <div className="expense-report-form-wrap">
        <div className="header">
          <h1>Quick Books Report</h1>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DatePickerInput
            id="mat_to_date"
            floatingLabelText="MAT Processing Date"
            onChange={(e, date) => this.setState({ date: moment(date).format('YYYY-MM-DD') })}
            style={{ marginRight: 10 }}
          />
          <DropDownSelect
            id="distribution_status"
            floatingLabelText="Distribution Status"
            floatingLabelStyle={{ left: 0 }}
            options={[
              { value: 'approved', text: 'Approved' },
              { value: 'pending', text: 'Pending' },
            ]}
            value={this.state.distributionStatus}
            onChange={distributionStatus => this.setState({ distributionStatus })}
            style={{ marginLeft: 10 }}
          />
        </div>
        <ButtonLink
          backgroundColor="#1E88E5"
          labelColor="#ffffff"
          label="Download"
          to={this.downloadUrl()}
          disabled={!Object.values(this.state).every(Boolean)}
          blank_target={true}
          style={{ marginTop: '20px' }}
        />
      </div>
    )
  }
}
export default connect(
  state => ({
    expenseReports: state.rootReducer.expenseReports
  })
)(QuickBooksReportForm);
