import {postAdvanceRequestRequest} from '../api/advance-requests';

import{
    POST_ADVANCE_REQUEST,
    POST_ADVANCE_REQUEST_SUCCESS,
    POST_ADVANCE_REQUEST_FAILURE
} from './types';

function postAdvanceRequest(){
    return{
        type: POST_ADVANCE_REQUEST
    }
}

function postAdvanceRequestSuccess(json){
    return{
        type: POST_ADVANCE_REQUEST_SUCCESS,
        payload: json
    }
}

function postAdvanceRequestFailure(error){
    return{
        type: POST_ADVANCE_REQUEST_FAILURE,
        payload: error
    }
}

export default function requestPostAdvanceRequest(formData, token) {
    return dispatch => {
        dispatch(postAdvanceRequest());
        return postAdvanceRequestRequest(formData, token)
            .then((json) => {
                return dispatch(postAdvanceRequestSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postAdvanceRequestFailure(errors));
                })
            });
    };
}
