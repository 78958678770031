import React, {Component} from 'react';

class DonationsList extends Component {
   render() {
      const donationsList = this.props.data.map((donation, index) => {
         return(
            <div className="message-list-items" key={index}>
               <div className="donation-list-item">
                  <p><strong>Name:</strong> {donation.name}</p>
                  <p><strong>Hold Fund:</strong> {donation.holdAccount}</p>
                  <p><strong>Amount:</strong> {donation.amount}</p>
                  <p><strong>Count:</strong> {donation.count}</p>
                  <p><strong>Date Added: </strong>{donation.date}</p>
                  <hr/>
               </div>         
            </div>
         )
      });

      return (
         <div>
            <br/>
            {donationsList}
         </div>
      )
   }
}

export default DonationsList;