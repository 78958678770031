import {deleteDonationRequest} from '../api/donations';

import {
   DELETE_DONATION,
   DELETE_DONATION_SUCCESS,
   DELETE_DONATION_FAILURE
} from '../actions/types';

function deleteDonation() {
   return {
      type: DELETE_DONATION
   };
}

function deleteDonationSuccess(json) {
   return {
      type: DELETE_DONATION_SUCCESS,
      payload: json
   };
}

function deleteDonationFailure(error) {
   return {
      type: DELETE_DONATION_FAILURE,
      payload: error
   };
}

export default function requestDeleteDonation(id, token) {
   return dispatch => {
      dispatch(deleteDonation());
      return deleteDonationRequest(id, token)
         .then((json) => {
            return dispatch(deleteDonationSuccess(json));
         })
         .catch((error) => {
            return error.json().then((errors) => {
               return dispatch(deleteDonationFailure(errors));
            })
         });
   };
}