import {postHoldAccountRequest} from '../api/hold-accounts';

import{
   POST_HOLD_ACCOUNT,
   POST_HOLD_ACCOUNT_SUCCESS,
   POST_HOLD_ACCOUNT_FAILURE
} from './types';


function postHoldAccount(){
    return{
        type: POST_HOLD_ACCOUNT
    }
}

function postHoldAccountSuccess(json){
    return{
        type: POST_HOLD_ACCOUNT_SUCCESS,
        payload: json
    }
}

function postHoldAccountFailure(error){
    return{
        type: POST_HOLD_ACCOUNT_FAILURE,
        payload: error
    }
}

export default function requestPostHoldAccount(formData, token) {
    return dispatch => {
        dispatch(postHoldAccount());
        return postHoldAccountRequest(formData, token)
            .then((json) => {
                return dispatch(postHoldAccountSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postHoldAccountFailure(errors));
                })
            });
    };
}