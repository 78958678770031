let formatter = null;

export function formatCurrency(val) {
  const f = getOrInitFormatter();
  return f.format(val);
}

function getOrInitFormatter() {
  if (!formatter)
    formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return formatter;
}
