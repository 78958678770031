import {deleteAdvanceRequestRequest} from '../api/advance-requests';

import {
	DELETE_ADVANCE_REQUEST,
	DELETE_ADVANCE_REQUEST_SUCCESS,
	DELETE_ADVANCE_REQUEST_FAILURE
} from '../actions/types';

function deleteAdvanceRequest() {
	return {
		type: DELETE_ADVANCE_REQUEST
	};
}

function deleteAdvanceRequestSuccess(json) {
	return {
		type: DELETE_ADVANCE_REQUEST_SUCCESS,
		payload: json
	};
}

function deleteAdvanceRequestFailure(error) {
	return {
		type: DELETE_ADVANCE_REQUEST_FAILURE,
		payload: error
	};
}


export default function requestDeleteAdvanceRequest(advance_request_id, token) {
	return dispatch => {
		dispatch(deleteAdvanceRequest());
		return deleteAdvanceRequestRequest(advance_request_id, token)
			.then((json) => {
				return dispatch(deleteAdvanceRequestSuccess(json));
			})
			.catch((error) => {
				return error.json().then((errors) => {
					return dispatch(deleteAdvanceRequestFailure(errors));
				})
			});
	};
}
