import {mergeState} from '11online-redux-helpers';
import {deleteFromState} from '11online-redux-helpers';

import{
	GET_ALL_CATEGORIES,
	GET_ALL_CATEGORIES_SUCCESS,
	GET_ALL_CATEGORIES_FAILURE,
	POST_CATEGORY,
	POST_CATEGORY_SUCCESS,
	POST_CATEGORY_FAILURE,
	DELETE_CATEGORY,
	DELETE_CATEGORY_SUCCESS,
	DELETE_CATEGORY_FAILURE,
	PATCH_CATEGORY,
	PATCH_CATEGORY_SUCCESS,
	PATCH_CATEGORY_FAILURE,
} from '../actions/types';


export function reducer( state = {
    isFetching: false,
    categories: {}
}, action = {type: ''}) {
	switch (action.type) {
		case GET_ALL_CATEGORIES:
			return {
					...state,
					isFetching: true
			};
		case GET_ALL_CATEGORIES_SUCCESS:
			return {
					...state,
					isFetching: false,
					error: [],
					categories: mergeState(state.categories, action.payload)
			};
		case GET_ALL_CATEGORIES_FAILURE:
			return {
					...state,
					error: action.payload,
					isFetching: false,
					categories: {}
			};
		case POST_CATEGORY:
			return {
					...state,
					isFetching: true
			};
		case POST_CATEGORY_SUCCESS:
			return {
					...state,
					isFetching: false,
					error: [],
					categories: mergeState(state.categories, action.payload)
			};
		case POST_CATEGORY_FAILURE:
			return {
					...state,
					error: action.payload,
					isFetching: false,
			};	
		case DELETE_CATEGORY:
			return {
					...state,
					isFetching: true
			};
		case DELETE_CATEGORY_SUCCESS:
			return {
					...state,
					isFetching: false,
					error: [],
					categories: deleteFromState(state.categories, action.payload)
			};
		case DELETE_CATEGORY_FAILURE:
			return {
					...state,
					error: action.payload,
					isFetching: false,
			};
		case PATCH_CATEGORY:
			return {
					...state,
					isFetching: true
			};
		case PATCH_CATEGORY_SUCCESS:
			return {
					...state,
					isFetching: false,
					error: [],
					categories: mergeState(state.categories, action.payload)
			};
		case PATCH_CATEGORY_FAILURE:
			return {
					...state,
					error: action.payload,
					isFetching: false,
			};

		default:
         return state;
	}
}

