import React from 'react';
import {connect} from 'react-redux';
import Actions from '../../../actions';
import Cookies from 'cookies-js';
import Table from '../table';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteButton from '../../blocks/delete-button';
import moment from 'moment';
import _ from 'lodash';
import {dateSortDesc} from '../../../utilities/date-sort';
import HoldAccountsDialog from './hold-accounts-dialog';
import HoldAccountsList from './hold-accounts-list';
import TransactonsDialog from './transactions-dialog';
import RecurringDialog from './recurring-dialog'
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import {
   POST_HOLD_ACCOUNT_SUCCESS,
   POST_HOLD_ACCOUNT_FAILURE,
   PATCH_HOLD_ACCOUNT_SUCCESS,
   PATCH_HOLD_ACCOUNT_FAILURE,
   DELETE_HOLD_ACCOUNT_SUCCESS,
   DELETE_HOLD_ACCOUNT_FAILURE, ARCHIVE_HOLD_ACCOUNT_SUCCESS, ARCHIVE_HOLD_ACCOUNT_ERROR
} from '../../../actions/types';
import DropDownSelect from "../../fields/dropdown-select-field";

const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
};

const initialState = {
   //edit & create
   open: false,
   acctBalance: '0.00',
   acctUserId: '',
   dialogTitle: '',
   actionButtonLabel: '',
   missionaryOptions: [],
   disabled: false,
   //update method
   form: '',
   acctId: '',
   //transactions
   transactionOpen: false,
   distTransactions: [],
   donations: [],
   //recurring
   recurringOpen: false,
   recurringDeposit: '',
   recurringTransfer: '',
   recurringWithdrawal: '',
   acctUserName: '',
   //universal
   actionMethod: '',
   forceUpdate: 0,
   acctFundName: '',
   status: 'active',
}

class HoldAccountsTable extends React.Component {
   constructor(props) {
      super(props);
      this.state = initialState;
   };

   componentDidMount() {
      if(_.isEmpty(this.props.donations.holdAccounts)) {
         this.props.dispatch(Actions.requestGetAllHoldAccounts(Cookies.get('token')));
      }
      if(_.isEmpty(this.props.donations.donations)) {
         this.props.dispatch(Actions.requestGetAllDonations(Cookies.get('token')));
      }

      if(_.isEmpty(this.props.users.users)) {
         this.props.dispatch(Actions.requestGetActiveUsers(Cookies.get('token')))
            .then(() => {
               this.setMissionaryOptions(_.values(this.props.users.users));
            });
      } else
         this.setMissionaryOptions(_.values(this.props.users.users));
   };


   //filter users for missionaries
   //and setup for dropdown select
   setMissionaryOptions = users => {
      const missionaryOptions = users
         .filter((user) => {
            return user.role === 'missionary'
         })
         .map((user) => {
            return({
               'value': user.id,
               'text': user.name
            });
         });

      this.setState({
         missionaryOptions
      })
   };

   updateHoldAccount = () => {
      const form = document.getElementById(this.state.form);
      const formData = new FormData(form);

      this.props.dispatch(Actions.requestPatchHoldAccount(this.state.acctId, formData, Cookies.get('token')))
         .then((result) => {
            if (result.type === PATCH_HOLD_ACCOUNT_SUCCESS)
               this.props.dispatch(Actions.newMessage('Hold Account Updated'));
            else if (result.type === PATCH_HOLD_ACCOUNT_FAILURE)
               this.props.dispatch(Actions.newError('Something went wrong.'));
            this.incrementForceUpdate();
         });
      this.handleClose();
   }

   createHoldAccount = () => {
      const form = document.getElementById('hold-accounts-form');
      const formData = new FormData(form);

      this.props.dispatch(Actions.requestPostHoldAccount(formData, Cookies.get('token')))
         .then((result) => {
            if (result.type === POST_HOLD_ACCOUNT_SUCCESS)
               this.props.dispatch(Actions.newMessage('Hold Account Created'));
            else if (result.type === POST_HOLD_ACCOUNT_FAILURE) {
               this.props.dispatch(Actions.newError(result.payload.message));
            }
            this.incrementForceUpdate();
         });
      this.handleClose();
   }

   deleteHoldAccount = (id) => {
      this.props.dispatch(Actions.requestDeleteHoldAccount(id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_HOLD_ACCOUNT_SUCCESS)
               this.props.dispatch(Actions.newMessage('Hold Account Deleted'));
            else if (result.type === DELETE_HOLD_ACCOUNT_FAILURE)
               this.props.dispatch(Actions.newError('Something went wrong.'));
            this.incrementForceUpdate();
         });
   }

   archiveHoldAccount = id => {
      this.props.dispatch(Actions.requestArchiveHoldAccount(id, Cookies.get('token')))
        .then(res => {
           if (res.type === ARCHIVE_HOLD_ACCOUNT_SUCCESS)
              this.props.dispatch(Actions.newMessage(`Hold Account ${!!res.payload.archived_at ? 'Archived' : 'Restored'}`));
           else if (res.type === ARCHIVE_HOLD_ACCOUNT_ERROR)
              this.props.dispatch(Actions.newError('Something went wrong.'));
        })
   }

   /******** dialog methods ********/

   handleCreateOpen = () => {
      let acctUserId = '';
      if(this.props.userId !== null) {
         acctUserId = this.props.userId
      }

      this.setState({
         open: true,
         acctUserId: acctUserId,
         dialogTitle: 'Create Hold Account',
         actionButtonLabel: 'Submit',
         disabled: false,
         actionMethod: this.createHoldAccount,
      });
   }

   handleEditOpen = (holdAccount) => {
      this.setState({
         open: true,
         acctId: holdAccount.id,
         acctUserId: holdAccount.user_id,
         acctFundName: holdAccount.fund_name,
         acctBalance: holdAccount.balance,
         dialogTitle: 'Edit Hold Account',
         actionButtonLabel: 'Update',
         disabled: true,
         actionMethod: this.updateHoldAccount,
         form: 'hold-accounts-form'
      });
   }

   handleRecurringOpen = (holdAccount) => {
      const recurringDeposit = holdAccount.mat_day_recurring_deposit ? holdAccount.mat_day_recurring_deposit : '0.00';
      const recurringTransfer = holdAccount.mat_day_recurring_transfer ? holdAccount.mat_day_recurring_transfer : '0.00';
      const recurringWithdrawal = holdAccount.mat_day_recurring_withdrawal ? holdAccount.mat_day_recurring_withdrawal : '0.00';
      this.setState({
         recurringOpen: true,
         acctId: holdAccount.id,
         acctFundName: holdAccount.fund_name,
         acctUserName: holdAccount.user.name,
         recurringDeposit: recurringDeposit, 
         recurringTransfer: recurringTransfer,
         recurringWithdrawal: recurringWithdrawal,
         actionMethod: this.updateHoldAccount,
         form: 'recurring-transactions-form',
      });
   }

   handleTransactionsOpen = (holdAccount) => {
      //get the donations for this hold account
      const donations = _.values(this.props.donations.donations)
                        .filter(donation => donation.hold_account_id === holdAccount.id);
      //get the distribution transactions for this hold account
      const distTransactions = holdAccount.distribution_transactions;
  
      this.setState({
         transactionOpen: true,
         distTransactions: distTransactions,
         donations: donations,
         acctFundName: holdAccount.fund_name
      });
   }

   handleClose = () => {
      this.setState(initialState);
      this.setMissionaryOptions(_.values(this.props.users.users));
   }

   incrementForceUpdate = () => {
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   };

   onChange = (key, value) => {
      this.setState({[key]: value})
   }

    /******** dialog methods end ********/


   /********table buttons ********/

   getButtons = (holdAccount) => {

      const editButton = <RaisedButton
                           key="hold-account-edit"
                           label="Edit"
                           className="edit-donation"
                           backgroundColor="#1E88E5"
                           labelColor="#ffffff"
                           style={buttonStyle}
                           onClick={() => this.handleEditOpen(holdAccount)}
                        />

      const transactionsButton = <RaisedButton
                                    key="hold-account-transactions-button"
                                    label="Transactions"
                                    className="view-transactions"
                                    backgroundColor="#525252"
                                    labelColor="#ffffff"
                                    style={buttonStyle}
                                    onClick={() => this.handleTransactionsOpen(holdAccount)}
                                 />

      const recurringButton = <RaisedButton
                                 key="recurring-button"
                                 label="Recurring"
                                 className="recurring-transactions"
                                 backgroundColor="#E3740D"
                                 labelColor="#ffffff"
                                 style={buttonStyle}
                                 onClick={() => this.handleRecurringOpen(holdAccount)}
                              />

      const deleteButton = <DeleteButton
                              key="hold-account-delete-button"
                              message="Delete this hold account?"
                              label="Delete"
                              backgroundColor="#e60000"
                              labelColor="#ffffff" 
                              style={buttonStyle} 
                              contentStyle={{width: '40%'}}                                   
                              onClick={()=>this.deleteHoldAccount(holdAccount.id)}
                           />

      const archiveButton = <RaisedButton
        key="hold-account-archive-button"
        label={!!holdAccount.archived_at ? 'Restore' : 'Archive'}
        backgroundColor={!!holdAccount.archived_at ? '#525252' : '#e60000'}
        labelColor="#ffffff"
        style={buttonStyle}
        onClick={() => this.archiveHoldAccount(holdAccount.id)}
      />;

      let buttons = [];
      if(this.props.admin === true) {
         buttons.push(editButton);
         buttons.push(deleteButton);
         buttons.push(recurringButton);
         buttons.push(archiveButton);
      }
      buttons.push(transactionsButton);

      return (<div>{buttons}</div>);
   }

   getAddAccountButton = () => {
      if(this.props.admin === true) {
         return (
            <RaisedButton
               key="add-account-button"
               label="Add Hold Account"
               className="add-acct-button"
               backgroundColor="#1E88E5"
               labelColor="#ffffff"
               onClick={this.handleCreateOpen}
            />
         )
      }
   }

   /********table buttons end ********/

   render() {
      let holdAccounts = _.values(this.props.donations.holdAccounts);
      //if userId is not null pull out the entries for that userId
      if(this.props.userId !== null) {
         holdAccounts = holdAccounts.filter((acct) => {
            return acct.user_id === this.props.userId;
         });
      }

      const columns = [
         {key: 'name', label: 'Name', 'sortable': true},
         {key: 'fund_name', label: 'Name', 'sortable': true},
         {key: 'balance', label: 'Balance', 'sortable': false},
         {key: 'unreimbursed', label: 'Unreimbursed', 'sortable': false},
         {key: 'date', label: 'Updated', 'sortable': true},
         {key: 'buttons', label: '', 'sortable': false},
         {},
         {}
      ];

      const holdAccountsData = holdAccounts.filter(x => !!x.archived_at === (this.state.status === 'archived')).map( acct => {
         return {
            name: acct.user.name,
            fund_name: acct.fund_name,
            balance: '$' + acct.balance,
            unreimbursed: acct.unreimbursed_amount !== null ? '$' + acct.unreimbursed_amount : '$0.00',
            date: !!acct.updated_at ? moment(acct.updated_at).format('MM/DD/YYYY') : null,
            buttons: this.getButtons(acct),
         }
      }).sort(dateSortDesc);

      return (
         <div className="list-view table-view">
            <Subheader className="table-style">Hold Accounts</Subheader>
            <div className="filters" style={{ backgroundColor: 'white', padding: 20 }}>
               <DropDownSelect
                 id="status"
                 floatingLabelText="Status"
                 floatingLabelStyle={{left: 0}}
                 options={[
                    { value: 'active', text: 'Active'},
                    { value: 'archived', text: 'Archived'},
                 ]}
                 value={this.state.status}
                 onChange={status => this.setState({ status })}
               />
            </div>
            <div className="hold-accounts-table">
               <Table data={holdAccountsData} columns={columns} forceUpdate={this.state.forceUpdate}/>
               {this.getAddAccountButton()}
            </div>
            <div className="hold-accounts-list">
               <HoldAccountsList data={holdAccountsData}/>
            </div>
            <RecurringDialog
               open={this.state.recurringOpen}
               handleClose={this.handleClose}
               acctFundName={this.state.acctFundName}
               acctUserName={this.state.acctUserName}
               deposit={this.state.recurringDeposit}
               transfer={this.state.recurringTransfer}
               withdrawal={this.state.recurringWithdrawal}
               onChange={this.onChange}
               actionMethod={this.state.actionMethod}
            />
            <HoldAccountsDialog
               open={this.state.open}
               handleClose={this.handleClose}
               onChange={this.onChange}
               missionaryOptions={this.state.missionaryOptions}
               acctUserId={this.state.acctUserId}
               acctBalance={this.state.acctBalance}
               acctFundName={this.state.acctFundName}
               dialogTitle={this.state.dialogTitle}
               actionButtonLabel={this.state.actionButtonLabel}
               disabled={this.state.disabled}
               actionMethod={this.state.actionMethod}
            />
            <TransactonsDialog
               open={this.state.transactionOpen}
               handleClose={this.handleClose}
               distTransactions={this.state.distTransactions}
               donations={this.state.donations}
               fundName={this.state.acctFundName}
            />
         </div>
      )
   }
}


export default connect(
   state => ({
      donations: state.rootReducer.donations,
      users: state.rootReducer.users
   })
)(HoldAccountsTable);
