import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';

export const postFileUploadRequest = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/fileUpload',
        {
            method: 'POST',
            headers: {
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const deleteFileUploadRequest = (file_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/fileUpload/' + file_id,
        {
            method: 'DELETE',
            headers: {
                "api-token": token
            }
        }
    ).then(handleResponse);
};
