import React, {Component} from 'react';


class ExpenseTypeTag extends Component {


    render() {

        let tag_style;
        let type_compressed = ' ';
        // eslint-disable-next-line
        if(this.props.type == "Reimbursement" || this.props.type == 'reimbursement'){
            type_compressed = 'R'
            tag_style= {
                backgroundColor: "#AED581",
                color: "white",
                textTransform: "uppercase",
                fontFamily: "Roboto",
                textAlign: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "12px",
                paddingRight: "12px"
            }
        // eslint-disable-next-line   
        }else if (this.props.type == "Advance" || this.props.type == 'advance'){
            type_compressed = 'A'
            tag_style = {
                backgroundColor: "#4DB6AC",
                color: "white",
                textTransform: "uppercase",
                fontFamily: "Roboto",
                textAlign: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "12px",
                paddingRight: "12px"
            }
        }


        return (
        <div style={tag_style}>
            <div>{type_compressed}</div>
        </div>


        )
    }

}

export default ExpenseTypeTag;