import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Check from '../fields/checkbox';
import Input from '../fields/input';
import Subheader from 'material-ui/Subheader';

export default class CategoriesDialog extends React.Component {

   render() {
      return (
         <Dialog
            modal={true}
            open={this.props.open}
            contentStyle={{width: '40%'}}
         >
            <div className="settings-dialog-container">
               <Subheader className="curved-style">{this.props.title}</Subheader>
               <form className="category-inputs" id="category-inputs">	
                  <Input
                     key="add-category-input"
                     className="category-input"
                     floatingLabelText="Category"
                     value={this.props.category}
                     id="expense_category"
                     onChange={category => this.props.categoryChange(category)}
                  />
                  <Input
                     key="category-description-input"
                     className="description-input"
                     floatingLabelText="Description"
                     value={this.props.description}
                     id="description"
                     onChange={description => this.props.descriptionChange(description)}
                  />
                  <div className="checkbox-container">
                     <div className="checkboxes">
                        <Check
                           key="advance-checkbox"
                           id="advance"
                           label="Advance"
                           labelPosition="left"
                           checked={this.props.advanceChecked}
                           onCheck={(event) => this.props.handleCheck(event, 'advanceChecked')}
                        />
                        <Check
                           key="expense-checkbox"
                           id="expense"
                           label="Expense"
                           labelPosition="left"
                           checked={this.props.expenseChecked}								
                           onCheck={(event) => this.props.handleCheck(event, 'expenseChecked')}
                        />
                        <Check
                           key="mileage-checkbox"
                           id="mileage"
                           label="Mileage"
                           labelPosition="left"
                           checked={this.props.mileageChecked}
                           onCheck={(event) => this.props.handleCheck(event, 'mileageChecked')}
                        />
                     </div>
                     <hr/>
                  </div>
               </form>
               <RaisedButton
                  label={this.props.buttonLabel}
                  className="category-action-button"
                  backgroundColor="#1E88E5"
                  labelColor="#ffffff"
                  onClick={this.props.onClickMethod}
               />
               <RaisedButton
                  label="Cancel"
                  className="cancel-category-dialog"
                  backgroundColor="#525252"
                  labelColor="#ffffff"
                  onClick={this.props.handleClose}
               />
            </div>
         </Dialog>
      )//return
   }//render
}