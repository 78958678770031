import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Input from '../fields/input';
import Subheader from 'material-ui/Subheader';
import {doesItemExistInState} from '../../utilities/exist-in-state';
import {updateForm} from '../../utilities/update';
import person_icon from '../../assets/images/user_icon@2x.png';
import DropDownSelect from '../fields/dropdown-select-field';
import {roles, taxStatusOptions} from '../../constants/options';
import Check from '../fields/checkbox';
import DonationsTable from '../blocks/donations/donations-table';
import HoldAccountsTable from '../blocks/HoldAccounts/hold-accounts-table';
import DistributionsTable from '../blocks/Distributions/distributions-table';

import {GET_USER_SUCCESS} from '../../actions/types';

class MissionaryProfile extends Component {

   constructor(props) {
      super(props);
      this.state = {
         ficaChecked: false,
         userName: '',
         taxStatusSelectValue: null,
         roleSelectValue: null
      };
   }

   componentDidMount() {
      const doesUserExistInState = doesItemExistInState(this.props.users.users, this.props.params.user_id);
      const that = this;
      if (!doesUserExistInState) {
         this.props.dispatch(Actions.requestGetUser(this.props.params.user_id, Cookies.get('token')))
               .then((result) => {
                  if (result.type === GET_USER_SUCCESS) {
                     //add decimal place to each item
                     result.payload.profile.unreimbursed_amount = result.payload.profile.unreimbursed_amount ? result.payload.profile.unreimbursed_amount.toFixed(2) : '';
                     result.payload.profile.net_salary = result.payload.profile.net_salary ? result.payload.profile.net_salary : '';
                     result.payload.profile.retirement_amount = result.payload.profile.retirement_amount ? result.payload.profile.retirement_amount : '';
                     result.payload.profile.insurance_amount =  result.payload.profile.insurance_amount ? result.payload.profile.insurance_amount : '';
                     result.payload.profile.fund_balance = result.payload.profile.fund_balance === null ? '0.00' : parseFloat(result.payload.profile.fund_balance);
                     result.payload.profile.admin_fee_percentage = result.payload.profile.admin_fee_percentage ? result.payload.profile.admin_fee_percentage : '';
                     that.updateFormsAndState(result.payload);
                  }
               });
      } else {
         that.updateFormsAndState(doesUserExistInState)
      }
   }

   updateFormsAndState = (user) => {
      this.setState({
         roleSelectValue: user.role,
         taxStatusSelectValue: user.profile.tax_status,
         ficaChecked: user.profile.fica_tax === 1 ? true : false ,
         userName: user.name
      })
      updateForm('update-user-form', user);
      updateForm('update-payroll-form', user.profile);
   }

   render() {
      
      return (
         <div className="missionary-profile">
            <div className="profile">
               <h2
                  className="form-heading"
                  style={{display: 'flex', alignItems: 'center'}}
               >
                  <img 
                     src={person_icon} 
                     style={{height: '30px', marginRight: '10px'}} 
                     className="person-icon"
                     alt="person icon"							
                  />
                  {this.state.userName}
               </h2>
               <div className="form-block-container">
                  <div className="form-block">
                     <Subheader className="table-style">Basic Info</Subheader>
                        <form id="update-user-form" encType="multipart/form-data">
                           <div className="form-section">
                              <Input
                                 className="inline-inputs"
                                 id="name"
                                 floatingLabelText="Name"
                                 disabled={true}						
                                 />
                              <Input
                                 className="inline-inputs"
                                 id="email"
                                 floatingLabelText="Email"
                                 disabled={true}										
                              />
                              <Input
                                 className="inline-inputs"
                                 id="profile_missionary_number"
                                 floatingLabelText="Account #"
                                 disabled={true}										
                              />
                              <DropDownSelect
                                    className="inline-inputs" id="role"
                                    floatingLabelText="Role"
                                    floatingLabelStyle={{left: 0}}
                                    options={roles}
                                    value={this.state.roleSelectValue}
                                    disabled={true}
                              />
                              <Input
                                 className="inline-inputs"
                                 id="profile_unreimbursed_amount"
                                 floatingLabelText="Unreimbursed Amount"
                                 disabled={true}
                                 prefix="$"										
                              />
                           </div>
                     </form>
                  </div>
               </div>
               <div className="form-block-container">
                  <div className="form-block">
                     <Subheader className="table-style"> Payroll Info </Subheader>
                     <form id="update-payroll-form" encType="multipart/form-data">
                        <div style={{float: 'left', width: '100%'}}>
                           <Input
                              className="inline-inputs"
                              id="net_salary"
                              floatingLabelText="Salary"
                              disabled={true}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}						
                           />
                           <Input
                              className="inline-inputs"
                              id="fund_balance"
                              floatingLabelText="Fund Balance"
                              disabled={true}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="missionary_number"
                              floatingLabelText="Account Number"
                              disabled={true}
                              errors={this.props.users}
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="admin_fee_percentage"
                              floatingLabelText="Admin Fee %"
                              disabled={true}
                              errors={this.props.users}
                              style={{maxWidth: 150}}							
                           />
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                           <Input
                              className="inline-inputs"
                              id="admin_fee_cap"
                              floatingLabelText="Admin Fee Cap"
                              disabled={true}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="retirement_amount"
                              floatingLabelText="Retirement"
                              disabled={true}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="insurance_amount"
                              floatingLabelText="Insurance"
                              disabled={true}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="wire_fee"
                              floatingLabelText="Wire Fee"
                              disabled={true}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                           <Input
                              className="inline-inputs"
                              id="housing_allowance"
                              floatingLabelText="Housing Allowance"
                              disabled={true}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                           <DropDownSelect
                                 className="inline-inputs"
                                 id="tax_status"
                                 floatingLabelText="Tax Status"
                                 floatingLabelStyle={{left: 0}}
                                 errors={this.props.users}
                                 options={taxStatusOptions}
                                 value={this.state.taxStatusSelectValue}
                                 disabled={true}
                                 style={{maxWidth: 150}}	
                           />
                           <Input
                              className="inline-inputs"
                              id="federal_income_tax"
                              floatingLabelText="Federal Income Tax"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                           <div className="fica_check_box" style={{display: 'inline-block', float: 'left', width: '100px', marginTop: '40px', marginLeft: '30px'}}>
                              <Check
                                 key="fica-checkbox"
                                 id="fica_tax"
                                 className="inline-inputs"
                                 label="FICA"
                                 labelPosition="right"
                                 disabled={true}
                                 checked={this.state.ficaChecked}
                                 onCheck={(event) => this.handleCheck(event, 'ficaChecked')}
                                 style={{width: 250}}	
                              />
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <DonationsTable admin={false} userId={parseInt(this.props.params.user_id, 10)}/>
               <HoldAccountsTable admin={false} userId={parseInt(this.props.params.user_id, 10)}/>
               <DistributionsTable admin={false} userId={parseInt(this.props.params.user_id, 10)} status="approved"/>
            </div>
         </div>
      )
   }	
}

export default connect(
   state => ({
      users: state.rootReducer.users,
   })
)(MissionaryProfile);