import React from 'react';
import CircularProgress from "material-ui/CircularProgress";

export const withLoading = WrappedComponent => {
  return class WithLoading extends React.Component {
    render = () => (
      <div>
        {
          !!this.props.loading &&
          <div style={{
            position: 'fixed',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(1,1,1,0.3)',
            top: 0,
            left: 0,
            zIndex: 999
          }}>
            <CircularProgress size={80} thickness={7} />
          </div>
        }
        <WrappedComponent { ...this.props } />
      </div>
    );
  }
};