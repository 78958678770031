import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import Table from '../blocks/table';
import Subheader from 'material-ui/Subheader';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import CategoriesDialog from '../blocks/categories-dialog';
import Dialog from 'material-ui/Dialog';
import Check from '../fields/checkbox';


import {
    PATCH_SETTINGS_SUCCESS,
    PATCH_SETTINGS_FAILURE,
    DELETE_CATEGORY_SUCCESS,
    POST_CATEGORY_SUCCESS,
    PATCH_CATEGORY_SUCCESS
} from '../../actions/types';

class Settings extends Component {

   constructor(props) {
      super(props);
      this.state = {
         forceUpdate: 0,
         open: false,
         category: '',
         description: '',
         advanceChecked: false,
         expenseChecked: false,
         mileageChecked: false,
         onClickMethod: null,
         dialogTitle: '',
         categoryId: '',
         buttonLabel: 'placeholder',
         mileage_reimbursement: '',
         admin_fee_percentage: '',
         admin_fee_cap: '',
         social_security_percentage: '',
         medicare_percentage: '',
         deleteCategory: false,
      };
    }

   componentDidMount() {
      const that = this;
      const token = Cookies.get('token');
      this.props.dispatch(Actions.requestGetAllSettings(token))
         .then(function(result) {
            result.payload.forEach((setting) => {
               that.setState({[setting.setting_name] : setting.setting_value})
            });
         });

      this.props.dispatch(Actions.requestGetAllCategories(token));
   }


   updateSettings = () => {
      const form = document.getElementById('settings-form');
      const formData = new FormData(form);
      const that = this;
      this.props.dispatch(Actions.requestPatchSettings(formData, Cookies.get('token')))
         .then(function (result) {
               if (result.type === PATCH_SETTINGS_SUCCESS) {
                  that.props.dispatch(Actions.newMessage('You have successfully updated settings'));
                  _.values(result.payload).forEach((setting) => {
                     that.setState({[setting.setting_name]: setting.setting_value});
                  });
               } else if (result.type === PATCH_SETTINGS_FAILURE) {
                  that.props.dispatch(Actions.newError('Something went wrong.'));
               }
         })
   };

   deleteCategory = () => {
       this.props.dispatch(Actions.requestDeleteCategory(this.state.categoryId, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_CATEGORY_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Category has been deleted.'));
               this.handleDeleteCategoryClose();
            	this.incrementForceUpdate();
            } else {
               this.props.dispatch(Actions.newError(result.payload.message));
            }
         });
   };

   addCategory = () => {
      const form = document.getElementById('category-inputs');
      const formData = new FormData(form);
      this.props.dispatch(Actions.requestPostCategory(formData, Cookies.get('token')))
         .then((result) => {
            this.incrementForceUpdate();
            if (result.type === POST_CATEGORY_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Category has been added.'));	
            } else {
               this.props.dispatch(Actions.newError('Both a category and description are required.'));
            }
         });
      this.handleClose();
   };

   updateCategory = () => {
      const form = document.getElementById('category-inputs');
      const formData = new FormData(form);
      this.props.dispatch(Actions.requestPatchCategory(this.state.categoryId, formData, Cookies.get('token')))
         .then((result) => {
            this.incrementForceUpdate();
            if (result.type === PATCH_CATEGORY_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Category has been updated.'));	
            } else {
               this.props.dispatch(Actions.newError('Both a category and description are required.'));
            }
         });
      this.handleClose();
   }

   onChangeCategory = (value) => {
      this.setState({category: value})
   }

   onChangeDescription = (value) => {
      this.setState({description: value})
   }

   incrementForceUpdate = () => {
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   };

   handleOpen = () => {
      this.setState({
         open: true,
         onClickMethod: this.addCategory,
         dialogTitle: 'Add Category',
         buttonLabel: 'Submit',
      })
    };

   handleEditOpen = (category) => {
      //eslint-disable-next-line
      const advanceChecked = category.advance == 0 ? false : true;
      //eslint-disable-next-line
      const expenseChecked = category.expense == 0 ? false : true;
      //eslint-disable-next-line
      const mileageChecked = category.mileage == 0 ? false : true;
      this.setState({
         open: true,
         onClickMethod: this.updateCategory,
         dialogTitle: 'Edit Category',
         buttonLabel: 'Update',
         categoryId: category.id,
         category: category.expense_category,
         description: category.description,
         advanceChecked: advanceChecked,
         expenseChecked: expenseChecked,
         mileageChecked: mileageChecked,
      });
    };


   handleClose = () => {
      this.setState({
         open: false,
         onClickMethod: null,
         dialogTitle: '',
         buttonLabel: 'placeholder',
         categoryId: '',
         category: '',
         description: '',
         advanceChecked: false,
         expenseChecked: false,
         mileageChecked: false,
      })
    };

   handleCheck = (event, type) => {
      this.setState({[type]: event})
   }
   
   handleDeleteCategoryOpen = (categoryId) => {
      this.setState({
         deleteCategory: true,
         categoryId: categoryId
      });
   }

   handleDeleteCategoryClose = () => {
      this.setState({
         deleteCategory: false,
         categoryId: ''
      });
   };
   

   render() {

      const actionsDeleteCategory = [
         <RaisedButton 
            key="cancel-dialog-button"
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Cancel"
            onClick={this.handleDeleteCategoryClose} 
            style={{marginRight: 3}}                      
         />,
         <RaisedButton 
            key="delete-category-button"
            labelColor="#ffffff"
            backgroundColor={'#d40a0a'}
            label="Delete"
            onClick={() => this.deleteCategory()}                       
         />
      ];

      const columns = [
         {key: 'category', label: 'Category', 'sortable': true},
         {key: 'description', label: 'Description', 'sortable': true},
         {key: 'advance', label: 'Advance', 'sortable': false},
         {key: 'expense', label: 'Expense', 'sortable': false},
         {key: 'mileage', label: 'Mileage', 'sortable': false},		
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];

      const categories = this.props.categories.categories;
      let categoriesTable = Object.keys(categories).map( key => {
         return {
            category: categories[key].expense_category,
            description: categories[key].description,
            advance: <Check
                        id={'advance' + key}
                        className="type-check"
                        //eslint-disable-next-line
                        checked={categories[key].advance == 0 ? false : true}
                        disabled={true}
                     />,
            expense: <Check
                        id={'expense' + key}
                        className="type-check"
                        //eslint-disable-next-line
                        checked={categories[key].expense == 0 ? false : true}
                        disabled={true}
                     />,
            mileage: <Check
                        id={'mileage' + key}
                        className="type-check"
                        //eslint-disable-next-line
                        checked={categories[key].mileage == 0 ? false : true}
                        disabled={true}
                     />,
            buttons: <div className="action-buttons">
                     <RaisedButton
                        label="Edit"
                        className="edit-category"
                        backgroundColor="#1E88E5"
                        labelColor="#ffffff"
                        onClick={() => this.handleEditOpen(categories[key])}
                     />
                     <RaisedButton
                        label="Delete"
                        className="delete-category"
                        backgroundColor="#EA0909"
                        labelColor="#ffffff"
                        onClick={() => this.handleDeleteCategoryOpen(categories[key].id)}
                     />
                     </div>
         }				
      })
      .sort((a,b) => {
         let x = a.category.toUpperCase();
         let y = b.category.toUpperCase();
         if(x > y) return 1;
         if(x < y) return -1;
         return 0	
      });


      return (
         <div className="settings-container">

            <div className="list-view">
               <Subheader className="table-style">Settings</Subheader>
               <form id="settings-form">
                  <div className="settings-list">
                     <Input
                        key="mileage-reimbursement-input"
                        floatingLabelText="Mileage Reimbursement"
                        value={this.state.mileage_reimbursement}
                        id="mileage_reimbursement"
                        prefix="$"
                        onChange={mileage_reimbursement => this.setState({mileage_reimbursement})}
                     />
                     <Input
                        key="admin-fee-percentage-input"
                        floatingLabelText="Admin Fee %"
                        value={this.state.admin_fee_percentage}
                        id="admin_fee_percentage"
                        onChange={admin_fee_percentage => this.setState({admin_fee_percentage})}
                     />
                     <Input
                        key="admin-fee-cap-input"
                        floatingLabelText="Admin Fee Cap"
                        value={this.state.admin_fee_cap}
                        id="admin_fee_cap"
                        prefix="$"
                        onChange={admin_fee_cap => this.setState({admin_fee_cap})}
                     />
                  </div>
                  <div className="settings-list">
                     <Input
                        key="social-security-percentage-input"
                        floatingLabelText="Social Security %"
                        value={this.state.social_security_percentage}
                        id="social_security_percentage"
                        onChange={social_security_percentage => this.setState({social_security_percentage})}
                     />
                     <Input
                        key="medicare-percentage-input"
                        floatingLabelText="Medicare %"
                        value={this.state.medicare_percentage}
                        id="medicare_percentage"
                        onChange={medicare_percentage => this.setState({medicare_percentage})}
                     />
                  </div>
                  <RaisedButton
                     label="Update"
                     className="update"
                     backgroundColor="#1E88E5"
                     labelColor="#ffffff"
                     onClick={this.updateSettings}
                  />
               </form>
            </div>

            <div className="list-view table-view">
               <Subheader className="table-style">Categories</Subheader>
                { 
                   <div className="table-container">
                     <Table data={categoriesTable} columns={columns} forceUpdate={this.state.forceUpdate}/>
                     <RaisedButton
                        label="Add Category"
                        className="add-button"
                        backgroundColor="#1E88E5"
                        labelColor="#ffffff"
                        onClick={this.handleOpen}
                     />
                     <CategoriesDialog
                        key="category-dialog"
                        open={this.state.open}
                        title={this.state.dialogTitle}
                        buttonLabel={this.state.buttonLabel}
                        category={this.state.category}
                        description={this.state.description}
                        advanceChecked={this.state.advanceChecked}
                        expenseChecked={this.state.expenseChecked}
                        mileageChecked={this.state.mileageChecked}
                        categoryChange={this.onChangeCategory}
                        descriptionChange={this.onChangeDescription}
                        handleCheck={this.handleCheck}
                        onClickMethod={this.state.onClickMethod}
                        handleClose={this.handleClose}
                     />
                     <Dialog
                        actions={actionsDeleteCategory}
                        modal={true}
                        open={this.state.deleteCategory}
                        contentStyle={{width: '90%'}}
                        actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
                     >
                        <Subheader className="curved-style">Are you sure you want to delete this category?</Subheader>
                     </Dialog>
                  </div>
                }
            </div>
         </div>
      )
   }

}

export default connect(
    state => ({
        settings: state.rootReducer.settings,
        categories: state.rootReducer.categories
    })
)(Settings);
