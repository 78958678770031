import {patchDistributionRequest} from '../api/distributions';

import{
   PATCH_DISTRIBUTION,
   PATCH_DISTRIBUTION_SUCCESS,
   PATCH_DISTRIBUTION_FAILURE
} from './types';


function patchDistribution(){
    return{
        type: PATCH_DISTRIBUTION
    }
}

function patchDistributionSuccess(json){
    return{
        type: PATCH_DISTRIBUTION_SUCCESS,
        payload: json
    }
}

function patchDistributionFailure(error){
    return{
        type: PATCH_DISTRIBUTION_FAILURE,
        payload: error
    }
}

export default function requestPatchDistribution(id, formData,token, json) {
    return dispatch => {
        dispatch(patchDistribution());
        return patchDistributionRequest(id, formData, token, json)
            .then((json) => {
                return dispatch(patchDistributionSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchDistributionFailure(errors));
                })
            });
    };
}