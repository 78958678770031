import React, {Component} from 'react';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import Actions from '../../actions';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';
import ActionLock from 'material-ui/svg-icons/action/lock';


class ResetPassword extends Component {

    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            passwordConfirm: ''
        }
    }

    resetPassword(event) {
        const that = this;
        event.preventDefault();
        const form = document.getElementById('reset-password-form');
        let formData = new FormData(form);
        this.props.dispatch(Actions.requestPasswordReset(formData))
            .then(function (result) {
                if (result.payload.success) {
                    that.props.dispatch(Actions.newMessage('Password Reset!'));
                    browserHistory.push('/');
                } else {
                    that.props.dispatch(Actions.newError('Something went wrong, please try again'));
                }
            })
    }

    userNameChange(event) { this.setState({username: event}); }
    passwordChange(event) { this.setState({password: event}); }
    passwordConfirmChange(event) { this.setState({passwordConfirm: event}); }

    render() {
        const token = window.location.href.split("/")[4];

        return (
            <div className="login-view">
                <div className="change-password-container">
                    <form onSubmit={(event) => this.resetPassword(event)} id='reset-password-form'>
                        <h3 className="reset-text">Reset Your Password</h3>
                        <div className="reset-password-form-inputs">
                            <Input
                                type="email"
                                id="email"
                                fieldIcon={<CommunicationEmail/>}
                                errors={this.props.auth}
                                onChange={(event) => this.userNameChange(event)}
                                value={this.state.username}
                                placeholder="Email"
                            />
                            <Input                           
                                type="password"
                                id="password"
                                fieldIcon={<ActionLock/>}
                                errors={this.props.auth}
                                onChange={(event) => this.passwordChange(event)}
                                value={this.state.password}
                                placeholder="New Password"/>
                           <div className="password-confirmation">
                              <Input                           
                                 type="password"
                                 id="password_confirmation"
                                 errors={this.props.auth}
                                 onChange={(event) => this.passwordConfirmChange(event)}
                                 value={this.state.passwordConfirm}
                                 placeholder="Re-enter Password"
                              />
                            </div>
                        </div>
                        <div className='form-group'>
                            <input type='hidden' id='token' name='token' value={token}/>
                        </div>
                        <br/>
                        <div className="reset-button">
                            <RaisedButton className="reset-password-submit" primary={true} label="Reset Password" type="submit"/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        auth: state.rootReducer.auth
    })
)(ResetPassword);