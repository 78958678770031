import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';

export const denariUploadRequest = (formData, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/donation/denari-upload', {
         method: 'POST',
         headers: {
            'api-token': token
         },
         body: formData
      }
   ).then(handleResponse);
};

export const getAllDonationsRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/donation',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};

export const getPaginatedDonationsRequest = (token, limit, page, order_by, order, q, user_id) => {
  const url = new URL(process.env.REACT_APP_API_ROOT + '/paginated_donation');
  const params = {
    limit,
    page,
    order_by,
    order,
    ...(q && q.length ? { q } : {}),
    ...(typeof user_id === 'number' ? { user_id } : {}),
  };
  url.search = new URLSearchParams(params).toString();
  return fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token
      }
    }
  ).then(handleResponse);
}

export const patchDonationRequest = (id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/donation/' + id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const deleteDonationRequest = (id, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/donation/' + id, {
         method: 'DELETE',
         headers: {
            "api-token": token
         }
      }
   ).then(handleResponse);
};

export const getRecentDonationUploadNamesRequest = (token) => {
   return fetch (
      process.env.REACT_APP_API_ROOT + '/recentUploadNames',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
}
