import {mergeState} from '11online-redux-helpers';

import{
	GET_ALL_SETTINGS,
	GET_ALL_SETTINGS_SUCCESS,
	GET_ALL_SETTINGS_FAILURE,
	PATCH_SETTINGS,
	PATCH_SETTINGS_SUCCESS,
	PATCH_SETTINGS_FAILURE
} from '../actions/types';
import {parseDecimalString} from "../utilities/string-helpers";

export const selectSettings = state => state.rootReducer.settings.settings;

export const selectAdminFeePercentage = state => {
  const settings = selectSettings(state);
  const adminFeeSetting = Object.values(settings || {}).find(x => x.setting_name === 'admin_fee_percentage');
  if (adminFeeSetting) return parseDecimalString(adminFeeSetting.setting_value);
};

export const selectAdminFeeCap = state => {
  const settings = selectSettings(state);
  const adminCapSetting = Object.values(settings || {}).find(x => x.setting_name === 'admin_fee_cap');
  if (adminCapSetting) return parseDecimalString(adminCapSetting.setting_value);
};

export const selectSocialSecurityPercentage = state => {
  const settings = selectSettings(state);
  const ssSetting = Object.values(settings || {}).find(x => x.setting_name === 'social_security_percentage');
  if (ssSetting) return parseDecimalString(ssSetting.setting_value);
};

export const selectMedicarePercentage = state => {
  const settings = selectSettings(state);
  const mcSetting = Object.values(settings || {}).find(x => x.setting_name === 'medicare_percentage');
  if (mcSetting) return parseDecimalString(mcSetting.setting_value);
};

export function reducer(state = {
    isFetching: false,
    settings: {}
}, action = {type: ''}) {
	switch (action.type) {
		case GET_ALL_SETTINGS:
			return {
					...state,
					isFetching: true
			};
    case GET_ALL_SETTINGS_SUCCESS:
			return {
			    ...state,
                isFetching: false,
                error: [], 
                settings: {
			        ...(state.settings || {}),
                    ...action.payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
                }
			};
		   case GET_ALL_SETTINGS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                settings: {}
            };
			case PATCH_SETTINGS:
            return {
                ...state,
                isFetching: true
            };
        case PATCH_SETTINGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                settings: mergeState(state.settings, action.payload)
            };
        case PATCH_SETTINGS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                settings: []
            };
			default:
            return state;
	}
}
