import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import Input from '../fields/input';
import DropDownSelect from '../fields/dropdown-select-field';
import { List } from 'material-ui/List';
import AdvanceRequestListItems from '../blocks/advance-request-list-items';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import _ from 'lodash';
import moment from 'moment';
import { dateSubmittedTableSort } from '../../utilities/date-sort';
import { dateSubmittedListSort } from '../../utilities/date-sort';
import expenseOptions from '../../utilities/expenseOptions';
import DeleteButton from "../blocks/delete-button";
import {
   POST_ADVANCE_REQUEST_SUCCESS,
   POST_ADVANCE_REQUEST_FAILURE,
   DELETE_ADVANCE_REQUEST_SUCCESS,
   DELETE_ADVANCE_REQUEST_FAILURE
} from '../../actions/types';
import { Link } from 'react-router';

const buttonStyle = {
   width: '9em',
   margin: '2px'
}

class MissionaryAdvanceRequest extends Component {

   constructor(props) {
      super(props);
      this.state = {
         selectValue: '',
         holdAccountId: '',
         advanceRequest: {},
         forceUpdate: 0
      };
   }

   componentDidMount() {
      if (_.get(this.props.auth, 'user.id')) {
         this.props.dispatch(Actions.requestGetAllAdvanceRequestsByUser(this.props.auth.user.id, Cookies.get('token')))
            .then(() => {
               this.incrementForceUpdate();
            });
      }
      this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));
      this.props.dispatch(Actions.requestGetAllHoldAccounts(Cookies.get('token')));
   }

   componentDidUpdate(prevProps, prevState) {
      // eslint-disable-next-line
      if (_.get(this.props.auth, 'user.id') != _.get(prevProps.auth, 'user.id')) {
         this.props.dispatch(Actions.requestGetAllAdvanceRequestsByUser(this.props.auth.user.id, Cookies.get('token')));
      }
   }

   incrementForceUpdate = () => {
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({ forceUpdate });
   }

   submitAdvanceRequest(event) {
      event.preventDefault();
      const today = (new Date()).toISOString().split("T")[0];

      const form = document.getElementById('advance-request-form');
      let formData = new FormData(form);
      formData.append('status', 'requested');
      formData.append('user_id', _.get(this.props.auth, 'user.id'));
      formData.append('date_submitted', today);

      const that = this;
      this.props.dispatch(Actions.requestPostAdvanceRequest(formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === POST_ADVANCE_REQUEST_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully posted your advance request.'));
               that.props.dispatch(Actions.requestGetAllAdvanceRequestsByUser(that.props.auth.user.id, Cookies.get('token')));
               that.clearForm();
            } else if (result.type === POST_ADVANCE_REQUEST_FAILURE) {
               that.props.dispatch(Actions.newError('Something went wrong.'));
            }
         })
   }

   deleteAdvanceRequest(advance_request_id) {
      this.props.dispatch(Actions.requestDeleteAdvanceRequest(advance_request_id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_ADVANCE_REQUEST_SUCCESS) {
               this.props.dispatch(Actions.newMessage('You have successfully deleted the advance request.'));
               this.props.dispatch(Actions.requestGetAllAdvanceRequestsByUser(this.props.auth.user.id, Cookies.get('token')));
            } else if (result.type === DELETE_ADVANCE_REQUEST_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   options = (type) => {
      const categories = this.props.categories.categories;
      return expenseOptions(categories, type);
   };

   getHoldAccountOptions = () => {
      const holdAccounts = _.values(this.props.donations.holdAccounts);
      const userId = this.props.auth.user.id;
      const holdAccountOptions = holdAccounts
         .filter(account => {
            return account.user_id === userId;
         })
         .map(account => {
            return {
               value: account.id,
               text: account.fund_name
            };
         });

      holdAccountOptions.unshift({
         value: '',
         text: 'None'
      });

      return holdAccountOptions;
   };

   clearForm = () => {
      document.getElementById('amount_requested').value = '';
      document.getElementById('type').value = '';
      this.setSelectValue('');
      this.setHoldAccountId('');
   }

   setSelectValue(event) {
      const selectValue = event;
      this.setState({
         selectValue
      });
   }

   setHoldAccountId(event) {
      const holdAccountId = event;
      this.setState({
         holdAccountId
      })
   }


   getButtons = (advanceRequest) => {
      const { distribution_id: distributionId, status, id } = advanceRequest;
      const delBtn = <DeleteButton
        key={ 'delete' + id }
        label='Delete'
        labelColor='#ffffff'
        backgroundColor='#e60000'
        style={ buttonStyle }
        message="Delete this advance request?"
        noActions={!!distributionId}
        onClick={() => !!distributionId ? undefined : this.deleteAdvanceRequest(id)}
        body={!distributionId
          ? undefined
          : <div>
             <p>This advance request is referenced in a distribution. Please contact an administrator to modify or delete it.</p>
          </div>
        }
      />;
      if (status === 'approved' && advanceRequest.expense_report_id === null) {
         return (
            <div>
               <ButtonLink label="Add Report" labelColor="#ffffff" backgroundColor={ '#525252' } style={ buttonStyle } to={ '/new-report/advance/' + advanceRequest.id } />
               { delBtn }
            </div>
         )
      } else if (status === 'submitted' || status === 'closed') {
         return ( <ButtonLink label="View Report" labelColor="#ffffff" backgroundColor='#525252' style={ buttonStyle } to={ '/expense-report/' + advanceRequest.expense_report_id } />)
      } else if (status === 'approved') {
         return (
            <div>
               <ButtonLink label="Edit Report" labelColor="#ffffff" backgroundColor={ '#1E88E5' } style={ buttonStyle } to={ '/expense-report/' + advanceRequest.expense_report_id + '/advance/' + advanceRequest.id + '/edit' } />
            </div>
         )
      } else if (status === 'requested') {
         return delBtn;
      }
       else {
         return null;
      }
   }

   render() {

      const columns = [
         { key: 'id', label: 'ID#', 'sortable': true },
         { key: 'date_submitted', label: 'Date Submitted', 'sortable': true },
         { key: 'type', label: 'Type', 'sortable': true },
         { key: 'hold_account', label: 'Hold Account', 'sortable': false},
         { key: 'amount_requested', label: 'Amount Requested', 'sortable': false },
         { key: 'amount_approved', label: 'Amount Approved', 'sortable': false },
         { key: 'status', label: 'Status', 'sortable': true },
         { key: 'buttons', label: '', 'sortable': false },
      ];

      const advanceRequests = _.get(this.props.advanceRequests, 'advanceRequestsByUser');
      const advanceRequestsArray = _.values(advanceRequests); //for the list
      advanceRequestsArray.sort(dateSubmittedListSort);

      const advanceRequestsTable = _.keys(advanceRequests).map(key => {
         return {
            id: advanceRequests[key].id,
            date_submitted: moment(advanceRequests[key].date_submitted).format('MM/DD/YYYY'),
            type: advanceRequests[key].category.expense_category,
            hold_account: _.get(this.props.donations.holdAccounts[advanceRequests[key].hold_account_id], 'fund_name', ''),
            amount_requested: advanceRequests[key].amount_requested ? '$' + advanceRequests[key].amount_requested.toFixed(2) : null,
            amount_approved: advanceRequests[key].amount_approved ? '$' + advanceRequests[key].amount_approved.toFixed(2) : null,
            status: _.upperFirst(advanceRequests[key].status),
            buttons: this.getButtons(advanceRequests[key])
         }
      });
      advanceRequestsTable.sort(dateSubmittedTableSort);

      return (

         <div className="advance-request">
            <h2 className="form-heading missionary-form-heading">Advance Requests</h2>
            <div className="form-block-container">
               <form id="advance-request-form" onSubmit={ (event) => this.submitAdvanceRequest(event) }
                  encType="multipart/form-data">
                  <div className="form-block">
                     <Subheader className="table-style">Advance Request</Subheader>
                     <div className="form-section new-report-section">
                        <p>Please Enter Your Request:</p>
                        <div className="request-amount-block">
                           <Input
                              className="advance-page-inputs amount-requested"
                              id="amount_requested"
                              floatingLabelText="Amount Requested"
                              style={ { width: '120px' } }
                              prefix='$'
                           />
                        </div>
                        <div className="request-type-block inline-inputs">
                           <DropDownSelect
                              tooltip="SVG Icon"
                              className="advance-page-inputs"
                              style={ { marginBottom: '10px' } }
                              id="type"
                              floatingLabelText="Type"
                              floatingLabelStyle={ { left: 0 } }
                              options={ this.options('advance') }
                              value={ this.state.selectValue }
                              onChange={ (event) => {this.setSelectValue(event)} }
                           />
                           <Link className="type-descriptions" target="_blank"
                              to={ '/expense-descriptions/advance' }>View Type Descriptions</Link>
                        </div>
                        <div className="request-hold-account-block inline-inputs">
                           <DropDownSelect
                              className="inline-inputs"
                              style={ { float: 'left' } }
                              id="hold_account_id"
                              floatingLabelText="From Held Account?"
                              floatingLabelStyle={ { left: 0 } }
                              errors={ this.props.expenseReports }
                              options={ this.getHoldAccountOptions() }
                              value={ this.state.holdAccountId }
                              onChange={ (event) => {this.setHoldAccountId(event)} }
                           />
                        </div>
                        <div className="form-buttons advance-page-form-buttons">
                           <RaisedButton labelColor="#ffffff" backgroundColor={ '#525252' } label="Clear"
                              onClick={ this.clearForm } />
                           <RaisedButton labelColor="#ffffff" backgroundColor={ '#1E88E5' } label="Submit"
                              type="submit" />
                        </div>
                     </div>
                  </div>
               </form>
            </div>

            <div className="missionary-advance-request-mobile mobile-list-view">
               <Subheader className="table-style">History</Subheader>
               <List>
                  <AdvanceRequestListItems
                     advance_requests={ advanceRequestsArray }
                  />
               </List>
            </div>


            <div className="advance-requests-by-user missionary-advance-request-desktop">
               <div className="list-view table-view">
                  <Subheader className="table-style">History</Subheader>
                  <Table data={ advanceRequestsTable } columns={ columns } forceUpdate={ this.state.forceUpdate } />
               </div>
            </div>
         </div>
      )
   }
}
export default connect(
   state => ({
      auth: state.rootReducer.auth,
      advanceRequests: state.rootReducer.advanceRequests,
      categories: state.rootReducer.categories,
      donations: state.rootReducer.donations
   })
)(MissionaryAdvanceRequest);
