import {patchHoldAccountRequest} from '../api/hold-accounts';

import{
   PATCH_HOLD_ACCOUNT,
   PATCH_HOLD_ACCOUNT_SUCCESS,
   PATCH_HOLD_ACCOUNT_FAILURE
} from './types';


function patchHoldAccount(){
    return{
        type: PATCH_HOLD_ACCOUNT
    }
}

function patchHoldAccountSuccess(json){
    return{
        type: PATCH_HOLD_ACCOUNT_SUCCESS,
        payload: json
    }
}

function patchHoldAccountFailure(error){
    return{
        type: PATCH_HOLD_ACCOUNT_FAILURE,
        payload: error
    }
}

export default function requestPatchHoldAccount(id, formData, token) {
    return dispatch => {
        dispatch(patchHoldAccount());
        return patchHoldAccountRequest(id, formData, token)
            .then((json) => {
                return dispatch(patchHoldAccountSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchHoldAccountFailure(errors));
                })
            });
    };
}