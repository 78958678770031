import React, {Component} from 'react';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import '../App.css';
import Actions from '../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import {LOGIN_USER_WITH_TOKEN_SUCCESS, LOGIN_USER_WITH_TOKEN_FAILURE} from '../actions/types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Snackbar from 'material-ui/Snackbar';
import Navigation from '../components/dashboard/navigation';


class AuthApp extends Component {

    constructor(props) {
        super(props);
        this.state = {logoutTimeoutPointer: null};
    }

    componentDidMount() {
        // check if the user is logged in and if he/she has a cookie
        if (Cookies.get('token') && !_.get(this.props, 'auth.user.api_token')) {
            const token = Cookies.get('token');
            this.props.dispatch(Actions.loginWithToken(token))
                .then(function (result) {
                    if (result.type === LOGIN_USER_WITH_TOKEN_SUCCESS) {
                        // if logged in
                        const role = result.payload.user.role;
                        if(!role) {
                           browserHistory.push('/');
                        }
                    } else if (result.type === LOGIN_USER_WITH_TOKEN_FAILURE) {
                        Cookies.expire('token');
                        browserHistory.push('/login');
                    }
                })
        }
    }

    handleRequestClose = () => {
        this.props.dispatch(Actions.clearMessage());
    }

    render() {
        return (
            <MuiThemeProvider>
                <div id="AuthApp"
                     className={this.props.location.pathname === '/' ? 'home' : this.props.location.pathname.replace(/[/0-9]/g, "")}>
                    <div className="main-container">
                        <Navigation location={this.props.location}/>
                        <div className="views">
                            <div className="inner">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    <Snackbar
                        open={this.props.message.open}
                        message={this.props.message.message}
                        autoHideDuration={this.props.message.bodyStyle ? 8000: 4000}
                        bodyStyle={this.props.message.bodyStyle}
                        onRequestClose={this.handleRequestClose}
                    />
                </div>
            </MuiThemeProvider>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            if (!_.isEmpty(this.props.auth.user)) {
                // clear previous logout timeout
                if (this.state.logoutTimeoutPointer) {
                    clearTimeout(this.state.logoutTimeoutPointer);
                }
                // set up our new logout timeout
                const that = this;
                const logoutTimeoutPointer = setTimeout(function () {
                    that.props.dispatch(Actions.logout())
                    window.location = '/'
                }, 4 * 60 * 60 * 1000);
                // save the new pointer to the state
                this.setState({logoutTimeoutPointer: logoutTimeoutPointer});
            }
        }
    }

}


export default connect(
    state => ({
        auth: state.rootReducer.auth,
        message: state.rootReducer.messages,
        isFetching:
        state.rootReducer.auth.isFetching
        ||
        state.rootReducer.users.isFetching
    })
)(AuthApp);


