import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import ButtonLink from '../blocks/button-link';
import RaisedButton from 'material-ui/RaisedButton';
import ExpenseTypeTag from '../blocks/expense-type-tag';
import ProcessedModal from '../blocks/processed-modal';
import DeleteButton from '../blocks/delete-button';
import Dialog from 'material-ui/Dialog';
import moment from 'moment';
import {
  DELETE_EXPENSE_REPORT_SUCCESS,
  DELETE_EXPENSE_REPORT_FAILURE,
  DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
  DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE
} from '../../actions/types';
import PaginatedTable from '../blocks/PaginatedTable';
import Subheader from 'material-ui/Subheader';
import { withLoading } from './with-loading';

const buttonStyle = {
  marginRight: 3,
  display: 'inline-block',
  width: 92
};


class ExpenseReportsTable extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      expenseReportToDelete: undefined,
      page: 0,
      limit: 10,
      total_count: undefined,
      sort: { column: 'id', order: 'desc' },
      q: undefined,
      selectedRows: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getExpenseReports();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getExpenseReports() {
    this.props.dispatch(Actions.requestGetPaginatedExpenseReports(
      Cookies.get('token'),
      {
        page: this.state.page,
        limit: this.state.limit,
        order_by: this.state.sort.column,
        order: this.state.sort.order,
        ...(this.state.q && this.state.q.length ? { q: this.state.q.trim().toLowerCase() } : {}),
        ...(typeof this.props.submitted === 'number' ? { submitted: this.props.submitted } : {}),
        ...(typeof this.props.processed === 'number' ? { processed: this.props.processed } : {}),
        ...(typeof this.props.userId === 'number' ? { user_id: this.props.userId } : {}),
      }
    )).then(json => {
      this.setStateIfMounted({ total_count: json.payload.total_count });
    })
  }

  setStateIfMounted = (updates, callback) =>
    this._isMounted ? this.setState(updates, () => callback ? callback() : undefined) : undefined;

  onNextPageClick = () => this.setStateIfMounted({ page: this.state.page + 1 }, () => {
    this.getExpenseReports();
  })

  onPreviousPageClick = () => this.setStateIfMounted({ page: this.state.page - 1 }, () => {
    this.getExpenseReports();
  })

  onRowSizeChange = (limit) => this.setStateIfMounted({ limit }, () => this.getExpenseReports());

  onSortOrderChange = (column, order) => this.setStateIfMounted({ sort: { column, order } }, () => this.getExpenseReports());

  onFilterValueChange = q => this.setStateIfMounted({ q, page: 0 }, () => this.getExpenseReports());

  onRowSelection = rows => this.setStateIfMounted({ selectedRows: rows });

  deleteExpenseReport(e) {
    e.preventDefault();
    if (!this.state.expenseReportToDelete) return;
    this.props.dispatch(Actions.requestDeleteExpenseReport(this.state.expenseReportToDelete.id, Cookies.get('token')))
      .then(result => {
        if (result.type === DELETE_EXPENSE_REPORT_SUCCESS){
          this.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
          this.handleClose();
        } else if (result.type === DELETE_EXPENSE_REPORT_FAILURE){
          this.props.dispatch(Actions.newError('Something west wrong.'));
        }
      });
  }

  deleteUnprocessedExpenseReport(id) {
    this.props.dispatch(Actions.requestDeleteUnsubmittedExpenseReport(id, Cookies.get('token')))
      .then(result => {
        if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS){
          this.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
        } else if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE){
          const { payload } = result,
            { message } = payload || {};
          this.props.dispatch(Actions.newError(message || 'Something west wrong.'));
        }
      });

  }

  openDeleteDialogBox = (expenseReport, event) => {
    event.preventDefault();
    this.setStateIfMounted({ expenseReportToDelete: expenseReport });
  }

  handleClose = () => this.setStateIfMounted({ expenseReportToDelete: undefined });

  getButtons = (expenseReport) => {
    const { distribution_id: distributionId } = expenseReport,
      totalReimbursement = expenseReport.total_amount + expenseReport.unreimbursed_amount;
    const processButton = <ProcessedModal
      key={'process-button' + expenseReport.id}
      expenseReport={expenseReport}
      type={expenseReport.type}
      totalAmount={expenseReport.total_amount}
      unreimbursedAmount={expenseReport.unreimbursed_amount}
      totalReimbursement={totalReimbursement}
      advanceRequestId={expenseReport.advance_request_id}
      style={buttonStyle}
      incrementForceUpdate={() => this.getExpenseReports()}
    />
    const viewButton = <ButtonLink
      key={'view-button' + expenseReport.id}
      backgroundColor="#525252" labelColor="#ffffff"
      label="View"
      to={'/expense-report/' + expenseReport.id}
      style={buttonStyle}
    />
    const archiveButton =  <RaisedButton
      key={'archive-button' + expenseReport.id}
      label='Archive'
      backgroundColor='#e60000'
      labelColor='#ffffff'
      onClick={(e)=>this.openDeleteDialogBox(expenseReport, e)}
      style={buttonStyle}
    />
    const deleteButton =  <DeleteButton
      key={'delete-button' + expenseReport.id}
      message="Delete this expense report?"
      label="Delete"
      backgroundColor="#e60000"
      labelColor="#ffffff"
      style={buttonStyle}
      onClick={()=> !!distributionId ? undefined : this.deleteUnprocessedExpenseReport(expenseReport.id)}
      noActions={!!distributionId}
      body={!distributionId
        ? undefined
        : <div>
          <p>
            This expense report is referenced in this
            <a href={`/admin/distribution-form/${distributionId}`}> distribution</a>.
          </p>
          <p>Please delete or modify the distribution before deleting this expense report.</p>
        </div>
      }
    />

    let buttons = [viewButton];
    if(!expenseReport.processed) {
      buttons.push(processButton);
      buttons.push(deleteButton);
    }
    else buttons.push(archiveButton);

    return (<div>{buttons}</div>);
  }

  tableRows = () => (this.props.paginatedExpenseReports || []).map(expReport => ({
    id: expReport.id,
    name: expReport.user_name,
    missionary_number: expReport.missionary_number,
    type: <ExpenseTypeTag type={expReport.type}/>,
    total_amount: expReport.total_amount ? '$' + parseFloat(expReport.total_amount).toFixed(2) : null,
    payout: expReport.payout ? '$' + parseFloat(expReport.payout).toFixed(2) : null,
    date_submitted: expReport.date_submitted ? moment(expReport.date_submitted).format('MM/DD/YYYY') : '',
    buttons: this.getButtons(expReport)
  }))

  tableCols = () => [
    {
      key: 'id',
      label: 'ID',
      sortable: true,
    },
    {key: 'name', label: 'Name', 'sortable': true},
    {key: 'missionary_number', label: 'Account #', 'sortable': true},
    {key: 'type', label: 'Expense Type', 'sortable': false},
    {key: 'total_amount', label: 'Amount', 'sortable': true},
    {key: 'payout', label: '$ Reimbursed', 'sortable': true},
    {key: 'date_submitted', label: 'Submitted', 'sortable': true},
    {key: 'buttons', label: '', 'sortable': false},
    {},
  ]

  render() {
    return (
      <span>
        <PaginatedTable
          data={this.tableRows()}
          columns={this.tableCols()}
          totalCount={this.state.total_count}
          page={this.state.page + 1}
          onNextPageClick={this.onNextPageClick}
          onPreviousPageClick={this.onPreviousPageClick}
          onRowSizeChange={this.onRowSizeChange}
          initialSort={this.state.sort}
          onSortOrderChange={this.onSortOrderChange}
          onFilterValueChange={this.onFilterValueChange}
          onRowSelection={this.onRowSelection}
          rowSize={this.state.limit}
        />
        <Dialog
          actions={[
            <RaisedButton
              labelColor="#ffffff"
              backgroundColor={'#525252'}
              label="Cancel"
              onClick={this.handleClose}
              style={{marginRight: 3}}
            />,
            <RaisedButton
              labelColor="#ffffff"
              backgroundColor={'#d40a0a'}
              label="Archive"
              onClick={(event) => this.deleteExpenseReport(event)}
            />
          ]}
          modal={true}
          open={!!this.state.expenseReportToDelete}
          contentStyle={{width: '90%'}}
          actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
        >
          <Subheader className="curved-style">Are you sure you want to archive this expense report?</Subheader>
        </Dialog>
      </span>
    )
  }
}

export default connect(
  state => ({
    paginatedExpenseReports: state.rootReducer.expenseReports.paginatedExpenseReports || [],
    loading: state.rootReducer.expenseReports.isFetching,
  })
)(withLoading(ExpenseReportsTable));
