import React from 'react';
import {connect} from 'react-redux';
import Actions from '../../../actions';
import Cookies from 'cookies-js';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteButton from '../../blocks/delete-button';
import moment from 'moment';
import DonationsDialog from '../../blocks/donations/donations-dialog';
import DonationsList from './donations-list';
import PaginatedTable from '../PaginatedTable';

import {
   PATCH_DONATION_SUCCESS,
   PATCH_DONATION_FAILURE,
   DELETE_DONATION_SUCCESS,
   DELETE_DONATION_FAILURE
} from '../../../actions/types';
import { withLoading } from '../with-loading';


const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
}

class DonationsTable extends React.Component {
   _isMounted = false;

   state = {
      open: false,
      amount: '',
      count: '',
      donationId: '',
      page: 0,
      limit: 10,
      total_count: undefined,
      sort: { column: 'id', order: 'desc' },
      q: undefined,
      selectedRows: [],
   };

   componentWillUnmount() {
      this._isMounted = false;
   }

   componentDidMount() {
      this._isMounted = true;
      this.getDonations();
   };

   getDonations = () => {
      this.props.dispatch(Actions.requestGetPaginatedDonations(
        Cookies.get('token'),
        this.state.limit,
        this.state.page,
        this.state.sort.column,
        this.state.sort.order,
        this.state.q,
        this.props.userId,
      )).then(res => {
           this.setStateIfMounted({ total_count: res.payload.total_count });
      })
   }

   updateDonation = () => {
      const form = document.getElementById('donations-form');
      const formData = new FormData(form);
      this.props.dispatch(Actions.requestPatchDonation(this.state.donationId, formData, Cookies.get('token')))
         .then((result) => {
            if (result.type === PATCH_DONATION_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Donation Updated'));
               this.getDonations();
            }
            else if (result.type === PATCH_DONATION_FAILURE)
               this.props.dispatch(Actions.newError(result.payload.message));
         })
      this.handleClose();
   }

   deleteDonation = (id) => {
      this.props.dispatch(Actions.requestDeleteDonation(id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_DONATION_SUCCESS)
               this.props.dispatch(Actions.newMessage('Donation Deleted'));
            else if (result.type === DELETE_DONATION_FAILURE)
               this.props.dispatch(Actions.newError('Something went wrong.'));
         });
   }

   handleOpen = (donation) => this.setState({
      open: true,
      amount: donation.amount,
      count: donation.count,
      donationId: donation.id
   });

   handleClose = () => this.setState({
      open: false,
      amount: '',
      count: '',
      donationId: ''
   });

   onChange = (key, value) => this.setState({[key]: value});

   setStateIfMounted = (updates, callback) =>
     this._isMounted ? this.setState(updates, () => callback ? callback() : undefined) : undefined;

   onNextPageClick = () => this.setStateIfMounted({ page: this.state.page + 1 }, () => {
      this.getDonations();
   })

   onPreviousPageClick = () => this.setStateIfMounted({ page: this.state.page - 1 }, () => {
      this.getDonations();
   })

   onRowSizeChange = (limit) => this.setStateIfMounted({ limit }, () => this.getDonations());

   onSortOrderChange = (column, order) => this.setStateIfMounted({ sort: { column, order } }, () => this.getDonations());

   onFilterValueChange = q => this.setStateIfMounted({ q, page: 0 }, () => this.getDonations());

   onRowSelection = rows => this.setStateIfMounted({ selectedRows: rows });

   getButtons = (donation) => {
      if (!this.props.admin) return;
      return (
        <div>
           <RaisedButton
             label="Edit"
             className="edit-donation"
             backgroundColor="#1E88E5"
             labelColor="#ffffff"
             style={buttonStyle}
             onClick={() => this.handleOpen(donation)}
           />
           <DeleteButton
             message="Delete this donation?"
             label="Delete"
             backgroundColor="#e60000"
             labelColor="#ffffff"
             style={buttonStyle}
             contentStyle={{width: '40%'}}
             onClick={()=>this.deleteDonation(donation.id)}
           />
        </div>
      )
   }

   getRows = () => {
      return (this.props.paginatedDonations || []).map(donation => ({
         id: donation.id,
         name: donation.user_name,
         fund_name: donation.fund_name,
         amount: '$' + donation.amount,
         count: donation.count,
         date: moment(donation.created_at).format('MM/DD/YYYY'),
         buttons: this.getButtons(donation),
         upload_name: donation.upload_name,
      }));
   }

   render() {
      const columns = [
         {key: 'id', label: 'ID', sortable: true},
         {key: 'name', label: 'Name', sortable: true},
         {key: 'fund_name', label: 'Hold Fund', sortable: true},
         {key: 'amount', label: 'Amount', 'sortable': true},
         {key: 'count', label: 'Count', 'sortable': true},
         {key: 'upload_name', label: 'Upload Name (Month)', sortable: true},
         {key: 'buttons', label: '', 'sortable': false},
      ];

      const data = this.getRows();

      return (
         <div className="list-view table-view">
            <Subheader className="table-style">Donations</Subheader>
            <div className="donation-table">
               <PaginatedTable
                 data={data || []}
                 columns={columns}
                 totalCount={this.state.total_count}
                 page={this.state.page + 1}
                 onNextPageClick={this.onNextPageClick}
                 onPreviousPageClick={this.onPreviousPageClick}
                 onRowSizeChange={this.onRowSizeChange}
                 initialSort={this.state.sort}
                 onSortOrderChange={this.onSortOrderChange}
                 onFilterValueChange={this.onFilterValueChange}
                 onRowSelection={this.onRowSelection}
                 rowSize={this.state.limit}
               />
            </div>
            <div className="donation-list">
               <DonationsList data={data}/>
            </div>
            <DonationsDialog
               open={this.state.open}
               amount={this.state.amount}
               count={this.state.count}
               donationId={this.state.donationId}
               handleClose={this.handleClose}
               onChange={this.onChange}
               onClick={this.updateDonation}
            />	
         </div>
      )
   }
}

export default connect(
   state => ({
      loading: state.rootReducer.donations.isFetching,
      paginatedDonations: state.rootReducer.donations.paginatedDonations || [],
   })
)(withLoading(DonationsTable));
