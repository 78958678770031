import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Actions from '../../actions';
import PaginatedTable from './PaginatedTable';
import { withLoading } from './with-loading';
import _ from 'lodash';
import ButtonLink from './button-link';
import RaisedButton from 'material-ui/RaisedButton';
import {
  DELETE_USER_FAILURE, DELETE_USER_SUCCESS, RESTORE_ARCHIVED_USER_FAILURE, RESTORE_ARCHIVED_USER_SUCCESS
} from '../../actions/types';

class UsersTable extends Component {
  _isMounted = false;

  state = {
    page: 0,
    limit: 10,
    total_count: undefined,
    sort: { column: 'id', order: 'desc' },
    q: undefined,
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUsers() {
    this.props.dispatch(Actions.requestGetPaginatedUsers(
      Cookies.get('token'),
      {
        limit: this.state.limit,
        page: this.state.page,
        order_by: this.state.sort.column,
        order: this.state.sort.order,
        ...(this.state.q && this.state.q.length ? { q: this.state.q } : {}),
        ...(this.props.trashed ? { trashed: this.props.trashed } : {}),
      }
    )).then(json => {
      this.setStateIfMounted({ total_count: json.payload.total_count });
    })
  }

  setStateIfMounted = (updates, callback) =>
    this._isMounted ? this.setState(updates, () => callback ? callback() : undefined) : undefined;

  onNextPageClick = () => this.setStateIfMounted({ page: this.state.page + 1 }, () => {
    this.getUsers();
  })

  onPreviousPageClick = () => this.setStateIfMounted({ page: this.state.page - 1 }, () => {
    this.getUsers();
  })

  onRowSizeChange = (limit) => this.setStateIfMounted({ limit }, () => this.getUsers());

  onSortOrderChange = (column, order) => this.setStateIfMounted({
    sort: {
      column,
      order
    }
  }, () => this.getUsers());

  onFilterValueChange = q => this.setStateIfMounted({ q, page: 0 }, () => this.getUsers());

  onRowSelection = rows => this.setStateIfMounted({ selectedRows: rows });

  archiveUser(id, e) {
    e.preventDefault();
    this.props.dispatch(Actions.requestDeleteUser(id, Cookies.get('token')))
      .then(result => {
        if (result.type === DELETE_USER_SUCCESS){
          this.props.dispatch(Actions.newMessage('You have successfully archived this user.'));
          this.getUsers();
        } else if (result.type === DELETE_USER_FAILURE){
          this.props.dispatch(Actions.newError('Something went wrong.'));
        }
      });
  }

  restoreUser(id, e) {
    e.preventDefault();
    this.props.dispatch(Actions.requestRestoreArchivedUser(id, Cookies.get('token')))
      .then(result => {
        if (result.type === RESTORE_ARCHIVED_USER_SUCCESS){
          this.props.dispatch(Actions.newMessage('You have successfully restored this user.'));
          this.getUsers();
        } else if (result.type === RESTORE_ARCHIVED_USER_FAILURE){
          this.props.dispatch(Actions.newError('Something went wrong.'));
        }
      });
  }

  deleteUserPermanently(id, e) {
    e.preventDefault();
    this.props.dispatch(Actions.requestDeleteUser(id, Cookies.get('token')))
      .then(result => {
        if (result.type === DELETE_USER_SUCCESS){
          this.props.dispatch(Actions.newMessage('You have successfully deleted this user.'));
          this.getUsers();
        } else if (result.type === DELETE_USER_FAILURE){
          this.props.dispatch(Actions.newError('Something went wrong.'));
        }
      });
  }


  getButtons = (user) => {
    const btns = [];
    if (user.deleted_at) {
      btns.push(
        <RaisedButton
          label='Restore'
          backgroundColor='#1E88E5'
          labelColor='#ffffff'
          buttonStyle={this.props.buttonStyle}
          style={this.props.style}
          labelStyle={this.props.labelStyle}
          onClick={(e)=>this.restoreUser(user.id, e)}
        />
      );
      btns.push(
        <RaisedButton
          label='Delete'
          backgroundColor='#e60000'
          labelColor='#ffffff'
          buttonStyle={this.props.buttonStyle}
          style={this.props.style}
          labelStyle={this.props.labelStyle}
          onClick={(e)=>this.deleteUserPermanently(user.id, e)}
        />
      )
    }
    else {
      btns.push(
        <ButtonLink
          key={'view-user' + user.id}
          backgroundColor="#525252"
          labelColor="#ffffff"
          label="View"
          style={{marginRight: 3, marginLeft: 75}}
          to={'/admin/user/' + user.id  + '/profile'}
        />
      );
      btns.push(
        <RaisedButton
          key={'archive-user' + user.id}
          label='Archive'
          backgroundColor='#e60000'
          labelColor='#ffffff'
          buttonStyle={this.props.buttonStyle}
          style={this.props.style}
          labelStyle={this.props.labelStyle}
          onClick={(e)=>this.archiveUser(user.id, e)}
        />
      )
    }
    return btns;
  }


  tableRows = () => (this.props.users || []).map(x => ({
    id: x.id,
    name: x.name,
    role:  _.upperFirst(x.role),
    email: x.email,
    missionary_number: x.missionary_number,
    unreimbursed_amount: '$' + (x.unreimbursed_amount || 0).toFixed(2),
    buttons: this.getButtons(x)
  }))

  tableCols = () => [
    {key: 'id', label: 'ID', 'sortable': true},
    {key: 'name', label: 'Name', 'sortable': true},
    {key: 'role', label: 'Role', 'sortable': true},
    {key: 'email', label: 'Email', 'sortable': true},
    {key: 'missionary_number', label: 'Account', 'sortable': true},
    {key: 'unreimbursed_amount', label: 'Unreimbursed Amount', 'sortable': true},
    {
      key: 'buttons',
      label: '',
      sortable: false,
    },
    {}
  ]

  render() {
    return (
      <span>
        <PaginatedTable
          data={this.tableRows()}
          columns={this.tableCols()}
          totalCount={this.state.total_count}
          page={this.state.page + 1}
          onNextPageClick={this.onNextPageClick}
          onPreviousPageClick={this.onPreviousPageClick}
          onRowSizeChange={this.onRowSizeChange}
          initialSort={this.state.sort}
          onSortOrderChange={this.onSortOrderChange}
          onFilterValueChange={this.onFilterValueChange}
          onRowSelection={this.onRowSelection}
          rowSize={this.state.limit}
        />
      </span>
    )
  }
}

export default connect(state => ({
  users: state.rootReducer.users.paginatedUsers || [],
  loading: state.rootReducer.users.isFetching,
}))(withLoading(UsersTable));
