import { getArchivedUsersRequest, getPaginatedUsersRequest } from '../api/users';

import {
    GET_ARCHIVED_USERS,
    GET_ARCHIVED_USERS_SUCCESS,
    GET_ARCHIVED_USERS_FAILURE, GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR
} from './types';


function getArchivedUsers(){
    return{
        type: GET_ARCHIVED_USERS
    }
}

function getArchivedUsersSuccess(json){
    return{
        type: GET_ARCHIVED_USERS_SUCCESS,
        payload: json
    }
}

function getArchivedUsersFailure(error){
    return{
        type: GET_ARCHIVED_USERS_FAILURE,
        payload: error
    }
}

export default function requestGetArchivedUsers(token) {
    return dispatch => {
        dispatch(getArchivedUsers());
        return getArchivedUsersRequest(token)
            .then((json) => {
                return dispatch(getArchivedUsersSuccess(json));
            })
            .catch((error) => {
            console.log(error);
                return error.json().then((errors) => {
                    return dispatch(getArchivedUsersFailure(errors));
                })
            });
    };
}

export const requestGetPaginatedUsers = (token, params) => dispatch => {
    dispatch({ type: GET_USERS });
    return getPaginatedUsersRequest(token, params)
      .then(json => {
          return dispatch({ type: GET_USERS_SUCCESS, payload: json });
      })
      .catch(e => {
          return dispatch({ type: GET_USERS_ERROR });
      })
}

