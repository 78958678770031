import {postDistributionRequest} from '../api/distributions';

import{
   POST_DISTRIBUTION,
   POST_DISTRIBUTION_SUCCESS,
   POST_DISTRIBUTION_FAILURE
} from './types';

function postDistribution(){
    return{
        type: POST_DISTRIBUTION
    }
}

function postDistributionSuccess(json){
    return{
        type: POST_DISTRIBUTION_SUCCESS,
        payload: json
    }
}

function postDistributionFailure(error){
    return{
        type: POST_DISTRIBUTION_FAILURE,
        payload: error
    }
}

export default function requestPostDistribution(formData, token, json) {
    return dispatch => {
        dispatch(postDistribution());
        return postDistributionRequest(formData, token, json)
            .then((json) => {
                return dispatch(postDistributionSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postDistributionFailure(errors));
                })
            });
    };
}