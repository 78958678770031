import React, {Component} from 'react';
import RaisedButton from "material-ui/RaisedButton";

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  error: {
    marginTop: 10,
    color: 'red',
    fontSize: 12,
    marginRight: 10,
  }
};

class FileInput extends Component {
  state = {
    valid: false,
    error: undefined,
    file: undefined,
  };

  submit = () => {
    if (!this.state.valid) return;
    this.props.onSubmit(this.state.file);
    document.getElementById('file_path').value = '';
  };

  validFileSize = file => (file.size / 1024) < 5120;

  onChange = e => {
    const [ file ] = e.target.files || [];
    if (!file) return;
    if (this.validFileSize(file)) {
      this.setState({ valid: true, error: undefined, file });
    }
    else {
      this.setState({
        valid: false,
        error: 'Invalid file. Please choose a file that is 5MB or smaller.',
        file: undefined,
      });
    }
  };

  render = () => (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <input
          type="file"
          id="file_path"
          name="file"
          onChange={this.onChange}
        />
        {
          !!this.state.error &&
          <p style={styles.error}>{this.state.error}</p>
        }
      </div>
      <RaisedButton
        labelColor="#ffffff"
        backgroundColor={'#525252'}
        label="Add File"
        onClick={this.submit}
        disabled={!this.state.valid}
      />
    </div>
  );
}

export default FileInput;
