import { deleteExpenseRequest } from '../api/expenses';

import {
    DELETE_EXPENSE,
    DELETE_EXPENSE_SUCCESS,
    DELETE_EXPENSE_FAILURE
} from '../actions/types';

function deleteExpense() {
    return {
        type: DELETE_EXPENSE
    };
}

function deleteExpenseSuccess(json) {
    return {
        type: DELETE_EXPENSE_SUCCESS,
        payload: json
    };
}

function deleteExpenseFailure(error) {
    return {
        type: DELETE_EXPENSE_FAILURE,
        payload: error
    };
}

export default function requestDeleteExpense(expense_id, token) {
    return dispatch => {
        dispatch(deleteExpense());
        return deleteExpenseRequest(expense_id, token)
            .then((json) => {
                return dispatch(deleteExpenseSuccess(json));
            })
            .catch((error) => {
            console.log(error);
                return error.json().then((errors) => {
                    return dispatch(deleteExpenseFailure(errors));
                })
            });
    };
}
