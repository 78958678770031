import React, {Component} from 'react';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import Table from '../blocks/table';
import {GET_USER_SUCCESS, PATCH_USER_SUCCESS} from '../../actions/types';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import ExpenseTypeTag from '../blocks/expense-type-tag';
import Icon from '../blocks/icon';
import {doesItemExistInState} from '../../utilities/exist-in-state';
import {updateForm} from '../../utilities/update';
import moment from 'moment';
import ProcessedModal from '../blocks/processed-modal';
import person_icon from '../../assets/images/user_icon@2x.png';
import DropDownSelect from '../fields/dropdown-select-field';
import {roles, taxStatusOptions} from '../../constants/options';
import AdvanceProcessModal from '../blocks/advance-process-modal';
import {dateSubmittedTableSort} from '../../utilities/date-sort';
import Check from '../fields/checkbox';
import DonationsTable from '../blocks/donations/donations-table';
import HoldAccountsTable from '../blocks/HoldAccounts/hold-accounts-table';
import DistributionsTable from '../blocks/Distributions/distributions-table';

const buttonStyle = {
   marginRight: 3,
   display: 'inline-block',
   width: 92
};


class AdminUserProfile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         editable: true,
         payrollEditable: true,
         forceUpdate: 0,
         roleSelectValue: null,
         ficaChecked: false,
         taxStatusSelectValue: null,
         userExpenseReports: [],
         userAdvanceRequests: []
      };
   }

   componentDidMount() {
      const doesUserExistInState = doesItemExistInState(this.props.users.users, this.props.params.user_id);
      const that = this;
      // this.setState = {disabled : false};
      //if group doesn't exist in state, get the group, and if it's successful, use the payload as the value for the field ELSE, just the selectedGroup attribute value as the value for the field
      if (!doesUserExistInState) {
         this.props.dispatch(Actions.requestGetUser(this.props.params.user_id, Cookies.get('token')))
               .then((result) => {
                  if (result.type === GET_USER_SUCCESS) {
                     //add decimal place to each item
                     result.payload.profile.unreimbursed_amount = result.payload.profile.unreimbursed_amount ? result.payload.profile.unreimbursed_amount.toFixed(2) : '0.00';
                     result.payload.profile.net_salary = result.payload.profile.net_salary ? result.payload.profile.net_salary : '0.00';
                     result.payload.profile.retirement_amount = result.payload.profile.retirement_amount ? result.payload.profile.retirement_amount : '0.00';
                     result.payload.profile.insurance_amount =  result.payload.profile.insurance_amount ? result.payload.profile.insurance_amount : '0.00';
                     result.payload.profile.fund_balance = result.payload.profile.fund_balance === null ? '0.00' : parseFloat(result.payload.profile.fund_balance);
                     result.payload.profile.admin_fee_percentage = result.payload.profile.admin_fee_percentage ? result.payload.profile.admin_fee_percentage.toFixed(2) : '0.00';
                     that.updateFormsAndState(result.payload);
                  }
      
               });
      } else {
         that.updateFormsAndState(doesUserExistInState)
      }

      this.props.dispatch(Actions.requestGetAllExpenseReportsByUser(this.props.params.user_id, Cookies.get('token')))
         .then(result => {
            this.setState({userExpenseReports: _.values(result.payload)});
         });
      this.props.dispatch(Actions.requestGetAllAdvanceRequestsByUser(this.props.params.user_id, Cookies.get('token')))
         .then(result => {
            this.setState({ userAdvanceRequests: _.values(result.payload)});
         });
   }


   updateFormsAndState = (user) => {
      this.setState({
         roleSelectValue: user.role,
         taxStatusSelectValue: user.profile.tax_status,
         ficaChecked: user.profile.fica_tax === 1 ? true : false ,
      })
      updateForm('update-user-form', user);
      updateForm('update-payroll-form', user.profile);
   }

   updateUser(event) {
      event.preventDefault();
      const form = document.getElementById('update-user-form');
      let formData = new FormData(form);
      this.patchUserRequest(formData);
      this.toggleEnableInputs();
   }

   updatePayroll = (event) => {
      event.preventDefault();
      const form = document.getElementById('update-payroll-form');
      let formData = new FormData(form);

      //add items that the controller method requires
      formData.append('name', document.getElementById('name').value);
      formData.append('role', document.getElementById('role').value);
      formData.append('email', document.getElementById('email').value);
      formData.append('profile_missionary_number', document.getElementById('profile_missionary_number').value);
      formData.append('profile_unreimbursed_amount', document.getElementById('profile_unreimbursed_amount').value);
      //set fica value
      formData.set('fica_tax', this.state.ficaChecked);

      this.patchUserRequest(formData);
      this.toggleEnablePayrollInputs();		
   }

   patchUserRequest = (formData) => {	
      let that = this;
      this.props.dispatch(Actions.requestPatchUser(this.props.params.user_id, formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === PATCH_USER_SUCCESS) {
                  that.props.dispatch(Actions.newMessage('You have successfully updated the user.'));
                  browserHistory.push('/admin/user/'+ result.payload.id + '/profile');
            } else {
                  that.props.dispatch(Actions.newError('There was an error updating the user, please try again later.'));
            }
         });
   }

   toggleEnableInputs = () => {
      const editable = this.state.editable;    
      this.setState({editable: !editable});
   }

   toggleEnablePayrollInputs = () => {
      const payrollEditable = this.state.payrollEditable;    
      this.setState({payrollEditable: !payrollEditable});
   }

   incrementForceUpdate = ()=>{
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   };

   handleCheck = (event, type) => {
      this.setState({[type]: event})
   }

   getButtons = (report) => {
      const processButton = <ProcessedModal
                              key={'report-process' + report.id} 
                              expenseReportSingle={report}
                              incrementForceUpdate={this.incrementForceUpdate}
                              style={buttonStyle}
                            />;
      const viewButton    = <ButtonLink
                              key={'report-view' + report.id}
                              backgroundColor="#525252"
                              labelColor="#ffffff"
                              label="View" 
                              to={'/expense-report/' + report.id }
                              style={buttonStyle}
                            />;

      let buttons = []
      if(!report.processed && report.submitted ) {
         buttons.push(processButton);
      }
      buttons.push(viewButton);
      return buttons;
   }

   getAdvanceTableButtons = (request) => {
      const processButton = <AdvanceProcessModal 
                              key={'request-process' + request.id}
                              advanceRequestSingle={request}
                              incrementForceUpdate={this.incrementForceUpdate}
                              style={buttonStyle}
                             />
      const viewButton =  <ButtonLink 
                              key={'request-view' + request.id}
                              backgroundColor="#525252"
                              labelColor="#ffffff"
                              label="View"
                              to={'/expense-report/' + request.expense_report_id}
                              style={buttonStyle}   
                          /> 


      let buttons = [];
      if(!request.amount_approved) {
         buttons.push(processButton);
      }
      if((request.status === 'approved' || request.status === 'closed') && request.expense_report_id) {
         buttons.push(viewButton);
      }
      return buttons;
   }


   render() {
      const selectedUser = doesItemExistInState(this.props.users.users, this.props.params.user_id);

      //expense reports table
      const columns = [
         {key: 'id', label: 'ID', 'sortable': true},
         {key: 'type', label: 'Expense Type', 'sortable': false},
         {key: 'date_submitted', label: 'Date Submitted', 'sortable': false},
         {key: 'total_amount', label: 'Amount', 'sortable': false},
         {key: 'start_date', label: 'Start Date', 'sortable': false},
         {key: 'end_date', label: 'End Date', 'sortable': false},
         {key: 'processed', label: 'Processed', 'sortable': true},
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];
      const userExpenseReportsTable = this.state.userExpenseReports
         .filter(report => {
            return report.submitted;
         })
         .map(report => {
            return({
               id: report.id,
               type: <ExpenseTypeTag type={report.type}/>,
               date_submitted: report.date_submitted ? moment(report.date_submitted).format('MM/DD/YYYY') : '',
               total_amount: report.total_amount ?  '$' + report.total_amount.toFixed(2) : '0.00',
               start_date: report.start_date ? moment(report.start_date).format('MM/DD/YYYY') : '',
               end_date: report.end_date ?  moment(report.end_date).format('MM/DD/YYYY') : '',
               processed: report.processed ? <Icon type="done"/> : <Icon type="clear"/>,
               buttons: this.getButtons(report)
            });
         })
         .sort(dateSubmittedTableSort);

      //advance requests table
      const advanceRequestcolumns = [
         {key: 'id', label: 'ID', 'sortable': true},
         {key: 'date_submitted', label: 'Date Submitted', 'sortable': true},
         {key: 'mat_date', label: 'MAT Date Requested', 'sortable': true},
         {key: 'type', label: 'Type', 'sortable': true},
         {key: 'amount_requested', label: 'Amount Requested', 'sortable': false},
         {key: 'amount_approved', label: 'Amount Approved', 'sortable': false},
         {key: 'status', label: 'Status', 'sortable': true},
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];  
      const advanceRequestTable = this.state.userAdvanceRequests
         .map(request => {
            return {
               id: request.id,
               date_submitted: moment(request.date_submitted).format('MM/DD/YYYY'),
               mat_date: moment(request.mat_date).format('MM/DD/YYYY'),
               type: _.upperFirst(request.type),
               amount_requested: '$' + request.amount_requested.toFixed(2),
               amount_approved: request.amount_approved ? '$' + (request.amount_approved).toFixed(2) : null,
               status: _.upperFirst(request.status),
               buttons: this.getAdvanceTableButtons(request)

            }
      });
      advanceRequestTable.sort(dateSubmittedTableSort);
      

      return (
         <div className="admin-dashboard">
            <div className="users">
               <h2 className="form-heading" style={{display: 'flex', alignItems: 'center'}}><img src={person_icon} style={{height: '30px', marginRight: '10px'}} className="person-icon" alt="person icon" />{selectedUser.name}</h2>
               <div className="form-block-container">
                  <div className="form-block">
                        <Subheader className="table-style">Basic Info</Subheader>
                           <form id="update-user-form" encType="multipart/form-data">
                           <div className="form-section">
                              <Input className="inline-inputs" id="name" floatingLabelText="Name" disabled={this.state.editable} errors={this.props.users}/>
                              <Input className="inline-inputs" id="email" floatingLabelText="Email" disabled={this.state.editable} errors={this.props.users}/>
                              <Input className="inline-inputs" id="profile_missionary_number" floatingLabelText="Account #" disabled={this.state.editable} errors={this.props.users}/>
                              <DropDownSelect
                                    className="inline-inputs" id="role"
                                    floatingLabelText="Role"
                                    floatingLabelStyle={{left: 0}}
                                    errors={this.props.users}
                                    options={roles}
                                    value={this.state.roleSelectValue}
                                    disabled={this.state.editable}
                              />
                              <Input className="inline-inputs" id="profile_unreimbursed_amount" floatingLabelText="Unreimbursed Amount" disabled={this.state.editable} errors={this.props.users} prefix="$"/>
                           </div>
                        </form>
                        <div className="form-buttons">
                           <RaisedButton labelColor={'#ffffff'} backgroundColor={'#525252'} label="Edit" onClick={this.toggleEnableInputs}/>
                           <RaisedButton labelColor={'#ffffff'} backgroundColor={'#1E88E5'} label="Save" onClick={(event) => this.updateUser(event)} disabled={this.state.editable}/>
                           <br/><br/>
                        </div>
                  </div>
               </div>
               <div className="form-block-container">
                  <div className="form-block">
                     <Subheader className="table-style"> Payroll Info </Subheader>
                     <form id="update-payroll-form" encType="multipart/form-data">
                        <div style={{float: 'left', width: '100%'}}>
                           <Input
                              className="inline-inputs"
                              id="net_salary"
                              floatingLabelText="Salary"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}						
                           />
                           <Input
                              className="inline-inputs"
                              id="fund_balance"
                              floatingLabelText="Fund Balance"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="missionary_number"
                              floatingLabelText="Account Number"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="admin_fee_percentage"
                              floatingLabelText="Admin Fee %"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              style={{maxWidth: 150}}							
                           />
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                           <Input
                              className="inline-inputs"
                              id="admin_fee_cap"
                              floatingLabelText="Admin Fee Cap"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="retirement_amount"
                              floatingLabelText="Retirement"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="insurance_amount"
                              floatingLabelText="Insurance"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix="$"
                              style={{maxWidth: 150}}							
                           />
                           <Input
                              className="inline-inputs"
                              id="wire_fee"
                              floatingLabelText="Wire Fee"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                           <Input
                              className="inline-inputs"
                              id="housing_allowance"
                              floatingLabelText="Housing Allowance"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                           <DropDownSelect
                                 className="inline-inputs"
                                 id="tax_status"
                                 floatingLabelText="Tax Status"
                                 floatingLabelStyle={{left: 0}}
                                 errors={this.props.users}
                                 options={taxStatusOptions}
                                 value={this.state.taxStatusSelectValue}
                                 disabled={this.state.payrollEditable}
                                 style={{maxWidth: 150}}	
                           />
                           <Input
                              className="inline-inputs"
                              id="federal_income_tax"
                              floatingLabelText="Federal Income Tax"
                              disabled={this.state.payrollEditable}
                              errors={this.props.users}
                              prefix='$'
                              style={{maxWidth: 150}}							
                           />
                           <div className="fica_check_box" style={{display: 'inline-block', float: 'left', width: '100px', marginTop: '40px', marginLeft: '30px'}}>
                              <Check
                                 key="fica-checkbox"
                                 id="fica_tax"
                                 className="inline-inputs"
                                 label="FICA"
                                 labelPosition="right"
                                 disabled={this.state.payrollEditable}
                                 checked={this.state.ficaChecked}
                                 onCheck={(event) => this.handleCheck(event, 'ficaChecked')}
                                 style={{width: 250}}	
                              />
                           </div>
                        </div>
                     </form>
                     <div className="form-buttons">
                        <RaisedButton
                           labelColor={'#ffffff'}
                           backgroundColor={'#525252'}
                           label="Edit"
                           onClick={this.toggleEnablePayrollInputs}									
                        />
                        <RaisedButton
                           disabled={this.state.payrollEditable}
                           labelColor={'#ffffff'}
                           backgroundColor={'#1E88E5'}
                           label="Save"
                           onClick={(event) => this.updatePayroll(event)}										
                        />
                        <br/><br/>
                     </div>
                  </div>
               </div>
            </div>
            <div className="form-block table-view">
                  <Subheader className="table-style">Report History</Subheader>
                  <Table data={userExpenseReportsTable} columns={columns} forceUpdate={this.state.forceUpdate}/>
            </div>
            <div className="form-block table-view">
               <Subheader className="table-style">Advance Request History</Subheader>
               <Table data={advanceRequestTable} columns={advanceRequestcolumns} forceUpdate={this.state.forceUpdate}/>
            </div>
            <DonationsTable admin={true} userId={parseInt(this.props.params.user_id, 10)}/>
            <HoldAccountsTable admin={true} userId={parseInt(this.props.params.user_id, 10)}/>
            <DistributionsTable admin={true} userId={parseInt(this.props.params.user_id, 10)} status="approved"/>
         </div>
      )
   }
}

export default connect(
   state => ({
      users: state.rootReducer.users,
   })
)(AdminUserProfile);
