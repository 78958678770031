export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export const RESET_PASSWORD_EMAIL_FAILURE = 'RESET_PASSWORD_EMAIL_FAILURE';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const LOGIN_USER_WITH_TOKEN_SUCCESS = 'LOGIN_USER_WITH_TOKEN_SUCCESS';
export const LOGIN_USER_WITH_TOKEN_FAILURE = 'LOGIN_USER_WITH_TOKEN_FAILURE';

export const NEW_MESSAGE = 'NEW_MESSAGE';
export const NEW_ERROR = 'NEW_ERROR';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

//USERS
export const POST_USER = 'POST_USER';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export const PATCH_USER = 'POST_USER';
export const PATCH_USER_SUCCESS = 'POST_USER_SUCCESS';
export const PATCH_USER_FAILURE = 'POST_USER_FAILURE';

export const GET_ACTIVE_USERS = 'GET_ACTIVE_USERS';
export const GET_ACTIVE_USERS_SUCCESS = 'GET_ACTIVE_USERS_SUCCESS';
export const GET_ACTIVE_USERS_FAILURE = 'GET_ACTIVE_USERS_FAILURE';

export const GET_ARCHIVED_USERS = 'GET_ARCHIVED_USERS';
export const GET_ARCHIVED_USERS_SUCCESS = 'GET_ARCHIVED_USERS_SUCCESS';
export const GET_ARCHIVED_USERS_FAILURE = 'GET_ARCHIVED_USERS_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const RESTORE_ARCHIVED_USER = 'RESTORE_ARCHIVED_USER';
export const RESTORE_ARCHIVED_USER_SUCCESS = 'RESTORE_ARCHIVED_USER_SUCCESS';
export const RESTORE_ARCHIVED_USER_FAILURE = 'RESTORE_ARCHIVED_USER_FAILURE';


//EXPENSE REPORTS
export const GET_ALL_EXPENSE_REPORTS = 'GET_ALL_EXPENSE_REPORTS';
export const GET_ALL_EXPENSE_REPORTS_SUCCESS = 'GET_ALL_EXPENSE_REPORTS_SUCCESS';
export const GET_ALL_EXPENSE_REPORTS_FAILURE = 'GET_ALL_EXPENSE_REPORTS_FAILURE';

export const GET_EXPENSE_REPORTS = 'GET_EXPENSE_REPORTS';
export const GET_EXPENSE_REPORTS_SUCCESS = 'GET_EXPENSE_REPORTS_SUCCESS';
export const GET_EXPENSE_REPORTS_ERROR = 'GET_EXPENSE_REPORTS_ERROR';

export const GET_ALL_EXPENSE_REPORTS_BY_USER = 'GET_ALL_EXPENSE_REPORTS_BY_USER';
export const GET_ALL_EXPENSE_REPORTS_BY_USER_SUCCESS = 'GET_ALL_EXPENSE_REPORTS_BY_USER_SUCCESS';
export const GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE = 'GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE';

export const POST_EXPENSE_REPORT = 'POST_EXPENSE_REPORT';
export const POST_EXPENSE_REPORT_SUCCESS = 'POST_EXPENSE_REPORT_SUCCESS';
export const POST_EXPENSE_REPORT_FAILURE = 'POST_EXPENSE_REPORT_FAILURE';

export const GET_EXPENSE_REPORT = 'GET_EXPENSE_REPORT';
export const GET_EXPENSE_REPORT_SUCCESS = 'GET_EXPENSE_REPORT_SUCCESS';
export const GET_EXPENSE_REPORT_FAILURE = 'GET_EXPENSE_REPORT_FAILURE';

export const SAVE_EXPENSE_REPORT = 'SAVE_EXPENSE_REPORT';
export const SAVE_EXPENSE_REPORT_SUCCESS = 'SAVE_EXPENSE_REPORT_SUCCESS';
export const SAVE_EXPENSE_REPORT_FAILURE = 'SAVE_EXPENSE_REPORT_FAILURE';

export const SUBMIT_EXPENSE_REPORT = 'SUBMIT_EXPENSE_REPORT';
export const SUBMIT_EXPENSE_REPORT_SUCCESS = 'SUBMIT_EXPENSE_REPORT_SUCCESS';
export const SUBMIT_EXPENSE_REPORT_FAILURE = 'SUBMIT_EXPENSE_REPORT_FAILURE';

export const DELETE_UNSUBMITTED_EXPENSE_REPORT = 'DELETE_UNSUBMITTED_EXPENSE_REPORT';
export const DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS = 'DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS';
export const DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE = 'DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE';

export const DELETE_EXPENSE_REPORT = 'DELETE_EXPENSE_REPORT';
export const DELETE_EXPENSE_REPORT_SUCCESS = 'DELETE_EXPENSE_REPORT_SUCCESS';
export const DELETE_EXPENSE_REPORT_FAILURE = 'DELETE_EXPENSE_REPORT_FAILURE';


//FILE UPLOADS
export const DELETE_FILE_UPLOAD = 'DELETE_FILE_UPLOAD';
export const DELETE_FILE_UPLOAD_SUCCESS = 'DELETE_FILE_UPLOAD_SUCCESS';
export const DELETE_FILE_UPLOAD_FAILURE = 'DELETE_FILE_UPLOAD_FAILURE';


export const POST_FILE_UPLOAD = 'POST_FILE_UPLOAD';
export const POST_FILE_UPLOAD_SUCCESS = 'POST_FILE_UPLOAD_SUCCESS';
export const POST_FILE_UPLOAD_FAILURE = 'POST_FILE_UPLOAD_FAILURE';


//EXPENSES
export const POST_EXPENSE = 'POST_EXPENSE';
export const POST_EXPENSE_SUCCESS = 'POST_EXPENSE_SUCCESS';
export const POST_EXPENSE_FAILURE = 'POST_EXPENSE_FAILURE';

export const PATCH_EXPENSE = 'PATCH_EXPENSE';
export const PATCH_EXPENSE_SUCCESS = 'PATCH_EXPENSE_SUCCESS';
export const PATCH_EXPENSE_FAILURE = 'PATCH_EXPENSE_FAILURE';

export const GET_EXPENSE = 'GET_EXPENSE';
export const GET_EXPENSE_SUCCESS = 'GET_EXPENSE_SUCCESS';
export const GET_EXPENSE_FAILURE = 'GET_EXPENSE_FAILURE';

export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS';
export const DELETE_EXPENSE_FAILURE = 'DELETE_EXPENSE_FAILURE';


//MILEAGE
export const POST_MILEAGE = 'POST_MILEAGE';
export const POST_MILEAGE_SUCCESS = 'POST_MILEAGE_SUCCESS';
export const POST_MILEAGE_FAILURE = 'POST_MILEAGE_FAILURE';

export const PATCH_MILEAGE = 'PATCH_MILEAGE';
export const PATCH_MILEAGE_SUCCESS = 'PATCH_MILEAGE_SUCCESS';
export const PATCH_MILEAGE_FAILURE = 'PATCH_MILEAGE_FAILURE';

export const GET_MILEAGE = 'GET_MILEAGE';
export const GET_MILEAGE_SUCCESS = 'GET_MILEAGE_SUCCESS';
export const GET_MILEAGE_FAILURE = 'GET_MILEAGE_FAILURE';

export const DELETE_MILEAGE = 'DELETE_MILEAGE';
export const DELETE_MILEAGE_SUCCESS = 'DELETE_MILEAGE_SUCCESS';
export const DELETE_MILEAGE_FAILURE = 'DELETE_MILEAGE_FAILURE';

export const PATCH_PROCESS = 'PATCH_PROCESS';
export const PATCH_PROCESS_SUCCESS = 'PATCH_PROCESS_SUCCESS';
export const PATCH_PROCESS_FAILURE = 'PATCH_PROCESS_FAILURE';

export const GET_PROCESSED_EXPENSE_REPORTS = 'GET_PROCESSED_EXPENSE_REPORTS';
export const GET_PROCESSED_EXPENSE_REPORTS_SUCCESS = 'GET_PROCESSED_EXPENSE_REPORTS_SUCCESS';
export const GET_PROCESSED_EXPENSE_REPORTS_FAILURE = 'GET_PROCESSED_EXPENSE_REPORTS_FAILURE';

export const GET_UNPROCESSED_EXPENSE_REPORTS = 'GET_UNPROCESSED_EXPENSE_REPORTS';
export const GET_UNPROCESSED_EXPENSE_REPORTS_SUCCESS = 'GET_UNPROCESSED_EXPENSE_REPORTS_SUCCESS';
export const GET_UNPROCESSED_EXPENSE_REPORTS_FAILURE = 'GET_UNPROCESSED_EXPENSE_REPORTS_FAILURE';

//ADVANCE REQUESTS
export const GET_ALL_ADVANCE_REQUESTS = 'GET_ALL_ADVANCE_REQUESTS';
export const GET_ALL_ADVANCE_REQUESTS_SUCCESS = 'GET_ALL_ADVANCE_REQUESTS_SUCCESS';
export const GET_ALL_ADVANCE_REQUESTS_FAILURE = 'GET_ALL_ADVANCE_REQUESTS_FAILURE';
export const GET_ADVANCE_REQUESTS = 'GET_ADVANCE_REQUESTS';
export const GET_ADVANCE_REQUESTS_SUCCESS = 'GET_ADVANCE_REQUESTS_SUCCESS';
export const GET_ADVANCE_REQUESTS_ERROR = 'GET_ADVANCE_REQUESTS_ERROR';

export const GET_ALL_ADVANCE_REQUESTS_BY_USER = 'GET_ALL_ADVANCE_REQUESTS_BY_USER';
export const GET_ALL_ADVANCE_REQUESTS_BY_USER_SUCCESS = 'GET_ALL_ADVANCE_REQUESTS_BY_USER_SUCCESS';
export const GET_ALL_ADVANCE_REQUESTS_BY_USER_FAILURE = 'GET_ADVANCE_REQUESTS_BY_USER_FAILURE';

export const PATCH_ADVANCE_REQUEST = 'PATCH_ADVANCE_REQUESTS';
export const PATCH_ADVANCE_REQUEST_SUCCESS = 'PATCH_ADVANCE_REQUEST_SUCCESS';
export const PATCH_ADVANCE_REQUEST_FAILURE = 'PATCH_ADVANCE_REQUEST_FAILURE';

export const POST_ADVANCE_REQUEST = 'POST_ADVANCE_REQUESTS';
export const POST_ADVANCE_REQUEST_SUCCESS = 'POST_ADVANCE_REQUEST_SUCCESS';
export const POST_ADVANCE_REQUEST_FAILURE = 'POST_ADVANCE_REQUEST_FAILURE';

export const DELETE_ADVANCE_REQUEST = 'DELETE_ADVANCE_REQUESTS';
export const DELETE_ADVANCE_REQUEST_SUCCESS = 'DELETE_ADVANCE_REQUEST_SUCCESS';
export const DELETE_ADVANCE_REQUEST_FAILURE = 'DELETE_ADVANCE_REQUEST_FAILURE';

export const DELETE_CLOSED_ADVANCE_REQUEST = 'DELETE_CLOSED_ADVANCE_REQUESTS';
export const DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS = 'DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS';
export const DELETE_CLOSED_ADVANCE_REQUEST_FAILURE = 'DELETE_CLOSED_ADVANCE_REQUEST_FAILURE';

//Settings
export const GET_ALL_SETTINGS = 'GET_ALL_SETTINGS';
export const GET_ALL_SETTINGS_SUCCESS = 'GET_ALL_SETTINGS_SUCCESS';
export const GET_ALL_SETTINGS_FAILURE = 'GET_ALL_SETTINGS_FAILURE';

export const PATCH_SETTINGS = 'PATCH_SETTING';
export const PATCH_SETTINGS_SUCCESS = 'PATCH_SETTING_SUCCESS';
export const PATCH_SETTINGS_FAILURE = 'PATCH_SETTING_FAILURE';

//Categories
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_FAILURE = 'GET_ALL_CATEGORIES_FAILURE';

export const POST_CATEGORY = 'POST_CATEGORY';
export const POST_CATEGORY_SUCCESS = 'POST_CATEGORY_SUCCESS';
export const POST_CATEGORY_FAILURE = 'POST_CATEGORY_FAILURE';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const PATCH_CATEGORY = 'PATCH_CATEGORY';
export const PATCH_CATEGORY_SUCCESS = 'PATCH_CATEGORY_SUCCESS';
export const PATCH_CATEGORY_FAILURE = 'PATCH_CATEGORY_FAILURE';

export const POST_DENARI_UPLOAD = 'POST_DENARI_UPLOADS';
export const POST_DENARI_UPLOAD_SUCCESS = 'POST_DENARI_UPLOAD_SUCCESS';
export const POST_DENARI_UPLOAD_FAILURE = 'POST_ADVANCE_REQUEST_FAILURE';

export const GET_ALL_DONATIONS = 'GET_ALL_DONATIONS';
export const GET_ALL_DONATIONS_SUCCESS = 'GET_ALL_DONATIONS_SUCCESS';
export const GET_ALL_DONATIONS_FAILURE = 'GET_ALL_DONATIONS_FAILURE';
export const GET_DONATIONS = 'GET_DONATIONS';
export const GET_DONATIONS_SUCCESS = 'GET_DONATIONS_SUCCESS';
export const GET_DONATIONS_ERROR = 'GET_DONATIONS_ERROR';

export const PATCH_DONATION = 'PATCH_DONATION';
export const PATCH_DONATION_SUCCESS = 'PATCH_DONATION_SUCCESS';
export const PATCH_DONATION_FAILURE = 'PATCH_DONATION_FAILURE';

export const DELETE_DONATION = 'DELETE_DONATION';
export const DELETE_DONATION_SUCCESS = 'DELETE_DONATION_SUCCESS';
export const DELETE_DONATION_FAILURE = 'DELETE_DONATION_FAILURE';

export const GET_ALL_HOLD_ACCOUNTS = 'GET_ALL_HOLD_ACCOUNTS';
export const GET_ALL_HOLD_ACCOUNTS_SUCCESS = 'GET_ALL_HOLD_ACCOUNTS_SUCCESS';
export const GET_ALL_HOLD_ACCOUNTS_FAILURE = 'GET_ALL_HOLD_ACCOUNTS_FAILURE';

export const POST_HOLD_ACCOUNT = 'POST_HOLD_ACCOUNT';
export const POST_HOLD_ACCOUNT_SUCCESS = 'POST_HOLD_ACCOUNT_SUCCESS';
export const POST_HOLD_ACCOUNT_FAILURE = 'POST_HOLD_ACCOUNT_FAILURE';

export const PATCH_HOLD_ACCOUNT = 'PATCH_HOLD_ACCOUNT';
export const PATCH_HOLD_ACCOUNT_SUCCESS = 'PATCH_HOLD_ACCOUNT_SUCCESS';
export const PATCH_HOLD_ACCOUNT_FAILURE = 'PATCH_HOLD_ACCOUNT_FAILURE';

export const DELETE_HOLD_ACCOUNT = 'DELETE_HOLD_ACCOUNT';
export const DELETE_HOLD_ACCOUNT_SUCCESS = 'DELETE_HOLD_ACCOUNT_SUCCESS';
export const DELETE_HOLD_ACCOUNT_FAILURE = 'DELETE_HOLD_ACCOUNT_FAILURE';

export const ARCHIVE_HOLD_ACCOUNT = 'ARCHIVE_HOLD_ACCOUNT';
export const ARCHIVE_HOLD_ACCOUNT_SUCCESS = 'ARCHIVE_HOLD_ACCOUNT_SUCCESS';
export const ARCHIVE_HOLD_ACCOUNT_ERROR = 'ARCHIVE_HOLD_ACCOUNT_ERROR';

export const GET_ALL_DISTRIBUTIONS = 'GET_ALL_DISTRIBUTIONS';
export const GET_ALL_DISTRIBUTIONS_SUCCESS = 'GET_ALL_DISTRIBUTIONS_SUCCESS';
export const GET_ALL_DISTRIBUTIONS_FAILURE = 'GET_ALL_DISTRIBUTIONS_FAILURE';

export const GET_DISTRIBUTION = 'GET_DISTRIBUTION';
export const GET_DISTRIBUTION_SUCCESS = 'GET_DISTRIBUTION_SUCCESS';
export const GET_DISTRIBUTION_FAILURE = 'GET_DISTRIBUTION_FAILURE';

export const SET_DISTRIBUTION = 'SET_DISTRIBUTION';
export const SET_DISTRIBUTION_FORM_USER_ID = 'SET_DISTRIBUTION_FORM_USER_ID';
export const SET_DONATION_UPLOAD = 'SET_DONATION_UPLOAD';
export const SET_HOLD_ACCOUNTS = 'SET_HOLD_ACCOUNTS';
export const ADD_HOLD_ACCOUNT_TRANSACTION = 'ADD_HOLD_ACCOUNT_TRANSACTION';
export const DELETE_HOLD_ACCOUNT_TRANSACTION = 'DELETE_HOLD_ACCOUNT_TRANSACTION';
export const SET_TOTAL_PAYROLL = 'SET_TOTAL_PAYROLL';
export const RESET_DISTRIBUTION_FORM = 'RESET_DISTRIBUTION_FORM';
export const SET_SOCIAL_SECURITY = 'SET_SOCIAL_SECURITY';
export const SET_MEDICARE = 'SET_MEDICARE';
export const SET_FEDERAL_INCOME_TAX = 'SET_FEDERAL_INCOME_TAX';
export const SET_MID_MONTH_DISBURSEMENT = 'SET_MID_MONTH_DISBURSEMENT';
export const SET_SALARY = 'SET_SALARY';
export const APPROVE_ADVANCE_REQUEST = 'APPROVE_ADVANCE_REQUEST';
export const SET_ADVANCE_REQUEST_AMOUNT_APPROVED = 'SET_ADVANCE_REQUEST_AMOUNT_APPROVED';
export const PROCESS_EXPENSE_REPORTS = 'PROCESS_EXPENSE_REPORTS';
export const APPROVE_DONATION = 'APPROVE_DONATION';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const APPROVE_HOLD_ACCOUNT_TRANSACTION = 'APPROVE_HOLD_ACCOUNT_TRANSACTION';
export const DISTRIBUTABLE_FUNDS_CHANGE = 'DISTRIBUTABLE_FUNDS_CHANGE';
export const SUBMIT_DISTRIBUTION = 'SUBMIT_DISTRIBUTION';
export const SET_DISTRIBUTION_COMMENTS = 'SET_DISTRIBUTION_COMMENTS';
export const SET_WIRE_FEE = 'SET_WIRE_FEE';
export const SET_HOUSING_ALLOWANCE = 'SET_HOUSING_ALLOWANCE';
export const SET_INSURANCE_PAYMENT = 'SET_INSURANCE_PAYMENT';
export const SET_MANUAL_MINISTRY_REIMBURSEMENT = 'SET_MANUAL_MINISTRY_REIMBURSEMENT';
export const SET_MANUAL_MINISTRY_ADVANCE = 'SET_MANUAL_MINISTRY_ADVANCE';
export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'SEARCH_USERS_ERROR';

export const POST_DISTRIBUTION = 'POST_DISTRIBUTION';
export const POST_DISTRIBUTION_SUCCESS = 'POST_DISTRIBUTION_SUCCESS';
export const POST_DISTRIBUTION_FAILURE = 'POST_DISTRIBUTION_FAILURE';

export const PATCH_DISTRIBUTION = 'PATCH_DISTRIBUTION';
export const PATCH_DISTRIBUTION_SUCCESS = 'PATCH_DISTRIBUTION_SUCCESS';
export const PATCH_DISTRIBUTION_FAILURE = 'PATCH_DISTRIBUTION_FAILURE';

export const DELETE_DISTRIBUTION = 'DELETE_DISTRIBUTION';
export const DELETE_DISTRIBUTION_SUCCESS = 'DELETE_DISTRIBUTION_SUCCESS';
export const DELETE_DISTRIBUTION_FAILURE = 'DELETE_DISTRIBUTION_FAILURE';

export const POST_BATCH_DISTRIBUTIONS = 'POST_BATCH_DISTRIBUTIONS';
export const POST_BATCH_DISTRIBUTIONS_SUCCESS = 'POST_BATCH_DISTRIBUTIONS_SUCCESS';
export const POST_BATCH_DISTRIBUTIONS_FAILURE = 'POST_BATCH_DISTRIBUTIONS_FAILURE';

export const POST_BATCH_DISTRIBUTIONS_APPROVE = 'POST_BATCH_DISTRIBUTIONS_APPROVE';
export const POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS = 'POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS';
export const POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE = 'POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE';

export const GET_DISTRIBUTIONS = 'GET_DISTRIBUTIONS';
export const GET_DISTRIBUTIONS_SUCCESS = 'GET_DISTRIBUTIONS_SUCCESS';
export const GET_DISTRIBUTIONS_ERROR = 'GET_DISTRIBUTIONS_ERROR';

