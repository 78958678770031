import {getExpenseRequest} from '../api/expenses';

import{
    GET_EXPENSE,
    GET_EXPENSE_SUCCESS,
    GET_EXPENSE_FAILURE
} from './types';


function getExpense(){
    return{
        type: GET_EXPENSE
    }
}

function getExpenseSuccess(json){
    return{
        type: GET_EXPENSE_SUCCESS,
        payload: json
    }
}

function getExpenseFailure(error){
    return{
        type: GET_EXPENSE_FAILURE,
        payload: error
    }
}


export default function requestGetExpense(expense_id, token) {
    return dispatch => {
        dispatch(getExpense());
        return getExpenseRequest(expense_id, token)
            .then((json) => {
                return dispatch(getExpenseSuccess(json));
            })
            .catch((error) => {            
                return error.json().then((errors) => {
                    return dispatch(getExpenseFailure(errors));
                })
            });
    };
}
