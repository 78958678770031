import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';
import { buildUrl } from '../utilities/request-helpers';


export const getAllAdvanceRequestsRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/advance-request',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};

export const getPaginatedAdvanceRequestsRequest = (token, params) => {
  return fetch(
    buildUrl(process.env.REACT_APP_API_ROOT + '/paginated_advance_request', params),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-token': token
      }
    }
  ).then(handleResponse);
};

export const getAdvanceRequestsByUserRequest = (id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/advance-request/user/' + id,
        {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'api-token': token
            },
        }
    ).then(handleResponse);
};

export const patchAdvanceRequestRequest = (id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/advance-request/' + id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const postAdvanceRequestRequest = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/advance-request',
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const deleteAdvanceRequestRequest = (advance_request_id, token) => {
	return fetch(
		process.env.REACT_APP_API_ROOT + '/advance-request/' + advance_request_id, {
			method: 'DELETE',
			headers: {
				"api-token": token
			}
		}
	).then(handleResponse);
};

export const deleteClosedAdvanceRequestRequest = (advance_request_id, token) => {
   return fetch(
		process.env.REACT_APP_API_ROOT + '/advance-request/closed/' + advance_request_id, {
			method: 'DELETE',
			headers: {
				"api-token": token
			}
		}
	).then(handleResponse);
}
