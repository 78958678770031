import React, {Component} from 'react';

class HoldAccountsList extends Component {
   render() {
      const holdAccountsList = this.props.data.map((acct, index) => {
         return(
            <div className="message-list-items" key={index}>
               <div className="hold-account-list-item">
                  <p><strong>Name:</strong> {acct.name}</p>
                  <p><strong>Fund:</strong> {acct.fund_name}</p>
                  <p><strong>Balance:</strong> {acct.balance}</p>
                  <p><strong>Updated: </strong>{acct.date}</p>
                  <hr/>
               </div>         
            </div>
         )
      });

      return (
         <div>
            <br/>
            {holdAccountsList}
         </div>
      )
   }
}

export default HoldAccountsList;