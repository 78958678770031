import React, {Component} from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';
import Icon from '../blocks/icon';
import Subheader from 'material-ui/Subheader';

const buttonStyle = {
   margin: '10px 3px 15px 3px',
}

class DeleteButton extends Component {

    state = {
        open: false,
    }

    handleOpen = () => {
        this.setState({open: true});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    confirmDelete = (event) => {
        event.preventDefault();
        this.handleOpen();
    }

    onConfirm = () => {
        this.handleClose();
        this.props.onClick();
    }


    render() {

        const actions = [
            <RaisedButton
                label="Delete"
                backgroundColor='#e60000'
                labelColor='#ffffff'
                keyboardFocused={true}
                style={buttonStyle}
                onClick={this.onConfirm}
            />,
            <RaisedButton
                label="Cancel"
                backgroundColor='#525252'
                labelColor='#ffffff'
                style={buttonStyle}
                onClick={this.handleClose}
            />,
        ];

        //let modalMessage = this.props.message ? this.props.message : null;

       /*  if (_.get(this.props.message, 'props')) {
            modalMessage = {...this.props.message ? {...this.props.message, ...{props: {...this.props.message.props, ...{handleClose: this.handleClose}}}} : 'This action cannot be undone.'};
        } */

        return (
            <div style={this.props.style}>
                {
                    this.props.icon
                        ?
                        <RaisedButton onClick={(event) => this.confirmDelete(event)} icon={<Icon type='clear'/>} style={{minWidth: 35, cursor: 'pointer'}}/>
                        :
                        <RaisedButton backgroundColor='#e60000' labelColor='#ffffff' icon={this.props.icon ? this.props.icon : null} label={this.props.label ? this.props.label : this.props.icon ? null : "Delete"} onClick={(event) => this.confirmDelete(event)}/>
                }
                <Dialog
                    actions={this.props.noActions ? null: actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    contentStyle={this.props.contentStyle}
                    autoScrollBodyContent={true}
                >
                   <Subheader className="curved-style">{this.props.message}</Subheader>
                    {
                        !!this.props.body && this.props.body
                    }
                </Dialog>
            </div>
        )
    }

}

export default DeleteButton;