import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';

export const postMileageRequest = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/mileage',
        {
            method: 'POST',
            headers: {
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const patchMileageRequest = (mileage_id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/mileage/' + mileage_id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};


export const deleteMileageRequest = (mileage_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/mileage/' + mileage_id,
        {
            method: 'DELETE',
            headers: {
                "api-token": token
            }
        }
    ).then(handleResponse);
};


export const getMileageRequest = (mileage_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/mileage/' + mileage_id,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);
};