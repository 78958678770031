import {getMileageRequest} from '../api/mileage';

import{
    GET_MILEAGE,
    GET_MILEAGE_SUCCESS,
    GET_MILEAGE_FAILURE
} from './types';


function getMileage(){
    return{
        type: GET_MILEAGE
    }
}

function getMileageSuccess(json){
    return{
        type: GET_MILEAGE_SUCCESS,
        payload: json
    }
}

function getMileageFailure(error){
    return{
        type: GET_MILEAGE_FAILURE,
        payload: error
    }
}


export default function requestGetMileage(mileage_id, token) {
    return dispatch => {
        dispatch(getMileage());
        return getMileageRequest(mileage_id, token)
            .then((json) => {
                return dispatch(getMileageSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getMileageFailure(errors));
                })
            });
    };
}
