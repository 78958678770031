import {postMileageRequest} from '../api/mileage';

import{
    POST_MILEAGE,
    POST_MILEAGE_SUCCESS,
    POST_MILEAGE_FAILURE
} from './types';

function postMileage(){
    return{
        type: POST_MILEAGE
    }
}

function postMileageSuccess(json){
    return{
        type: POST_MILEAGE_SUCCESS,
        payload: json
    }
}

function postMileageFailure(error){
    return{
        type: POST_MILEAGE_FAILURE,
        payload: error
    }
}


export default function requestPostMileage(formData, token) {
    return dispatch => {
        dispatch(postMileage());
        return postMileageRequest(formData, token)
            .then((json) => {
                return dispatch(postMileageSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postMileageFailure(errors));
                })
            });
    };
}
