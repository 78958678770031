import {getAllCategoriesRequest} from '../api/categories';

import{
    GET_ALL_CATEGORIES,
    GET_ALL_CATEGORIES_SUCCESS,
    GET_ALL_CATEGORIES_FAILURE
} from './types';

function getAllCategories(){
	return{
      type: GET_ALL_CATEGORIES
   }
}

function getAllCategoriesSuccess(json){
   return{
		type: GET_ALL_CATEGORIES_SUCCESS,
		payload: json
   }
}

function getAllCategoriesFailure(error){
   return{
		type: GET_ALL_CATEGORIES_FAILURE,
		payload: error
   }
}

export default function requestGetAllCategories(token) {
    return dispatch => {
      dispatch(getAllCategories());
      return getAllCategoriesRequest(token)
			.then((json) => {
					return dispatch(getAllCategoriesSuccess(json));
			})
			.catch((error) => {
					return error.json().then((errors) => {
						return dispatch(getAllCategoriesFailure(errors));
					})
			});
    };
}