import {
    NEW_MESSAGE
} from '../actions/types';

function showMessage(message) {
    return {
        type: NEW_MESSAGE,
        message: message
    };
}

export default function newMessage(message) {
    return dispatch => {
        dispatch(showMessage(message));
    };
}