import { loginRequestWithToken } from '../api/users';

import {
    LOGIN_USER,
    LOGIN_USER_WITH_TOKEN_SUCCESS,
    LOGIN_USER_WITH_TOKEN_FAILURE
} from '../actions/types';

function loginUser() {
    return {
        type: LOGIN_USER
    };
}

function loginUserWithTokenSuccess(json) {
    return {
        type: LOGIN_USER_WITH_TOKEN_SUCCESS,
        payload: {
            user: json
        }
    };
}

function loginUserWithTokenFailure(error) {
    return {
        type: LOGIN_USER_WITH_TOKEN_FAILURE,
        payload: error
    };
}

export default function loginWithToken(token) {
    return dispatch => {
        dispatch(loginUser());
        return loginRequestWithToken(token)
            .then((json) => {
                return dispatch(loginUserWithTokenSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(loginUserWithTokenFailure(errors));
                })
            });
    };
}