import React, {Component} from 'react';


export default class DistributionsList extends Component {

   render () {

      const distributionsList = this.props.data.map((distribution, index) => {
         return(
            <div className="message-list-items" key={index}>
               <div className="distributions-list-item">
                  <p><strong>Name:</strong> {distribution.name}</p>
                  <p><strong>From:</strong> {distribution.from}</p>
                  <p><strong>To:</strong> {distribution.to}</p>
                  <p><strong>Ending Balance: </strong>{distribution.endingBalance}</p>
                  <p><strong>Payroll: </strong>{distribution.payroll}</p>
                  <p><strong>Expenses: </strong>{distribution.expenses}</p>
                  <p><strong>Distribution: </strong>{distribution.distribution}</p>
                  <p><strong>Date Added: </strong>{distribution.date}</p>
                  {distribution.buttons}
                  <hr/>
               </div>         
            </div>
         );
      });

      return (
         <div>
            <br/>
            {distributionsList}
         </div>
      );
   }
}