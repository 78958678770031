import React from 'react';
import {
    Step,
    Stepper,
    StepLabel,
} from 'material-ui/Stepper';
import Filters from './Filters';
import Payroll from './Payroll';
import Expenses from './Expenses';
import Approvals from "./Approvals";
import connector from './DistributionForm.connect';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from "material-ui/Subheader";
import HoldAccountDialog from "./HoldAccountsDialog";
import DonationsDialog from "./DonationsDialog";
import Summary from './Summary';
import Distribution from './Distribution';
import {PATCH_DISTRIBUTION_SUCCESS, POST_DISTRIBUTION_SUCCESS} from "../../../actions/types";
import {browserHistory} from 'react-router';
import {withLoading} from "../with-loading";
import {Link} from 'react-router';
import FlatButton from 'material-ui/FlatButton';
import Icon from "../icon";

class DistributionForm extends React.PureComponent {
    state = {
        donationsDialogOpen: false,
        holdAccountsDialogOpen: false,
        holdAccountsDialogTransactionType: null,
        stepIndex: 0,
    };

    componentDidMount() {
        this.props.getAllSettings().then(() => {
            const { id } = this.props;
            if (id && id.length) this.props.rebuildDistribution(id);
            else this.props.resetDistributionForm();
        });
    }

    componentDidUpdate(prevProps) {
        const { id: prevDistId } = prevProps,
          { id: currDistId } = this.props;
        if (prevDistId && prevDistId.length && !currDistId) this.props.resetDistributionForm();
        else if (!prevDistId && currDistId && currDistId.length) this.props.rebuildDistribution(currDistId);
    }

    componentWillUnmount() {
        this.props.resetDistributionForm();
    }

    stepContent = () => {
        const { stepIndex } = this.state;
        switch (stepIndex) {
            case 0:
                return <Filters
                    users={this.props.users}
                    onUserSelect={this.props.setDistributionFormUserId}
                    user={this.props.user}
                    donationUpload={this.props.donationUpload}
                    onDonationUploadSelect={this.props.setDonationUpload}
                    onSetDateRange={this.props.setDateRange}
                    resetDistributionForm={this.props.resetDistributionForm}
                    distributionId={this.props.id}
                    readOnly={this.props.readOnly}
                />;
            case 1:
                return <Payroll
                    user={this.props.user}
                    readOnly={this.props.readOnly}
                />;
            case 2:
                return <Approvals readOnly={this.props.readOnly} />;
            case 3:
                return <Expenses readOnly={this.props.readOnly} />;
            case 4:
                return <Distribution
                  openHoldAccountsDialog={() =>
                    this.setState({ holdAccountsDialogOpen: true, holdAccountsDialogTransactionType: 'Withdrawal' })
                  }
                  readOnly={this.props.readOnly}
                />;
            default:
                return null;
        }
    };

    nextStep = () => {
        const { stepIndex } = this.state;
        if (stepIndex < 4) this.setState({ stepIndex: stepIndex + 1 });
        else if (!this.props.readOnly) this.props.submitDistribution().then(res => {
            const { type } = res || {};
            if (type === POST_DISTRIBUTION_SUCCESS) {
                this.props.resetDistributionForm();
                this.props.newMessage('Distribution saved!');
                browserHistory.push('/admin/distributions/pending');
            }
            else if (type === PATCH_DISTRIBUTION_SUCCESS) {
                this.props.resetDistributionForm();
                this.props.newMessage('Distribution updated!');
                browserHistory.push('/admin/distributions/pending');
            }
            else this.props.newError('An error occurred!');
        });
    };

    prevStep = () => {
        const { stepIndex } = this.state;
        this.setState({ stepIndex: stepIndex - 1 });
    };

    stepDisabled = () => {
        const { stepIndex } = this.state;
        const { completeSteps, readOnly } = this.props;
        const { [stepIndex]: complete } = completeSteps;
        return !complete || (readOnly && stepIndex === Object.keys(completeSteps).length - 1);
    };

    hideNext = () => {
        const { readOnly, completeSteps } = this.props;
        const { stepIndex } = this.state;
        return readOnly && stepIndex === Object.keys(completeSteps).length - 1;
    };

    setHoldAccountsDialogOpen = val => this.setState({ holdAccountsDialogOpen: !!val });

    backLink = () => {
        const { user } = this.props,
          { id, role } = user || {};
        if (role !== 'missionary') return null;
        return (
          <Link
            to={`/profile/${id}`}
          >
              <FlatButton
                label="Back"
                labelPosition="after"
                style={{ color: 'white', marginRight: 20 }}
                icon={<Icon type="arrow_back" color="white" />}
              />
          </Link>
        );
    };

    render = () => {
        const { stepIndex } = this.state;
        return (
          <div className="list-view">
              <Subheader className="table-style">
                  {
                      this.backLink()
                  }
                  Distribution Form
              </Subheader>
              <div className="distribution-form">
                  <div className="summary">
                      <Summary
                        openDonationsDialog={() => this.setState({ donationsDialogOpen: true })}
                        openHoldAccountTransfersDialog={() => this.setState({
                            holdAccountsDialogOpen: true,
                            holdAccountsDialogTransactionType: 'Transfer',
                        })}
                        openHoldAccountWithdrawalsDialog={() => this.setState({
                            holdAccountsDialogOpen: true,
                            holdAccountsDialogTransactionType: 'Withdrawal',
                        })}
                        openHoldAccountDepositsDialog={() => this.setState({
                            holdAccountsDialogOpen: true,
                            holdAccountsDialogTransactionType: 'Deposit',
                        })}
                        { ...this.props }
                      />
                  </div>
                  <div className="step-content">
                      {
                          this.stepContent()
                      }
                  </div>
                  <Stepper activeStep={stepIndex}>
                      <Step>
                          <StepLabel>Select a missionary</StepLabel>
                      </Step>
                      <Step>
                          <StepLabel>Customize payroll</StepLabel>
                      </Step>
                      <Step>
                          <StepLabel>Approvals</StepLabel>
                      </Step>
                      <Step>
                          <StepLabel>Expenses</StepLabel>
                      </Step>
                      <Step>
                          <StepLabel>Distribution</StepLabel>
                      </Step>
                  </Stepper>
                  <div className="buttons-container">
                      <FlatButton
                        label="Back"
                        disabled={stepIndex === 0}
                        onClick={this.prevStep}
                      />
                      {
                          !this.hideNext() &&
                          <RaisedButton
                            label={stepIndex < 4 ? 'Next' : 'Submit'}
                            primary={true}
                            onClick={this.nextStep}
                            disabled={this.stepDisabled()}
                          />
                      }
                  </div>
              </div>
              <HoldAccountDialog
                transactionType={this.state.holdAccountsDialogTransactionType}
                open={this.state.holdAccountsDialogOpen}
                handleClose={() => this.setHoldAccountsDialogOpen(false)}
                readOnly={this.props.readOnly}
              />
              <DonationsDialog
                open={this.state.donationsDialogOpen}
                onClose={() => this.setState({ donationsDialogOpen: false })}
                readOnly={this.props.readOnly}
              />
          </div>
        )
    }
}

export default connector(withLoading(DistributionForm));
