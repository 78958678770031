import {getExpenseReportRequest} from '../api/expense-reports';

import{
    GET_EXPENSE_REPORT,
    GET_EXPENSE_REPORT_SUCCESS,
    GET_EXPENSE_REPORT_FAILURE
} from './types';


function getExpenseReport(){
    return{
        type: GET_EXPENSE_REPORT
    }
}

function getExpenseReportSuccess(json){
    return{
        type: GET_EXPENSE_REPORT_SUCCESS,
        payload: json
    }
}

function getExpenseReportFailure(error){
    return{
        type: GET_EXPENSE_REPORT_FAILURE,
        payload: error
    }
}


export default function requestGetExpenseReport(expense_report_id, token) {
    return dispatch => {
        dispatch(getExpenseReport());
        return getExpenseReportRequest(expense_report_id, token)
            .then((json) => {
                return dispatch(getExpenseReportSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getExpenseReportFailure(errors));
                })
            });
    };
}
