import { archiveHoldAccountRequest } from "../api/hold-accounts";
import {
  ARCHIVE_HOLD_ACCOUNT,
  ARCHIVE_HOLD_ACCOUNT_ERROR,
  ARCHIVE_HOLD_ACCOUNT_SUCCESS,
} from '../actions/types';

export default function requestArchiveHoldAccount(id, token) {
  return dispatch => {
    dispatch({ type: ARCHIVE_HOLD_ACCOUNT });
    return archiveHoldAccountRequest(id, token)
      .then((json) => {
        return dispatch({ type: ARCHIVE_HOLD_ACCOUNT_SUCCESS, payload: json });
      })
      .catch((error) => {
        console.log(error);
        return dispatch({ type: ARCHIVE_HOLD_ACCOUNT_ERROR, payload: error });
      });
  };
}
