import React from 'react';
import { connect } from 'react-redux';
import {titleCase} from "../../../utilities/string-helpers";
import {
    selectAdvanceRequests, selectHoldAccountAdvanceRequests, selectHoldAccounts,
    selectRecurringHoldAccountTransactions,
    selectUploadHoldAccountDonations
} from "../../../reducers/distribution-form";
import {formatCurrency} from "../../../utilities/currency-helpers";
import { approveDonation, approveHoldAccountTransaction, approveAdvanceRequest } from "../../../actions/distribution-form";
import DataTables from 'material-ui-datatables';
import Subheader from 'material-ui/Subheader';

class Approvals extends React.PureComponent {
    getApprovals = (selectedRows, collection) => {
        if (selectedRows === 'none') selectedRows = [];
        const selectedIds = selectedRows === 'all'
          ? collection.map(x => x.id)
          : selectedRows.map(idx => collection[idx].id);
        const toApprove = [],
          toUnApprove = [];
        (collection || []).forEach(x => {
            const { id, approved } = x;
            if (selectedIds.includes(id) && !approved) toApprove.push(id);
            else if (approved && !selectedIds.includes(id)) toUnApprove.push(id);
        });
        return { toApprove, toUnApprove };
    };

    _selectedRows = collection => (collection || [])
      .reduce((acc, x, idx) => (x.approved ? [ ...acc, idx ] : acc), []);

    buildTable = (title, data, columns, onRowSelect, selectedRows) => {
        const { readOnly } = this.props;
        return (
          <div style={{ marginTop: 15, padding: '0 2%' }}>
              <Subheader>{ title }</Subheader>
              <DataTables
                data={data}
                columns={columns}
                showFooterToolbar={false}
                selectable={!readOnly}
                showCheckboxes={!readOnly}
                enableSelectAll={!readOnly}
                multiSelectable={!readOnly}
                onRowSelection={onRowSelect}
                selectedRows={selectedRows}
              />
          </div>
        )
    };

    onApproveDonation = selectedRows => {
        const { holdAccountDonations = [], readOnly } = this.props;
        if (readOnly) return;
        const { toApprove, toUnApprove } = this.getApprovals(selectedRows, holdAccountDonations);
        toApprove.forEach(id => this.props.approveDonation(id, true));
        toUnApprove.forEach(id => this.props.approveDonation(id, false));
    };

    donationsTable = () => {
        const { holdAccountDonations } = this.props;
        if (!holdAccountDonations || !holdAccountDonations.length) return null;
        const rows = (holdAccountDonations || []).map(x => ({
            holdAccount: x.hold_account_name,
            amountRequested: formatCurrency(x.amount || 0),
        }));
        const cols = [
            {
                key: 'holdAccount',
                label: 'Hold Account',
                sortable: false
            },
            {
                key: 'amountRequested',
                label: 'Amount Donated',
                sortable: false
            },
        ];
        return this.buildTable("Hold Account Donations", rows, cols, this.onApproveDonation, this._selectedRows(holdAccountDonations));
    };

    onApproveTransaction = selectedRows => {
        const { recurringTransactions = [], readOnly } = this.props;
        if (readOnly) return;
        const { toApprove, toUnApprove } = this.getApprovals(selectedRows, recurringTransactions);
        toApprove.forEach(id => {
            const transaction = recurringTransactions.find(x => x.id === id);
            const { accountId, type } = transaction;
            this.props.approveHoldAccountTransaction(accountId, id, type, true);
        });
        toUnApprove.forEach(id => {
            const transaction = recurringTransactions.find(x => x.id === id);
            const { accountId, type } = transaction;
            this.props.approveHoldAccountTransaction(accountId, id, type, false);
        });
    };

    recurringTransactionsTable = () => {
        const { recurringTransactions } = this.props;
        if (!recurringTransactions || !recurringTransactions.length) return null;
        const rows = (recurringTransactions || []).map(x => ({
            type: titleCase(x.type),
            holdAccount: x.holdAccountName,
            amountRequested: formatCurrency(x.amount),
            amountApproved: formatCurrency(x.amount_approved || 0),
        }));
        const cols = [
            {
                key: 'type',
                label: 'Type',
                sortable: false
            },
            {
                key: 'holdAccount',
                label: 'Hold Account',
                sortable: false
            },
            {
                key: 'amountRequested',
                label: 'Amount Requested',
                sortable: false
            },
            {
                key: 'amountApproved',
                label: 'Amount Approved',
                sortable: false
            },
        ];
        return this.buildTable(
          "Recurring Hold Account Transactions",
          rows,
          cols,
          this.onApproveTransaction,
          this._selectedRows(recurringTransactions)
        );
    };

    onApproveAdvanceRequest = selectedRows => {
        const { advanceRequests = [], holdAccountAdvanceRequests = [], readOnly } = this.props;
        if (readOnly) return;
        const collection = [ ...(advanceRequests || []), ...(holdAccountAdvanceRequests || []) ];
        const { toApprove, toUnApprove } = this.getApprovals(selectedRows, collection);
        toApprove.forEach(id => {
            const holdAcctReq = holdAccountAdvanceRequests.find(x => x.id === id);
            if (holdAcctReq) {
                const { accountId, type } = holdAcctReq;
                this.props.approveHoldAccountTransaction(accountId, id, type, true);
            }
            else this.props.approveAdvanceRequest(id, true);
        });
        toUnApprove.forEach(id => {
            const holdAcctReq = holdAccountAdvanceRequests.find(x => x.id === id);
            if (holdAcctReq) {
                const { accountId, type } = holdAcctReq;
                this.props.approveHoldAccountTransaction(accountId, id, type, false);
            }
            else this.props.approveAdvanceRequest(id, false);
        });
    };

    advanceRequestsTable = () => {
        const { advanceRequests, holdAccounts, holdAccountAdvanceRequests } = this.props;
        const collection = [ ...(advanceRequests || []), ...(holdAccountAdvanceRequests || []) ];
        if (!collection.length) return null;
        const rows = [
          ...(advanceRequests || []).map(x => ({
              fundName: x.hold_account_id ? (holdAccounts || {})[x.hold_account_id].fund_name : 'General Fund',
              amountRequested: formatCurrency(x.amount_requested || 0),
              amountApproved: formatCurrency(x.amount_approved || 0)
          })),
          ...(holdAccountAdvanceRequests || []).map(x => ({
              fundName: (holdAccounts || {})[x.accountId].fund_name,
              amountRequested: formatCurrency(x.amount || 0),
              amountApproved: formatCurrency(x.amount_approved || 0)
          })),
        ];
        const cols = [
            {
                key: 'fundName',
                label: 'Fund Name',
                sortable: false
            },
            {
                key: 'amountRequested',
                label: 'Amount Requested',
                sortable: false
            },
            {
                key: 'amountApproved',
                label: 'Amount Approved',
                sortable: false
            },
        ];
        return this.buildTable("Advance Requests", rows, cols, this.onApproveAdvanceRequest, this._selectedRows(collection));
    };

    render = () => {
        return (
            <div>
                {
                    this.donationsTable()
                }
                {
                    this.recurringTransactionsTable()
                }
                {
                    this.advanceRequestsTable()
                }
            </div>
        )
    }
}

export default connect(state => {
    const sortedDonations = Object.values(selectUploadHoldAccountDonations(state) || {})
        .sort((a, b) => a.id - b.id);
    return {
        holdAccountDonations: sortedDonations,
        recurringTransactions: selectRecurringHoldAccountTransactions(state).sort((a, b) => a.id - b.id),
        advanceRequests: Object.values(selectAdvanceRequests(state) || {}).filter(x => !x.hold_account_id).sort((a, b) => a.id - b.id),
        holdAccountAdvanceRequests: selectHoldAccountAdvanceRequests(state).sort((a, b) => a.id - b.id),
        holdAccounts: selectHoldAccounts(state),
    };
}, {
    approveDonation,
    approveHoldAccountTransaction,
    approveAdvanceRequest,
})(Approvals);
