import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';

export const getAllHoldAccountsRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/hold-account',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};

export const postHoldAccountRequest = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/hold-account',
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const patchHoldAccountRequest = (id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/hold-account/' + id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const deleteHoldAccountRequest = (id, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/hold-account/' + id, {
         method: 'DELETE',
         headers: {
            "api-token": token
         }
      }
   ).then(handleResponse);
};

export const archiveHoldAccountRequest = (id, token) => {
  return fetch(
    process.env.REACT_APP_API_ROOT + '/hold-account/' + id + '/archive', {
      method: 'GET',
      headers: {
        "api-token": token
      }
    }
  ).then(handleResponse);
};
