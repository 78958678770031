import React from 'react';
import TextField from 'material-ui/TextField';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

function CustomTextField({ inputSuffix, containerStyle, ...props }) {
    return <div className="form-group" style={containerStyle || {}}>
        <TextField
            className={props.disabled ? 'custom-disabled' : ''}
            { ...props }
        />
        {
            !!inputSuffix && <span>{ inputSuffix }</span>
        }
    </div>
}

export default ({ onChange, debounceMs, ...props }) => {
    const debouncedOnChange = _.debounce(values => {
        if (onChange && props.value !== values.floatValue) onChange(values.floatValue);
    }, debounceMs || 0);

    return <NumberFormat
        onValueChange={debouncedOnChange}
        thousandSeparator
        isNumericString
        decimalScale={2}
        fixedDecimalScale={true}
        prefix="$"
        customInput={CustomTextField}
        allowEmptyFormatting={true}
        { ...props }
    />;
}
