import {getAllExpenseReportsByUserRequest} from '../api/expense-reports';

import{
    GET_ALL_EXPENSE_REPORTS_BY_USER,
    GET_ALL_EXPENSE_REPORTS_BY_USER_SUCCESS,
    GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE
} from './types';


function getAllExpenseReportsByUser(){
    return{
        type: GET_ALL_EXPENSE_REPORTS_BY_USER
    }
}

function getAllExpenseReportsByUserSuccess(json){
    return{
        type: GET_ALL_EXPENSE_REPORTS_BY_USER_SUCCESS,
        payload: json
    }
}

function getAllExpenseReportsByUserFailure(error){
    return{
        type: GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE,
        payload: error
    }
}


export default function requestGetAllExpenseReportsByUser(user_id, token) {
    return dispatch => {
        dispatch(getAllExpenseReportsByUser());
        return getAllExpenseReportsByUserRequest(user_id, token)
            .then((json) => {
                return dispatch(getAllExpenseReportsByUserSuccess(json));
            })
            .catch((error) => {
            console.log(error);
                return error.json().then((errors) => {
                    return dispatch(getAllExpenseReportsByUserFailure(errors));
                })
            });
    };
}
