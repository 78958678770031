import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {Router, Route, browserHistory, IndexRoute} from 'react-router';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {syncHistoryWithStore, routerReducer} from 'react-router-redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import Cookies from 'cookies-js';
import rootReducer from './reducers';
import injectTapEventPlugin from 'react-tap-event-plugin';


//import our components
import App from './containers/App';
import MissionaryApp from './containers/MissionaryApp';
import AdminApp from './containers/AdminApp';
import AuthApp from './containers/AuthApp';
import Login from './components/pages/Login';
import ResetPassword from './components/pages/ResetPassword';
import ForgotPassword from './components/pages/ForgotPassword';
import AdminDashboard from './components/pages/AdminDashboard';
import AdminUserProfile from './components/pages/AdminUserProfile';
import MissionaryDashboard from './components/pages/MissionaryDashboard';
import MissionaryNewReport from './components/pages/MissionaryNewReport';
import MissionaryEditReport from './components/pages/MissionaryEditReport';
import MissionaryViewExpenseReport from './components/pages/MissionaryViewExpenseReport';
import MissionaryAdvanceRequests from './components/pages/MissionaryAdvanceRequest';
import AdminActiveUsers from './components/pages/AdminActiveUsers';
import AdminArchivedUsers from './components/pages/AdminArchivedUsers';
import MissionaryNewAdvanceReport from './components/pages/MissionaryNewAdvanceReport';
import MissionaryEditAdvanceReport from './components/pages/MissionaryEditAdvanceReport';
import ReimbursementDescription from './components/pages/ReimbursementDescription';
import AdvanceDescription from './components/pages/AdvanceDescription';
import MileageDescription from './components/pages/MileageDescription';
import Settings from './components/pages/Settings';
import DenariUpload from './components/pages/DenariUpload';
import AdminDonations from './components/pages/AdminDonations';
import MissionaryProfile from './components/pages/MissionaryProfile';
import AdminHoldAccounts from './components/pages/AdminHoldAccounts';
import AdminDistributions from './components/pages/AdminDistributions';
import DistributionFormManager from './components/pages/DistributionFormManager';

/*Create*/
import CreateUser from './components/pages/CreateUser';

/*Expense Reports*/
import ProcessedExpenseReports from './components/pages/ProcessedExpenseReports';
import UnprocessedExpenseReports from './components/pages/UnprocessedExpenseReports';
import UnprocessedAdvanceRequests from './components/pages/UnprocessedAdvanceRequest';
import AdvanceRequests from './components/pages/AdvanceRequests';

/*Reports*/
import Reports from './components/reports/Reports';






// Create an enhanced history that syncs navigation events with the store

// Add the reducer to your store on the `routing` key

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    combineReducers({
        rootReducer,
        routing: routerReducer,
    }), {}, composeEnhancers(
        applyMiddleware(thunk)
    )
);


//create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

// we need this to prevent tap event console errors
injectTapEventPlugin();

// set up our routes
function getRoutes(store) {

    //set up our auth functions
    function requireAuth(nextState, replace) {
        const token = Cookies.get('token');
        if (!token) {
            replace({
                pathname: '/login'
            })
        }
    }

    function requireEmployeeUp(nextState, replace) {
        const state = store.getState();
        const role = _.get(state.rootReducer.auth, 'user.role');
        // if there is a role, then check against it
        if (role) {
            if (role !== 'admin' && role !== 'employee') {
                replace({
                    pathname: '/'
                })
            }
        } else {
            // if no role is yet set in state, check to see if they have a token or not
            // TODO: this is more for dev, we should take this out at some point
            const token = Cookies.get('token');
            if (!token) {
                replace({
                    pathname: '/login'
                })
            }
        }

    }

    function requireMissionaryOnly(nextState, replace) {
        const state = store.getState();
        const role = _.get(state.rootReducer.auth, 'user.role');
        // if there is a role, then check against it
        if (role) {
            if (role !== 'missionary') {
                replace({
                    pathname: '/'
                })
            }
        } else {
            // if no role is yet set in state, check to see if they have a token or not
            // TODO: this is more for dev, we should take this out at some point
            const token = Cookies.get('token');
            if (!token) {
                replace({
                    pathname: '/login'
                })
            }
        }
    }


    return [
        <Route key='main-app' path="/" component={App}>
            {/* user routes */}
            <IndexRoute component={Login}/>
            <Route path='/login' component={Login}/>
            <Route path="/reset-password/:token" component={ResetPassword}/>
            <Route path='/forgot-password' component={ForgotPassword}/>
        </Route>,
        <Route key='missionary-app' path="/" component={MissionaryApp}>
            {/* missionary routes */}
            <Route path="/dashboard/:auth_id(/:forceUpdate)" onEnter={requireMissionaryOnly} component={MissionaryDashboard}/>
            <Route path="/new-report/:type" onEnter={requireMissionaryOnly} component={MissionaryNewReport}/>
            <Route path="/new-report/advance/:advance_request_id" onEnter={requireMissionaryOnly} component={MissionaryNewAdvanceReport}/>
            <Route path="/expense-report/:expense_report_id/edit" onEnter={requireMissionaryOnly} component={MissionaryEditReport}/>
            <Route path="/expense-report/:expense_report_id/advance/:advance_request_id/edit" onEnter={requireMissionaryOnly} component={MissionaryEditAdvanceReport}/>
            <Route path="/advance-request" onEnter={requireMissionaryOnly} component={MissionaryAdvanceRequests}/>
            <Route path="/expense-descriptions/mileage" onEnter={requireMissionaryOnly} component={MileageDescription}/>
            <Route path="/expense-descriptions/reimbursement" onEnter={requireMissionaryOnly} component={ReimbursementDescription}/>
            <Route path="/expense-descriptions/advance" onEnter={requireMissionaryOnly} component={AdvanceDescription}/>
            <Route path="/profile/:user_id" onEnter={requireMissionaryOnly} component={MissionaryProfile}/>
            <Route path="/distribution-form(/:id)" component={DistributionFormManager} onEnter={requireAuth} />
        </Route>,
        <Route key='admin-app' path="/" component={AdminApp}>
            <Route path="/admin/user/create" onEnter={requireEmployeeUp} component={CreateUser}/>
            <Route path="/admin/users" onEnter={requireEmployeeUp} component={AdminActiveUsers}/>
            <Route path="/admin/users/archived" onEnter={requireEmployeeUp} component={AdminArchivedUsers}/>
            <Route path="/admin/user/:user_id/profile" onEnter={requireEmployeeUp} component={AdminUserProfile}/>
            <Route path="/admin/dashboard" onEnter={requireEmployeeUp} component={AdminDashboard}/>
            <Route path="/admin/expense-reports/processed" onEnter={requireEmployeeUp} component={ProcessedExpenseReports}/>
            <Route path="/admin/expense-reports/unprocessed" onEnter={requireEmployeeUp} component={UnprocessedExpenseReports}/>
            <Route path="/admin/advance-requests/unprocessed" onEnter={requireEmployeeUp} component={UnprocessedAdvanceRequests}/>
            <Route path="/admin/advance-requests" onEnter={requireEmployeeUp} component={AdvanceRequests}/>
            <Route path="/reports" component={Reports} onEnter={requireEmployeeUp}/>
            <Route path="/admin/settings" component={Settings} onEnter={requireEmployeeUp}/>
            <Route path="/admin/denari-import" component={DenariUpload} onEnter={requireEmployeeUp}/>
            <Route path="/admin/donations" component={AdminDonations} onEnter={requireEmployeeUp}/>
            <Route path="/admin/distributions/:status" component={AdminDistributions} onEnter={requireEmployeeUp}/>
            <Route path="/admin/hold-accounts" component={AdminHoldAccounts} onEnter={requireEmployeeUp}/>
            <Route path="/admin/distribution-form(/:id)" component={DistributionFormManager} onEnter={requireEmployeeUp} />
        </Route>,
        <Route key='auth-app' path="/" component={AuthApp}>
            <Route path="/expense-report/:expense_report_id" onEnter={requireAuth} component={MissionaryViewExpenseReport}/>
        </Route>
    ]
}

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            {getRoutes(store)}
        </Router>
    </Provider>,
    document.getElementById('root')
)
;
