// import our reducers here
import {reducer as auth} from './auth';
import {reducer as users} from './users';
import {reducer as archivedUsers} from './archived-users';
import {reducer as expenseReports} from './expense-reports';
import {reducer as messages} from './messages';
import {reducer as advanceRequests} from './advance-requests';
import {reducer as settings} from './settings';
import {reducer as categories} from './categories';
import {reducer as donations} from './donations';
import {reducer as distributions} from './distributions';
import {reducer as distributionForm} from './distribution-form';


import {combineReducers} from 'redux';

import {
    LOGOUT_USER
} from '../actions/types';

const appReducer = combineReducers({
	auth,
	users,
	expenseReports,
	messages,
	advanceRequests,
	settings,
	categories,
	donations,
	distributions,
	archivedUsers,
	distributionForm
});



const rootReducer = (state, action) => {
   if (action.type === LOGOUT_USER) {
      return appReducer({}, action)
   } else {
      return appReducer(state, action)
   }
};


export default rootReducer;
