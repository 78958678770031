import {saveExpenseReportRequest} from '../api/expense-reports';

import{
    SAVE_EXPENSE_REPORT,
    SAVE_EXPENSE_REPORT_SUCCESS,
    SAVE_EXPENSE_REPORT_FAILURE
} from './types';


function saveExpenseReport(){
    return{
        type: SAVE_EXPENSE_REPORT
    }
}

function saveExpenseReportSuccess(json){
    return{
        type: SAVE_EXPENSE_REPORT_SUCCESS,
        payload: json
    }
}

function saveExpenseReportFailure(error){
    return{
        type: SAVE_EXPENSE_REPORT_FAILURE,
        payload: error
    }
}


export default function requestSaveExpenseReport(expense_report_id, formData, token) {
    return dispatch => {
        dispatch(saveExpenseReport());
        return saveExpenseReportRequest(expense_report_id, formData, token)
            .then((json) => {
                return dispatch(saveExpenseReportSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(saveExpenseReportFailure(errors));
                })
            });
    };
}