import React, {Component} from 'react';
import {ListItem} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Avatar from 'material-ui/Avatar';
import {grey400, darkBlack} from 'material-ui/styles/colors';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import {
    PATCH_MILEAGE_SUCCESS,
    PATCH_MILEAGE_FAILURE,
    DELETE_MILEAGE_SUCCESS,
    DELETE_MILEAGE_FAILURE
} from '../../actions/types';
import {connect} from 'react-redux';
import Input from '../fields/input';
import DropDownSelect from '../fields/dropdown-select-field';
import MileageIcon from '../../assets/images/mileage_icon@2x.png';
import DatePickerInput from '../fields/date-picker-input';
import {Link} from 'react-router';
import expenseOptions from '../../utilities/expenseOptions';


class MileageListItems extends Component {

   constructor(props) {
      super(props);
      this.state = {
         open_edit_mileage: false,
         open_delete_mileage: false,
         odo_start: '',
         odo_end: '',
         mileage_type: '',
         total_cost: '',
         total_distance: '',
      };
   }

   componentDidMount() { 
      this.props.dispatch(Actions.requestGetAllCategories(Cookies.get('token')));
   }

   handleOpenDeleteMileage = () => {
      this.setState({open_delete_mileage: true});
   };

   handleCloseMileage = () => {
      this.setState({open_edit_mileage: false});
      this.setState({open_delete_mileage: false});
      this.setState({odo_start: null});
      this.setState({odo_end: null});
   };

   handleOpenEditMileage = () => {
      this.setState({
         open_edit_mileage: true,
         odo_start: this.props.mileage.odometer_start,
         odo_end: this.props.mileage.odometer_end,
         mileage_type: this.props.mileage.type,
      }, () => {
         this.updateDateInput(this.props.mileage.date);
         this.computeMileage();
      });
   };

   computeMileage = () => {
      //calculate mileage reimbursement per mile. If km, convert to mi first.
      let total_distance = this.state.odo_end - this.state.odo_start;
      let total_cost = 0;
      let mileageReimbursement = this.props.mileageReimbursement;

      // eslint-disable-next-line
      if (this.props.distanceType == 'km') {
         let mi_conversion = total_distance * 0.621371;
         if (this.state.odo_end >= 0 && this.state.odo_start >= 0) {
            total_cost = mi_conversion * mileageReimbursement;
         }
      } else {
         if (this.state.odo_end >= 0 && this.state.odo_start >= 0 ) {
            total_cost = total_distance * mileageReimbursement;
         }
      }

      this.setState({
         total_cost: total_cost.toFixed(2),
         total_distance: total_distance  
      });
   }

   updateDateInput(date) {
      const dateInput = document.getElementById('date');
      if (dateInput) {
         dateInput.value = date;
      } else {
         setTimeout(() => {
            this.updateDateInput(date)
         }, 300)
      }
   }


   patchMileage(e) {
      e.preventDefault();
      const form = document.getElementById('update-mileage-form');
      let formData = new FormData(form);
      const that = this;
      this.props.dispatch(Actions.requestPatchMileage(this.props.mileage_id, formData, Cookies.get('token')))
         .then(function (result) {
               if (result.type === PATCH_MILEAGE_SUCCESS) {
                  that.props.dispatch(Actions.newMessage('You have successfully updated this mileage expense item'));
                  that.handleCloseMileage();
               } else if (result.type === PATCH_MILEAGE_FAILURE) {
                  that.props.dispatch(Actions.newError('Something went wrong.'));
                  that.handleCloseMileage();

               }
         })
   }


   deleteMileage(e) {
      e.preventDefault();
      const that = this;
      this.props.dispatch(Actions.requestDeleteMileage(this.props.mileage_id, Cookies.get('token')))
         .then(function (result) {
               if (result.type === DELETE_MILEAGE_SUCCESS) {
                  that.props.dispatch(Actions.newMessage('You have successfully deleted this mileage expense item'));
                  that.handleCloseMileage();
               } else if (result.type === DELETE_MILEAGE_FAILURE) {
                  that.props.dispatch(Actions.newError('Something went wrong.'));
                  that.handleCloseMileage();
               }
         })
   }

   odoStart(event) {
      this.setState({odo_start: event}, () => {
         this.computeMileage();
      });
   }

   odoEnd(event) {
      this.setState({odo_end: event}, () => {
         this.computeMileage();
      });
      this.setState({odo_start: event});
   }

   options = (type) => {	
      const categories = this.props.categories.categories;
      return expenseOptions(categories, type);
   };

   render() {

      const iconButtonElement = (
         <IconButton
               touch={true}
               tooltip="more"
               tooltipPosition="bottom-left"
         >
               <MoreVertIcon color={grey400}/>
         </IconButton>
      );


      const rightIconMenu = (
         <IconMenu iconButtonElement={iconButtonElement}>
               <MenuItem onClick={this.handleOpenEditMileage}>Edit</MenuItem>
               <MenuItem onClick={this.handleOpenDeleteMileage}>Delete</MenuItem>
         </IconMenu>
      );


      //buttons for mileage items
      const actions_edit_mileage = [
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Cancel"
            onClick={this.handleCloseMileage} 
            style={{marginRight: 3}}                      
         />,
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#1E88E5'}
            label="Update"
            onClick={(event) => this.patchMileage(event)}                       
         />
      ];

      //buttons for delete mileage items
      const actions_delete_mileage = [
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Cancel"
            onClick={this.handleCloseMileage} 
            style={{marginRight: 3}}                      
         />,
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#d40a0a'}
            label="Delete"
            onClick={(event) => this.deleteMileage(event)} 
            style={{marginRight: 3}}                      
         />
      ];

      return (
         <div>
               <div className="message-list-items">
                  <ListItem
                     className="list-item-style"
                     leftAvatar={<Avatar src={MileageIcon}/>}
                     // eslint-disable-next-line
                     rightIconButton={this.props.view_only == true ? null : rightIconMenu}
                     primaryText={<div className="dollar_amount">{this.props.primaryText}</div>}
                     secondaryText={
                           <p>
                              <span style={{color: darkBlack}}>{this.props.secondaryText}</span><br />
                              {this.props.tertiaryText}
                           </p>
                     }
                     secondaryTextLines={2}
                  />
                  <Divider inset={true}/>
               </div>
               <Dialog
                  modal={true}
                  actions={actions_edit_mileage}
                  open={this.state.open_edit_mileage}
                  contentStyle={{width: '90%'}}
                  autoScrollBodyContent={true}
                  actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
               >
                  <Subheader className="curved-style">Edit Mileage Expense Entry"</Subheader>
                  <form id="update-mileage-form" onSubmit={(event) => this.patchMileage(event)}
                        encType="multipart/form-data" style={{margin: '0 auto'}}>
                     <Input className="inline-inputs" id="odometer_start" floatingLabelText="Odometer Start"
                              errors={this.props.mileage} onChange={(event) => this.odoStart(event)}
                              value={this.state.odo_start}/>
                     <Input className="inline-inputs" id="odometer_end" floatingLabelText="Odometer End"
                              errors={this.props.mileage} onChange={(event) => this.odoEnd(event)}
                              value={this.state.odo_end}/>
                     <Input disabled={true} className="inline-inputs" id="total_distance"
                              floatingLabelText="Total Distance"
                              errors={this.props.mileage} value={this.state.total_distance}/>
                     <input type="hidden" name="total_distance" id="total_distance"
                              value={this.state.total_distance}/>
                     <DatePickerInput
                           className="inline-inputs"
                           id="date"
                           floatingLabelText="Date *"
                           errors={this.props.expenseReports}
                     />
                     <Input className="inline-inputs" id="total_cost" floatingLabelText="Calculated Amount"
                              errors={this.props.mileage} value={this.state.total_cost} prefix="$"/>
                     <input type="hidden" name="total_cost" id="total_cost"
                              value={this.state.total_cost}/>
                     {/* add type dropdown once created in the migrators*/}
                     <div className="inline-inputs">
                           <DropDownSelect style={{marginBottom: '5px'}} id="type"
                                          floatingLabelText="Expense Type"
                                          errors={this.props.expenseReports} options={this.options('mileage')}
                                          value={this.state.mileage_type}/>
                           <Link className="type-descriptions" target="_blank"
                                 to={'/expense-descriptions/mileage'}>View Type Descriptions</Link>
                           <input type="hidden" name="expense_report_id" id="expense_report_id"
                                 value={this.props.expense_report_id}/>
                     </div>
                  </form>
               </Dialog>
               <Dialog
                  modal={true}
                  actions={actions_delete_mileage}
                  open={this.state.open_delete_mileage}
                  contentStyle={{width: '90%'}}
                  autoScrollBodyContent={true}
                  actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
               >
                  <Subheader className="curved-style">Delete Mileage Expense Item?</Subheader>
               </Dialog>
         </div>
      )
   };
}

export default connect(
    state => ({
        expenseReports: state.rootReducer.expenseReports,
        categories: state.rootReducer.categories
    })
)(MileageListItems);
