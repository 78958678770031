import {forgotPasswordEmailRequest} from '../api/users';

import {
    RESET_PASSWORD_EMAIL,
    RESET_PASSWORD_EMAIL_SUCCESS,
    RESET_PASSWORD_EMAIL_FAILURE
} from '../actions/types';

function resetPasswordEmail() {
    return {
        type: RESET_PASSWORD_EMAIL
    };
}

function resetPasswordEmailSuccess(json) {
    return {
        type: RESET_PASSWORD_EMAIL_SUCCESS,
        payload: json
    };
}

function resetPasswordEmailFailure(error) {
    return {
        type: RESET_PASSWORD_EMAIL_FAILURE,
        payload: error
    };
}

export default function requestResetPasswordEmail(formData) {
    return dispatch => {
        dispatch(resetPasswordEmail());
        return forgotPasswordEmailRequest(formData)
            .then((json) => {
                return dispatch(resetPasswordEmailSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(resetPasswordEmailFailure(errors));
                })
            });
    };
}