import {patchExpenseRequest} from '../api/expenses';

import{
    PATCH_EXPENSE,
    PATCH_EXPENSE_SUCCESS,
    PATCH_EXPENSE_FAILURE
} from './types';


function patchExpense(){
    return{
        type: PATCH_EXPENSE
    }
}

function patchExpenseSuccess(json){
    return{
        type: PATCH_EXPENSE_SUCCESS,
        payload: json
    }
}

function patchExpenseFailure(error){
    return{
        type: PATCH_EXPENSE_FAILURE,
        payload: error
    }
}


export default function requestPatchExpense(expense_id, formData, token) {
    return dispatch => {
        dispatch(patchExpense());
        return patchExpenseRequest(expense_id, formData, token)
            .then((json) => {
                return dispatch(patchExpenseSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchExpenseFailure(errors));
                })
            });
    };
}
