import React, {Component} from 'react';
import _ from 'lodash';
import TextField from 'material-ui/TextField';

const styles = {
    // Make sure the field label is on the left.
    prefix: {
        position: 'absolute',
        left: -12,
        bottom: 9,
        fontWeight: 600,
        cursor: 'pointer'
    },
    formgroup: {
        position: 'relative'
    },
    inlineBlock: {
        display: 'inline-block',
        position: 'relative'
    }
};

class Input extends Component {

    handleChange = (event) => {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    handleBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event.target.value);
        }
    }

    handleFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event.target.value);
        }
    }


    render() {
        const prefix = this.props.prefix || '';
        const suffix = this.props.suffix || '';
        const description = this.props.description || '';
        let classes = "form-group";
        if(this.props.className){
            classes = "form-group " + this.props.className;
        }
        return (
            <div className={classes} style={this.props.fieldIcon ? styles.inlineBlock : styles.formgroup}>
                {this.props.fieldIcon}
                <div>
                    {prefix ? <span className="prefix" style={this.props.prefixStyle ? this.props.prefixStyle : styles.prefix}>{prefix}</span> : null}
                    <TextField
                        type={this.props.type ? this.props.type : 'text'}
                        rows={this.props.rows}
                        multiLine={this.props.multiLine}
                        value={this.props.value}
                        style={this.props.style}
                        underlineDisabledStyle={this.props.underline}
                        id={this.props.id}
                        name={this.props.id}
                        hintText={this.props.placeholder}
                        step={this.props.step ? this.props.step : 1}
                        disabled={this.props.disabled}
                        errorText={_.get(this.props.errors, 'error.' + this.props.id) ? _.get(this.props.errors, 'error.' + this.props.id)  : null}
                        defaultValue={this.props.defaultValue}
                        floatingLabelText={this.props.floatingLabelText}
                        floatingLabelStyle={this.props.floatingLabelStyle ? this.props.floatingLabelStyle : {whiteSpace: 'nowrap'}}
                        // eslint-disable-next-line
                        floatingLabelFixed={this.props.floatingLabelFixed == false ? this.props.floatingLabelFixed : !!this.props.floatingLabelText}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        textareaStyle={this.props.textareaStyle}
                        fullWidth={this.props.fullWidth}
                        underlineShow={'underlineShow' in this.props ? this.props.underlineShow : true}
                        className={this.props.disabled ? 'custom-disabled' : ''}
                    />
                    {suffix ? <span>{suffix}</span> : null}
                    {description ? <div style={{width:256, textAlign:"left", fontSize:".6em"}}>{ description }</div> : null}
                </div>
            </div>

        )
    }
}

export default Input;
