import React, { Component } from 'react';
import { connect } from 'react-redux';
import Subheader from 'material-ui/Subheader';
import ExpenseReportsTable from '../blocks/ExpenseReportsTable';

class ProcessedExpenseReports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="processed-expense-reports">
        <div className="list-view table-view">
          <Subheader className="table-style">Processed Expense Reports</Subheader>
          <ExpenseReportsTable
            processed={1}
            submitted={1}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(ProcessedExpenseReports);
