import { deleteUserRequest } from '../api/users';

import {
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
} from '../actions/types';

function deleteUser() {
    return {
        type: DELETE_USER   
    };
}

function deleteUserSuccess(json) {
    return {
        type: DELETE_USER_SUCCESS,
        payload: json
    };
}

function deleteUserFailure(error) {
    return {
        type: DELETE_USER_FAILURE,
        payload: error
    };
}

export default function requestDeleteUser(user_id, token) {
    return dispatch => {
        dispatch(deleteUser());
        return deleteUserRequest(user_id, token)
            .then((json) => {
                return dispatch(deleteUserSuccess(json));
            })
            .catch((error) => {
            console.log(error);
                return error.json().then((errors) => {
                    return dispatch(deleteUserFailure(errors));
                })
            });
    };
}


