import React, { Component } from 'react';
import { connect } from 'react-redux';
import Subheader from 'material-ui/Subheader';
import AdvanceRequestsTable from '../blocks/AdvanceRequestsTable';

class AdvanceExpenseReports extends Component {
  render() {
    return (
      <div className="unprocessed-advance-requests">
        <div className="list-view table-view">
          <Subheader className="table-style">Unprocessed Advance Requests</Subheader>
          <AdvanceRequestsTable
            status="requested"
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(AdvanceExpenseReports);
