import { getAllDonationsRequest, getPaginatedDonationsRequest } from '../api/donations';

import {
    GET_ALL_DONATIONS,
    GET_ALL_DONATIONS_SUCCESS,
    GET_ALL_DONATIONS_FAILURE, GET_DONATIONS, GET_DONATIONS_SUCCESS, GET_DONATIONS_ERROR
} from './types';


function getAllDonations(){
    return{
        type: GET_ALL_DONATIONS
    }
}

function getAllDonationsSuccess(json){
    return{
        type: GET_ALL_DONATIONS_SUCCESS,
        payload: json
    }
}

function getAllDonationsFailure(error){
    return{
        type: GET_ALL_DONATIONS_FAILURE,
        payload: error
    }
}

export default function requestGetAllDonations(token) {
    return dispatch => {
        dispatch(getAllDonations());
        return getAllDonationsRequest(token)
            .then((json) => {
                return dispatch(getAllDonationsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllDonationsFailure(errors));
                })
            });
    };
}

export const requestGetPaginatedDonations = (token, limit = 100, page = 0, orderBy = 'id', order = 'desc', q = null, userId = null) => dispatch => {
    dispatch({ type: GET_DONATIONS });
    return getPaginatedDonationsRequest(token, limit, page, orderBy, order, q, userId)
      .then(json => {
          return dispatch({ type: GET_DONATIONS_SUCCESS, payload: json })
      })
      .catch(e => {
          return dispatch({ type: GET_DONATIONS_ERROR });
      })
}
