import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import Table from '../table';
import moment from 'moment';
import {dateSortDesc} from '../../../utilities/date-sort';

export default class TransactionsDialog extends React.Component {
   
   render() {
      const columns = [
         {key: 'type', label: 'Type', 'sortable': true},
         {key: 'description', label: 'Description', 'sortable': false},
         {key: 'expenseId', label: 'Expense Id', 'sortable': false},
         {key: 'amount', label: 'Amount', 'sortable': true},
         {key: 'amountApproved', label: 'Approved', 'sortable': false},
         {key: 'unreimbursed', label: 'Unreimbursed', 'sortable': false},
         {key: 'startBalance', label: 'Starting Balance', 'sortable': false},
         {key: 'endBalance', label: 'Ending Balance', 'sortable': true},
         {key: 'date', label: 'Transaction Date', 'sortable': false},
      ];

      //from distribution tranactions
      const transactionsData = this.props.distTransactions
         //remove transactions for distributions that haven't been approved
         .filter(transaction => {
            return (transaction.status !== 'pending');
         })
         .map(transaction => {
            return {
               type: transaction.transaction_type,
               description: transaction.description ? transaction.description : 'No Description',
               expenseId: transaction.expense_id ? transaction.expense_id : '',
               amount: transaction.transaction_amount,
               amountApproved: transaction.amount_approved,
               unreimbursed: transaction.added_unreimbursed_amount,
               startBalance: transaction.starting_balance,
               endBalance: transaction.ending_balance,
               date: !!transaction.mat_date ? moment(transaction.mat_date).format('MM/DD/YYYY') : null
            }
         }).sort(dateSortDesc);

      return (
         <Dialog
            modal={true}
            open={this.props.open}
            autoScrollBodyContent={true}
            contentStyle={{maxWidth: '100%', width: '98%', position: 'fixed', top: 0, left: 13}}
         >
            <div className="transactions-dialog-container">
               <Subheader className="table-style">Transactions for: {this.props.fundName}</Subheader>
               <Table data={transactionsData} columns={columns} style={{ tableLayout: 'auto' }} fixedHeader={false}/>
               <RaisedButton
                  label="Close"
                  className="cancel-donation-dialog"
                  backgroundColor="#525252"
                  labelColor="#ffffff"
                  onClick={this.props.handleClose}
               />
            </div>
         </Dialog>
      );
   }
}
