import React, { Component } from 'react';
import { connect } from 'react-redux';
import Subheader from 'material-ui/Subheader';
import AdvanceRequestsTable from '../blocks/AdvanceRequestsTable';

class AdvanceRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="advance-requests">
        <div className="list-view table-view">
          <Subheader className="table-style">Advance History</Subheader>
          <AdvanceRequestsTable/>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({})
)(AdvanceRequests);
