import React, {Component} from 'react';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import DynamicMenuItem from './dynamic-menu-item';
import moment from 'moment';



class CustomListItemComponent extends Component {
    render() {
        const iconButtonElement = (
            <IconButton
                touch={true}
                tooltip="more"
                tooltipPosition="bottom-left"
            >
                <MoreVertIcon color={'black'}/>
            </IconButton>
        );

        const lightGray = {
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.54)"
        };



        const expenseReportsList = this.props.expense_reports.map((expenseReport, index) => {
            let submitted;
            let processed;
            let typeColor;
            const rightIconMenu = (
                <IconMenu iconButtonElement={iconButtonElement}>
                    {!expenseReport.submitted ? <DynamicMenuItem id="edit" name="Edit"
                                                                 to={'/expense-report/' + expenseReport.id + '/edit'}/> : null}
                    {expenseReport.submitted ?
                        <DynamicMenuItem id="view" name="View" to={'/expense-report/' + expenseReport.id}/> : null}
                </IconMenu>
            );

            if(expenseReport.type === 'reimbursement'){
                typeColor = "rgb(174, 213, 129)"
            }else if (expenseReport.type === 'advance'){
                typeColor = "#4DB6AC"
            }


            if (expenseReport.submitted) {
                submitted = "Submitted: " + moment(expenseReport.date_submitted).format('MM/DD/YYYY');
            } else {
                submitted = "Not Submitted"
            }
            if (expenseReport.processed) {
                processed = "Processed";
            } else {
                processed = "Not Processed";
            }
            let expense_report_total_amount = expenseReport.total_amount ? expenseReport.total_amount.toFixed(2) : 0;

            return (

                <div className="message-list-items" key={index}>
                    <div className="message-list-items">
                        <div>
                           <span tabIndex='0' style={{
                               border: '10px',
                               boxSizing: "border-box",
                               display: "block",
                               fontFamily: "Roboto, sans-serif",
                               cursor: "pointer",
                               textDecoration: "none",
                               padding: "0",
                               outline: "none",
                               fontSize: "16px",
                               fontWeight: "inherit",
                               position: "relative",
                               color: "rgba(0, 0, 0, 0.87)",
                               lineHeight: "16px",
                               transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                               background: "none",
                               height: "100%",
                               float: "left",
                               width: "100%",
                               margin: "10px 0"
                           }}>
                               <div>
                                   <div style={{
                                       marginLeft: "0px",
                                       padding: "16px 0",
                                       position: "relative"
                                   }}>
                                       <div style={{
                                            display: "block",
                                            position: "absolute",
                                            top: "50%",
                                            right: 0,
                                            transform: 'translateY(-50%)'
                                       }}>
                                           {rightIconMenu}
                                       </div>
                                       <div style={{
                                           backgroundColor: typeColor,
                                           color: "white",
                                           textTransform: "uppercase",
                                           fontFamily: "Roboto",
                                           textAlign: "center",
                                           padding: "10px 12px",
                                           width: "100%"
                                       }}>
                                           <div>{expenseReport.type}</div>
                                       </div>
                                       <div style={{width: "75%", float: "left"}}>
                                             <p><strong>Total Amount:</strong> ${expense_report_total_amount}</p>
                                           <p><strong>Start Date:</strong> {moment(expenseReport.start_date).format('MM/DD/YYYY')}</p>
                                               <p><strong>End Date:</strong>{moment(expenseReport.end_date).format('MM/DD/YYYY')}</p>
                                           <p style={lightGray}>{submitted}</p>
                                           <p style={lightGray}>{processed}</p>
                                       </div>
                                   </div>

                               </div>
                           </span>
                        </div>
                    </div>
                    <Divider style={{clear: 'both'}} inset={true}/>


                </div>
            )

        });


        return (
            <div>
                <Subheader className="message-list-subheader">Entries</Subheader>
                {expenseReportsList}
            </div>
        )
    };
}

export default CustomListItemComponent;