import {getUnprocessedExpenseReportsRequest} from '../api/expense-reports';

import {
   GET_UNPROCESSED_EXPENSE_REPORTS,
   GET_UNPROCESSED_EXPENSE_REPORTS_SUCCESS,
   GET_UNPROCESSED_EXPENSE_REPORTS_FAILURE
} from './types';


function getUnprocessedExpenseReports() {
   return {
      type: GET_UNPROCESSED_EXPENSE_REPORTS
   }
}

function getUnprocessedExpenseReportsSuccess(json) {
   return {
      type: GET_UNPROCESSED_EXPENSE_REPORTS_SUCCESS,
      payload: json
   }
}

function getUnprocessedExpenseReportsFailure(error) {
   return {
      type: GET_UNPROCESSED_EXPENSE_REPORTS_FAILURE,
      payload: error
   }
}


export default function requestGetUnprocessedExpenseReports(token) {
   return dispatch => {
       dispatch(getUnprocessedExpenseReports());
       return getUnprocessedExpenseReportsRequest(token)
           .then((json) => {
               return dispatch(getUnprocessedExpenseReportsSuccess(json));
           })
           .catch((error) => {
               return error.json().then((errors) => {
                   return dispatch(getUnprocessedExpenseReportsFailure(errors));
               })
           });
   };
}