import React from 'react';
import { connect } from 'react-redux';
import {
    selectDistributionComments,
    selectDistributionFormUser,
    selectHoldAccountsWithdrawalAmount,
    selectHousingAllowance, selectManualMinistryAdvance, selectManualMinistryReimbursement,
    selectMinistryAdvance,
    selectMinistryReimbursement,
    selectPayrollDisbursement,
    selectSalary, selectTotalFundsDisbursed,
} from "../../../reducers/distribution-form";
import CurrencyInput from "../../fields/CurrencyInput";
import Input from '../../fields/input';
import Icon from '../icon';
import IconButton from 'material-ui/IconButton';
import _ from 'lodash';
import { setDistributionComments } from "../../../actions/distribution-form";

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    input: {
        width: '170px',
    },
    inputContainer: {
        margin: 20,
    }
};

class Distribution extends React.PureComponent {
    state = {
        comments: undefined,
    };

    componentWillUnmount = () => this.debouncedCommentsChange.flush;

    debouncedCommentsChange = _.debounce(val => this.props.setDistributionComments(val), 500);

    _onCommentsChange = comments => this.setState({ comments }, () => this.debouncedCommentsChange(comments));

    render = () => {
        const {
            user,
            heldLiabilityAccountWithdrawal,
            ministryReimbursement,
            ministryAdvance,
            salary,
            comments: propsComments,
            readOnly,
            manualMinistryReimbursement,
            manualMinistryAdvance,
        } = this.props,
            { profile } = user || {},
            { retirement_amount: retirement } = profile || {};

        const { comments: stateComments } = this.state;
        const comments = stateComments === undefined && propsComments ? propsComments : stateComments;

        const AddIcon = <Icon type="plus" />,
            MinusIcon = <Icon type="minus" />,
            EqualsIcon = <Icon type="drag_handle" />;

        return (
            <div>
                <div style={styles.container}>
                    <CurrencyInput
                        id="net_payroll_dist"
                        floatingLabelText="Net Payroll"
                        value={salary || 0}
                        disabled={true}
                        underlineShow={false}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        AddIcon
                    }
                    <CurrencyInput
                        id="housing_allowance_dist"
                        floatingLabelText="Housing Allowance"
                        value={this.props.housingAllowance || 0}
                        disabled={true}
                        underlineShow={false}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        MinusIcon
                    }
                    <CurrencyInput
                        id="retirement"
                        floatingLabelText="Retirement 403(b)"
                        value={retirement || 0}
                        disabled={true}
                        underlineShow={false}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        EqualsIcon
                    }
                    <CurrencyInput
                        id="payroll_disbursement"
                        floatingLabelText="Payroll Disbursement"
                        value={this.props.payrollDisbursement}
                        disabled={true}
                        underlineShow={false}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                </div>
                <div style={styles.container}>
                    <CurrencyInput
                        id="payroll_disbursement"
                        floatingLabelText="Payroll Disbursement"
                        value={this.props.payrollDisbursement}
                        disabled={true}
                        underlineShow={false}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        AddIcon
                    }
                    <CurrencyInput
                        id="held_liability_account_withdrawal"
                        floatingLabelText="Held Liability Accounts Withdrawal"
                        value={heldLiabilityAccountWithdrawal || 0}
                        disabled={true}
                        underlineShow={false}
                        inputSuffix={
                            <IconButton onClick={this.props.openHoldAccountsDialog}>
                                <Icon type="info_outline" />
                            </IconButton>
                        }
                        style={{ ...styles.input, ...{ width: '250px' } }}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        AddIcon
                    }
                    <CurrencyInput
                        id="ministry_expense_reimbursement"
                        floatingLabelText="Ministry Expense Reimbursement"
                        value={ministryReimbursement || 0}
                        disabled={true}
                        underlineShow={false}
                        style={{ ...styles.input, ...{ width: '240px' } }}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        AddIcon
                    }
                    <CurrencyInput
                      id="manual_ministry_reimbursement"
                      floatingLabelText="Manual Ministry Reimbursement"
                      value={manualMinistryReimbursement || 0}
                      disabled={true}
                      underlineShow={false}
                      style={{ ...styles.input, ...{ width: '240px' } }}
                      containerStyle={styles.inputContainer}
                    />
                    {
                        AddIcon
                    }
                    <CurrencyInput
                        id="ministry_expense_advance"
                        floatingLabelText="Ministry Expense Advance"
                        value={ministryAdvance || 0}
                        disabled={true}
                        underlineShow={false}
                        style={{ ...styles.input, ...{ width: '190px' } }}
                        containerStyle={styles.inputContainer}
                    />
                    {
                        AddIcon
                    }
                    <CurrencyInput
                      id="manual_ministry_advance"
                      floatingLabelText="Manual Ministry Advance"
                      value={manualMinistryAdvance || 0}
                      disabled={true}
                      underlineShow={false}
                      style={{ ...styles.input, ...{ width: '190px' } }}
                      containerStyle={styles.inputContainer}
                    />
                    {
                        EqualsIcon
                    }
                    <CurrencyInput
                        id="total_funds_disbursed"
                        floatingLabelText="Total Funds Disbursed"
                        value={this.props.totalFundsDisbursed || 0}
                        disabled={true}
                        underlineShow={false}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                </div>
                <div style={styles.container}>
                    <Input
                      id="comments"
                      floatingLabelText="Comments"
                      multiLine={true}
                      textareaStyle={{ backgroundColor: '#E0F2F7', width: '400px' }}
                      fullWidth={true}
                      rows={7}
                      onChange={this._onCommentsChange}
                      value={comments}
                      disabled={readOnly}
                    />
                </div>
            </div>
        )
    }
}

export default connect(state => {
    return {
        user: selectDistributionFormUser(state),
        heldLiabilityAccountWithdrawal: selectHoldAccountsWithdrawalAmount(state),
        ministryReimbursement: selectMinistryReimbursement(state),
        ministryAdvance: selectMinistryAdvance(state),
        payrollDisbursement: selectPayrollDisbursement(state),
        salary: selectSalary(state),
        comments: selectDistributionComments(state),
        housingAllowance: selectHousingAllowance(state),
        totalFundsDisbursed: selectTotalFundsDisbursed(state),
        manualMinistryReimbursement: selectManualMinistryReimbursement(state),
        manualMinistryAdvance: selectManualMinistryAdvance(state),
    };
}, {
    setDistributionComments,
})(Distribution);
