import {
  ADD_HOLD_ACCOUNT_TRANSACTION,
  APPROVE_ADVANCE_REQUEST,
  APPROVE_DONATION,
  DELETE_HOLD_ACCOUNT_TRANSACTION,
  PROCESS_EXPENSE_REPORTS,
  RESET_DISTRIBUTION_FORM,
  SET_ADVANCE_REQUEST_AMOUNT_APPROVED,
  SET_DISTRIBUTION_FORM_USER_ID,
  SET_DISTRIBUTION,
  SET_DONATION_UPLOAD,
  SET_END_DATE,
  SET_FEDERAL_INCOME_TAX,
  SET_HOLD_ACCOUNTS,
  SET_MEDICARE,
  SET_SOCIAL_SECURITY,
  SET_START_DATE,
  SET_TOTAL_PAYROLL,
  APPROVE_HOLD_ACCOUNT_TRANSACTION,
  DISTRIBUTABLE_FUNDS_CHANGE,
  SET_MID_MONTH_DISBURSEMENT,
  SET_SALARY,
  SET_DISTRIBUTION_COMMENTS,
  SET_WIRE_FEE,
  SET_HOUSING_ALLOWANCE,
  SET_INSURANCE_PAYMENT,
  SET_MANUAL_MINISTRY_REIMBURSEMENT,
  SET_MANUAL_MINISTRY_ADVANCE,
  SEARCH_USERS,
  SEARCH_USERS_ERROR,
  SEARCH_USERS_SUCCESS,
} from "./types";
import Cookies from 'cookies-js';
import requestGetUser from './get-user';
import {
    selectAdminFeeCap,
    selectAdminFeePercentage,
    selectMedicarePercentage,
    selectSocialSecurityPercentage
} from "../reducers/settings";
import Actions from '../actions';
import {distributionReadOnly, serializeDistributionForm} from "../utilities/distribution-form-helpers";

const adminOnly = func => (dispatch, getState) => {
    const state = getState(),
      { user } = state.rootReducer.auth,
      { distribution } = state.rootReducer.distributionForm;
    if (distributionReadOnly(user, distribution)) return Promise.reject();
    return dispatch(func);
};

export const rebuildDistribution = id => dispatch => {
  return dispatch(Actions.requestGetDistribution(Cookies.get('token'), id)).then(({ payload }) => {
    const { user_id: userId } = payload;
    dispatch(setDistributionFormUserId(userId)).then(() => {
      dispatch(setDistribution(payload));
    });
  })
};

export const setDistribution = distribution => (dispatch, getState) => {
    const state = getState();
    const adminFeeCap = selectAdminFeeCap(state);
    const adminFeePercentage = selectAdminFeePercentage(state);
    dispatch({ type: SET_DISTRIBUTION, payload: { ...distribution, adminFeeCap, adminFeePercentage } });
};

export const setDistributionFormUserId = userId => (dispatch, getState) => {
  return dispatch(requestGetUser(userId, Cookies.get('token')))
    .then(res => {
      const { payload: user = {} } = res;
      if ('hold_accounts' in user) dispatch(setHoldAccounts(user.hold_accounts));
      const state = getState();
      const adminFeePercentage = selectAdminFeePercentage(state);
      const adminFeeCap = selectAdminFeeCap(state);
      return dispatch(({ type: SET_DISTRIBUTION_FORM_USER_ID, user, adminFeePercentage, adminFeeCap }));
    });
};

export const setDonationUpload = donationUpload => dispatch => dispatch(
  adminOnly(() => dispatch({ type: SET_DONATION_UPLOAD, payload: donationUpload }))
);

export const addHoldAccountTransaction = transaction => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: ADD_HOLD_ACCOUNT_TRANSACTION, payload: transaction });
      dispatch(distributableFundsChange());
  })
);

export const deleteHoldAccountTransaction = (accountId, transactionId, type) => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: DELETE_HOLD_ACCOUNT_TRANSACTION, payload: { accountId, transactionId, type }});
      dispatch(distributableFundsChange());
  })
);

export const setTotalPayroll = totalPayroll => ({ type: SET_TOTAL_PAYROLL, payload: totalPayroll });

export const resetDistributionForm = () => ({ type: RESET_DISTRIBUTION_FORM });

export const setSocialSecurity = ss => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: SET_SOCIAL_SECURITY, payload: ss });
      dispatch(distributableFundsChange());
  })
);

export const setMedicare = medicare => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: SET_MEDICARE, payload: medicare });
      dispatch(distributableFundsChange());
  })
);

export const setWireFee = wireFee => dispatch => dispatch(
  adminOnly(() => {
    dispatch({ type: SET_WIRE_FEE, payload: wireFee });
    dispatch(distributableFundsChange());
  })
);

export const setManualMinistryReimbursement = amount => dispatch => dispatch(
  adminOnly(() => {
    dispatch({ type: SET_MANUAL_MINISTRY_REIMBURSEMENT, payload: amount });
    dispatch(distributableFundsChange());
  })
);

export const setManualMinistryAdvance = amount => dispatch => dispatch(
  adminOnly(() => {
    dispatch({ type: SET_MANUAL_MINISTRY_ADVANCE, payload: amount });
    dispatch(distributableFundsChange());
  })
);

export const setHousingAllowance = housingAllowance => dispatch => dispatch(
  adminOnly(() => {
    dispatch({ type: SET_HOUSING_ALLOWANCE, payload: housingAllowance });
    dispatch(distributableFundsChange());
  })
);

export const setInsurancePayment = insurancePayment => dispatch => dispatch(
  adminOnly(() => {
    dispatch({ type: SET_INSURANCE_PAYMENT, payload: insurancePayment });
    dispatch(distributableFundsChange());
  })
);

export const setFederalIncomeTax = val => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: SET_FEDERAL_INCOME_TAX, payload: val });
      dispatch(distributableFundsChange());
  })
);

export const approveAdvanceRequest = (advanceRequestId, approved) => dispatch => dispatch(
  adminOnly(() => dispatch({ type: APPROVE_ADVANCE_REQUEST, advanceRequestId, approved }))
);

export const setAdvanceRequestAmountApproved = (advanceRequestId, amount) => ({ type: SET_ADVANCE_REQUEST_AMOUNT_APPROVED, advanceRequestId, amount });

export const processExpenseReports = () => ({ type: PROCESS_EXPENSE_REPORTS });

export const approveDonation = (donationId, approved) => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: APPROVE_DONATION, donationId, approved });
      dispatch(distributableFundsChange());
  })
);

export const setStartDate = startDate => dispatch => dispatch(
  adminOnly(() => dispatch({ type: SET_START_DATE, payload: startDate }))
);

export const setEndDate = endDate => dispatch => dispatch(
  adminOnly(() => dispatch({ type: SET_END_DATE, payload: endDate }))
);

const setHoldAccounts = holdAccounts => ({ type: SET_HOLD_ACCOUNTS, payload: holdAccounts });

export const approveHoldAccountTransaction = (accountId, transactionId, transactionType, approved) => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: APPROVE_HOLD_ACCOUNT_TRANSACTION, payload: { accountId, transactionId, transactionType, approved } });
      dispatch(distributableFundsChange());
  })
);

export const distributableFundsChange = () => ({ type: DISTRIBUTABLE_FUNDS_CHANGE });

export const setMidMonthDisbursement = payload => dispatch => dispatch(
  adminOnly(() => {
      dispatch({ type: SET_MID_MONTH_DISBURSEMENT, payload });
      dispatch(distributableFundsChange());
  })
);

export const setSalary = salary => (dispatch, getState) => dispatch(
  adminOnly(() => {
      const state = getState(),
        ssPercent = selectSocialSecurityPercentage(state),
        mcPercent = selectMedicarePercentage(state);
      dispatch({ type: SET_SALARY, payload: { salary, ssPercent, mcPercent } });
      dispatch(distributableFundsChange());
  })
);

export const submitDistribution = () => (dispatch, getState) => dispatch(
  adminOnly(() => {
    const distFormState = getState().rootReducer.distributionForm;
    const serialized = serializeDistributionForm(distFormState);
    const { distributionId } = distFormState;
    if (distributionId) {
      return dispatch(Actions.requestPatchDistribution(distributionId, serialized, Cookies.get('token'), true));
    }
    else {
      return dispatch(Actions.requestPostDistribution(serialized, Cookies.get('token'), true));
    }
  })
);

export const setDistributionComments = payload => dispatch => dispatch(
  adminOnly(() => dispatch({ type: SET_DISTRIBUTION_COMMENTS, payload }))
);
