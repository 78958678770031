
export const updateForm = (form_id, object, prefix = '') => {
    const form = document.getElementById(form_id);
    loopThroughObject(form, object, prefix);
};

// recursive function for going through our form and objects
function loopThroughObject(form, object, prefix) {
    // loop through our object
    for(const key in object){
        // look for our input
        let input = form.elements[prefix + key];
        if (key === 'length') {
            input = document.getElementById(key);
        }
        // if we have an input, we set it
        if(input && input['type'] !== 'file'){
            input.value = object[key];
        } else if(object[key] !== null && typeof object[key] === 'object') {

            const prefix = key + '_';
            // keep going through our objects recursively
            loopThroughObject(form, object[key], prefix);

        }
    }
}