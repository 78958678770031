import {postExpenseReportRequest} from '../api/expense-reports';

import{
    POST_EXPENSE_REPORT,
    POST_EXPENSE_REPORT_SUCCESS,
    POST_EXPENSE_REPORT_FAILURE
} from './types';

function postExpenseReport(){
    return{
        type: POST_EXPENSE_REPORT
    }
}

function postExpenseReportSuccess(json){
    return{
        type: POST_EXPENSE_REPORT_SUCCESS,
        payload: json
    }
}

function postExpenseReportFailure(error){
    return{
        type: POST_EXPENSE_REPORT_FAILURE,
        payload: error
    }
}


export default function requestPostExpenseReport(formData, token) {
    return dispatch => {
        dispatch(postExpenseReport());
        return postExpenseReportRequest(formData, token)
            .then((json) => {
                return dispatch(postExpenseReportSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postExpenseReportFailure(errors));
                })
            });
    };
}
