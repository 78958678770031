import React from 'react';
import { connect } from 'react-redux';
import {
  selectHoldAccounts,
  selectUploadDonations
} from "../../../reducers/distribution-form";
import { approveDonation } from "../../../actions/distribution-form";
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import Checkbox from 'material-ui/Checkbox';
import DataTables from 'material-ui-datatables';
import {formatCurrency} from "../../../utilities/currency-helpers";

class DonationsDialog extends React.PureComponent {
    approveDonation = donation => (event, approved) => {
        const { id } = donation;
        this.props.approveDonation(id, approved);
    };


    table = () => {
        const { donations = {}, holdAccounts = {} } = this.props;
        const rows = Object.values(donations || {}).map(x => {
            const { hold_account_id: holdAcctId, amount, approved } = x;
            const fundName = holdAcctId ? (holdAccounts[holdAcctId] || {})['fund_name'] : 'General Fund';
            return {
                fundName,
                amount: formatCurrency(amount),
                approved: !!holdAcctId
                  ? <Checkbox checked={!!approved} onCheck={this.approveDonation(x)} disabled={this.props.readOnly} />
                  : null,
            }
        });
        return (
          <DataTables
            data={rows}
            columns={[
                {
                    key: 'fundName',
                    label: 'Fund Name',
                    sortable: false
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: false
                },
                {
                    key: 'approved',
                    label: 'Approved',
                    sortable: false
                },
            ]}
            showHeaderToolbar={true}
            showHeaderToolbarFilterIcon={false}
          />
        );
    };

    render = () => {
        return (
            <Dialog
                modal={true}
                open={this.props.open}
                contentStyle={{width: '50%', maxWidth: 'none'}}
                autoScrollBodyContent={true}
                overlayClassName={'hold-accounts-dialog-table'}
                onRequestClose={this.props.onClose}
                actions={[
                    <RaisedButton
                      key="dialog-close"
                      label="Close"
                      className="cancel-category-dialog"
                      backgroundColor="#525252"
                      labelColor="#ffffff"
                      onClick={this.props.onClose}
                    />
                ]}
            >
                <Subheader className="curved-style">Donations</Subheader>
                {
                    this.table()
                }
            </Dialog>
        )
    }
}

export default connect(state => {
    return {
        donations: selectUploadDonations(state),
        holdAccounts: selectHoldAccounts(state),
    };
}, {
    approveDonation
})(DonationsDialog);
