import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Input from '../../fields/input';
import Subheader from 'material-ui/Subheader';
import DropDownSelect from '../../fields/dropdown-select-field';

const inputStyle = {
   width: '200px',
};

export default class HoldAccountsDialog extends React.Component {

   render() {
      return (
         <Dialog
            modal={true}
            open={this.props.open}
            contentStyle={{width: '40%'}}
         >
            <div className="hold-accounts-dialog-container">
               <Subheader className="curved-style">{this.props.dialogTitle}</Subheader>
               <form className="hold-accounts-form" id="hold-accounts-form">
                  <DropDownSelect
                     id="user_id"
                     floatingLabelText="Missionary"
                     floatingLabelStyle={{left: 0}}
                     options={this.props.missionaryOptions}
                     value={this.props.acctUserId}
                     style={inputStyle}
                     disabled={this.props.disabled}
                  />
                  <Input
                     key="fund-name-input"
                     className="fund-name-input"
                     floatingLabelText="Fund Name"
                     value={this.props.acctFundName}
                     id="fund_name"
                     disabled={this.props.disabled}
                     style={inputStyle}
                     onChange={acctFundName => this.props.onChange('acctFundName', acctFundName)}
                  />
                  <div style={{maxWidth: 200, margin: '0 auto'}}>
                     <Input	
                        key="hold-account-balance"
                        className="hold-account-balance"
                        floatingLabelText="Balance"
                        value={this.props.acctBalance}
                        id="balance"
                        prefix="$"
                        style={inputStyle}
                        onChange={acctBalance => this.props.onChange('acctBalance', acctBalance)}
                     />
                  </div>
               </form>
               <RaisedButton
                  label={this.props.actionButtonLabel ? this.props.actionButtonLabel : 'placeholder'}
                  className="hold-account-action-button"
                  backgroundColor="#1E88E5"
                  labelColor="#ffffff"
                  onClick={this.props.actionMethod ? this.props.actionMethod : () => {}}
               />
               <RaisedButton
                  label="Cancel"
                  className="cancel-hold-account-dialog"
                  backgroundColor="#525252"
                  labelColor="#ffffff"
                  onClick={this.props.handleClose}
               />
            </div>
         </Dialog>
      )
   }
}