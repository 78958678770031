import {
    CLEAR_MESSAGE
} from '../actions/types';

function clear() {
    return {
        type: CLEAR_MESSAGE
    };
}

export default function clearMessage() {
    return dispatch => {
        dispatch(clear());
    };
}