import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Dialog from 'material-ui/Dialog';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import {
   PATCH_ADVANCE_REQUEST_SUCCESS,
} from '../../actions/types';

const customContentStyle = {
   width: '60%',
   maxWidth: 'none',
};

const inputBlockStyle = {
   display: 'inline-block',
   paddingBottom: '20px',
   paddingTop: '0',
   marginTop: '0',
   marginRight: '10px',
   marginLeft: '10px',
   marginBottom: '10px'
}

const inputStyle = {
   width: '120px',
}

const formStyle = {
   textAlign: 'left'
}


class AdvanceProcessedModal extends Component {
   _isMounted = false; //for set state errors

   constructor(props) {
      super(props);
      this.state = {
         open: false,
         amount_approved: 0
      }
   }

   componentDidMount() {
      this._isMounted = true;
   }

   componentWillUnmount() {
      this._isMounted = false;
   }

   processAdvanceRequest(event) {
      event.preventDefault();
      const that = this;
      if(this.state.amount_approved > 0) {
         const advanceRequestID = this.props.advanceRequestSingle.id;
         const form = document.getElementById('advance-process-form');
         let formData = new FormData(form);
         formData.append('status', 'approved');

         this.props.dispatch(Actions.requestPatchAdvanceRequest(advanceRequestID, formData, Cookies.get('token')))
            .then(result => {
               if (result.type === PATCH_ADVANCE_REQUEST_SUCCESS) {
                  that.props.dispatch(Actions.newMessage('You have processed the advance request.'));
                  that.props.incrementForceUpdate();
                  that.handleClose();
                  that.updateApprovedAmount(0);
               }
            })
      } else {
         that.props.dispatch(Actions.newError('Approved amount cannot be zero.'));
      }
   }

   handleOpen = () => {
      if(this._isMounted === true) {
         this.setState({
            open: true
         });
      }
   };

   handleClose = () => {
      if(this._isMounted === true) {
         this.setState({
            open: false
         });
      }
   };

   updateApprovedAmount(value) {
      if(this._isMounted === true) {
         this.setState({
            amount_approved: value
         });
      }
   };

   render() {

      const amountRequested = this.props.advanceRequestSingle.amount_requested;
      const matDate = this.props.advanceRequestSingle.mat_date;

      return (
         <div className="advance-process-button" style={ { display: 'inline-block' } }>
            <RaisedButton
               label="Process"
               labelColor="#ffffff"
               backgroundColor={ '#1E88E5' }
               onClick={ this.handleOpen }
               style={this.props.style}
            />
            <Dialog
               modal={ false }
               open={ this.state.open }
               onRequestClose={ this.handleClose }
               autoScrollBodyContent={ true }
               titleClassName="processed"
               bodyClassName="advance-process-content"
               contentStyle={ customContentStyle }
            >
               <Subheader className="curved-style" style={ { marginBottom: 20 } }>Advance Request</Subheader>
               <form id="advance-process-form" onSubmit={ (event) => this.processAdvanceRequest(event) } encType="multipart/form-data" style={ formStyle }>
                  <div className="mat-date-block" style={ inputBlockStyle }>
                     <Input
                        className="inline-inputs"
                        id="mat_date"
                        defaultValue={ matDate }
                        disabled={ true }
                        floatingLabelText="MAT Date To Receive Advance"
                        style={ inputStyle }
                     />
                  </div>
                  <div className="amount-requested-block" style={ inputBlockStyle }>
                     <Input
                        className="inline-inputs"
                        id="amount_requested"
                        defaultValue={ amountRequested.toFixed(2) }
                        disabled={ true }
                        floatingLabelText="AmountRequested"
                        style={ inputStyle }
                        prefix="$"
                     />
                  </div>
                  <div className="amount-approved-block" style={ inputBlockStyle }>
                     <Input
                        className="inline-inputs"
                        id="amount_approved"
                        defaultValue={ this.state.amount_approved }
                        floatingLabelText="Amount Approved"
                        onChange={ (value, event) => this.updateApprovedAmount(value) }
                        style={ inputStyle }
                        prefix="$"
                     />
                  </div>
                  <div style={ { textAlign: 'center' } }>
                     <RaisedButton
                        label="Cancel"
                        labelColor="#ffffff"
                        backgroundColor={ '#525252' }
                        onClick={ this.handleClose }
                        style={ { marginRight: 3 } }
                     />
                     <RaisedButton
                        label="Process"
                        labelColor="#ffffff"
                        backgroundColor={ '#1E88E5' }
                        onClick={ (event) => this.processAdvanceRequest(event) }
                     />
                  </div>
               </form>
            </Dialog>
         </div>
      );
   }
}

export default connect(
   state => ({
      advanceRequests: state.rootReducer.advanceRequests
   })
)(AdvanceProcessedModal);
