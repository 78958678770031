import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import Input from '../../fields/input';


const inputStyle = {
   width: '200px',
};

export default class RecurringDialog extends React.Component {

   render() {

      return(
         <Dialog
            modal={true}
            open={this.props.open}
            contentStyle={{width: '40%'}}
         >
            <div className="hold-accounts-dialog-container">
               <Subheader className="curved-style">
                  MAT Day Recurring Transactions
               </Subheader>
               <br/>
               <h4><b>Missionary: {this.props.acctUserName}</b></h4>
               <h4><b>Account: {this.props.acctFundName}</b></h4>
               <hr/>
               <form className="recurring-transactions-form" id="recurring-transactions-form">
                  <Input
                     key="recurring-deposit"
                     className="recurring-deposit-input"
                     floatingLabelText="Deposit into this account (Expense)"
                     value={this.props.deposit}
                     id="mat_day_recurring_deposit"
                     style={inputStyle}
                     onChange={recurringDeposit => this.props.onChange('recurringDeposit', recurringDeposit)}
                  />
                  <Input
                     key="recurring-transfer"
                     className="recurring-transfer-input"
                     floatingLabelText="Transfer to Missionary General Fund"
                     value={this.props.transfer}
                     id="mat_day_recurring_transfer"
                     style={inputStyle}
                     onChange={recurringTransfer => this.props.onChange('recurringTransfer', recurringTransfer)}
                  />
                  <Input
                     key="recurring-withdrawal"
                     className="recurring-withdrawal-input"
                     floatingLabelText="Withdrawal from this account (Distribution)"
                     value={this.props.withdrawal}
                     id="mat_day_recurring_withdrawal"
                     style={inputStyle}
                     onChange={recurringWithdrawal => this.props.onChange('recurringWithdrawal', recurringWithdrawal)}
                  />
               </form>
               <div className="button-container">
                  <RaisedButton
                     label="Submit"
                     className="recurring-action-button"
                     backgroundColor="#1E88E5"
                     labelColor="#ffffff"
                     onClick={this.props.actionMethod ? this.props.actionMethod : () => {}}
                  />
                  <RaisedButton
                     label="Cancel"
                     className="cancel-recurring-dialog"
                     backgroundColor="#525252"
                     labelColor="#ffffff"
                     onClick={this.props.handleClose}
                  />
               </div>
            </div>
         </Dialog>
      );
   }
}