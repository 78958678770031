import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Dialog from 'material-ui/Dialog';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import { browserHistory } from 'react-router';
import {
   PATCH_PROCESS_SUCCESS
} from '../../actions/types';

const customContentStyle = {
   width: '60%',
   maxWidth: 'none',
};

const initialState = {
   open: false,
   confirmOpen: false,
   type: '',
   totalAmount: 0,
   unreimbursedAmount: 0,
   totalReimbursement: 0,
   unreimbursedTotal: 0,
   payout: 0,
   advanceRequestId: null
}

class ProcessedModal extends Component {
   constructor(props) {
      super(props);
      this.state = initialState;
   }

   submitProcess(event) {
      event.preventDefault();
      if (this.state.type === 'reimbursement') {
         let form = document.getElementById('processed-form');
         let formData = new FormData(form);
         this.processReport(formData);
         this.toggleConfirmOpen();
      } else if(this.state.type === 'advance') {
         // just send an empty object
         let formData = new FormData();
         this.processReport(formData);
      }
      this.handleClose();
   }

   processReport = (formData) => {
      let that = this;
      this.props.dispatch(Actions.requestUpdateProcess(this.props.expenseReport.id, formData, Cookies.get('token')))
         .then(function (result) {
            if (result.type === PATCH_PROCESS_SUCCESS) {
               that.props.dispatch(Actions.newMessage('Expense Report has been processed.'));
               //pull unprocessed expense reports to keep redux current - for unprocessed expense reports
               that.props.dispatch(Actions.requestGetUnprocessedExpenseReports(Cookies.get('token')))
                  .then(() => {
                     if (that.props.incrementForceUpdate) {
                        that.props.incrementForceUpdate();
                     }
                     if (that.props.redirectLink) {
                        browserHistory.push(that.props.redirectLink);
                     }
                  });
               //pull all expense reports to keep redux current for dashboard
               that.props.dispatch(Actions.requestGetAllExpenseReports(Cookies.get('token')))
                  .then(() => {
                     if (that.props.incrementForceUpdate) {
                        that.props.incrementForceUpdate();
                     }
                     if (that.props.redirectLink) {
                        browserHistory.push(that.props.redirectLink);
                     }
                  });
               //if advance request gets updated we need to reload to keep redux current
               if (that.state.type === 'advance') {
                  that.props.dispatch(Actions.requestGetAllAdvanceRequests(Cookies.get('token')));
               }

            } else {
               that.props.dispatch(Actions.newError('There was an error updating the processed status, please try again later.'));
            }
         })
   }

   handleOpen = () => {
      this.setState({
         open: true,
         type: this.props.type,
         totalAmount: this.props.totalAmount,
         unreimbursedAmount: this.props.unreimbursedAmount,
         totalReimbursement: this.props.totalReimbursement,
         unreimbursedTotal: this.props.totalReimbursement,
         advanceRequestId: this.props.advanceRequestId
      });
   };

   handleClose = () => {
      this.setState(initialState);
   };

   toggleConfirmOpen = () => {
      const confirmOpen = this.state.confirmOpen === true ? false : true;
      this.setState({
         confirmOpen
      });
   }

   updateUnreimbursed(value) {
      const totalReimbursement = this.state.totalReimbursement;
      const unreimbursedTotal = totalReimbursement - value;
      this.setState({ unreimbursedTotal });
      this.setState({ payout: value });
   };
   render() {
      const actions = [
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#525252' }
            label="Cancel"
            onClick={ this.handleClose }
            style={ { marginRight: 3 } }
         />,
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#1E88E5' }
            label="Process"
            onClick={ this.toggleConfirmOpen }
         />
      ];

      const actions_advance = [
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#525252' }
            label="Cancel"
            onClick={ this.handleClose }
            style={ { marginRight: 3 } }
         />,
         <RaisedButton
            labelColor="#ffffff"
            backgroundColor={ '#1E88E5' }
            label="Confirm Receipt"
            onClick={ (event) => this.submitProcess(event) }
         />
      ];

      const actions_confirm = [
         <RaisedButton
            label="Confirm"
            backgroundColor="#1E88E5"
            labelColor="#ffffff"
            style={{marginRight: '5px'}}
            onClick={(event) => this.submitProcess(event)}
         />,
         <RaisedButton
            label="Cancel"
            backgroundColor="#525252"
            labelColor="#ffffff"
            onClick={this.toggleConfirmOpen}
         />
      ]

      const title = (
         <div className="process-head">
            <Subheader className="table-style" style={ { textAlign: 'center' } }>Process Reimbursement</Subheader>
            <p className="processed-desc">You are about to process this expense report. </p>

         </div>
      );

      const title_advance = (
         <div className="process-head">
            <Subheader className="table-style" style={ { textAlign: 'center' } }>Process Advance Expense Report</Subheader>
            <p className="processed-desc">
               This expense report has been submitted for Advance Request ID #
                  { 
                     this.state.advanceRequestId
                     ? this.state.advanceRequestId + '.'
                     : null 
                  }
                </p>
         </div>
      );

      const confirm_title = (
         <div className="process-head">
            <Subheader className="table-style" style={{ textAlign: 'center', backgroundColor: '#e60000' }}>
               Confirm Payout
            </Subheader>
         </div>
      );

      return (
         <div style={ this.props.style } className="processed-button">
            <RaisedButton
               label="Process"
               labelColor="#ffffff"
               backgroundColor={ '#1E88E5' }
               onClick={ this.handleOpen }
            />
            {
               this.state.type === "reimbursement"
                  ?
                  <Dialog title={ title }
                     actions={ actions }
                     modal={ true }
                     open={ this.state.open }
                     onRequestClose={ this.handleClose }
                     autoScrollBodyContent={ true }
                     titleClassName="processed"
                     bodyClassName="processed-content"
                     contentStyle={ customContentStyle }
                     actionsContainerStyle={ { textAlign: 'center', paddingBottom: 20, paddingTop: 20 } }
                  >
                     <div style={ { paddingTop: 15 } }>
                        <form
                           id="processed-form"
                           onSubmit={ (event) => this.submitProcess(event) }
                           encType="multipart/form-data"
                        >
                           <div className="expense-total-block">
                              <Input
                                 id="total_amount"
                                 disabled={ true }
                                 floatingLabelText="Expense Report Total"
                                 value={ '$' + this.state.totalAmount.toFixed(2) }
                                 errors={ this.props.expense_report }
                                 style={ { width: '170px' } }
                              />
                              <Input
                                 id="user_profile_unreimbursed_amount"
                                 disabled={ true }
                                 value={ '$' + this.state.unreimbursedAmount.toFixed(2) }
                                 floatingLabelText="Unreiumbursed Amount"
                                 errors={ this.props.expense_report }
                                 style={ { width: '170px' } }
                              />
                              <Input
                                 id="total_reimbursement"
                                 disabled={ true }
                                 value={ '$' + this.state.totalReimbursement.toFixed(2) }
                                 floatingLabelText="Reimbursement Due"
                                 errors={ this.props.expense_report }
                                 style={ { width: '170px' } }
                              />
                           </div>
                           <div className="fix"></div>
                           <div className="bottom-block">
                              <div className="payout-container">
                                 <Input
                                    className="payout"
                                    id="payout"
                                    value={ this.state.payout }
                                    onChange={ (value, event) => this.updateUnreimbursed(value) }
                                    floatingLabelText="Total Payout"
                                    errors={ this.props.expense_report }
                                    prefix="$"
                                    style={ { width: '170px' } }
                                 />
                              </div>
                              <Input
                                 className="new-unreimbursed-total"
                                 disabled={ true }
                                 id="new_unreimbursed_total"
                                 value={ '$' + this.state.unreimbursedTotal.toFixed(2) }
                                 floatingLabelText="New Unreiumbursed Total"
                                 errors={ this.props.expense_report }
                                 style={ { width: '170px' } }
                              />
                           </div>
                           <input
                              className="hidden"
                              type="hidden" id="new_unreimbursed_total"
                              name="new_unreimbursed_total"
                              value={ this.state.unreimbursedTotal }
                           />
                        </form>
                     </div>
                  </Dialog>

                  :

                  <Dialog title={ title_advance }
                     actions={ actions_advance }
                     modal={ false }
                     open={ this.state.open }
                     onRequestClose={ this.handleClose }
                     autoScrollBodyContent={ true }
                     titleClassName="processed"
                     bodyClassName="processed-content"
                     contentStyle={ customContentStyle }
                     actionsContainerStyle={ { textAlign: 'center', paddingBottom: 20, paddingTop: 20 } }
                  >
                     <div style={ { paddingTop: 15 } }>
                        <p style={ { textAlign: "center" } }>To confirm that you have received the Expense Report, and to finish the Advance Request process, click "Confirm Receipt".</p>
                     </div>
                  </Dialog>
            }
            <Dialog
               title={confirm_title}
               actions={actions_confirm}
               modal={true}
               open={this.state.confirmOpen}
               contentStyle={{width: '25%', textAlign: 'center'}}
               actionsContainerStyle={{textAlign: 'center'}}
            >
               <Input
                  className="payout-confirm"
                  disabled={ true }
                  value={'$' + this.state.payout}
                  floatingLabelText="Payout"
                  style={{ width: '100px'}}
               />
            </Dialog>
         </div>
      );
   }
}

export default connect(
   state => ({
      user: state.rootReducer.users,
      expenseReports: state.rootReducer.expenseReports
   })
)(ProcessedModal);
