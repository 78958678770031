import {submitExpenseReportRequest} from '../api/expense-reports';

import{
    SUBMIT_EXPENSE_REPORT,
    SUBMIT_EXPENSE_REPORT_SUCCESS,
    SUBMIT_EXPENSE_REPORT_FAILURE
} from './types';


function submitExpenseReport(){
    return{
        type: SUBMIT_EXPENSE_REPORT
    }
}

function submitExpenseReportSuccess(json){
    return{
        type: SUBMIT_EXPENSE_REPORT_SUCCESS,
        payload: json
    }
}

function submitExpenseReportFailure(error){
    return{
        type: SUBMIT_EXPENSE_REPORT_FAILURE,
        payload: error
    }
}


export default function requestSubmitExpenseReport(expense_report_id, formData, token) {
    return dispatch => {
        dispatch(submitExpenseReport());
        return submitExpenseReportRequest(expense_report_id, formData, token)
            .then((json) => {
                return dispatch(submitExpenseReportSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(submitExpenseReportFailure(errors));
                })
            });
    };
}
