import {deleteUnsubmittedExpenseReportRequest} from '../api/expense-reports';

import {
    DELETE_UNSUBMITTED_EXPENSE_REPORT,
    DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
    DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE
} from '../actions/types';

function deleteUnsubmittedExpenseReport() {
    return {
        type: DELETE_UNSUBMITTED_EXPENSE_REPORT
    };
}

function deleteUnsubmittedExpenseReportSuccess(json) {
    return {
        type: DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
        payload: json
    };
}

function deleteUnsubmittedExpenseReportFailure(error) {
    return {
        type: DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE,
        payload: error
    };
}

export default function requestDeleteUnsubmittedExpenseReport(expense_report_id, token) {
    return dispatch => {
        dispatch(deleteUnsubmittedExpenseReport());
        return deleteUnsubmittedExpenseReportRequest(expense_report_id, token)
            .then((json) => {
                return dispatch(deleteUnsubmittedExpenseReportSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(deleteUnsubmittedExpenseReportFailure(errors));
                })
            });
    };
}
