import {patchSettingsRequest} from '../api/settings';

import{
    PATCH_SETTINGS,
    PATCH_SETTINGS_SUCCESS,
    PATCH_SETTINGS_FAILURE
} from './types';

function patchSettings(){
    return{
        type: PATCH_SETTINGS
    }
}

function patchSettingsSuccess(json){
    return{
        type: PATCH_SETTINGS_SUCCESS,
        payload: json
    }
}

function patchSettingsFailure(error){
    return{
        type: PATCH_SETTINGS_FAILURE,
        payload: error
    }
}

export default function requestPatchSettings(formData, token) {
    return dispatch => {
        dispatch(patchSettings());
        return patchSettingsRequest(formData, token)
            .then((json) => {
                return dispatch(patchSettingsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(patchSettingsFailure(errors));
                })
            });
    };
}