import {deleteClosedAdvanceRequestRequest} from '../api/advance-requests';

import {
    DELETE_CLOSED_ADVANCE_REQUEST,
    DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS,
    DELETE_CLOSED_ADVANCE_REQUEST_FAILURE
} from './types';

function deleteClosedAdvanceRequest() {
    return {
        type: DELETE_CLOSED_ADVANCE_REQUEST
    };
}

function deleteClosedAdvanceRequestSuccess(json) {
    return {
        type: DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS,
        payload: json
    };
}

function deleteClosedAdvanceRequestFailure(error) {
    return {
        type: DELETE_CLOSED_ADVANCE_REQUEST_FAILURE,
        payload: error
    };
}

export default function requestDeleteClosedAdvanceRequest(expense_report_id, token) {
    return dispatch => {
        dispatch(deleteClosedAdvanceRequest());
        return deleteClosedAdvanceRequestRequest(expense_report_id, token)
            .then((json) => {
                return dispatch(deleteClosedAdvanceRequestSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(deleteClosedAdvanceRequestFailure(errors));
                })
            });
    };
}
