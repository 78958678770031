import {postBatchDistributionsApproveRequest} from '../api/distributions';

import{
   POST_BATCH_DISTRIBUTIONS_APPROVE,
   POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS,
   POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE
} from './types';


function postBatchDistributionsApprove(){
    return{
        type: POST_BATCH_DISTRIBUTIONS_APPROVE
    }
}

function postBatchDistributionsApproveSuccess(json){
    return{
        type: POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS,
        payload: json
    }
}

function postBatchDistributionsApproveFailure(error){
    return{
        type: POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE,
        payload: error
    }
}

export default function requestPostBatchDistributionsApprove(formData, token) {
    return dispatch => {
        dispatch(postBatchDistributionsApprove());
        return postBatchDistributionsApproveRequest(formData, token)
            .then((json) => {
                return dispatch(postBatchDistributionsApproveSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postBatchDistributionsApproveFailure(errors));
                })
            });
    };
}