import {postBatchDistributionsRequest} from '../api/distributions';

import{
   POST_BATCH_DISTRIBUTIONS,
   POST_BATCH_DISTRIBUTIONS_SUCCESS,
   POST_BATCH_DISTRIBUTIONS_FAILURE
} from './types';


function postBatchDistributions(){
    return{
        type: POST_BATCH_DISTRIBUTIONS
    }
}

function postBatchDistributionsSuccess(json){
    return{
        type: POST_BATCH_DISTRIBUTIONS_SUCCESS,
        payload: json
    }
}

function postBatchDistributionsFailure(error){
    return{
        type: POST_BATCH_DISTRIBUTIONS_FAILURE,
        payload: error
    }
}

export default function requestPostBatchDistributions(formData, token) {
    return dispatch => {
        dispatch(postBatchDistributions());
        return postBatchDistributionsRequest(formData, token)
            .then((json) => {
                return dispatch(postBatchDistributionsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(postBatchDistributionsFailure(errors));
                })
            });
    };
}