import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {titleCase} from "./string-helpers";
import {holdAccountEndingBalance} from "./hold-account-helpers";
import {
  selectAccruedUnreimbursedAmount,
  selectAdminFee,
  selectDistributableFunds,
  selectEndingBalance,
  selectEndingUnreimbursedAmount,
  selectFederalIncomeTax,
  selectGrossPayroll,
  selectHoldAccountsWithdrawalAmount,
  selectHousingAllowance,
  selectInsurancePayment,
  selectManualMinistryAdvance,
  selectManualMinistryReimbursement,
  selectMedicare,
  selectMidMonthDisbursement,
  selectMinistryAdvance,
  selectMinistryReimbursement,
  selectPayrollDisbursement,
  selectReimbursedAmount,
  selectSalary,
  selectSocialSecurity,
  selectStartingUnreimbursedAmount,
  selectTotalDonations,
  selectTotalExpenses,
  selectTotalFundsDisbursed,
  selectTotalPayroll,
  selectWireFee
} from "../reducers/distribution-form";

export const distributionReadOnly = (user, distribution) => {
  const { status } = distribution || {};
  const { role } = user || {};
  return status === 'approved' || role !== 'admin';
};

export const getProcessedExpReports = (fundsAvailable, expenseReports) =>
    expenseReports.reduce((acc, report) => {
        const { total_amount: rawTotal, id, expense = [] } = report;
        const holdAccountWithdrawals = expense.reduce((sum, exp) => (exp.hold_account_id ? sum + exp.usd_amount : sum), 0);
        const adjustedTotal = rawTotal - holdAccountWithdrawals;

        let processedReport = {
            reportId: id,
            amount: adjustedTotal,
            holdAccountWithdrawals,
        };

        if (fundsAvailable >= adjustedTotal) {
            fundsAvailable -= adjustedTotal;
            processedReport = {
                ...processedReport,
                amountReimbursed: adjustedTotal,
                unreimbursedAmount: 0,
            };
        }
        else if (fundsAvailable > 0 && fundsAvailable < adjustedTotal) {
            const unreimbursedAmount = adjustedTotal - fundsAvailable;
            processedReport = {
                ...processedReport,
                amountReimbursed: fundsAvailable,
                unreimbursedAmount: unreimbursedAmount,
            };
            fundsAvailable = 0;
        }
        else if (fundsAvailable <= 0) {
            processedReport = {
                ...processedReport,
                amountReimbursed: 0,
                unreimbursedAmount: adjustedTotal,
            }
        }
        return [ ...acc, processedReport ];
    }, []);

/**
 * Returns expense reports to process, for the given user, within the
 * time range of startDate - endDate
 *
 * @param user
 * @param startDate
 * @param endDate
 * @returns {*[]}
 */
export const expenseReportsToProcess = (user, startDate, endDate, state) => {
  const { distribution } = state,
    { status, id: distId } = distribution || {};
  if (startDate instanceof Date) startDate = moment(startDate).unix();
  if (endDate instanceof Date) endDate = moment(endDate).unix();
  const { expense_report: expReports = [] } = user;
  return (expReports || []).filter(report => {
    const { submitted, type, processed, date_submitted: rawDateSubmitted, distribution_id } = report;
    if (!!distId && status === 'approved') return distribution_id === distId;
    if (!rawDateSubmitted) return false;
    const dateMom = moment(rawDateSubmitted);
    return submitted
      && type === 'reimbursement'
      && !processed
      && dateMom.isSameOrAfter(moment.unix(startDate))
      && dateMom.isSameOrBefore(moment.unix(endDate));
  });
};

export const advanceRequestsToProcess = (user, startDate, endDate) => {
  if (startDate instanceof Date) startDate = moment(startDate).unix();
  if (endDate instanceof Date) endDate = moment(endDate).unix();
  const { advance_request: advanceRequests = [] } = user;
  return (advanceRequests || []).filter(req => {
    const { date_submitted: rawDateSubmitted, status } = req;
    if (!rawDateSubmitted || status !== 'requested') return false;
    const dateMom = moment(rawDateSubmitted);
    return dateMom.isSameOrAfter(moment.unix(startDate)) && dateMom.isSameOrBefore(moment.unix(endDate));
  })
};

export const buildRecurringTransaction = (amount, type, accountId, holdAccountName) => {
  return {
    amount,
    approved: false,
    id: uuidv4(),
    description: `MAT Day Recurring ${titleCase(type)}`,
    recurring: true,
    accountId,
    holdAccountName,
    type,
  };
};

export const serializeDistributionForm = distributionForm => {
  const {
    userId,
    startDate,
    endDate,
    donationUpload,
    holdAccounts,
    comments,
  } = distributionForm;
  const startingUnreimbursed = selectStartingUnreimbursedAmount(distributionForm);
  const ministryAdv = selectMinistryAdvance(distributionForm) || 0;
  const ministryReimbursement = selectMinistryReimbursement(distributionForm) || 0;
  const endingBal = selectEndingBalance(distributionForm) || 0;
  const holdAcctsBal = Object.values(holdAccounts || {}).reduce((sum, x) => roundDecimal((holdAccountEndingBalance(x) || 0) + sum), 0);
  return {
    user_id: userId,
    start_date: moment.unix(startDate).format('YYYY-MM-DD'),
    end_date: moment.unix(endDate).format('YYYY-MM-DD'),
    processedExpenseReports: JSON.stringify(serializeExpReports(distributionForm)),
    processedAdvanceRequests: JSON.stringify(serializeAdvanceReqs(distributionForm)),
    ministry_advance: ministryAdv,
    ministry_expense_advance: ministryAdv,
    ministry_reimbursement: ministryReimbursement,
    ministry_expense_reimbursement: ministryReimbursement,
    donation_upload_name: donationUpload,
    total_donations: selectTotalDonations(distributionForm, true),
    funds_available: selectDistributableFunds(distributionForm, true),
    admin_fee: selectAdminFee(distributionForm),
    ending_balance: endingBal,
    total_funds_disbursed: selectTotalFundsDisbursed(distributionForm),
    total_expenses: selectTotalExpenses(distributionForm, true),
    total_funds_on_account: roundDecimal(holdAcctsBal + endingBal),
    unreimbursed_amount_starting: startingUnreimbursed,
    unreimbursed_reimbursement: selectReimbursedAmount(distributionForm) || 0,
    unreimbursed_amount_accrued: selectAccruedUnreimbursedAmount(distributionForm),
    unreimbursed_amount_ending: selectEndingUnreimbursedAmount(distributionForm),
    comments,
    status: 'pending',
    mid_month_disbursement: selectMidMonthDisbursement(distributionForm),
    manual_ministry_reimbursement: selectManualMinistryReimbursement(distributionForm),
    manual_ministry_advance: selectManualMinistryAdvance(distributionForm),
    ...serializeProfileData(distributionForm),
    ...serializePayrollData(distributionForm),
    ...serializeHoldAccountData(distributionForm),
  }
};

const serializeHoldAccountData = distributionForm => {
  const { holdAccounts } = distributionForm;
  return {
    held_liability_account_balance: Object.values(holdAccounts || {})
      .reduce((sum, x) => roundDecimal((holdAccountEndingBalance(x) || 0) + sum), 0),
    held_liability_account_deposit: Object.values(holdAccounts || {})
      .reduce((sum, x) => roundDecimal(sum + (x.totalDepositAmount || 0)), 0),
    held_liability_account_starting_balance: Object.values(holdAccounts || {})
      .reduce((sum, x) => roundDecimal(sum + (x.balance || 0)), 0),
    held_liability_account_withdrawal: selectHoldAccountsWithdrawalAmount(distributionForm) || 0,
    missionaryHoldAccounts: JSON.stringify(serializeHoldAccounts(distributionForm)),
    transfer_from_held: Object.values(holdAccounts || {})
      .reduce((sum, x) => roundDecimal(sum + (x.totalTransferAmount || 0)), 0),
  };
};

const serializeProfileData = distributionForm => {
  const { user = {} } = distributionForm,
    { profile } = user || {},
    housingAllowance = selectHousingAllowance(distributionForm),
    { tax_status, fica_tax, retirement_amount, fund_balance } = profile;
  return {
    fica_tax,
    housing_allowance: housingAllowance,
    housing_allowance_dist: housingAllowance,
    insurance_payment: selectInsurancePayment(distributionForm),
    retirement: retirement_amount,
    starting_balance: fund_balance,
    tax_status,
    wire_fee: selectWireFee(distributionForm),
  }
};

const serializePayrollData = distributionForm => {
  const salary = selectSalary(distributionForm);
  const socialSec = selectSocialSecurity(distributionForm);
  const medicare = selectMedicare(distributionForm);
  return {
    employee_mc: medicare,
    employer_mc: medicare,
    employee_ss: socialSec,
    employer_ss: socialSec,
    federal_income_tax: selectFederalIncomeTax(distributionForm),
    gross_payroll: selectGrossPayroll(distributionForm),
    net_payroll: salary,
    net_payroll_dist: salary,
    payroll_disbursement: selectPayrollDisbursement(distributionForm),
    total_payroll: selectTotalPayroll(distributionForm),
  };
};

export const roundDecimal = number => Math.round(number * 100) / 100;

/**
 * Serializes advance requests for distribution form submission
 *
 * @param distributionForm:   distribution form state
 * @returns {unknown[]}
 */
const serializeAdvanceReqs = distributionForm => {
  const { advanceRequests, holdAccounts } = distributionForm;
  return Object.values(advanceRequests || {}).map(x => {
    const { id: requestId, approved, hold_account_id: holdAcctId, amount_approved } = x;
    const ret = { requestId, type: 'general fund', approved: 0, holdAccountWithdrawalApproved: 0 };
    // general fund advance req
    if (!holdAcctId) {
      if (!approved) return ret;
      else return { ...ret, approved: amount_approved };
    }
    // hold account advance req. find the withdrawal transaction for this request
    const holdAcct = (holdAccounts || {})[holdAcctId] || {},
      { withdrawals = [] } = holdAcct || {},
      transaction = (withdrawals || []).find(x => x.advance_request_id === requestId) || {},
      { amount_approved: transAmountApproved, approved: transApproved } = transaction || {};
    ret.type = 'hold account';
    if (!transApproved) return ret;
    return { ...ret, holdAccountWithdrawalApproved: (transAmountApproved || 0) };
  })
};

/**
 * Serializes expense reports for distribution form submission
 *
 * @param distributionForm:   distribution form state
 * @returns {{reportId: *, unreimbursedAmount: *, holdAccountWithdrawals: *, amountReimbursed: *}[]}
 */
const serializeExpReports = distributionForm => {
  const { processedExpenseReports = [] } = distributionForm;
  return (processedExpenseReports || []).map(x => {
    const { reportId, amountReimbursed, holdAccountWithdrawals, unreimbursedAmount } = x;
    return { reportId, amountReimbursed, holdAccountWithdrawals, unreimbursedAmount };
  });
};

/**
 * Serializes hold accounts for distribution form submission
 *
 * @param distributionForm:   distribution form state
 * @returns {{balance: *, id: *, transactions: *, unreimbursed_amount: *}[]}
 */
const serializeHoldAccounts = distributionForm => {
  const { holdAccounts = {} } = distributionForm;
  return Object.values(holdAccounts || {}).map(acct => {
    const { transfers = [], withdrawals = [], deposits = [], unreimbursed_amount, id: holdAcctId, startingBalance, startingUnreimbursedAmount, reimbursedAmount, endingBalance } = acct;
    const transactions = [
      ...(transfers || []),
      ...(withdrawals || []),
      ...(deposits || [])
    ].reduce((acc, transaction) => {
      const {
        amount,
        amount_approved,
        approved,
        recurring,
        description,
        type,
        accountId,
        expense_id,
        added_unreimbursed_amount,
        advance_request_id,
        id
      } = transaction;
      if ((recurring || advance_request_id) && !approved) return acc;
      return [
        ...acc,
        {
          transaction_type: titleCase(type),
          transaction_amount: amount,
          description,
          amount_approved,
          expense_id,
          added_unreimbursed_amount,
          hold_account_id: accountId,
          starting_balance: startingBalance,
          ending_balance: endingBalance,
          starting_unreimbursed_amount: startingUnreimbursedAmount,
          ending_unreimbursed_amount: unreimbursed_amount,
          id: id && typeof id === 'number' ? id : null,
          DBid: id && typeof id === 'number' ? id : null,
        }
      ]
    }, []);

    if (typeof reimbursedAmount === 'number' && reimbursedAmount) {
      transactions.push({
        transaction_type: 'Reimbursement',
        transaction_amount: reimbursedAmount,
        description: 'Unreimbursed Reimbursement',
        amount_approved: reimbursedAmount,
        expense_id: null,
        added_unreimbursed_amount: null,
        hold_account_id: holdAcctId,
        starting_balance: startingBalance,
        ending_balance: endingBalance,
        starting_unreimbursed_amount: startingUnreimbursedAmount,
        ending_unreimbursed_amount: unreimbursed_amount,
        id: null,
        DBid: null,
      });
    }

    return {
      id: holdAcctId,
      transactions,
      unreimbursed_amount,
      balance: holdAccountEndingBalance(acct),
    }
  });
};
