import {getAdvanceRequestsByUserRequest} from '../api/advance-requests';

import{
   GET_ALL_ADVANCE_REQUESTS_BY_USER,
   GET_ALL_ADVANCE_REQUESTS_BY_USER_SUCCESS,
   GET_ALL_ADVANCE_REQUESTS_BY_USER_FAILURE
} from './types';


function getAllAdvanceRequestsByUser(){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_BY_USER
    }
}

function getAllAdvanceRequestsByUserSuccess(json){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_BY_USER_SUCCESS,
        payload: json
    }
}

function getAllAdvanceRequestsByUserFailure(error){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_BY_USER_FAILURE,
        payload: error
    }
}

export default function requestGetAllAdvanceRequestsByUser(id, token) {
    return dispatch => {
        dispatch(getAllAdvanceRequestsByUser());
        return getAdvanceRequestsByUserRequest(id, token)
            .then((json) => {
                return dispatch(getAllAdvanceRequestsByUserSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllAdvanceRequestsByUserFailure(errors));
                })
            });
    };
}
