import {resetPasswordRequest} from '../api/users';

import{
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from '../actions/types';

function resetPassword() {
    return {
        type: RESET_PASSWORD
    };
}

function resetPasswordSuccess(json) {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: json
    };
}

function resetPasswordFailure(error) {
    return {
        type: RESET_PASSWORD_FAILURE,
        payload: error
    };
}

export default function requestPasswordReset(formData) {
    return dispatch => {
        dispatch(resetPassword());
        return resetPasswordRequest(formData)
            .then((json) => {
                return dispatch(resetPasswordSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(resetPasswordFailure(errors));
                })
            });
    };
}
